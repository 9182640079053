import React from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { PaymentElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js'
import clsx from 'clsx'
import size from 'lodash/size'

import { beautifulAmount, usDate } from '../../utils/functions'
import { invalidateQueries, useGet, useCreate } from '../../hooks/useNewAPI'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'

import Alert from '../../components/Alert'
import AmountInput from '../../components/Forms/AmountInput'
import Button from '../../components/Button'
import ButtonGroup from '../../components/ButtonGroup'
import Card from '../../components/Card'
import Glyph from '../../components/Glyph'
import Label from '../../components/Label'
import Overlay from '../../components/Overlay'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import State from '../../components/State'
import SummonOverlay from '../../components/SummonOverlay'

import { InvoiceStatus } from './InvoiceStatus'
import AddPaymentMethodOverlay from '../PaymentMethods/AddPaymentMethodOverlay'
import { PaymentMethodIcon, PaymentMethodTitle } from '../BillingElements/PaymentMethodElements'

const stripePromise = loadStripe(process.env.BH_STRIPE_BEHAVE_PUBLIC_KEY)

export const InvoicePayOverlay = (props: any) => {
  const { isPublic } = props

  const { tenant, isEHRApp } = useSettings()

  const [amount, setAmount] = React.useState()
  const [paymentType, setPaymentType] = React.useState('full')
  const [paymentMethodId, setPaymentMethodId] = React.useState(props?.paymentMethodId || null)
  const [isSuccess, setIsSuccess] = React.useState(false)

  const { close, data, isOverlayLoading } = useOverlay({
    name: 'invoices',
    endpoint: props.isPublic ? '/apps/invoices' : '/invoices',
    options: props,
  })

  const [paymentMethod, setPaymentMethod] = React.useState('card')

  const shouldPassFees = !!data?.pass_fee_to_payer

  const totalAmount = data?.calculated_amounts?.total || 0
  const cardTotalAmount = data?.calculated_amounts?.card_total
  const achTotalAmount = data?.calculated_amounts?.ach_total

  const cardFees = data?.calculated_amounts?.card_fees
  const achFees = data?.calculated_amounts?.ach_fees

  const finalAmount = shouldPassFees ? (paymentMethod === 'card' ? cardTotalAmount : achTotalAmount) : totalAmount
  const finalFees = shouldPassFees ? (paymentMethod === 'card' ? cardFees : achFees) : 0

  const allowPartialCollection = false // data?.use_partial_collection
  const usePaymentMethods = isEHRApp && tenant && tenant?.stripe_customer_id && !isPublic

  const activePaymentMethodTypes = React.useMemo(() => {
    let types = []

    if (data.active_payment_method_types?.card === true) types.push('card')
    if (data.active_payment_method_types?.us_bank_account === true) types.push('us_bank_account')

    return types
  }, [data.active_payment_method_types])

  React.useEffect(() => {
    if (data?.calculated_amounts?.total) {
      setAmount(data.calculated_amounts.total)
    }
  }, [data])

  React.useEffect(() => {
    if (data?.status === 'paid') {
      setIsSuccess(true)
    }
  }, [data?.status])

  React.useEffect(() => {
    if (paymentType === 'full') {
      setAmount(data?.calculated_amounts?.total)
    }
  }, [paymentType])

  return (
    <Overlay showBackdrop onClose={close} position="center">
      <Overlay.Header title="Pay Invoice" icon="invoices" />

      {isOverlayLoading || !data ? (
        <State isLoading minHeight={160} />
      ) : isSuccess ? (
        <Overlay.Content className="p-5">
          <Card className="py-4 px-4">
            <header className="flex flex-col items-center">
              <Glyph glyph="tick_circle" />
              <h2 className="mt-1.5">Payment Successful</h2>
              <p className="text-text-muted opacity-80 mt-2">Thank you, you will receive a receipt via email.</p>
            </header>
          </Card>
        </Overlay.Content>
      ) : (
        <Overlay.Content className="p-5">
          <Card className="py-4 px-4">
            {paymentType === 'full' ? (
              <FullPayment
                allowPartialCollection={allowPartialCollection}
                amount={finalAmount ? parseFloat(finalAmount) : 0}
                data={data}
                isPublic={isPublic}
                activePaymentMethodTypes={activePaymentMethodTypes}
                paymentMethodId={paymentMethodId}
                paymentType={paymentType}
                setPaymentMethodId={setPaymentMethodId}
                setPaymentType={setPaymentType}
                usePaymentMethods={usePaymentMethods}
                isSuccess={isSuccess}
                setIsSuccess={setIsSuccess}
                shouldPassFees={shouldPassFees}
                fees={finalFees}
                setPaymentMethod={setPaymentMethod}
              />
            ) : (
              <PartialPayment
                allowPartialCollection={allowPartialCollection}
                amount={finalAmount ? parseFloat(finalAmount) : 0}
                data={data}
                isPublic={isPublic}
                activePaymentMethodTypes={activePaymentMethodTypes}
                paymentMethodId={paymentMethodId}
                paymentType={paymentType}
                setAmount={setAmount}
                setPaymentMethodId={setPaymentMethodId}
                setPaymentType={setPaymentType}
                usePaymentMethods={usePaymentMethods}
                isSuccess={isSuccess}
                setIsSuccess={setIsSuccess}
                shouldPassFees={shouldPassFees}
                fees={finalFees}
                setPaymentMethod={setPaymentMethod}
              />
            )}
          </Card>
        </Overlay.Content>
      )}
    </Overlay>
  )
}

const PaymentTypeToggle = ({ allowPartialCollection, setPaymentType, paymentType }: any) => {
  if (!allowPartialCollection) return null

  return (
    <div className="flex justify-center mb-6">
      <ButtonGroup>
        <Button
          label="Pay in Full"
          color="text"
          size={200}
          onClick={() => setPaymentType('full')}
          type={paymentType === 'full' ? 'primary' : 'default'}
          glyph={paymentType === 'full' && 'tick_circle'}
        />
        <Button
          label="Pay Partially"
          color="text"
          size={200}
          onClick={() => setPaymentType('partial')}
          type={paymentType === 'partial' ? 'primary' : 'default'}
          glyph={paymentType === 'partial' && 'tick_circle'}
        />
      </ButtonGroup>
    </div>
  )
}

const PaymentInfo = ({ data, amount }: any) => {
  if (!data) return null

  return (
    <header className="flex flex-col items-center mt-1 mb-5">
      <InvoiceStatus status={data?.status} className="mr-2" />
      <h1 className="mt-2">{beautifulAmount(amount)}</h1>
      <div className="text-text-muted opacity-80 text-[0.95rem] uppercase font-[700] tracking-[0.5px] mt-1">Total Due</div>
      {data?.due_date && <div className="text-text-muted opacity-80 mt-1">{usDate(data?.due_date)}</div>}
    </header>
  )
}

const FullPayment = (props: any) => {
  const {
    amount,
    data,
    isPublic,
    paymentMethodId,
    setPaymentMethodId,
    usePaymentMethods,
    activePaymentMethodTypes,
    allowPartialCollection,
    setPaymentType,
    paymentType,
    isSuccess,
    setIsSuccess,
    shouldPassFees,
    fees,
    setPaymentMethod,
  } = props

  return (
    <>
      <PaymentInfo data={data} amount={amount} shouldPassFee={shouldPassFees} fee={fees} />

      <PaymentTypeToggle allowPartialCollection={allowPartialCollection} setPaymentType={setPaymentType} paymentType={paymentType} />

      {usePaymentMethods ? (
        <PaymentMethods
          data={data}
          amount={amount}
          paymentMethodId={paymentMethodId}
          activePaymentMethodTypes={activePaymentMethodTypes}
          setPaymentMethodId={setPaymentMethodId}
          isPublic={isPublic}
          isSuccess={isSuccess}
          setIsSuccess={setIsSuccess}
          shouldPassFees={shouldPassFees}
          fees={fees}
          setPaymentMethod={setPaymentMethod}
        />
      ) : amount > 0 ? (
        <PaymentForm
          amount={amount}
          data={data}
          isPublic={isPublic}
          isSuccess={isSuccess}
          setIsSuccess={setIsSuccess}
          shouldPassFees={shouldPassFees}
          activePaymentMethodTypes={activePaymentMethodTypes}
          fees={fees}
          setPaymentMethod={setPaymentMethod}
        />
      ) : (
        <Alert>The total amount to pay must be greater than zero.</Alert>
      )}
    </>
  )
}

const PartialPayment = (props: any) => {
  const {
    amount,
    data,
    isPublic,
    setAmount,
    paymentMethodId,
    setPaymentMethodId,
    usePaymentMethods,
    allowPartialCollection,
    setPaymentType,
    paymentType,
    shouldPassFees,
    fees,
  } = props

  const [showPaymentForm, setShowPaymentForm] = React.useState(false)

  if (showPaymentForm) {
    return (
      <div>
        <Button
          label="← Back"
          onClick={() => setShowPaymentForm(false)}
          display="inline-flex"
          type="minimal"
          size={200}
          className="-ml-2 -mt-4"
        />

        <header className="flex flex-col items-center -mt-2 mb-8">
          <div className="text-text-muted opacity-80 text-[0.88rem] uppercase font-[600] tracking-[0.5px] mt-3">Amount to Pay</div>
          <h1 className="mb-1">{beautifulAmount(amount)}</h1>
        </header>

        <PaymentForm amount={amount} data={data} isPublic={isPublic} />
      </div>
    )
  }

  return (
    <div>
      <PaymentInfo data={data} amount={amount} shouldPassFee={shouldPassFees} fee={fees} />

      <PaymentTypeToggle allowPartialCollection={allowPartialCollection} setPaymentType={setPaymentType} paymentType={paymentType} />

      <div className={clsx('flex mt-4 mb-2', !usePaymentMethods && 'justify-center')}>
        <AmountInput isEditable withHover={false} label="Amount to Pay" value={amount} onUpdate={({ value }) => setAmount(value)} />
      </div>

      {usePaymentMethods ? (
        <PaymentMethods
          data={data}
          amount={amount}
          paymentMethodId={paymentMethodId}
          setPaymentMethodId={setPaymentMethodId}
          isPublic={isPublic}
        />
      ) : (
        <Button
          label="Continue to Payment →"
          onClick={() => setShowPaymentForm(true)}
          type="primary"
          className="mt-4"
          isDisabled={!amount}
        />
      )}
    </div>
  )
}

const PaymentMethods = (props: any) => {
  const {
    amount,
    data,
    paymentMethodId,
    setPaymentMethodId,
    isPublic,
    setIsSuccess,
    shouldPassFees,
    fees,
    setPaymentMethod,
    activePaymentMethodTypes,
  } = props

  const { isEHRApp, tenant, timezone } = useSettings()

  const { mutateAsync: payInvoice, isLoading: isPayingInvoice } = useCreate({
    name: ['pay-quote'],
    url: isPublic ? `/apps/invoices/${data?.external_id}/pay` : `/invoices/${data?.id}/pay`,
  })

  const { data: paymentMethods, isLoading: isLoadingPaymentMethods }: any = useGet({
    name: ['internal_billing', 'payment_methods', tenant?.id],
    url: `/facilities/${tenant?.id}/global_payment_methods?category=internal_billing`,
    options: { enabled: !!isEHRApp && !!tenant?.id },
  })

  const handlePay = async () => {
    try {
      await payInvoice({ payment_method_id: paymentMethodId })
      setIsSuccess(true)
      invalidateQueries(null, 'invoices')
    } catch (error) {
      console.error(error)
    }
  }

  React.useEffect(() => {
    if (!!paymentMethodId) return

    const defaultPaymentMethod = paymentMethods?.find((o) => !!o.is_default)

    if (defaultPaymentMethod?.id) {
      setPaymentMethodId(defaultPaymentMethod?.id)
    }
  }, [paymentMethodId, paymentMethods])

  const isPaymentMethodsEmpty = size(paymentMethods) === 0

  const addAction = (
    <SummonOverlay
      overlay={
        <AddPaymentMethodOverlay
          category="internal_billing"
          tenant={tenant?.subdomain}
          name={name}
          facilityId={tenant?.id}
          activePaymentMethodTypes={activePaymentMethodTypes}
          serviceCustomerId={tenant?.stripe_customer_id}
          recordId={tenant?.id}
          recordType={tenant?.type}
          data={{
            company_name: tenant.name,
            name: `${tenant.first_name} ${tenant.last_name}`,
            email: tenant.email,
            phone_no: tenant.phone_no,
          }}
          onSuccess={() => {
            invalidateQueries(['internal_billing', 'payment_methods'])
          }}
        />
      }
    >
      <Button
        label="Add Payment Method"
        glyph="add"
        size={isPaymentMethodsEmpty ? 200 : 100}
        type={isPaymentMethodsEmpty ? 'primary' : 'default'}
      />
    </SummonOverlay>
  )

  if (isLoadingPaymentMethods || isPaymentMethodsEmpty) {
    return (
      <State
        isLoading={isLoadingPaymentMethods}
        isEmpty={isPaymentMethodsEmpty}
        title="Payment Methods"
        icon="billing"
        minHeight={160}
        emptyDescription="No payment methods added yet"
        emptyActions={addAction}
      />
    )
  }

  return (
    <>
      <Label label="Payment Method" after={addAction} />

      <RadioGroup
        isEditable
        withHover={false}
        value={paymentMethodId}
        defaultValue={paymentMethodId || paymentMethods?.[0]?.id}
        onUpdate={({ value }) => {
          const method = paymentMethods?.find?.((o: any) => o.id === value)

          if (method?.payment_method_type) {
            setPaymentMethod(method.payment_method_type)
          }

          setPaymentMethodId(value)
        }}
      >
        {paymentMethods.map((method: any) => {
          if (method.payment_method_type === 'card') {
            return (
              <Radio
                isEditable
                key={method.id}
                label={<PaymentMethodTitle paymentMethod={method} />}
                description={
                  <div>
                    <b className="font-[600]">Date Added: </b> {usDate(method.created_at, timezone)}
                  </div>
                }
                value={method.id}
                graphic={<PaymentMethodIcon paymentMethod={method} />}
              />
            )
          }

          return (
            <Radio
              isEditable
              key={method.id}
              label={<PaymentMethodTitle paymentMethod={method} />}
              description={
                <div>
                  <b className="font-[600]">Date Added: </b> {usDate(method.created_at, timezone)}
                </div>
              }
              value={method.id}
              graphic={<PaymentMethodIcon paymentMethod={method} />}
            />
          )
        })}
      </RadioGroup>

      <div className="grid gap-1">
        {shouldPassFees && fees && (
          <Alert small glyph="info" className="mt-4">
            Amount displayed includes payment processing fees of {beautifulAmount(fees)}
          </Alert>
        )}

        <Button
          label={`Pay ${beautifulAmount(amount)} to Behave Health`}
          color="green"
          type="primary"
          glyph="tick_circle"
          className="mt-4"
          isDisabled={!amount}
          onClick={handlePay}
          isLoading={isPayingInvoice}
        />
      </div>
    </>
  )
}

const PaymentForm = (props: any) => {
  const {
    amount,
    data,
    isPublic,
    setIsSuccess,
    shouldPassFees,
    fees,
    setPaymentMethod,
    activePaymentMethodTypes = ['card', 'us_bank_account'],
  } = props

  const options = React.useMemo(() => {
    return {
      mode: 'payment',
      amount: parseFloat(amount) * 100,
      currency: 'usd',
      setupFutureUsage: 'off_session',
      captureMethod: 'automatic',
      appearance: {},
      paymentMethodTypes: activePaymentMethodTypes,
    }
  }, [amount])

  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm
        data={data}
        isPublic={isPublic}
        amount={amount}
        setIsSuccess={setIsSuccess}
        shouldPassFees={shouldPassFees}
        fees={fees}
        setPaymentMethod={setPaymentMethod}
      />
    </Elements>
  )
}

const CheckoutForm = (props: any) => {
  const { data, isPublic, amount, setIsSuccess, shouldPassFees, fees, setPaymentMethod } = props

  const stripe = useStripe()
  const elements = useElements()

  const [errorMessage, setErrorMessage] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(false)
  const [isPaymentElementComplete, setIsPaymentElementComplete] = React.useState(false)

  const { mutateAsync: payInvoice, isLoading: isPayingQuote } = useCreate({
    name: ['pay-quote'],
    url: isPublic ? `/apps/invoices/${data?.external_id}/pay` : `/invoices/${data?.id}/pay`,
  })

  const handlePaymentElementChange = (event: any) => {
    if (event.value?.type) setPaymentMethod(event.value.type)
    setIsPaymentElementComplete(event.complete && !event.error)
  }

  const handleServerResponse = async (response: any) => {
    if (response.error) {
      // Show error from server on payment form
    } else if (response.status === 'requires_action') {
      // Use Stripe.js to handle the required next action
      const { error, paymentIntent } = await stripe.handleNextAction({
        clientSecret: response.clientSecret,
      })

      if (error) {
        // Show error from Stripe.js in payment form
      } else {
        // Actions handled, show success message
      }
    } else {
      // No actions needed, show success message
    }
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault()

    if (elements == null) return

    setIsLoading(true)

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit()
    if (submitError) {
      // Show error to your customer
      setErrorMessage(submitError.message)
      return
    }

    const { error: confirmationTokenError, confirmationToken } = await stripe.createConfirmationToken({
      elements,
      params: {
        payment_method_data: {
          billing_details: {
            name: data.signing_name,
          },
        },
      },
    })

    if (confirmationTokenError) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message)
      setIsLoading(false)
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.

      // SEND REQUEST TO API TO CONFIRM PAYMENT
      try {
        // run
        const payInvoiceResult = await payInvoice({ confirmation_token: confirmationToken.id })

        handleServerResponse(payInvoiceResult)

        setIsSuccess(true)
      } catch (error) {
        setIsLoading(false)
      } finally {
        setIsLoading(false)
        invalidateQueries(null, 'invoices')
      }
    }
  }

  React.useEffect(() => {
    elements?.update({ amount: amount * 100 })
  }, [elements, amount])

  return (
    <>
      <PaymentElement
        options={{
          layout: 'tabs',
          business: { name: 'Behave Health Corp.' },
          fields: {
            billingDetails: {
              name: 'auto',
            },
          },
          defaultValues: {
            billingDetails: {
              name: data.signing_name,
              email: data.billed_to_email,
              phone: data.billed_to_phone_no,
            },
          },
        }}
        onChange={handlePaymentElementChange}
      />

      {data?.payment_legal_language_text && (
        <Alert small contrast type="warning" glyph="info" className="mt-4">
          {data.parsed_payment_legal_language_text || data.payment_legal_language_text}
        </Alert>
      )}

      {errorMessage && <Alert glyph="error">{errorMessage}</Alert>}

      <div className="grid gap-1">
        {shouldPassFees && fees && (
          <Alert small glyph="info" className="mt-4">
            Amount displayed includes payment processing fees of {beautifulAmount(fees)}
          </Alert>
        )}

        <Button
          label={`Pay ${beautifulAmount(amount)} to Behave Health`}
          color="green"
          type="primary"
          glyph="tick_circle"
          className="mt-4"
          onClick={handleSubmit}
          isDisabled={!isPaymentElementComplete}
          isLoading={isLoading}
        />
      </div>
    </>
  )
}

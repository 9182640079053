import React from 'react'
import { useSelector } from 'react-redux'

import Button from '../../components/Button'
import Grid from '../../components/Grid'
import Overlay from '../../components/Overlay'
import Section from '../../components/Section'
import NoInternet from '../../components/Alerts/NoInternet'
import HelpTag from '../../components/HelpTag'

import Form from '../../components/Forms/Form'
import Radio from '../../components/Forms/Radio'
import Textarea from '../../components/Forms/Textarea'
import RadioGroup from '../../components/Forms/RadioGroup'
import FormSection from '../../components/Forms/FormSection'
import DateTimeInput from '../../components/Forms/DateTimeInput'

import withSettings from '../../hocs/withSettings'

import { currentAdmission } from '../../reducers/selectors'
import { apiGet, apiUpdate } from '../../modules/api'
import { withOverlayError } from '../../hocs/withOverlayError'

const RootIntakeEdit = ({ location, history, online, timezone }: any) => {
  const admission = useSelector(currentAdmission)
  const loading = useSelector((state) => state.data.admissions?.loading)
  const form = React.useRef()

  if (!admission) return null

  const close = () => {
    if (location.parent) {
      if (location.parent.url) history.push(location.parent.url)
      else history.push(location.parent)
    } else {
      const path = location.pathname
      history.push(path.substr(0, path.lastIndexOf('/')))
    }
  }

  const update = async () => {
    await apiUpdate({
      name: 'admissions',
      url: `/admissions/${admission.id}`,
      params: form.current.getFormValue(),
    })
    apiGet({ name: 'clients', url: `/residents/${admission.customer.id}` })
    close()
  }

  return (
    <Overlay position="center" showBackdrop={true} maxWidth={50}>
      <Overlay.Header icon="intake_forms" title="Edit Episode" help={<HelpTag articleId="5dccb4c02c7d3a7e9ae3ed1a" />} />
      <Overlay.Content>
        <Form useLocalForm getForm={form} timezone={timezone} initialModel={admission}>
          {(data) => (
            <>
              <Section>
                <FormSection layout="vertical" maxWidth={700}>
                  {['pending', 'incoming', 'waitlist', 'no_show', 'declined'].includes(admission.status) && (
                    <RadioGroup label="Treatment Episode Admission Status" model="status" layout="vertical-dense">
                      <Radio label="Pending" description="No decision to be made yet" value="pending" />
                      <Radio label="Incoming" description="Client is expected to move in soon" value="incoming" />
                      <Radio label="Waitlist" description="Client is approved but there are no vacant beds" value="waitlist" />
                      <Radio label="Planned" description="Client is approved but unable to admit yet" value="planned" />
                    </RadioGroup>
                  )}

                  {/* BEFORE ADMITTION */}
                  {admission.status === 'declined' && <Textarea useQuickText label="Decline Reason" model="decline_reason" />}
                  {admission.status === 'no_show' && <Textarea useQuickText label="No Show Notes" model="no_show_notes" />}

                  {['pending', 'incoming', 'waitlist'].includes(admission.status) && (
                    <Grid columns="1fr" gap={1}>
                      <DateTimeInput label="Estimated Arrival Date" model="estimated_arrival" />
                      <DateTimeInput label="Estimated Discharge Date" model="estimated_discharge" />
                    </Grid>
                  )}

                  {/* ADMITTED */}
                  {admission.status === 'admitted' && (
                    <Grid columns="1fr" gap={1}>
                      <DateTimeInput label="Admit Date" model="admitted_at" />
                      <DateTimeInput defaultToNow label="Estimated Discharge Date" model="estimated_discharge" />
                    </Grid>
                  )}

                  {/* ON DISCHARGE */}
                  {admission.status === 'discharged' && (
                    <Grid columns="1fr" gap={1}>
                      <DateTimeInput label="Discharge Date" model="discharged_at" />
                    </Grid>
                  )}

                  {admission.status === 'discharged' && <Textarea useQuickText label="Discharge Notes" model="discharge_notes" />}

                  <Textarea useQuickText label="Admission Status Notes" model="notes" />
                </FormSection>
              </Section>
            </>
          )}
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {online && (
          <>
            {admission.status === 'pending' && (
              <Button label="Save as Pending" type="primary" color="green" onClick={update} isLoading={loading} />
            )}

            {admission.status === 'incoming' && (
              <Button label="Save as Incoming" type="primary" color="green" onClick={update} isLoading={loading} />
            )}

            {admission.status === 'waitlist' && (
              <Button label="Add Client to Waitlist" type="primary" color="green" onClick={update} isLoading={loading} />
            )}

            {admission.status === 'no_show' && (
              <Button label="Save as No Show" type="default" color="red" onClick={update} isLoading={loading} />
            )}

            {admission.status === 'declined' && (
              <Button label="Decline Intake" type="default" color="red" onClick={update} isLoading={loading} />
            )}

            {admission.status === 'admitted' && (
              <Button label="Update Episode" type="primary" color="green" onClick={update} isLoading={loading} />
            )}

            {admission.status === 'discharged' && (
              <Button label="Update Episode" type="primary" color="green" onClick={update} isLoading={loading} />
            )}
          </>
        )}
        {!online && <NoInternet />}
      </Overlay.Footer>
    </Overlay>
  )
}

export const IntakeEdit = withOverlayError(withSettings(RootIntakeEdit))

import React from 'react'

import Flex from '../../components/Flex'
import FinancialCard from '../../components/Cards/FinancialCard'

export const InvoiceLinesFinancialCards = ({ data = {} }: any) => (
  <div>
    <Flex gap="1rem" stretchChildrenX>
      <FinancialCard cypress="stats_current_balance" title="Current Balance" value={data.balance} valueColor="green" />
      <FinancialCard cypress="stats_total_charges" title="Charged Amount" value={data.charges} valueColor="red" prefix="-" />
      <FinancialCard
        cypress="stats_total_successful_payments"
        title="Collected Payments"
        value={data.collected_payments}
        valueColor="green"
        prefix="+"
      />
      <FinancialCard title="Write-Off's" value={data.write_offs} valueColor="yellow" prefix="+" />

      <div className="full-h max-w-[1px] bg-divider !mx-2" />

      <FinancialCard
        cypress="stats_total_successful_payments"
        title="Processing Payments"
        value={data.processing_payments}
        valueColor="textMuted"
      />
      <FinancialCard title="Discounted Amount" value={data.discounts} valueColor="textMuted" />
      {data.pass_fee_to_payer && <FinancialCard title="Processing Fees" value={data.processing_fees} valueColor="textMuted" prefix=" " />}
    </Flex>
  </div>
)

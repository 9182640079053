import React from 'react'
import { NavLink, useParams } from 'react-router-dom-v5-compat'

import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { VitalSignsDataTable } from '@behavehealth/constructs/TestResults/VitalSignsDataTable'
import withPermissions from '@behavehealth/hocs/withPermissions'

import Button from '@behavehealth/components/Button'
import Grid from '@behavehealth/components/Grid'

const VitalSigns: React.FC = () => {
  const { resource_id }: any = useParams()

  const tableProps = useDataTable({
    name: ['employees', resource_id, 'vital-signs'],
    endpoint: `/employees/${resource_id}/vital_signs`,
    updateDeleteEndpoint: '/vital_signs',
    enabled: !!resource_id,
    localStorageKey: 'vital_signs_v1',
  })

  return (
    <Grid gap="1rem" columns="100%">
      <VitalSignsDataTable
        {...tableProps}
        to={(row: any) => row.id}
        headerLinksConfig={[
          {
            type: 'report',
            to: '/reports/vital-signs',
          },
        ]}
        emptyActions={
          <Button as={NavLink} label="Add Vital Signs" type="primary" glyph="add" link="new" permission="test_results.vital_signs.create" />
        }
        batchActionsConfig={[
          {
            type: 'delete',
            permission: 'test_results.vital_signs.delete',
            action: async ({ ids }: any) => {
              await tableProps.deleteRecords(ids.join(','))
            },
          },
        ]}
      />
    </Grid>
  )
}

export default withPermissions(VitalSigns)

import React from 'react'

import { address } from '../../../utils/functions'
import DataList from '../../../components/DataList'

import { ReviewSection } from './ReviewSection'

export const Parties = ({ data, isOpen }: any) => {
  if (!data) return null

  return (
    <ReviewSection isOpen={isOpen} title="Parties" icon="outreach" sectionModel="parties" data={data}>
      <div className="grid grid-cols-1 mq1600:grid-cols-2 gap-6">
        <DataList>
          <DataList.Title title="From" />
          <DataList.Item label="Company" value={data.billed_by_company_name} />
          <DataList.Item label="Name" value={data.billed_by_name} />
          <DataList.Item
            label="Email"
            value={data.billed_by_email ? <a href={`mailto:${data.billed_by_email}`}>{data.billed_by_email}</a> : null}
          />
          <DataList.Item label="Phone" value={data.billed_by_phone_no} />
          <DataList.Item label="Address" value={address(data.billed_by_address)} />
        </DataList>

        <DataList>
          <DataList.Title title="To" />
          <DataList.Item label="Company" value={data.billed_to_company_name} />
          <DataList.Item label="Name" value={data.billed_to_name} />
          <DataList.Item
            label="Email"
            value={data.billed_to_email ? <a href={`mailto:${data.billed_to_email}`}>{data.billed_to_email}</a> : null}
          />
          <DataList.Item label="Phone" value={data.billed_to_phone_no} />
          <DataList.Item label="Address" value={address(data.billed_to_address)} />
        </DataList>
      </div>
    </ReviewSection>
  )
}

import React from 'react'

import FormSection from '../../components/Forms/FormSection'
import Section from '../../components/Section'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import Checkbox from '../../components/Forms/Checkbox'
import Form from '../../components/Forms/Form'
import Value from '../../components/Value'

import { withFormContext } from '../../components/Forms/context'
import { useFormField } from '../../components/Forms/hooks/useFormField'

export const CATEGORIES = {
  form_inbox: {
    label: 'Form Inboxes',
    graphicType: 'icon',
    graphic: 'web_form',
  },
  applicant: {
    label: 'Applicants',
    graphicType: 'icon',
    graphic: 'applications',
  },
  accepted: {
    label: 'Accepted Admissions',
    graphicType: 'glyph',
    graphic: 'check_in',
  },
  declined: {
    label: 'Declined Admissions',
    graphicType: 'glyph',
    graphic: 'decline',
  },
  current_clients: {
    label: 'Current Clients',
    graphicType: 'icon',
    graphic: 'clients',
  },
  past_clients: {
    label: 'Past Clients',
    graphicType: 'icon',
    graphic: 'alumni_relations',
  },
  location_checkins: {
    label: "Location Check-In's",
    graphicType: 'icon',
    graphic: 'recovery_navigation',
  },
  files: {
    label: 'Files',
    graphicType: 'icon',
    graphic: 'files',
  },

  employees: {
    label: 'Staff',
    graphicType: 'icon',
    graphic: 'employees',
  },
  locations: {
    label: 'Locations',
    graphicType: 'icon',
    graphic: 'properties',
  },
  organizations: {
    label: 'Organizations',
    graphicType: 'icon',
    graphic: 'organizations',
  },
  insurance_payers: {
    label: 'Insurance Payers',
    graphicType: 'icon',
    graphic: 'insurance',
  },
  data_exports: {
    label: 'Data Exports',
    graphicType: 'icon',
    graphic: 'reports',
  },

  treatment_episodes: {
    label: 'Treatment Episodes',
    graphicType: 'icon',
    graphic: 'treatment_data',
  },
  discharges: {
    label: 'Discharges',
    graphicType: 'icon',
    graphic: 'discharge_forms',
  },
  applications: {
    label: 'Applications',
    graphicType: 'icon',
    graphic: 'checklist',
  },
  agreements: {
    label: 'Agreements',
    graphicType: 'icon',
    graphic: 'legal_agreement_alt',
  },
  programs: {
    label: 'Programs',
    graphicType: 'icon',
    graphic: 'checklist',
  },

  contacts: {
    label: 'Contacts',
    graphicType: 'icon',
    graphic: 'contacts',
  },
  communications: {
    label: 'Communications',
    graphicType: 'icon',
    graphic: 'communications',
  },
  care_team: {
    label: 'Care Team',
    graphicType: 'icon',
    graphic: 'connections',
  },

  financial_transactions: {
    label: 'Financials',
    graphicType: 'icon',
    graphic: 'financials',
  },

  insurance_policies: {
    label: 'Insurance Policies',
    graphicType: 'icon',
    graphic: 'insurance',
  },
  evobs: {
    label: 'eVOBs',
    graphicType: 'icon',
    graphic: 'insurance',
  },
  vob_summaries: {
    label: 'Full VOB Summaries',
    graphicType: 'icon',
    graphic: 'insurance',
  },

  shift_notes: {
    label: 'Shift Notes',
    graphicType: 'icon',
    graphic: 'housing_notes',
  },
  recovery_notes: {
    label: 'Recovery Notes',
    graphicType: 'icon',
    graphic: 'recovery_coaching',
  },
  custom_notes: {
    label: 'Custom Notes',
    graphicType: 'icon',
    graphic: 'clinical_assessments',
  },
  peer_notes: {
    label: 'Peer Notes',
    graphicType: 'icon',
    graphic: 'peer_notes',
  },

  clinical_assessments: {
    label: 'Clinical Assessments',
    graphicType: 'icon',
    graphic: 'clinical_assessments',
  },
  treatment_plans: {
    label: 'Treatment Plans',
    graphicType: 'icon',
    graphic: 'treatment_plans',
  },
  progress_reviews: {
    label: 'Progress Reviews',
    graphicType: 'icon',
    graphic: 'progress_reviews',
  },
  clinical_notes: {
    label: 'Clinical Notes',
    graphicType: 'icon',
    graphic: 'clinical_notes',
  },
  clinical_measures: {
    label: 'Clinical Measures',
    graphicType: 'icon',
    graphic: 'patient_progress_measure',
  },
  outcome_measures: {
    label: 'Outcome Measures',
    graphicType: 'icon',
    graphic: 'outcome_measures',
  },

  allergies: {
    label: 'Allergies',
    graphicType: 'icon',
    graphic: 'treatment_data',
  },
  ua_tests: {
    label: 'UA Tests',
    graphicType: 'icon',
    graphic: 'test_results',
  },
  breathalyzer_tests: {
    label: 'Breathalyzer Tests',
    graphicType: 'icon',
    graphic: 'test_results',
  },
  vital_signs: {
    label: 'Vital Signs',
    graphicType: 'icon',
    graphic: 'test_results',
  },
  communicable_disease_tests: {
    label: 'Communicable Disease Tests',
    graphicType: 'icon',
    graphic: 'test_results',
  },
  labs: {
    label: 'Labs',
    graphicType: 'icon',
    graphic: 'test_results',
  },
  medications: {
    label: 'Medications',
    graphicType: 'icon',
    graphic: 'medication',
  },
  medication_logs: {
    label: 'Medication Logs',
    graphicType: 'icon',
    graphic: 'med_pass',
  },
  medication_destruction_logs: {
    label: 'Medication Destruction Logs',
    graphicType: 'icon',
    graphic: 'med_pass',
  },
  medical_assessments: {
    label: 'Medical Assessments',
    graphicType: 'icon',
    graphic: 'medical_assessments',
  },
  physician_orders: {
    label: 'Physician Orders',
    graphicType: 'icon',
    graphic: 'physician_orders',
  },
  nursing_assessments: {
    label: 'Nursing Assessments',
    graphicType: 'icon',
    graphic: 'nursing',
  },
  claims: {
    label: 'Claims',
    graphicType: 'icon',
    graphic: 'outbox',
  },
  roster: {
    label: 'Roster',
    graphicType: 'icon',
    graphic: 'roster',
  },
}

const TagSmartCategorySelector = ({ form, model, isEditable, value }: any) => {
  const [data, setData] = React.useState({})

  const { formActions } = useFormField({
    model: model,
    form: form,
  })

  React.useEffect(() => {
    if (!form) return
    if (!value) return

    let finalValue = {}
    value.map((o: any) => (finalValue[o] = true))

    if (finalValue) setData(finalValue)
  }, [value])

  React.useEffect(() => {
    if (form && data) {
      formActions.setValue(Object.keys(data).filter((o) => data[o]))
    }
  }, [form, data])

  return (
    <Section
      title="Smart Categories"
      description={isEditable && 'Select the categories you would like to limit tags to'}
      paddingLeft="0"
      paddingRight="0"
    >
      <FormSection>
        {isEditable ? (
          <Form
            initialModel={data}
            onUpdate={(formData: any) => {
              setData(formData)
            }}
          >
            <FormSection>
              <CheckboxGroup withToggle label="General" layout="vertical-dense" trueIcon="check" falseStyle="hidden" falseIcon="cross">
                <Checkbox icon="web_form" label="Form Inboxes" model="form_inbox" />
                <Checkbox icon="applications" label="Applicants" model="applicant" />
                <Checkbox glyph="check_in" label="Accepted Admissions" model="accepted" />
                <Checkbox glyph="decline" label="Declined Admissions" model="declined" />
                <Checkbox icon="clients" label="Current Clients" model="current_clients" />
                <Checkbox icon="alumni_relations" label="Past Clients" model="past_clients" />
                <Checkbox icon="recovery_navigation" label="Location Check-In's" model="location_checkins" />
                <Checkbox icon="files" label="Files" model="files" />
              </CheckboxGroup>

              <CheckboxGroup withToggle label="Company" layout="vertical-dense" trueIcon="check" falseStyle="hidden" falseIcon="cross">
                <Checkbox icon="employees" label="Staff" model="employees" />
                <Checkbox icon="properties" label="Locations" model="locations" />
                <Checkbox icon="organizations" label="Organizations" model="organizations" />
                <Checkbox icon="insurance" label="Insurance Payers" model="insurance_payers" />
                <Checkbox icon="reports" label="Data Exports" model="data_exports" />
              </CheckboxGroup>

              <CheckboxGroup
                withToggle
                label="Care Coordination"
                layout="vertical-dense"
                trueIcon="check"
                falseStyle="hidden"
                // falseIcon="cross"
              >
                <Checkbox icon="treatment_data" label="Treatment Episodes" model="treatment_episodes" />
                <Checkbox icon="discharge_forms" label="Discharges" model="discharges" />
                <Checkbox icon="checklist" label="Applications" model="applications" />
                <Checkbox icon="legal_agreement_alt" label="Agreements" model="agreements" />
                <Checkbox icon="checklist" label="Programs" model="programs" />
              </CheckboxGroup>

              <CheckboxGroup withToggle label="Contacts" layout="vertical-dense" trueIcon="check" falseStyle="hidden" falseIcon="cross">
                <Checkbox icon="contacts" label="Contacts" model="contacts" />
                <Checkbox icon="communications" label="Communications" model="communications" />
                <Checkbox icon="connections" label="Care Team" model="care_team" />
              </CheckboxGroup>

              <CheckboxGroup withToggle label="Financials" layout="vertical-dense" trueIcon="check" falseStyle="hidden" falseIcon="cross">
                <Checkbox icon="financials" label="Financials" model="financial_transactions" />
              </CheckboxGroup>

              <CheckboxGroup withToggle label="Insurance" layout="vertical-dense" trueIcon="check" falseStyle="hidden" falseIcon="cross">
                <Checkbox icon="insurance" label="Insurance Policies" model="insurance_policies" />
                <Checkbox icon="insurance" label="eVOBs" model="evobs" />
                <Checkbox icon="insurance" label="Full VOB Summaries" model="vob_summaries" />
                <Checkbox icon="outbox" label="Claims" model="claims" />
                <Checkbox icon="roster" label="Roster" model="roster" />
              </CheckboxGroup>

              <CheckboxGroup withToggle label="Notes" layout="vertical-dense" trueIcon="check" falseStyle="hidden" falseIcon="cross">
                <Checkbox icon="housing_notes" label="Shift Notes" model="shift_notes" />
                <Checkbox icon="recovery_coaching" label="Recovery Notes" model="recovery_notes" />
                <Checkbox icon="clinical_assessments" label="Custom Notes" model="custom_notes" />
                <Checkbox icon="peer_notes" label="Peer Notes" model="peer_notes" />
              </CheckboxGroup>

              <CheckboxGroup withToggle label="Clinical" layout="vertical-dense" trueIcon="check" falseStyle="hidden" falseIcon="cross">
                <Checkbox icon="clinical_assessments" label="Clinical Assessments" model="clinical_assessments" />
                <Checkbox icon="treatment_plans" label="Treatment Plans" model="treatment_plans" />
                <Checkbox icon="progress_reviews" label="Progress Reviews" model="progress_reviews" />
                <Checkbox icon="clinical_notes" label="Clinical Notes" model="clinical_notes" />
                <Checkbox icon="patient_progress_measure" label="Clinical Measures" model="clinical_measures" />
                <Checkbox icon="outcome_measures" label="Outcome Measures" model="outcome_measures" />
              </CheckboxGroup>

              <CheckboxGroup withToggle label="Medical" layout="vertical-dense" trueIcon="check" falseStyle="hidden" falseIcon="cross">
                <Checkbox
                  model="allergies"
                  label={CATEGORIES['allergies'].label}
                  {...{ [CATEGORIES['allergies'].graphicType]: CATEGORIES['allergies'].graphic }}
                />
                <Checkbox icon="test_results" label="UA Tests" model="ua_tests" />
                <Checkbox icon="test_results" label="Breathalyzer Tests" model="breathalyzer_tests" />
                <Checkbox icon="test_results" label="Vital Signs" model="vital_signs" />
                <Checkbox icon="test_results" label="Communicable Disease Tests" model="communicable_disease_tests" />
                <Checkbox icon="test_results" label="Labs" model="labs" />
                <Checkbox icon="medication" label="Medications" model="medications" />
                <Checkbox icon="med_pass" label="Medication Logs" model="medication_logs" />
                <Checkbox icon="med_pass" label="Medication Destruction Logs" model="medication_destruction_logs" />
                <Checkbox icon="medical_assessments" label="Medical Assessments" model="medical_assessments" />
                <Checkbox icon="physician_orders" label="Physician Orders" model="physician_orders" />
                <Checkbox icon="nursing" label="Nursing Assessments" model="nursing_assessments" />
              </CheckboxGroup>
            </FormSection>
          </Form>
        ) : (
          <>
            {Object.keys(data)?.map((item: any) => {
              if (data[item] !== true) return

              return <Value key={item} {...{ [CATEGORIES[item].graphicType]: CATEGORIES[item].graphic }} value={CATEGORIES[item].label} />
            })}
          </>
        )}
      </FormSection>
    </Section>
  )
}

export default withFormContext(TagSmartCategorySelector)

import React from 'react'
import * as HoverCard from '@radix-ui/react-hover-card'
import isUndefined from 'lodash/isUndefined'

import { niceAmount, nicePercentage } from '../../utils/functions'
import { BILLING_TYPE, PRICING_STRATEGIES, PRICING_UNIT_TYPES, YEARLY_PRICING_UNIT_TYPES } from '../../components/Billing/constants'

import Icon from '../../components/Icon'
import Status from '../../components/Status'

export const QUOTE_CELLS = {
  product: (product: any) => {
    if (!product || !product?.name) {
      return <span className="text-text-muted">–</span>
    }

    const { name, icon } = product

    return (
      <span className="truncate min-w-[0] max-w-full inline-flex items-center font-[600]">
        {icon && <Icon icon={icon} size={18} className="mr-2" />}

        <HoverCard.Root openDelay={300} closeDelay={50}>
          <HoverCard.Trigger asChild>
            <div className="truncate pr-2">{name}</div>
          </HoverCard.Trigger>

          <HoverCard.Portal container={document?.getElementById?.('portal-radix')}>
            <HoverCard.Content asChild align="start" side="bottom">
              <div className="w-full flex items-center px-3 py-2 max-w-[400px] bg-white shadow-soft-5 rounded-md">
                {icon && <Icon icon={icon} size={18} className="mr-2" />}
                <div className="font-[400]">{name}</div>
              </div>
            </HoverCard.Content>
          </HoverCard.Portal>
        </HoverCard.Root>
      </span>
    )
  },
  billingType: (product: any) => {
    if (!product || !product?.bill_type || !BILLING_TYPE[product.bill_type]) {
      return <span className="text-text-muted">–</span>
    }

    return BILLING_TYPE[product.bill_type]
  },
  amount: (product: any, other: any = {}) => {
    if (!product) return <span className="text-text-muted">–</span>

    const { hasDiscount, discountedPrice } = other

    return (
      <span className="flex flex-wrap">
        {product?.price < 1 ? (
          <span className="font-[600] inline-block mr-1.5">${product.price}</span>
        ) : (
          <span className="font-[600] inline-block mr-1.5 min-w-[110px]">
            {hasDiscount && discountedPrice ? (
              <>
                <span className="line-through text-[0.9em] text-text-muted opacity-80">{niceAmount(product.price)}</span>
                <span className="ml-1.5 text-green-700">{niceAmount(discountedPrice)}</span>
              </>
            ) : (
              niceAmount(product.price)
            )}
          </span>
        )}

        {product?.pricing_type && (
          <span className="text-text-muted">
            {PRICING_STRATEGIES[product.pricing_type]}

            {product?.pricing_type === 'metered_monthly' && product?.price_unit_type && ` / ${PRICING_UNIT_TYPES[product.price_unit_type]}`}

            {product?.pricing_type === 'metered_annually' &&
              product?.price_unit_type &&
              ` / ${YEARLY_PRICING_UNIT_TYPES[product.price_unit_type]}`}
          </span>
        )}
      </span>
    )
  },
  discount: (discount: any) => {
    if (!discount) return <span className="text-text-muted">–</span>

    let info: any = ''

    const { discount_type, percent_off, amount_off } = discount

    if (discount_type === 'percentage') {
      info = `${nicePercentage(percent_off)}% off`
    } else if (discount_type === 'fixed') {
      info = `${niceAmount(amount_off)} off`
    }

    return info
  },
}

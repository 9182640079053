import React from 'react'

import SmartStatus from '@behavehealth/components/SmartStatus'

const statuses = {
  draft: {
    label: 'draft',
    color: 'gray',
  },
  open: {
    label: 'open',
    color: 'blue',
  },
  paid: {
    label: 'paid',
    color: 'green',
  },
  processing: {
    label: 'Processing',
    color: 'orange',
  },
  failed: {
    label: 'Payment Failed',
    color: 'red',
  },
  refunded: {
    label: 'Refunded',
    color: 'orange',
  },
  cancelled: {
    label: 'Cancelled',
    color: 'red',
  },
  written_off: {
    label: 'Written Off',
    color: 'orange',
  },
}

export const InvoiceStatus = ({ ...rest }) => {
  return <SmartStatus statuses={statuses} {...rest} />
}

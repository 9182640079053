import React from 'react'
import { useParams, useRouteMatch } from 'react-router-dom'

import Button from '../../../components/Button'
import Card from '../../../components/Card'
import Overlay from '../../../components/Overlay'
import SummonOverlay from '../../../components/SummonOverlay'

import { ClaimNoteOverlay } from '../components/ClaimNoteOverlay'
import { ClaimNotes } from '../components/ClaimNotes'

export const ClaimOverlayNotes = (props) => {
  const match = useRouteMatch()

  return (
    <Overlay.Content css={{ zIndex: 0 }}>
      <div className="!p-4">
        <Card>
          <ClaimNotes
            claimID={props?.id}
            to={(id: any) => `${match.url}/${id}`}
            titleAfter={
              <SummonOverlay overlay={<ClaimNoteOverlay showBackdrop dataID="new" claimID={props?.id} />}>
                <Button label="Add Claim Note" glyph="add" type="primary" size={100} />
              </SummonOverlay>
            }
          />
        </Card>
      </div>
    </Overlay.Content>
  )
}

import React from 'react'
import startCase from 'lodash/startCase'

import { withFormContext } from '../../../components/Forms/context'
import { encodeObjectToURL } from '../../../utils/functions'

import Button from '../../../components/Button'
import Card from '../../../components/Card'
import CardHeader from '../../../components/CardHeader'
import CardContent from '../../../components/CardContent'
import { ComboBox } from '../../../components/Forms/ComboBoxes/ComboBox'
import DateTimeInput from '../../../components/Forms/DateTimeInput'
import Flex from '../../../components/Flex'
import Grid from '../../../components/Grid'
import Input from '../../../components/Forms/Input'
import Type from '../../../components/Typography/Type'
import QuickView from '../../../components/QuickView'
import PrintQuickView from './PrintQuickView'

import ClaimInternalStatusSelect from './ClaimInternalStatusSelect'
import { ClaimFlagSelect } from './ClaimFlagSelect'

import { URL } from '../../../utils/globals'
import { getToken } from '../../../modules/axios'
import useStore from '../../../modules/store'

import { SectionCard } from '../../../components/SectionCard'

const ClaimGeneralDetails = ({ isNew, claimID, includeObject }: any) => {
  const tenant = useStore((state) => state.tenant)
  const [token, setToken] = React.useState(null)

  const previewURL = `${URL.api}/insurance_claims/${claimID}/pdf?${encodeObjectToURL({
    variant: 'with_bg', // no_bg
    tenant: tenant.subdomain,
    token: token,
  })}`

  const printURL = `${URL.api}/insurance_claims/${claimID}/pdf?${encodeObjectToURL({
    variant: 'no_bg',
    tenant: tenant.subdomain,
    token: token,
  })}`

  React.useEffect(() => {
    const buildToken = async () => {
      const tkn = await getToken()
      setToken(tkn?.token)
    }

    buildToken()
  }, [])

  return (
    <SectionCard
      title="General Details"
      className="mq900:min-w-[420px]"
      aside={
        !isNew && (
          <Flex gap="1rem">
            <QuickView title="Preview PDF Claim" contentType="application/pdf" url={previewURL}>
              <Button useGlyphForTarget={false} size={100} glyph="view" label="Preview PDF" type="link" className="!p-0" />
            </QuickView>

            <PrintQuickView title="Print PDF" url={printURL}>
              <Button useGlyphForTarget={false} size={100} glyph="print" label="Print PDF" type="link" className="!p-0" />
            </PrintQuickView>
          </Flex>
        )
      }
    >
      <Grid gap="1rem">
        {!isNew && <ClaimInternalStatusSelect model="status" />}
        {!isNew && <ClaimFlagSelect label="Flag Status" model="flagged_status" />}

        <ComboBox isPolymorphic includeObject={includeObject} model="assignee" label="Assignee" type="employees" icon="employees" />

        {!isNew && (
          <>
            <DateTimeInput isEditable={false} label="Initial Bill Date" model="initial_submission_at" />
            <DateTimeInput isEditable={false} label="Last Bill Date" model="last_submission_at" />
          </>
        )}
      </Grid>
    </SectionCard>
  )
}

export default withFormContext(ClaimGeneralDetails)

import React from 'react'
import { useDebouncedCallback } from 'use-lodash-debounce'
import size from 'lodash/size'

import { COLORS, INPUT_STYLES } from '../theme'
import useIsMounted from '../hooks/useIsMounted'

import Graphic from './Graphic'
import Loader from './Loader'
import ClearButton from './Forms/ClearButton'

const Search = ({
  after,
  autoFocus = false,
  className,
  debounce = 400,
  glyph = 'search',
  icon,
  isLoading,
  onBlur,
  onChange,
  onClear,
  onFocus,
  placeholder,
  showClearButton = true,
  showGraphic = true,
  showLoader = false,
  graphicWidth = '36px',
  graphicSize = '22px',
  glyphColor,
  value,
  onKeyDown,
  inputHeight,
  getRef,
}: any) => {
  const isMounted = useIsMounted()

  const [isAutoFocus, setIsAutoFocus] = React.useState(autoFocus)
  const [search, setSearch] = React.useState(value)
  const [localSearch, setLocalSearch] = React.useState(value)
  const debouncedSetSearch = useDebouncedCallback(setSearch, debounce)
  const inputRef = React.useRef()

  const hasSearch = size(search) > 0
  const onInputChange = (event) => {
    return setLocalSearch(event?.target?.value)
  }

  React.useEffect(() => {
    if (onChange) onChange(search)
  }, [onChange, search])

  React.useEffect(() => {
    debouncedSetSearch(localSearch)
  }, [localSearch])

  React.useEffect(() => {
    // if (value !== search) setSearch(value)
    if (value !== search) setLocalSearch(value)
  }, [value])

  React.useEffect(() => {
    if (getRef && inputRef.current) getRef(inputRef.current)
  }, [inputRef.current, getRef])

  React.useEffect(() => {
    if (!isMounted || !autoFocus) return
    setIsAutoFocus(autoFocus)
    inputRef.current?.focus?.()
  }, [isMounted, autoFocus])

  const stopPropagation = (event: any) => {
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
  }

  const clearSearch = () => {
    setLocalSearch('')
    if (onClear) onClear()
    if (inputRef.current) inputRef.current.value = ''
  }

  const showClear = !isLoading && hasSearch && showClearButton

  return (
    <div
      css={styles.root}
      className={className}
      onClick={stopPropagation}
      style={{ '--graphic-width': graphicWidth, '--input-min-height': inputHeight }}
    >
      {showGraphic && <Graphic glyph={glyph} glyphColor={glyphColor} icon={icon} size={graphicSize} css={styles.graphic} />}

      <input
        ref={inputRef}
        type="text"
        autoFocus={isAutoFocus}
        placeholder={placeholder}
        onChange={onInputChange}
        onFocus={onFocus}
        onBlur={onBlur}
        value={localSearch}
        onKeyDown={onKeyDown}
        css={{
          ...styles.input,
          ...(showGraphic && { paddingLeft: 'var(--graphic-width)' }),
        }}
      />
      <div css={styles.actions}>
        {isLoading && showLoader && (
          <div css={styles.loaderWrapper}>
            <Loader color={COLORS.blue} size={16} />
          </div>
        )}

        {showClear && <ClearButton onClick={clearSearch} css={styles.clearButton} />}

        {after}
      </div>
    </div>
  )
}

const styles = {
  root: {
    ...INPUT_STYLES,
    position: 'relative',
    height: 'var(--input-min-height)',
    borderRadius: 7,
    padding: 0,
    display: 'flex',
    flexWrap: 'nowrap',
    overflow: 'visible',

    '&.has-after input': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },

  graphic: {
    left: 0,
    width: 'var(--graphic-width)',
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
  },

  input: {
    ...INPUT_STYLES,
    height: '100%',
    minHeight: '100%',
    flex: '1 1 auto',
    border: `1px solid ${COLORS.transparent}`,
    boxShadow: 'none',

    '&:hover': {
      border: `1px solid ${COLORS.transparent}`,
    },
  },

  actions: {
    display: 'flex',
  },

  clearButton: {
    position: 'static',
    width: '2rem',
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
  },

  loaderWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '2rem',
  },
}

Search.defaultProps = {
  placeholder: 'Search…',
}

export default Search

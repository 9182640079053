import React from 'react'

import { DataTable } from '../../components/DataTable/DataTable'
import { FILTERS } from '../Filters/config'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import Status from '../../components/Status'

export const ClientConnectionsDataTable = (props: any) => {
  const { to, ...rest } = props

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'to.name',
        width: 260,
        disableSort: true,
        disableHide: true,
        formatValue: ({ data, value }: any) => <MainCell id={data.id} value={value} to={to?.(data)} />,
      },
      {
        width: 200,
        model: 'category',
        title: 'Category',
        formatValue: ({ value }: any) => {
          if (value === 'care_team') return <Status label="Care Team" color="orange" />
          if (value === 'organization') return <Status label="Organization" color="purple" />
          return null
        },
      },
      {
        width: 200,
        model: 'subcategory',
        title: 'Relation Type',
        type: 'title',
      },
      {
        width: 280,
        model: 'notes',
        title: 'Notes',
      },
      {
        width: 200,
        model: 'created_at',
        title: 'Date Added',
        type: 'date_time',
      },
      {
        width: 200,
        model: 'updated_at',
        title: 'Last Updated',
        type: 'date_time',
      },
    ],
    [to],
  )

  return <DataTable asCard title="Connections" icon="connections" columns={columns} filtersConfig={FILTERS.client_connections} {...rest} />
}

import React from 'react'

import SmartStatus from '../../components/SmartStatus'

const statuses = {
  private: {
    label: 'Internal',
    color: 'blue',
    glyph: 'lock',
  },
  public: {
    label: 'Public',
    color: 'green',
    glyph: 'public',
  },
}

export const ClientInvoiceShareStatus = (props) => {
  if (!props.status) return null

  return <SmartStatus statuses={statuses} {...props} />
}

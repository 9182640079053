import React from 'react'
import { NavLink, useParams } from 'react-router-dom-v5-compat'

import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import withPermissions from '@behavehealth/hocs/withPermissions'

import { LabsDataTable } from '@behavehealth/constructs/TestResults/LabsDataTable'
import Button from '@behavehealth/components/Button'
import Grid from '@behavehealth/components/Grid'

const Labs = () => {
  const { resource_id }: any = useParams()

  const tableProps = useDataTable({
    name: ['employees', resource_id, 'lab-tests'],
    endpoint: `/employees/${resource_id}/labs`,
    updateDeleteEndpoint: '/labs',
    enabled: !!resource_id,
    localStorageKey: 'labs_v1',
  })

  return (
    <Grid gap="1rem" columns="100%">
      <LabsDataTable
        {...tableProps}
        to={(row: any) => row.id}
        headerLinksConfig={[
          {
            type: 'report',
            to: '/reports/labs',
          },
        ]}
        emptyActions={
          <Button as={NavLink} label="Add Lab Test" type="primary" glyph="add" link="new" permission="test_results.labs.create" />
        }
        batchActionsConfig={[
          {
            type: 'delete',
            permission: 'test_results.labs.delete',
            action: async ({ ids }: any) => {
              await tableProps.deleteRecords(ids.join(','))
            },
          },
        ]}
      />
    </Grid>
  )
}

export default withPermissions(Labs)

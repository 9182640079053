import React from 'react'

import { COLORS } from '../../theme'
import { useSettings } from '../../hooks/useSettings'

import NavGroup from '../NavGroup'
import NavItem from '../NavItem'
import Alert from '../Alert'

import { useCommunityPermissions } from '../../constructs/Community/useCommunityPermissions'

type Props = {
  organization: object
  isPartner: boolean
}

export const CommunityOrganizationSharedNavItems = (props: Props) => {
  const { organization } = props

  const { canViewPartnerSharedFiles, canViewPartnerReferrals, canViewPartnerShareSettings } = useCommunityPermissions()

  const basePath = `/community/organizations/${organization?.id}`
  const isJoiningCommunity = organization.status_group === 'joining_community'

  const { isBehave } = useSettings()

  return (
    <>
      {isJoiningCommunity ? (
        <>
          <NavGroup label="Profile">
            <NavItem label="Community Profile" icon="community_profile" to={`${basePath}/shared/community-profile`} />
            <NavItem label={`Help to join Community`} icon="community" to={`${basePath}/shared/community-setup`} />
          </NavGroup>

          <Alert contrast type="positive" className="mt-8 mb-4">
            After <b>{organization.name}</b> joins the <b>Behave Community</b>, you will be able to do the following:
          </Alert>

          <div className="opacity-50">
            <NavGroup label="Profile">
              <NavItem label="Message Board" icon="message_board" color={COLORS.orange} />
            </NavGroup>

            <NavGroup label="Shared Data">
              <NavItem label="Staff" feature="employees" />
              <NavItem label="Locations" icon="locations" color={COLORS.red} />
              <NavItem label="Partners" icon="community_partners" color={COLORS.green} />
              <NavItem label="Shared Files" feature="files" permission={canViewPartnerSharedFiles} />
              <NavItem label="Bed Availability" icon="beds" color={COLORS.purple} />
            </NavGroup>

            <NavGroup label="Shared Forms">
              <NavItem label="Application Form" icon="admissions" color={COLORS.green} />
              <NavItem label="EVOB Application Form" icon="insurance" color={COLORS.green} />
              <NavItem label="Contact Us" icon="communications" color={COLORS.orange} />
            </NavGroup>

            {isBehave && (
              <>
                <NavGroup label="Applications">
                  <NavItem
                    label="Lead Application Forms"
                    icon="web_form"
                    to={`${basePath}/shared/lead-application-forms`}
                    color={COLORS.blue}
                  />
                </NavGroup>

                <NavGroup label="Certification & Inspections">
                  <NavItem label="Certifications" icon="web_form" to={`${basePath}/shared/certification-forms`} color={COLORS.blue} />
                  <NavItem label="Inspections" icon="web_form" to={`${basePath}/shared/inspection-forms`} color={COLORS.blue} />
                </NavGroup>

                <NavGroup label="Agreements">
                  <NavItem label="Agreements" icon="web_form" to={`${basePath}/shared/agreement-forms`} color={COLORS.blue} />
                </NavGroup>

                <NavGroup label="Complaints & Issues">
                  <NavItem label="Grievance Forms" icon="web_form" to={`${basePath}/shared/grievance-forms`} color={COLORS.blue} />
                  <NavItem label="Incident Reporting" icon="web_form" to={`${basePath}/shared/incident-reporting`} color={COLORS.blue} />
                </NavGroup>

                <NavGroup label="Studies & Surveys">
                  <NavItem label="Studies" icon="web_form" to={`${basePath}/shared/studies`} color={COLORS.blue} />
                  <NavItem label="Surveys" icon="web_form" to={`${basePath}/shared/surveys`} color={COLORS.blue} />
                </NavGroup>
              </>
            )}

            <NavGroup label="Workflows">
              <NavItem label="Referrals" icon="referrals" color={COLORS.purple} permission={canViewPartnerReferrals} />
              <NavItem label="Requests" icon="web_form" color={COLORS.blue} />
            </NavGroup>
          </div>
        </>
      ) : (
        <>
          <NavItem
            label="Data Sharing Settings"
            icon="settings"
            to={`${basePath}/shared/share-settings`}
            permission={canViewPartnerShareSettings}
          />

          <NavGroup label="Profile">
            <NavItem label="Community Profile" icon="community_profile" to={`${basePath}/shared/community-profile`} />
            <NavItem label="Message Board" icon="message_board" to={`${basePath}/shared/message-board`} color={COLORS.orange} />
          </NavGroup>

          <NavGroup label="Shared Data">
            <NavItem label="Staff" feature="employees" to={`${basePath}/shared/staff`} />
            <NavItem label="Locations" icon="locations" to={`${basePath}/shared/locations`} color={COLORS.red} />
            <NavItem label="Partners" icon="community_partners" to={`${basePath}/shared/partners`} color={COLORS.green} />
            <NavItem label="Shared Files" feature="files" to={`${basePath}/shared/files`} permission={canViewPartnerSharedFiles} />
            <NavItem label="Bed Availability" icon="beds" to={`${basePath}/shared/bed-availability`} color={COLORS.purple} />
          </NavGroup>

          <NavGroup label="Shared Forms">
            <NavItem label="Application Form" icon="admissions" to={`${basePath}/shared/application-form`} color={COLORS.green} />
            <NavItem label="EVOB Application Form" icon="insurance" to={`${basePath}/shared/evob-application-form`} color={COLORS.green} />
            <NavItem label="Contact Us" icon="communications" to={`${basePath}/shared/contact-us`} color={COLORS.orange} />
          </NavGroup>

          {isBehave && (
            <>
              <NavGroup label="Applications">
                <NavItem
                  label="Lead Application Forms"
                  icon="web_form"
                  to={`${basePath}/shared/lead-application-forms`}
                  color={COLORS.blue}
                />
              </NavGroup>

              <NavGroup label="Certification & Inspections">
                <NavItem label="Certifications" icon="web_form" to={`${basePath}/shared/certification-forms`} color={COLORS.blue} />
                <NavItem label="Inspections" icon="web_form" to={`${basePath}/shared/inspection-forms`} color={COLORS.blue} />
              </NavGroup>

              <NavGroup label="Agreements">
                <NavItem label="Agreements" icon="web_form" to={`${basePath}/shared/agreement-forms`} color={COLORS.blue} />
              </NavGroup>

              <NavGroup label="Complaints & Issues">
                <NavItem label="Grievance Forms" icon="web_form" to={`${basePath}/shared/grievance-forms`} color={COLORS.blue} />
                <NavItem label="Incident Reporting" icon="web_form" to={`${basePath}/shared/incident-reporting`} color={COLORS.blue} />
              </NavGroup>

              <NavGroup label="Studies & Surveys">
                <NavItem label="Studies" icon="web_form" to={`${basePath}/shared/studies`} color={COLORS.blue} />
                <NavItem label="Surveys" icon="web_form" to={`${basePath}/shared/surveys`} color={COLORS.blue} />
              </NavGroup>
            </>
          )}

          <NavGroup label="Workflows">
            <NavItem
              label="Referrals"
              icon="referrals"
              to={`${basePath}/shared/referrals`}
              color={COLORS.purple}
              permission={canViewPartnerReferrals}
            />
            <NavItem label="Requests" icon="web_form" to={`${basePath}/shared/requests`} color={COLORS.blue} />
          </NavGroup>
        </>
      )}
    </>
  )
}

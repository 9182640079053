import React from 'react'
import { NavLink, Navigate, Route, Routes, useLocation } from 'react-router-dom-v5-compat'

import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Button, Page, Grid, Tab, TabList, Tabs, HelpTagIframe } from '@behavehealth/components'
import { ProtectedRouteV6 } from '@behavehealth/components/ProtectedRouteV6'
import { TestResultsOverlayRoutes } from '@behavehealth/constructs/TestResults/TestResultsOverlayRoutes'
import Dropdown from '@behavehealth/components/Dropdown'
import DropdownItem from '@behavehealth/components/DropdownItem'

import BreathalyzerTests from './test_results/breathalyzer_tests'
import CommunicableDiseaseTests from './test_results/communicable_disease_tests'
import UATests from './test_results/ua_tests'
import VitalSigns from './test_results/vital_signs'
import Labs from './test_results/labs'

const pageConfig = {
  feature: 'test_results',
  help: <HelpTagIframe id="test_results" />,
  marketingID: 'test_results',
}

const TestResults = () => {
  return (
    <>
      <TestResultsIndex />
      <TestResultsOverlayRoutes />
    </>
  )
}

const TestResultsIndex: React.FC = () => {
  const { pathname } = useLocation()

  const uaSelected = pathname.includes(`ua-tests`)
  const breathalyzerSelected = pathname.includes(`breathalyzer-tests`)
  const vitalSignsSelected = pathname.includes(`vital-signs`)
  const communicableDiseaseTestsSelected = pathname.includes(`communicable-disease-tests`)
  const labsSelected = pathname.includes(`labs`)

  return (
    <Page
      actions={
        <>
          {uaSelected && (
            <Dropdown label="Add UA Test…" buttonType="primary" permission="test_results.ua_tests.create">
              <DropdownItem as={NavLink} label="Add UA Test" icon="test_results" color="blue" link="ua-tests/new" />
              <DropdownItem
                as={NavLink}
                label="Add Many UA Tests…"
                glyph="stack"
                color="blue"
                link="ua-tests/new-batch"
                featureFlagV2="bulk_add"
              />
            </Dropdown>
          )}

          {breathalyzerSelected && (
            <Dropdown label="Add Breathalyzer Test…" buttonType="primary" permission="test_results.breathalyzer_tests.create">
              <DropdownItem as={NavLink} label="Add Breathalyzer Test" icon="test_results" color="blue" link="breathalyzer-tests/new" />
              <DropdownItem
                as={NavLink}
                label="Add Many Breathalyzer Tests…"
                glyph="stack"
                color="blue"
                link="breathalyzer-tests/new-batch"
                featureFlagV2="bulk_add"
              />
            </Dropdown>
          )}

          {vitalSignsSelected && (
            <Button
              as={NavLink}
              label="Add Vital Signs"
              type="primary"
              glyph="add"
              link="vital-signs/new"
              permission="test_results.vital_signs.create"
            />
          )}

          {communicableDiseaseTestsSelected && (
            <Button
              as={NavLink}
              label="Add Communicable Disease Test"
              type="primary"
              glyph="add"
              link="communicable-disease-tests/new"
              permission="test_results.communicable_disease_tests.create"
            />
          )}

          {labsSelected && (
            <Button as={NavLink} label="Add Lab Test" glyph="add" type="primary" link="labs/new" permission="test_results.labs.create" />
          )}
        </>
      }
      {...pageConfig}
    >
      <Grid gap="1rem">
        <Tabs>
          <TabList className="-mt-4">
            <Tab
              as={NavLink}
              showForCommunity
              label="UA Tests"
              to="ua-tests"
              permission="test_results.ua_tests.view"
              featureFlagV2="ua_tests"
            />
            <Tab
              as={NavLink}
              showForCommunity
              label="Breathalyzer Tests"
              to="breathalyzer-tests"
              permission="test_results.breathalyzer_tests.view"
              featureFlagV2="breathalyzer_tests"
            />
            <Tab
              as={NavLink}
              label="Vital Signs"
              to="vital-signs"
              newFeatureFlag="lab_tests_2"
              permission="test_results.vital_signs.view"
              featureFlagV2="vital_signs"
            />
            <Tab
              as={NavLink}
              label="Communicable Disease Tests"
              to="communicable-disease-tests"
              newFeatureFlag="lab_tests_2"
              permission="test_results.communicable_disease_tests.view"
              featureFlagV2="communicable_disease_tests"
            />
            <Tab
              as={NavLink}
              label="Labs"
              to="labs"
              newFeatureFlag="lab_tests_2"
              permission="test_results.labs.view"
              featureFlagV2="labs"
            />
          </TabList>
        </Tabs>

        <Routes>
          <Route index element={<Navigate to="ua-tests" replace />} />

          <Route
            path="ua-tests/*"
            element={
              <ProtectedRouteV6 permission="test_results.ua_tests.view">
                <UATests />
              </ProtectedRouteV6>
            }
          />
          <Route
            path="breathalyzer-tests/*"
            element={
              <ProtectedRouteV6 permission="test_results.breathalyzer_tests.view">
                <BreathalyzerTests />
              </ProtectedRouteV6>
            }
          />
          <Route
            path="vital-signs/*"
            element={
              <ProtectedRouteV6 permission="test_results.vital_signs.view">
                <VitalSigns />
              </ProtectedRouteV6>
            }
          />
          <Route
            path="communicable-disease-tests/*"
            element={
              <ProtectedRouteV6 permission="test_results.communicable_disease_tests.view">
                <CommunicableDiseaseTests />
              </ProtectedRouteV6>
            }
          />
          <Route
            path="labs/*"
            element={
              <ProtectedRouteV6 permission="test_results.labs.view">
                <Labs />
              </ProtectedRouteV6>
            }
          />
        </Routes>
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(TestResults, pageConfig))

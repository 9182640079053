import React from 'react'
import clsx from 'clsx'
import size from 'lodash/size'
import snakeCase from 'lodash/snakeCase'

import { COLORS } from '../../theme'
import { countWord } from '../../utils/functions'
import { PlainSwitch } from '../../components/Forms/Switch'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Avatar from '../../components/Avatar'
import Button from '../../components/Button'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Glyph from '../../components/Glyph'
import Label from '../../components/Label'
import Nav from '../../components/Nav'
import Overlay from '../../components/Overlay'
import PageGrid from '../../components/PageGrid'
import ScrollMenu from '../../components/ScrollMenu'
import ScrollView from '../../components/ScrollView'
import Section from '../../components/Section'
import SmartStatus from '../../components/SmartStatus'
import SmartTextarea from '../../components/Forms/SmartTextarea'

import { COMMENT_STATUSES, COMMENTS, CONTACT_SECTIONS, FORM_STATUSES, HOME_LOCATION_SECTIONS, ORGANIZATION_SECTIONS } from './data'
import { NARRFormAssignmentSelector } from './NARRFormAssignmentSelector'
import { NARRFormStatus } from './NARRFormStatus'

const RootCertificationApplicationFormOverlay = (props: any) => {
  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    id,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
  } = useOverlay({
    name: 'todo',
    // endpoint: '/todo',
    invalidate: 'todo',
    options: props,
  })

  const [isCommentsVisible, setIsCommentsVisible] = React.useState(true)

  const handleCommentsChange = () => {
    setIsCommentsVisible((c) => !c)
  }

  const showComments = true

  return (
    <Overlay onClose={close} showBackdrop={isNew || isEditable} isDirty={isEditable} maxWidth={100}>
      <Overlay.Header icon="web_form" title="Certification Application Form" />

      <ScrollView>
        <PageGrid scroll breakpoint={3} className="overlay-page-grid flex-[1_1_auto]">
          <Nav
            top="0"
            breakpoint={3}
            headingSize={300}
            title="Table of Contents"
            // background={transparentize(0.4, 'white')}
            className="!bg-transparent shadow-right-hard-1 mq1024:max-w-[300px] [&_header]:!bg-transparent [&_header]:!shadow-none"
          >
            <ScrollMenu />
          </Nav>

          <Overlay.Content>
            <Overlay.SubHeader className="flex items-center justify-between flex-nowrap px-2 !py-1.5">
              <Flex alignItems="center" gap="1rem">
                <NARRFormStatus status="draft" statuses={FORM_STATUSES} />
                <NARRFormAssignmentSelector />
              </Flex>

              <div className="flex flex-nowrap items-center">
                <Label isCompact label="Show Comments" onClick={handleCommentsChange} className="!flex-[0_0_auto] cursor-pointer" />
                <PlainSwitch size={200} isChecked={isCommentsVisible} onCheckedChange={handleCommentsChange} />
              </div>
            </Overlay.SubHeader>

            <Form isCompact getForm={form} isEditable={isEditable} initialModel={initialModel} onValidationUpdate={onValidationUpdate}>
              <Sections
                title="Organization"
                sections={ORGANIZATION_SECTIONS}
                showComments={showComments}
                disableComments={!isCommentsVisible}
              />
              <Sections
                title="Home / Location"
                sections={HOME_LOCATION_SECTIONS}
                showComments={showComments}
                disableComments={!isCommentsVisible}
              />
              <Sections title="Contact" sections={CONTACT_SECTIONS} showComments={showComments} disableComments={!isCommentsVisible} />
            </Form>
          </Overlay.Content>
        </PageGrid>
      </ScrollView>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={save}
              isDisabled={isInvalid}
              flex="100 1 auto"
            />
            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {!isEditable && (
          <>
            <Button glyph="edit" label="Edit Form" type="default" isDisabled={isLoading} onClick={edit} flex="100 1 auto" />

            <DeleteDialog
              title="Delete Form?"
              message="Are you sure you want to delete this Form? This action cannot be undone."
              onYes={deleteRecord}
            >
              <Button glyph="delete" label="Delete" type="default" color="red" isLoading={isDeleting} fullWidth />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const Sections = ({ title, sections, showComments, disableComments }) => {
  if (!sections) return null

  return sections.map((section) => (
    <Section
      title={section.title}
      headingType="h2"
      scrollview={{
        id: snakeCase(section.title),
        name: section.title,
        parent: { id: snakeCase(title), name: title },
      }}
    >
      <FormSection maxWidth="100%" className="pb-5">
        {section?.questions?.map?.((question, index) => (
          <Question key={question} index={index} question={question} disableComments={disableComments} showComments={showComments} />
        ))}
      </FormSection>
    </Section>
  ))
}

export const Questions = ({ title, questions, showComments, disableComments }) => {
  return (
    <Section
      title={title}
      headingType="h2"
      scrollview={{
        id: snakeCase(title),
        name: title,
      }}
    >
      <FormSection maxWidth="100%">
        <div className="grid gap-4">
          {questions.map((question, index) => (
            <Question key={question} index={index} question={question} disableComments={disableComments} showComments={showComments} />
          ))}
        </div>
      </FormSection>
    </Section>
  )
}

export const Question = (props: any) => {
  const { question, index, showComments, disableComments } = props

  const [comments, setComments]: any = React.useState(
    showComments && !disableComments ? COMMENTS.filter((comment) => comment.index === index) : [],
  )

  const [newComment, setNewComment] = React.useState('')
  const [showNewComment, setShowNewComment] = React.useState(false)

  const hasComments = size(comments) > 0

  const { user } = useSettings()

  const addComment = () => {
    setComments([...comments, { index, comment: newComment, name: user?.name || 'Unknown' }])
    setNewComment('')
  }

  const commentsHeaderClasses = clsx('flex items-center flex-nowrap py-2', !hasComments && 'HOVER opacity-0')

  return (
    <div
      className={clsx(
        'grid grid-cols-1 hover:bg-white hover:shadow-soft-4 rounded-[5px] overflow-hidden [&:hover_.HOVER]:opacity-100 ring-[1px] ring-divider',
        disableComments ? '' : 'mq900:grid-cols-[3fr_300px]',
      )}
    >
      <div className="px-3 pt-3 pb-3 bg-white">
        <SmartTextarea
          useQuickText={false}
          useDictation
          key={question}
          label={`${index + 1}. ${question}`}
          model={snakeCase(question)}
          withHover={false}
        />
      </div>

      {!disableComments && (
        <div className="px-3 pb-4 bg-white bg-opacity-60 border-t mq900:border-t-0 mq900:border-l border-0 border-solid border-divider">
          <header className={commentsHeaderClasses}>
            <div className="flex items-center flex-nowrap">
              <Glyph
                glyph="chat"
                size={14}
                color={COLORS.textMuted}
                className={clsx('mr-1.5', hasComments || showNewComment ? 'opacity-100' : 'opacity-40')}
              />

              <div
                className={clsx(
                  'text-text-muted text-[0.8rem] uppercase font-[600] tracking-[0.5px]',
                  hasComments || showNewComment ? 'opacity-100' : 'opacity-40',
                )}
              >
                {hasComments ? countWord('Comments', size(comments)) : 'Comments'}
              </div>
            </div>
          </header>

          <div className="grid gap-3 text-[0.96rem]">
            {comments.map(({ comment, name, status }, index) => (
              <div key={`${comment}-${index}`} className="pt-1 border-b border-solid border-0 border-divider pb-3 last:border-b-0">
                <div className="flex items-center flex-nowrap">
                  <div className="w-8 h-8 flex items-center justify-start">
                    <Avatar src="" initials={name} size={20} />
                  </div>

                  <div className="font-[600]">{name}</div>
                  <SmartStatus small statuses={COMMENT_STATUSES} status={status} className="ml-auto" />
                </div>

                <div className="pl-8 mt-0 text-text-muted">{comment}</div>
              </div>
            ))}
          </div>

          {!showNewComment && (
            <div>
              <Button
                label="Add Comment"
                size={100}
                type="minimal"
                glyph="add"
                display="inline-flex"
                onClick={() => setShowNewComment(true)}
                className="HOVER opacity-0"
              />
            </div>
          )}

          {showNewComment && (
            <>
              <div className="flex justify-end mb-2">
                <NARRFormStatus status="draft" statuses={COMMENT_STATUSES} />
              </div>

              <SmartTextarea
                isEditable
                useQuickText={false}
                useDictation
                placeholder="Your comment…"
                value={newComment}
                onUpdate={({ value }) => setNewComment(value)}
                withHover={false}
              />

              <div className="mt-2">
                <Flex gap="0.75rem">
                  <Button
                    label="Add Comment"
                    type="primary"
                    size={100}
                    color="green"
                    glyph="tick_circle"
                    display="inline-flex"
                    onClick={addComment}
                    isDisabled={!newComment}
                  />

                  <Button
                    label="Cancel"
                    size={100}
                    type="default"
                    onClick={() => {
                      setShowNewComment(false)
                      setNewComment('')
                    }}
                  />
                </Flex>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  )
}

export const CertificationApplicationFormOverlay = withOverlayError(RootCertificationApplicationFormOverlay)

import React from 'react'
import { useParams } from 'react-router-dom'

import Card from '../../../components/Card'
import Grid from '../../../components/Grid'
import Overlay from '../../../components/Overlay'

import { ClaimServiceLinesDataTable } from '../components/ClaimServiceLinesDataTable'
import { ClaimEventsDataTable } from '../components/ClaimEventsDataTable'

export const ClaimOverlaySummary = (props) => {
  return (
    <Overlay.Content css={{ zIndex: 0 }}>
      <Grid gap="1rem" className="!p-4">
        <Card>
          <ClaimEventsDataTable claimID={props.id} />
        </Card>

        <ClaimServiceLinesDataTable claimID={props.id} />
      </Grid>
    </Overlay.Content>
  )
}

import React from 'react'
import { v4 as uuid } from 'uuid'
import clsx from 'clsx'
import produce from 'immer'

import get from 'lodash/get'
import size from 'lodash/size'

import { DEFAULT_EMPTY_VALUE } from '../../../../utils/constants'
import { encodeObjectToURL } from '../../../../utils/functions'
import { apiGet } from '../../../../modules/api'

import { withFormContext } from '../../context'
import { validate } from '../../validators'

import Grid from '../../../Grid'
import SelectorBase from '../SelectorBase'
import SmartPortal from '../../../SmartPortal'
import Value from '../../../Value'
import Search from '../../../Search'

import MultiSearchMenu from './MultiSearchMenu'
import MultiObjectItem from './MultiSearchItem'

import { LINKS, getDefaultGroupBy } from '../SelectorBase'

class MultiSearchSelector extends SelectorBase {
  constructor(props) {
    super(props)

    let errors = []
    let vs = props.validations
    const value = props.value || get(props.form?.getInitialModel(), props.model, [])
    if (vs) errors = validate(value, vs)

    this.state = {
      type: 'OBJECT',
      id: `${props.model}-${uuid()}`,
      model: props.model,
      object: value,
      value: value,
      apiData: [],
      search: '',
      isLoading: false,
      includeObject: props.includeObject,
      isOpen: props.isOpen,
      isValid: errors.length ? false : true,
      isInvalid: errors.length ? true : false,
      isRelations: props.isRelations,
      isPristine: true,
      isDirty: false,
      isTouched: false,
      isUntouched: true,
      isValidations: props.validations,
      isRequired: props.validations && props.validations.hasOwnProperty('presence'),
      errors: [],
      reset: this.onReset,
      validate: this.onValidate,
    }

    this.initialData = {
      object: value,
      value: value,
      apiData: [],
      search: '',
      isOpen: props.isOpen,
      isValid: errors.length ? false : true,
      isInvalid: errors.length ? true : false,
    }

    this.updateType = 'DATA'
    this.fieldRef = null
    this.referenceRef = null
  }

  /*
    CUSTOM FUNCTIONS
  */
  queryData = async (query = '') => {
    const types = this.props.type.split('.')

    const params = {
      q: query,
      type: types[0],
      subtype: types[1],
      excluded: this.state.object.map((o) => o.id).join(','),
    }

    const response = await apiGet({ url: `/search_by?${encodeObjectToURL(params)}` })
    return response.data.data
  }

  processChangeValue = (item: any) => {
    if (this.props.isDisabled) return

    const items = produce(this.state.object, (draft: any) => {
      let itemFound = false

      // check that the items doesn't already exist
      for (let i = 0; i < draft.length; i++) {
        if (draft[i].id === item.id) {
          itemFound = true
          break
        }
      }

      // if item is not added to the object, add it
      if (!itemFound) draft.push(item)
    })

    const newState = produce(this.state, (draft: any) => {
      draft.object = items
      draft.value = items
      draft.isOpen = false
      draft.isDirty = true
      draft.isPristine = false
    })

    this.setState({
      object: newState.object,
      value: newState.value,
      isOpen: newState.isOpen,
      isDirty: newState.isDirty,
      isPristine: newState.isPristine,
    })

    return newState
  }

  onSearch = async (search: string) => {
    this.setState({ isLoading: true, search: search })

    try {
      const result = await this.queryData(search)
      this.setState({ isLoading: false, apiData: result })
    } catch (error) {
      console.error(error)
      this.setState({ isLoading: false })
    }
  }

  /*
    RENDER
  */
  renderEdit = () => (
    <Search
      showLoader
      showGraphic={!!this.props.icon}
      icon={this.props.icon}
      placeholder={this.props.placeholder}
      onChange={this.onSearch}
      onFocus={this.open}
      isLoading={this.state.isLoading}
      value={this.props.selectTitle?.(this.state.value)}
      css={STYLES.search}
      className={clsx(this.props.isCompact && 'is-compact')}
    />
  )

  renderSelected = () => {
    const { selectTitle } = this.props
    const { object } = this.state

    return (
      size(object) > 0 && (
        <div css={{ marginTop: '0.25rem !important', paddingLeft: '0.75rem' }}>
          {object.map((item: any) => (
            <MultiObjectItem
              key={item?.id}
              label={selectTitle?.(item) || item?.name}
              avatar={item?.avatar}
              // onClear={() => this.removeItem(item?.id)}
              isDisabled={item?.isRequired}
            />
          ))}
        </div>
      )
    )
  }

  renderReadOnly = () => {
    const { object } = this.state
    const { disableLink } = this.props

    if (object && object.length > 0) {
      return (
        <Grid gap={10}>
          {object.map((item) => (
            <Value
              key={item.id}
              value={this.props.selectTitle?.(item)}
              description={item.description}
              icon={this.props.icon}
              avatar={item.avatar}
              avatarMagnify
              avatarMagnifyScale={2.5}
              link={!disableLink && LINKS[this.props.type]?.(item.id)}
            />
          ))}
        </Grid>
      )
    } else return DEFAULT_EMPTY_VALUE
  }

  renderSelector = () => {
    const { icon, type, selectTitle, selectDescription, showAvatars, emptyActions, groupBy, testKey } = this.props
    const { apiData } = this.state

    const groupByConfig = groupBy || getDefaultGroupBy(type)

    return (
      <SmartPortal testKey={`${testKey}_portal`} useReferenceWidth portal="selector" position="bottom-start">
        <MultiSearchMenu
          icon={icon}
          type={type}
          items={apiData}
          showAvatars={showAvatars}
          selectTitle={selectTitle}
          selectDescription={selectDescription}
          emptyActions={emptyActions}
          onSelect={this.changeValue}
          onClose={this.close}
          groupBy={groupByConfig}
        />
      </SmartPortal>
    )
  }
}

const STYLES = {
  search: {
    input: {
      height: 'var(--input-min-height) !important',
    },

    // '&.is-compact': {

    // },
  },
}

MultiSearchSelector.defaultProps = {
  showAs: 'inline',
  showAvatars: true,
  prefetch: false,
  isEditable: true,
  isDisabled: false,
  initialModelRequired: false,
  layout: 'vertical-dense',
  isOpen: false,
  placeholder: 'Search…',
  validateOn: 'blur-change',
}

export default withFormContext(MultiSearchSelector)

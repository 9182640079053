import React from 'react'
import compact from 'lodash/compact'
import produce from 'immer'

import Button from '../../Button'
import Flex from '../../Flex'
import Status from '../../Status'
import Table from '../../Table/Table'
import TableCell from '../../Table/TableCell'

import { AGREEMENTS } from '../../../utils/constants'
import withSettings from '../../../hocs/withSettings'

const columns = (to, onClick, mainLinkAs, duplicateFormLinkProps) =>
  compact([
    {
      id: 'name',
      accessor: 'name',
      Header: 'Name',
      width: 240,
      Cell: ({ cell, row }: any) => {
        return <TableCell.MainLink as={mainLinkAs} to={to?.(row.original.id)} onClick={() => onClick?.(row.original)} label={cell?.value} />
      },
    },
    {
      id: 'category',
      accessor: 'category',
      Header: 'Category',
      width: 160,
      Cell: ({ value }: any) => AGREEMENTS?.[value] || '–',
    },
    {
      id: 'should_sign',
      accessor: 'should_sign',
      Header: 'Signee Signature',
      width: 160,
      Cell: ({ value }: any) => {
        if (value === true) return <Status label="Required" color="green" />
        return <Status label="Not Required" color="gray" />
      },
    },
    {
      Header: 'Order',
      accessor: 'order',
      width: 100,
    },
    {
      id: 'author.name',
      accessor: 'author',
      Header: 'Created by',
      width: 160,
      Cell: ({ row, value }: any) => <TableCell.Profile avatar={row.original?.author?.avatar} name={row.original.author?.name} />,
    },
    duplicateFormLinkProps && {
      width: 130,
      id: 'duplicate',
      accessor: 'id',
      Header: 'Actions',
      Cell: ({ row }: any) => {
        const duplicated = produce(row.original, (draft: any) => {
          // add copy to the name
          draft.name = draft.name + ' Copy'

          delete draft.id
          delete draft.author
          delete draft.created_at
          delete draft.updated_at
        })

        return (
          <Flex gap="0.5rem">
            <Button label="Duplicate" glyph="add" size={100} {...duplicateFormLinkProps?.(duplicated)} />
          </Flex>
        )
      },
    },
  ])

type Props = {
  batchActionsConfig?: Object
  data: Object
  isLoading: boolean
  localStorageKey: string
  onClick?: Function
  title?: string
  titleAfter?: React.ReactNode
  to?: Function
}

const ClientAgreementTemplatesTable = (props: Props) => {
  const { batchActionsConfig, data, isLoading, localStorageKey, onClick, title, titleAfter, to, ...rest } = props

  return (
    <Table
      data={data}
      columns={columns(to, onClick, props.mainLinkAs, props.duplicateFormLinkProps)}
      title={title}
      titleAfter={titleAfter}
      icon="legal_agreement_alt"
      isLoading={isLoading}
      emptyDescription="No agreement templates have been added yet"
      batchActionsConfig={batchActionsConfig}
      localStorageKey={localStorageKey}
      {...rest}
    />
  )
}

ClientAgreementTemplatesTable.defaultProps = {
  title: 'Agreement Templates',
  localStorageKey: 'agreement_templates',
}

export default withSettings(ClientAgreementTemplatesTable)

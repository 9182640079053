import { DateTime } from 'luxon'
import { flatten } from 'flat'

import compact from 'lodash/compact'
import isUndefined from 'lodash/isUndefined'
import startCase from 'lodash/startCase'
import camelCase from 'lodash/camelCase'
import capitalize from 'lodash/capitalize'
import lowerCase from 'lodash/lowerCase'
import split from 'lodash/split'
import size from 'lodash/size'
import pluralize from 'pluralize'
import get from 'lodash/get'
import isBoolean from 'lodash/isBoolean'
import isString from 'lodash/isString'
import { v4 as uuid } from 'uuid'

import dinero from 'dinero.js'

import { CLIENT_SLUG_BY_STATUS, DEFAULT_EMPTY_VALUE, TIMEZONE } from './constants'

/*
  -----------------
  GENERAL FUNCTIONS
  -----------------
*/
export const isURL = (value: any) => {
  if (!value) return false

  let pattern = new RegExp(
    '^((ft|htt)ps?:\\/\\/)?' +
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
      '((\\d{1,3}\\.){3}\\d{1,3}))' +
      '(\\:\\d+)?' +
      '(\\/[-a-z\\d%@_.~+&:]*)*' +
      '(\\?[;&a-z\\d%@_.,~+&:=-]*)?' +
      '(\\#[-a-z\\d_]*)?$',
    'i',
  )

  return pattern.test(value)
}

export const isBase64Image = (value: string) => {
  if (!value) return false

  return value.includes('data:image/png;base64,')
}

export const isUUID = (value: string) => {
  if (!value) return false

  const regexExp = /^[0-9a-fA-F]{8}-?[0-9a-fA-F]{4}-?[0-9a-fA-F]{4}-?[0-9a-fA-F]{4}-?[0-9a-fA-F]{12}$/gi
  return regexExp.test(value)
}

export const isEmail = (value: string) => {
  var regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
  return regex.test(value)
}

export const formatURL = (url) => {
  const isValid = isURL(url)
  if (!isValid) return null

  if (url.includes('://')) return url
  return '//' + url
}

export const isDefined = (value: any) => {
  return !isUndefined(value) && value !== null
}

export const isBlank = (value: any) => {
  return isUndefined(value) || value === '' || value === null || value.length === 0
}

export const notBlank = (value: any) => {
  return !isBlank(value)
}

export const isHexColor = (value: any) => {
  return /^#([0-9A-Fa-f]{3}){1,23}$/i.test(value)
}

export const isObject = (value: any) => {
  return Object.prototype.toString.call(value) === '[object Object]'
}

export const isArrayOrObject = (value: any) => {
  return Object(value) === value
}

export const isEmptyObject = (value: any) => {
  return Object.keys(value).length === 0
}

export const titleCase = (obj: any) => {
  if (!obj) return DEFAULT_EMPTY_VALUE
  return startCase(camelCase(obj))
}

export const pascalCase = (obj: any) => {
  if (!obj) return DEFAULT_EMPTY_VALUE
  return capitalize(camelCase(obj))
}

export const initials = (obj: any) => {
  if (!obj) return DEFAULT_EMPTY_VALUE
  return split(lowerCase(obj), ' ')
    .map((n) => n[0])
    .join('')
}

export const initialsWithDots = (obj) => {
  if (!obj) return DEFAULT_EMPTY_VALUE
  return split(lowerCase(obj), ' ')
    .map((n) => `${n[0].toUpperCase()}. `)
    .join('')
}

export const fullname = (obj) => {
  if (!obj) return DEFAULT_EMPTY_VALUE
  return compact([obj.first_name, obj.middle_name, obj.last_name]).join(' ') || DEFAULT_EMPTY_VALUE
}

export const fullnameShort = (obj) => {
  if (!obj) return DEFAULT_EMPTY_VALUE
  return (
    compact([obj.first_name, `${obj.middle_name?.substr(0, 1)}${obj.middle_name ? '.' : ''}`, obj.last_name]).join(' ') ||
    DEFAULT_EMPTY_VALUE
  )
}

export const firstNameFromFullName = (fullName: string) => {
  if (!fullName) return DEFAULT_EMPTY_VALUE
  return fullName.split(' ')[0]
}

export const lastNameFromFullName = (fullName: string) => {
  if (!fullName) return DEFAULT_EMPTY_VALUE

  const split = fullName.split(' ')

  return split[split.length - 1]
}

export const namePartsFromFullName = (fullName: string) => {
  if (!fullName) return { firstName: DEFAULT_EMPTY_VALUE, lastName: DEFAULT_EMPTY_VALUE }

  const split = fullName.split(' ')

  return {
    firstName: split[0],
    lastName: split[split.length - 1],
  }
}

export const address = (obj, empty = DEFAULT_EMPTY_VALUE) => {
  if (!obj) return empty
  return compact([obj.address_line_1, obj.address_line_2, obj.city, obj.state, obj.zip_code]).join(', ') || empty
}

export const addressMultiLine = (obj, empty = DEFAULT_EMPTY_VALUE) => {
  if (!obj) return empty
  return compact([obj.address_line_1, obj.address_line_2, obj.city, obj.state, obj.zip_code]).join('\n') || empty
}

export const amount = (value, positive = false) => {
  if (!value) return 0
  const amnt = positive ? Math.abs(parseInt((parseFloat(value) * 100).toFixed(2))) : parseInt((parseFloat(value) * 100).toFixed(2))
  return dinero({ amount: amnt }).toFormat('0,0.00')
}

export const niceAmount = (value: any) => {
  if (!value) return '$0.00'
  const amount = Math.abs(parseInt((parseFloat(value) * 100).toFixed(2)))

  return dinero({ amount: amount }).toFormat('$0,0.00')
}

export const beautifulAmount = (value, positive = false) => {
  if (!value) return '$0'

  const amount = Math.abs(parseInt((parseFloat(value) * 100).toFixed(2)))
  const dineroAmount = dinero({ amount: amount })

  return dineroAmount.hasSubUnits() ? dineroAmount.toFormat('$0,0.00') : dineroAmount.toFormat('$0,0')
}

export const getCentsAmount = (amount) => {
  if (!amount) return 0
  return Math.floor(parseFloat(amount) * 100)
}

export const getDollarAmount = (amount) => {
  if (!amount) return 0
  return parseFloat(parseFloat(amount).toFixed(2))
}

export const setAmountString = (amount) => {
  if (!amount) return '0.00'
  return parseFloat(amount).toFixed(2)
}

export const clientCheck = (obj) => {
  if (!obj) return {}
  if (!obj.type === 'resident') return {}

  return obj
}

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const singularOrPlural = (value) => {
  if (value !== 1) return 's'
  return ''
}

export const hasOrHave = (value) => {
  if (value !== 1) return 'have'
  return 'has'
}

export const possessiveNoun = (value) => {
  if (!value || value.length === 0) return null
  if (value.slice(-1) === 's') return value + "'"
  return value + "'s"
}

export const numberToWords = (value) => {
  if (!value) return DEFAULT_EMPTY_VALUE

  let th = ['', 'thousand', 'million', 'billion', 'trillion']
  let dg = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine']
  let tn = ['ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen']
  let tw = ['twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety']

  function toWords(s) {
    s = s.toString()
    s = s.replace(/[, ]/g, '')

    let x = s.indexOf('.')
    if (x === -1) {
      x = s.length
    }
    if (x > 15) {
      return 'too big'
    }

    let n = s.split('')
    let str = ''
    let sk = 0

    for (let i = 0; i < x; i++) {
      if ((x - i) % 3 === 2) {
        if (n[i] === '1') {
          str += tn[Number(n[i + 1])] + ' '
          i++
          sk = 1
        } else if (n[i] !== 0) {
          str += tw[n[i] - 2] + ' '
          sk = 1
        }
      } else if (n[i] !== 0) {
        str += dg[n[i]] + ' '
        if ((x - i) % 3 === 0) {
          str += 'hundred '
        }
        sk = 1
      }

      if ((x - i) % 3 === 1) {
        if (sk) {
          str += th[(x - i - 1) / 3] + ' '
        }
        sk = 0
      }
    }

    if (x !== s.length) {
      let y = s.length
      str += 'point '
      for (let i = x + 1; i < y; i++) {
        str += dg[n[i]] + ' '
      }
    }

    return str.replace(/\s+/g, ' ')
  }

  return toWords(parseInt(value))
}

export const encodeObjectToURL = (value) => {
  if (!value) return null
  return compact(
    Object.entries(value).map(([key, val]) => {
      if (!val) return null
      return `${key}=${encodeURIComponent(val)}`
    }),
  ).join('&')
}

export const asLink = (link) => {
  if (!link) return DEFAULT_EMPTY_VALUE
  const hasProtocol = link.includes('://')
  return (
    <a href={`${hasProtocol ? '' : '//'}${link}`} target="_blank" rel="noopener noreferrer">
      {link}
    </a>
  )
}

export const isiOSSafari = () => {
  const ua = window.navigator.userAgent
  const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i)
  const webkit = !!ua.match(/WebKit/i)

  return iOS && webkit && !ua.match(/CriOS/i)
}

export const randomID = () => {
  return Math.random().toString(16).slice(2)
}

export const randomIntBetween = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export const nicePercentage = (value) => {
  if (!value) return 0

  if (Number.isInteger(value)) return Math.floor(value)
  return parseFloat(value)
}

export const plural = (value: string, empty = DEFAULT_EMPTY_VALUE) => {
  if (!value) return empty
  return pluralize(value)
}

export const singular = (value: string, empty = DEFAULT_EMPTY_VALUE) => {
  if (!value) return empty
  return pluralize.singular(value)
}

export const countWord = (word: string, count = 0, empty = DEFAULT_EMPTY_VALUE) => {
  if (!word) return empty
  return `${count} ${pluralize(word, count)}`
}

export const nicePhoneNo = (phoneNo: string) => {
  if (!phoneNo) return DEFAULT_EMPTY_VALUE

  //Filter only numbers from the input
  let cleaned = ('' + phoneNo).replace(/\D/g, '')

  //Check if the input is of correct
  let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)

  if (match) {
    //Remove the matched extension code
    //Change this to format for any country code.
    let intlCode = match[1] ? '+1 ' : ''
    return ['(', match[2], ') ', match[3], '-', match[4]].join('')
  }

  return phoneNo || DEFAULT_EMPTY_VALUE
}

/*
  ----------------------
  DATE RELATED FUNCTIONS
  ----------------------
*/
export const getUTCDateAsDate = (date = Date.now()) => {
  const d = new Date(date)
  return new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate(), d.getUTCHours(), d.getUTCMinutes(), d.getUTCSeconds())
}

export const DT = (date, timezone = TIMEZONE) => {
  if (!date) return null
  if (typeof date === 'string') date = DateTime.fromISO(date).setZone(timezone)
  if (typeof date === 'number') {
    const stringDate = date.toString()
    if (stringDate.length >= 13) {
      date = DateTime.fromMillis(date).setZone(timezone)
    } else if (stringDate.length >= 10) {
      date = DateTime.fromSeconds(date).setZone(timezone)
    }
  }

  return date
}

export const age = (date, timezone = TIMEZONE) => {
  if (!date) return DEFAULT_EMPTY_VALUE
  if (typeof date === 'string') date = DateTime.fromISO(date).setZone(timezone)
  if (!date.isValid) return DEFAULT_EMPTY_VALUE

  const now = DateTime.local().setZone(timezone)

  return Math.floor(now.diff(date, 'years').toObject().years)
}

export const isPast = (date, timezone = TIMEZONE) => {
  if (!date) return false

  if (typeof date === 'string') date = DateTime.fromISO(date).setZone(timezone)
  const now = DateTime.local().setZone(timezone)

  if (date <= now) return true
  else return false
}

export const isFuture = (date, timezone = TIMEZONE) => {
  if (!date) return false

  if (typeof date === 'string') date = DateTime.fromISO(date).setZone(timezone)
  const now = DateTime.local().setZone(timezone)

  if (date.startOf('day') >= now.startOf('day')) return true
  else return false
}

// there is at
export const isExclusivePast = (date, timezone = TIMEZONE, defaultValue = DEFAULT_EMPTY_VALUE) => {
  if (!date) return defaultValue

  if (typeof date === 'string') date = DateTime.fromISO(date).setZone(timezone)
  const now = DateTime.local().setZone(timezone)

  if (date <= now) return true
  else return false
}

export const daysToWords = (date, timezone = TIMEZONE) => {
  if (!date) return DEFAULT_EMPTY_VALUE
  if (typeof date === 'string') {
    return DateTime.fromISO(date).setZone(timezone).toRelative()
  } else {
    return date.setZone(timezone).toRelative()
  }
}

export const daysTo = (date, timezone = TIMEZONE) => {
  if (!date) return DEFAULT_EMPTY_VALUE
  if (typeof date === 'string') date = DateTime.fromISO(date).setZone(timezone)
  const now = DateTime.local().setZone(timezone)

  return Math.floor(date.diff(now, 'days').toObject().days) + 1
}

export const daysFrom = (date, timezone = TIMEZONE) => {
  if (!date) return DEFAULT_EMPTY_VALUE
  if (typeof date === 'string') date = DateTime.fromISO(date).setZone(timezone)
  const now = DateTime.local().setZone(timezone)

  return Math.floor(now.diff(date, 'days').toObject().days) + 1
}

export const daysHoursFrom = (date, timezone = TIMEZONE, empty: any = DEFAULT_EMPTY_VALUE) => {
  if (!date) return empty
  if (typeof date === 'string') date = DateTime.fromISO(date).setZone(timezone)
  const now = DateTime.local().setZone(timezone)

  return now.diff(date, ['days', 'hours']).toObject()
}

export const hoursFrom = (date, timezone = TIMEZONE) => {
  if (!date) return DEFAULT_EMPTY_VALUE
  if (typeof date === 'string') date = DateTime.fromISO(date).setZone(timezone)
  const now = DateTime.local().setZone(timezone)

  return Math.floor(now.diff(date, 'hours').toObject().hours)
}

export const daysBetween = (date1, date2, timezone = TIMEZONE) => {
  if (!date1) return DEFAULT_EMPTY_VALUE
  if (!date2) return DEFAULT_EMPTY_VALUE
  if (typeof date1 === 'string') date1 = DateTime.fromISO(date1).setZone(timezone)
  if (typeof date2 === 'string') date2 = DateTime.fromISO(date2).setZone(timezone)

  return Math.floor(Math.abs(date2.diff(date1, 'days').toObject().days)) + 1
}

export const hoursBetween = (date1, date2, timezone = TIMEZONE) => {
  if (!date1) return DEFAULT_EMPTY_VALUE
  if (!date2) return DEFAULT_EMPTY_VALUE
  if (typeof date1 === 'string') date1 = DateTime.fromISO(date1).setZone(timezone)
  if (typeof date2 === 'string') date2 = DateTime.fromISO(date2).setZone(timezone)

  return Math.floor(Math.abs(date2.diff(date1, 'hours').toObject().hours))
}

export const hoursBetweenRelative = (date1, date2, timezone = TIMEZONE, empty: any = null) => {
  if (!date1) return empty
  if (!date2) return empty
  if (typeof date1 === 'string') date1 = DateTime.fromISO(date1).setZone(timezone)
  if (typeof date2 === 'string') date2 = DateTime.fromISO(date2).setZone(timezone)

  return date2.diff(date1, 'hours').toObject().hours
}

export const monthsBetweenRelative = (date1, date2, timezone = TIMEZONE, empty: any = null) => {
  if (!date1) return empty
  if (!date2) return empty
  if (typeof date1 === 'string') date1 = DateTime.fromISO(date1).setZone(timezone)
  if (typeof date2 === 'string') date2 = DateTime.fromISO(date2).setZone(timezone)

  return date2.diff(date1, 'months').toObject().months
}

export const minutesBetween = (date1, date2, timezone = TIMEZONE) => {
  if (!date1) return DEFAULT_EMPTY_VALUE
  if (!date2) return DEFAULT_EMPTY_VALUE
  if (typeof date1 === 'string') date1 = DateTime.fromISO(date1).setZone(timezone)
  if (typeof date2 === 'string') date2 = DateTime.fromISO(date2).setZone(timezone)

  return Math.floor(Math.abs(date2.diff(date1, 'minutes').toObject().minutes))
}

export const secondsBetween = (date1, date2, timezone = TIMEZONE, empty: any = DEFAULT_EMPTY_VALUE) => {
  if (!date1) return empty
  if (!date2) return empty
  if (typeof date1 === 'string') date1 = DateTime.fromISO(date1).setZone(timezone)
  if (typeof date2 === 'string') date2 = DateTime.fromISO(date2).setZone(timezone)

  return Math.floor(Math.abs(date2.diff(date1, 'seconds').toObject().seconds))
}

export const secondsBetweenRelative = (date1, date2, timezone = TIMEZONE, empty: any = DEFAULT_EMPTY_VALUE) => {
  if (!date1) return empty
  if (!date2) return empty
  if (typeof date1 === 'string') date1 = DateTime.fromISO(date1).setZone(timezone)
  if (typeof date2 === 'string') date2 = DateTime.fromISO(date2).setZone(timezone)

  return Math.floor(date2.diff(date1, 'seconds').toObject().seconds)
}

export const usDate = (date, timezone = TIMEZONE) => {
  if (!date) return DEFAULT_EMPTY_VALUE
  if (typeof date === 'string') date = DateTime.fromISO(date).setZone(timezone)
  return date.toFormat('MM-dd-yyyy')
}

export const usDateShort = (date, timezone = TIMEZONE) => {
  if (!date) return DEFAULT_EMPTY_VALUE
  if (typeof date === 'string') date = DateTime.fromISO(date).setZone(timezone)
  return date.toFormat('MM-dd-yy')
}

export const usTime = (date, timezone = TIMEZONE) => {
  if (!date) return DEFAULT_EMPTY_VALUE
  if (typeof date === 'string') date = DateTime.fromISO(date).setZone(timezone)
  return date.toFormat('hh:mma')
}

export const usTimeShort = (date, timezone = TIMEZONE) => {
  if (!date) return DEFAULT_EMPTY_VALUE
  if (typeof date === 'string') date = DateTime.fromISO(date).setZone(timezone)
  return date.toFormat('h:mma')
}

export const usDateTime = (date, timezone = TIMEZONE) => {
  if (!date) return DEFAULT_EMPTY_VALUE
  return DT(date, timezone)?.toFormat('MM-dd-yyyy, hh:mma')
}

export const titleDate = (date, timezone = TIMEZONE) => {
  if (!date) return DEFAULT_EMPTY_VALUE
  return DT(date, timezone)?.toFormat('ccc, MMM dd yyyy')
}

export const spelledDate = (date, timezone = TIMEZONE) => {
  if (!date) return DEFAULT_EMPTY_VALUE
  return DT(date, timezone)?.toFormat('MMMM dd, yyyy')
}

export const beautifulDate = (date, timezone = TIMEZONE) => {
  if (!date) return DEFAULT_EMPTY_VALUE
  return DT(date, timezone)?.toFormat('ccc, MMM dd, hh:mma z')
}

export const addDays = (date, days, timezone = TIMEZONE) => {
  if (!date || !days) return null

  return DT(date)
    .setZone(timezone)
    .plus({ days: parseInt(days) })
    .toISO()
}

export const addWeeks = (date, weeks, timezone = TIMEZONE) => {
  if (!date || !weeks) return null

  return DT(date)
    .plus({ weeks: parseInt(weeks) })
    .toISO()
}

export const addMonths = (date, months, timezone = TIMEZONE) => {
  if (!date || !months) return null

  return DT(date)
    .plus({ months: parseInt(months) })
    .toISO()
}

export const combineDateTime = (date, time) => {
  if (!date || !time) return null

  const dateStr = date.substr(0, 10)
  return time.replace(/.{1,10}/, dateStr)
}

/*
  ----------------------
  DATA RELATED FUNCTIONS
  ----------------------
*/
export const readFileAsDataURL = (file) => {
  const temporaryFileReader = new FileReader()

  return new Promise((resolve, reject) => {
    temporaryFileReader.onerror = () => {
      temporaryFileReader.abort()
      reject(new DOMException('Problem parsing input file.'))
    }

    temporaryFileReader.onload = () => {
      resolve(temporaryFileReader.result)
    }

    temporaryFileReader.readAsDataURL(file)
  })
}

export const arrayToMap = (value) => {
  if (!value) return []
  if (!Array.isArray(value)) return value

  let map = {}
  for (let i = 0; i < value.length; i++) map[value[i].id] = value[i]

  return map
}

export const arrayToMapWithKey = (value, key) => {
  if (!value) return []
  if (!Array.isArray(value)) return value

  let map = {}
  for (let i = 0; i < value.length; i++) map[value[i][key]] = value[i]

  return map
}

export const arrayToMapWithKeyPrefix = (value, key, prefix) => {
  if (!value) return []
  if (!Array.isArray(value)) return value

  let map: any = {}
  for (let i = 0; i < value.length; i++) {
    map[`${prefix}${value[i][key]}`] = value[i]
  }

  return map
}

export const arrayToInternalMap = (value) => {
  if (!value) return []
  if (!Array.isArray(value)) return value

  let map = {}
  for (let i = 0; i < value.length; i++) map[value[i]._id] = value[i]

  return map
}

export const arrayToInternalMapWithUUID = (value) => {
  if (!value) return []
  if (!Array.isArray(value)) return value

  let map = {}
  for (let i = 0; i < value.length; i++) {
    const id = uuid()
    map[id] = { ...value[i], _id: id }
  }

  return map
}

export const mapToArray = (value: any, empty = {}) => {
  if (size(value) === 0) return []
  if (Array.isArray(value)) return value

  let array = []
  for (let key in value) array.push(value[key])

  return array
}

export const addInternalUUIDToArray = (value: any) => {
  if (size(value) === 0) return []
  if (!Array.isArray(value)) return value

  let array = []
  for (let i = 0; i < value.length; i++) {
    array.push({ ...value[i], _id: uuid() })
  }

  return array
}

export const arrayToIndexMap = (value: any) => {
  if (!value) return []
  if (!Array.isArray(value)) return value

  let map = {}
  for (let i = 0; i < value.length; i++) map[i] = value[i]

  return map
}

export const flattenJSON = (value, map = new Map(), prefix = '') => {
  if (Array.isArray(value)) {
    for (let k = 0; k < value.length; k++) {
      if (typeof value[k] == 'object' && value[k]) flattenJSON(value[k], map, prefix + '[' + k + ']')
      else map.set(prefix + '[' + k + ']', value[k])
    }
    return map
  }

  for (const k in value) {
    if (typeof value[k] == 'object' && value[k]) flattenJSON(value[k], map, (prefix ? prefix + '.' : '') + k)
    else map.set((prefix ? prefix + '.' : '') + k, value[k])
  }

  return map
}

export const objectToFlatObject = (object: any, trgt = {}, pth = []) => {
  let target = trgt || {}
  let path = pth || []
  const opts = {
    keepArray: false,
    separator: '.',
  }

  const isArray = Array.isArray(object)

  Object.keys(object).forEach((key: any) => {
    let index = isArray ? '[' + key + ']' : key

    if (
      isArrayOrObject(object[key]) &&
      ((isObject(object[key]) && !isEmptyObject(object[key])) ||
        (Array.isArray(object[key]) && !opts.keepArray && object[key].length !== 0))
    ) {
      if (isArray) {
        let previousKey = path[path.length - 1] || ''
        return objectToMap(object[key], target, path.slice(0, -1).concat(previousKey + index))
      } else {
        return objectToMap(object[key], target, path.concat(index))
      }
    } else {
      if (isArray) {
        target[path.join(opts.separator).concat('[' + key + ']')] = object[key]
      } else {
        target[path.concat(index).join(opts.separator)] = object[key]
      }
    }
  })

  return target
}

export const objectToMap = (object: any) => {
  if (!object) return object

  return new Map(
    Object.entries(
      flatten(object, {
        safe: true,
      }),
    ),
  )
}

export const cleanJSON = (json, result = {}) => {
  // if (Array.isArray(json)) {
  //   for (let i = 0; i < value.length; i++) {
  //     if (typeof value[i] == 'object' && value[i]) cleanJSON(value[i], json)
  //     else value[i] = pick(object, 'id')
  //   }
  //   return map
  // }
  // for (const key in json) {
  //   if (typeof json[key] == 'object' && json[key]) cleanJSON(json[key])
  //   else if (key !== 'id')
  // }
  // j
  // return map
}

export const omitDeep = (obj, key) => {
  if (Array.isArray(obj)) return omitDeepArrayWalk(obj, key)

  const keys = Object.keys(obj)
  const newObj = {}

  keys.forEach((i) => {
    if (i !== key) {
      const val = obj[i]
      if (Array.isArray(val)) newObj[i] = omitDeepArrayWalk(val, key)
      else if (typeof val === 'object' && val !== null) newObj[i] = omitDeep(val, key)
      else newObj[i] = val
    }
  })

  return newObj
}

export const omitDeepArrayWalk = (arr, key) => {
  return arr.map((val) => {
    if (Array.isArray(val)) return omitDeepArrayWalk(val, key)
    else if (typeof val === 'object') return omitDeep(val, key)
    return val
  })
}

export const pickDeep = (obj, key) => {
  if (Array.isArray(obj)) return pickDeepArrayWalk(obj, key)

  const keys = Object.keys(obj)
  const newObj = {}

  keys.forEach((i) => {
    if (i !== key) {
      const val = obj[i]
      if (Array.isArray(val)) newObj[i] = pickDeepArrayWalk(val, key)
      else if (typeof val === 'object' && val !== null) newObj[i] = pickDeep(val, key)
    } else newObj[i] = obj[i]
  })

  return newObj
}

export const pickDeepArrayWalk = (arr, key) => {
  return arr.map((val) => {
    if (Array.isArray(val)) return pickDeepArrayWalk(val, key)
    else if (typeof val === 'object') return pickDeep(val, key)
    return val
  })
}

export const exclusiveMerge = (obj1, obj2) => {
  let obj1Keys = Object.keys(obj1)
  let obj = {}

  for (let i = 0; i < obj1Keys.length; i++) {
    if (obj2.hasOwnProperty(obj1Keys[i])) {
      if (typeof obj1[obj1Keys[i]] === 'object') obj[obj1Keys[i]] = Object.assign(obj1[obj1Keys[i]], obj2[obj1Keys[i]])
      else obj[obj1Keys[i]] = obj2[obj1Keys[i]]
    }
  }

  return obj
}

export const sortObjectKeys = (object, sort) => {
  if (!object) return {}

  const keys = Object.keys(object)
  keys.sort(sort)

  let result = {}
  for (let i = 0; i < keys.length; i++) {
    result[keys[i]] = object[keys[i]]
  }

  return result
}

export const getFirstValueFromMap = (obj) => {
  return obj ? Object.values(obj)[0] : null
}

export const getLastValueFromMap = (obj) => {
  if (!obj) return null

  const valuesArray = Object.values(obj)
  return valuesArray[valuesArray.length - 1]
}

export const findIndexByID = (arr: any, id: string) => {
  let index = null

  if (!Array.isArray(arr)) return index

  for (let i = 0; i < arr.length; i++) {
    if (id !== arr[i].id) continue

    index = i
    break
  }

  return index
}

/*
  AVAILABILITY
*/
export const isWebGLAvailable = () => {
  try {
    const canvas = document.createElement('canvas')
    return !!(window.WebGLRenderingContext && (canvas.getContext('webgl') || canvas.getContext('experimental-webgl')))
  } catch (e) {
    return false
  }
}

/*
  FORMS VERSIONING
*/

export const getVersionSlug = (version?: string) => {
  if (!version) return '10'
  return version.replace('.', '')
}

export const notionIDFormURL = (value: string) => {
  if (!value) return null
  if (value.length < 32) return null

  const segments = value.split('/')
  const lastSlug = segments[segments.length - 1]
  const slugSegments = lastSlug.split('-')
  const notionID = slugSegments[slugSegments.length - 1]

  if (notionID.length !== 32) return null

  return notionID
}

/*
  App URLs
*/

export const getClientLink = (client: any) => {
  if (!client) return null

  const slug = CLIENT_SLUG_BY_STATUS[client.status] || 'clients'

  return `/${slug}/${client.id}`
}

export const getResourceLink = (resource: any) => {
  if (!resource?.id || !resource?.type) return null

  const { id, type } = resource

  if (type === 'resident') return getClientLink(resource)
  else if (type === 'employee') return `/employees/${id}`
  else if (type === 'house') return `/locations/${id}`
  else if (type === 'organization') return `/community/organizations/${id}`
  else if (type === 'insurance_local_payer') return `/insurance-payers/${id}`
  else return null
}

export const copyToClipboard = (value: any) => {
  if (!value) return null

  if ('clipboard' in navigator) {
    navigator.clipboard.writeText(value)
  } else {
    document.execCommand('copy', true, value)
  }
}

/*
  Permissions & Feature Flags
*/

export const processFeatureFlag = (key: any, flags: any) => (isBoolean(key) ? key : get(flags, key))

export const processPermission = (key: any, permissions: any) => {
  let permissionValue = true

  // process permission
  if (isBoolean(key)) {
    permissionValue = key
  } else if (key) {
    if (key === 'test_results') {
      let uat_view = get(permissions, 'test_results.ua_tests.view')
      let bt_view = get(permissions, 'test_results.breathalyzer_tests.view')

      if (uat_view === 'all') uat_view = true
      if (bt_view === 'all') bt_view = true

      permissionValue = uat_view || bt_view
    } else {
      // check if view is on
      let viewKeyArray = key.split('.')
      viewKeyArray.splice(-1, 1)
      viewKeyArray.push('view')

      // check that the view key is allowed; we do this to block create/update/delete without view
      const viewKey = viewKeyArray.join('.')
      const viewPermissions = get(permissions, viewKey)
      if (isString(viewPermissions) && viewPermissions === 'none') return false
      else if (viewPermissions === false) return false

      // check that the key is allowed
      permissionValue = get(permissions, key, false)
      if (isString(permissionValue)) {
        if (permissionValue === 'all') permissionValue = true
        else if (permissionValue === 'none') permissionValue = false
      }
    }
  }

  return permissionValue
}

export const getPermission = (args: any) => {
  const { permission, featureFlag, dev, marketing, userPermissions, userFeatureFlags, tenant, user } = args

  // extra checks
  const hasFeatureFlags = size(userFeatureFlags) > 0
  const isTrialing = tenant?.plan_status === 'trialing'
  const showMarketing = isTrialing && marketing
  const isBehave = user?.type === 'bh_employee'

  // what do we need to check for?
  const checkForDev = isDefined(dev)
  const checkForPermission = isDefined(permission)
  const checkForFeatureFlag = isDefined(featureFlag) && hasFeatureFlags

  // check behave
  if (checkForDev && !isBehave) return false

  // check flag
  if (checkForFeatureFlag) {
    const flagValue = processFeatureFlag(featureFlag, userFeatureFlags)
    if (!flagValue && !showMarketing) return false
  }

  // check permission
  if (checkForPermission) {
    const permissionValue = processPermission(permission, userPermissions)
    if (!permissionValue) return false
  }

  return true
}

/*
  HUBSPOT LINKS
*/

export const getHubspotCompanyLink = (id: string) => {
  if (!id) return null
  return `https://app.hubspot.com/contacts/2828210/company/${id}`
}

export const getHubspotContactLink = (id: string) => {
  if (!id) return null
  return `https://app.hubspot.com/contacts/2828210/record/0-1/${id}`
}

export const getHubspotDealLink = (id: string) => {
  if (!id) return null
  return `https://app.hubspot.com/contacts/2828210/record/0-3/${id}`
}

export const getHubspotEHRAccountLink = (id: string) => {
  if (!id) return null
  return `https://app.hubspot.com/contacts/2828210/record/2-20336856/${id}`
}

export const getHubspotCommunityProfileLink = (id: string) => {
  if (!id) return null
  return `https://app.hubspot.com/contacts/2828210/record/2-20337433/${id}`
}

import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import size from 'lodash/size'

import { LOC } from '../../utils/constants'
import { useSettings } from '../../hooks/useSettings'

import { DataTable } from '../../components/DataTable/DataTable'
import { HighRiskAlertStatus } from '../HighRiskAlert/HighRiskAlertStatus'
import { ClientTrackSelector } from '../ClientTracks/ClientTrackSelector'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import { useDataTable } from '../../components/DataTable/useDataTable'

import Flex from '../../components/Flex'
import Avatar from '../../components/Avatar'
import ClientColorPicker from '../../components/ColorPickers/ClientColorPicker'
import ClientQuickView from '../../components/Overlays/quick/ClientQuickView'

import { FILTERS } from '../Filters/config'

export const ClientsDataTable = (props: any) => {
  const { testKey, localStorageKey = 'clients_v1' } = props
  const match = useRouteMatch()

  const { tenant, isBehave } = useSettings()

  const tableProps = useDataTable({
    name: 'clients',
    endpoint: '/residents',
    params: { status: 'current' },
    localStorageKey,
  })

  const isEHR = tenant?.category === 'healthcare_facility' || tenant?.category === 'healthcare_practice'
  const showERXFeatures = tenant?.feature_flags?.erx === true

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        width: 300,
        disableHide: true,
        // disableSort: true,
        disableSelect: true,
        formatValue: ({ data }: any) => {
          if (props.renderMainCell) {
            return props.renderMainCell(data)
          }

          return (
            <MainCell
              testKey="main_cell"
              id={data.id}
              value={data.name}
              avatar={data.avatar}
              to={`${match.url}/${data.id}`}
              quickViewOverlay={<ClientQuickView client={data} />}
            />
          )
        },
      },
      {
        title: 'High Risk Alert',
        model: 'high_risk_title',
        width: 300,
        formatValue: ({ data }: any) => {
          if (!data.high_risk_title) return null

          return <HighRiskAlertStatus title={data.high_risk_title} notes={data.high_risk_notes} />
        },
      },
      {
        title: 'Tags',
        model: 'tags',
        type: 'tags',
        featureFlag: 'tags',
        editPermission: 'tags.edit',
        tagSmartCategories: 'current_clients',
      },
      {
        title: 'Client Track',
        model: 'client_track',
        featureFlag: 'client_tracks',
        formatValue: ({ value, data }) => <ClientTrackSelector clientId={data.id} activeTrack={value} buttonLabel={null} />,
      },
      {
        title: 'Sex',
        model: 'sex',
        type: 'title',
        width: 140,
      },
      {
        id: 'house',
        title: 'Location',
        model: 'current_bed_occupancy.place.house',
        type: 'profile',
      },
      {
        id: 'room',
        title: 'Room',
        model: 'current_bed_occupancy.place.room',
        type: 'profile',
      },
      {
        title: 'Bed',
        id: 'bed',
        model: 'current_bed_occupancy.place',
        type: 'profile',
      },
      {
        id: 'assigned_staff',
        title: 'Assigned Staff',
        model: 'current_bed_occupancy.place.house.employees',
        formatValue: ({ value }: any) => {
          if (size(value) === 0) return null

          return (
            <Flex centerY nowrap gap="0.75rem">
              {value.map((employee) => (
                <Flex key={employee.id} centerY nowrap gap="0.5rem">
                  <Avatar src={employee.avatar} initials={employee.name} size={20} />
                  <div>{employee.name}</div>
                </Flex>
              ))}
            </Flex>
          )
        },
      },
      {
        title: 'Phone',
        type: 'phone',
        model: 'phone_no',
      },
      {
        title: 'Email',
        type: 'email',
        model: 'email',
      },
      {
        title: 'Color',
        model: 'color_code',
        width: 70,
        centerX: true,
        formatValue: ({ data }: any) => <ClientColorPicker clientID={data.id} color={data.color_code} />,
      },
      {
        title: 'Client ID',
        model: 'behave_id',
        width: 180,
        formatValue: ({ data }: any) => `#${data.behave_id}`,
      },
      {
        title: 'Preferred Name',
        model: 'preferred_name',
        width: 180,
      },
      {
        title: 'Last Name',
        model: 'last_name',
        width: 180,
      },
      {
        title: 'Admit Date',
        model: 'admitted_at',
        id: 'admission_admitted_at',
        type: 'date',
      },
      {
        title: 'Additional Info',
        model: 'notes',
        id: 'notes',
      },
      {
        title: 'Synced with eRX',
        model: 'erx_status',
        permission: showERXFeatures,
        formatValue: ({ data }: any) => {
          const status = data.erx_status

          if (!status) return null

          if (status === 'synced') return 'Yes'
          else if (status === 'not_synced') return 'No'
        },
      },
      {
        title: 'Using Portal',
        model: 'client_portal_status',
        width: 120,
        formatValue: ({ data }: any) => {
          const value = data.client_portal_status

          if (value === 'access') return 'Yes'
          else if (value === 'invited') return 'Invited'
          else return 'No'
        },
      },
      // {
      //   title: 'Level of Care',
      //   model: 'level_of_care',
      //   permission: isEHR,
      //   formatValue: ({ data }: any) => {
      //     if (!data.level_of_care) return null

      //     return LOC[data.level_of_care]
      //   },
      // },
      {
        title: 'Level of Care',
        id: 'level_of_care',
        model: 'active_level_of_care',
        permission: isEHR,
        formatValue: ({ data }: any) => {
          if (!data.active_level_of_care) return null

          return data.active_level_of_care?.name
        },
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
      },
    ],
    [showERXFeatures, isEHR, isBehave],
  )

  return (
    <>
      <DataTable
        {...tableProps}
        testKey={testKey}
        title="Clients"
        icon="clients"
        columns={columns}
        canSelect={false}
        filtersConfig={FILTERS.clients}
        onRowSelect={() => {}}
        onDataInitialized={() => {}}
        updateKey="client"
        updateDeleteEndpoint="/residents"
        invalidate={['clients', { status: 'current' }]}
        headerLinksConfig={[
          {
            type: 'report',
            to: '/reports/clients',
          },
        ]}
        {...props}
      />
    </>
  )
}

import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { useGet } from '@behavehealth/hooks/useNewAPI'
import { useSettings } from '@behavehealth/hooks/useSettings'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { AllergiesDataTable } from '@behavehealth/constructs/Allergies/AllergiesDataTable'
import { Button, Grid, Page } from '@behavehealth/components'
import { EPrescribingAccess } from '@behavehealth/constructs/ERX/EPrescribingAccess'

type Props = {
  canCreate: boolean
}

const pageConfig = {
  feature: 'allergies',
  title: 'Allergies',
}

const Allergies: React.FC<Props> = ({ canCreate = true }) => {
  const match = useRouteMatch()
  const clientId = match.params?.resource_id

  const tenant = useSettings()

  const { data: client }: any = useGet({
    name: ['client', clientId],
    url: `/residents/${clientId}`,
  })

  const tableProps = useDataTable({
    name: ['client', clientId, 'allergies'],
    endpoint: `/residents/${clientId}/allergies`,
    enabled: !!clientId,
    localStorageKey: 'client_allergies_v1',
  })

  const usesERX = tenant?.feature_flags?.erx === true

  const action = (
    <Button
      label="Add Allergy"
      glyph="add"
      type="primary"
      link={{
        pathname: `${match.url}/new`,
        parent: match,
      }}
    />
  )

  return (
    <Page feature="allergies" actions={canCreate && !usesERX && action}>
      <Grid gap="1rem">
        <EPrescribingAccess tenant={tenant} client={client} />

        <AllergiesDataTable
          {...tableProps}
          to={(row) => ({
            pathname: `${match.url}/${row?.id}`,
            parent: match,
          })}
        />
      </Grid>
    </Page>
  )
}

export default withMarketing(Allergies, pageConfig)

import React from 'react'
import clsx from 'clsx'

import Icon from '../../../components/Icon'
import TreeItem from '../../../components/TreeItem'

export const ReviewSection = (props: any) => {
  const { title, titleAfter, icon, children, className, isOpen = true } = props

  const classes = clsx('border-b border-0 border-solid border-divider pt-2 pb-1', className)

  return (
    <TreeItem
      isOpen={isOpen}
      className={classes}
      headerClassName="!pl-3 !py-1"
      shouldIndent={false}
      title={
        <div className="flex items-center flex-nowrap">
          {icon && (
            <div className="w-12 flex justify-center items-center">
              <Icon icon={icon} size={20} />
            </div>
          )}

          <div className="py-2.5 text-[1.15rem] font-[600] mr-2">{title}</div>

          {titleAfter}
        </div>
      }
    >
      {children && <div className="px-10 pb-6 pt-2">{children}</div>}
    </TreeItem>
  )
}

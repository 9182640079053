import React from 'react'
import { v4 as uuid } from 'uuid'
import produce from 'immer'
import size from 'lodash/size'
import isLodashEmpty from 'lodash/isEmpty'

import { COLORS } from '../../../theme'
import { niceAmount, arrayToInternalMap, mapToArray } from '../../../utils/functions'
import { withFormContext } from '../../../components/Forms/context'

import Alert from '../../../components/Alert'
import Button from '../../../components/Button'
import Card from '../../../components/Card'
import CardContent from '../../../components/CardContent'
import CardHeader from '../../../components/CardHeader'
import CardTitle from '../../../components/CardTitle'
import DateInput from '../../../components/Forms/DateInput'
import GridTable from '../../../components/GridTable'
import NumberInput from '../../../components/Forms/NumberInput'
import AmountInput from '../../../components/Forms/AmountInput'
import MultiObjectSelector from '../../../components/Forms/Selectors/MultiObject/MultiObjectSelector'

import ModifierCodesSelector from '../../../components/Forms/elements/ModifierCodesSelector'
import { ComboBox } from '../../../components/Forms/ComboBoxes/ComboBox'

import { useFormField } from '../../../components/Forms/hooks/useFormField'

const columns = [
  {
    name: 'Start Date',
    width: 'minmax(240px, 1fr)',
  },
  {
    name: 'End Date',
    width: 'minmax(240px, 1fr)',
  },
  {
    name: 'Insurance Code *',
    width: 'minmax(350px, 1fr)',
  },
  {
    name: 'Modifier Codes',
    width: 'minmax(160px, 1fr)',
  },
  {
    name: 'Diagnoses',
    width: 'minmax(220px, 1fr)',
  },
  {
    name: 'Units',
    width: 'minmax(65px, 1fr)',
  },
  {
    name: 'Unit Price',
    width: 'minmax(160px, 1fr)',
  },
  {
    name: 'Total Charge',
    width: 'minmax(120px, 1fr)',
  },
  {
    name: '',
    width: '50px',
    hideOnReadonly: true,
  },
]

const gridColumnsEdit = columns.map((column) => column.width).join(' ')
const readonlyColumns = [...columns].slice(0, -1)
const gridColumnsReadonly = readonlyColumns.map((column) => column.width).join(' ')

const ProfessionalServiceLines = ({ value, model, form, isEditable, client, feeSchedule, includeObject }: any) => {
  const [initialData, setInitialData]: any = React.useState({})
  const [data, setData]: any = React.useState({})

  const isEmpty = size(data) === 0

  const { formActions } = useFormField({
    model: model,
    form: form,
    isNested: true,
    includeObject: includeObject,
  })

  // ON VALUE CHANGE
  React.useEffect(() => {
    let initialDta = value
    if (!initialDta) return

    initialDta = produce(initialDta, (draft: any) => {
      if (!Array.isArray(draft)) return

      draft?.map((o: any) => {
        o._id = uuid()

        // set defaults
        o.units ||= 1
        o.unit_price ||= null

        return o
      })
    })

    const initData = arrayToInternalMap(initialDta)

    if (isLodashEmpty(initialData)) setInitialData(initData)
    setData(initData)
  }, [])

  // UPDATE FORM
  React.useEffect(() => {
    formActions.setValue(mapToArray(data))
  }, [data])

  // FUNCTIONS
  const add = () => {
    const newID = uuid()

    setData((o: any) => ({
      ...o,
      [newID]: {
        _id: newID,
        service_date_start: null,
        service_date_end: null,
        insurance_new_code: null,
        modifier_codes: null,
        diagnoses: null,
        units: 1,
        unit_price: null,
        total_charge: 0,
      },
    }))
  }

  const remove = (id: string) => {
    const newData = produce(data, (draft: any) => {
      if (draft[id].id) {
        draft[id]._destroy = true
      } else {
        delete draft[id]
      }
    })

    setData(newData)
  }

  const findAmountInFeeSchedule = (insurance_new_code_id: string) => {
    if (!feeSchedule) return 0
    if (!feeSchedule.insurance_new_fee_schedule_services) return 0

    for (let i = 0; i < feeSchedule.insurance_new_fee_schedule_services.length; i++) {
      if (feeSchedule.insurance_new_fee_schedule_services[i].insurance_new_code.id === insurance_new_code_id) {
        return feeSchedule.insurance_new_fee_schedule_services[i].price
      }
    }

    return 0
  }

  const handleUpdate = (model: string, id: string, value: any) => {
    const newData = produce(data, (draft: any) => {
      draft[id][model] = value
    })
    setData(newData)
  }

  const handleMultiUpdate = (id: string, updates: object) => {
    const newData = produce(data, (draft: any) => {
      draft[id] = Object.assign({}, draft[id], updates)
    })

    setData(newData)
  }

  return (
    <>
      <Card>
        <CardHeader
          after={
            isEditable &&
            feeSchedule && <Button label="Add Service Line" glyph="add" type="minimal" display="inline-flex" size={200} onClick={add} />
          }
        >
          <CardTitle title="Service Lines" css={styles.cardTitle} />
        </CardHeader>

        <CardContent padding="0">
          <GridTable templateColumns={isEditable ? gridColumnsEdit : gridColumnsReadonly}>
            <GridTable.Header>
              {columns.map((column) => {
                if (!isEditable && column.hideOnReadonly) return null
                return <GridTable.Cell key={column.name}>{column.name}</GridTable.Cell>
              })}
            </GridTable.Header>

            {isEditable && !feeSchedule && (
              <Alert type="warning" glyph="info" css={{ margin: '1rem 0.5rem 1rem 0.5rem' }}>
                You must select a <strong>Fee Schedule</strong> before adding any Service Lines
              </Alert>
            )}

            {feeSchedule && isEmpty && (
              <div css={styles.emptyState}>
                <div>No service lines added yet</div>
              </div>
            )}

            {!isEmpty &&
              mapToArray(data)
                ?.filter((o) => o._destroy !== true)
                ?.map((item: any) => (
                  <GridTable.Row key={item._id}>
                    <GridTable.Cell>
                      <DateInput
                        value={data[item._id].service_date_start}
                        onUpdate={({ value }: any) => handleUpdate('service_date_start', item._id, value)}
                      />
                    </GridTable.Cell>

                    <GridTable.Cell>
                      <DateInput
                        value={data[item._id].service_date_end}
                        onUpdate={({ value }: any) => handleUpdate('service_date_end', item._id, value)}
                      />
                    </GridTable.Cell>

                    <GridTable.Cell>
                      <ComboBox
                        includeObject={includeObject}
                        type="insurance.new_fee_schedules.insurance_new_codes"
                        dependentValue={feeSchedule?.id}
                        value={data[item._id]?.insurance_new_code}
                        onUpdate={(object: any) => {
                          if (!object) return

                          if (data[item._id]?.insurance_new_code?.id !== object.id) {
                            const price = findAmountInFeeSchedule(object.id)

                            // update the Codes
                            handleMultiUpdate(item._id, {
                              insurance_new_code: object,
                              insurance_new_code_id: object.id,
                              modifier_codes: object.modifier_codes,
                              unit_price: parseFloat(price),
                            })
                          } else {
                            // update the Codes
                            handleMultiUpdate(item._id, {
                              insurance_new_code: object,
                              insurance_new_code_id: object.id,
                              modifier_codes: initialData[item._id]?.modifier_codes,
                              unit_price: initialData[item._id]?.unit_price,
                            })
                          }
                        }}
                        onClear={() => {
                          handleUpdate('insurance_new_code_id', item._id, null)
                        }}
                      />
                    </GridTable.Cell>

                    <GridTable.Cell>
                      <ModifierCodesSelector
                        includeObject={includeObject}
                        label={false}
                        value={data[item._id].modifier_codes}
                        onUpdate={({ value }: any) => {
                          handleUpdate('modifier_codes', item._id, value)
                        }}
                      />
                    </GridTable.Cell>

                    <GridTable.Cell>
                      <MultiObjectSelector
                        label=" "
                        includeObject
                        icon="diagnosis"
                        type="client.diagnoses"
                        value={data[item._id].diagnoses}
                        dependentValue={client?.id}
                        selectTitle={(data: any) => data.code}
                        selectDescription={(data: any) => data.description}
                        onUpdate={({ value, object }: any) => {
                          const ids = value?.map((o: any) => o.id)

                          handleUpdate('diagnoses', item._id, object)
                          handleUpdate('diagnoses_ids', item._id, ids)
                        }}
                      />
                    </GridTable.Cell>

                    <GridTable.Cell>
                      <NumberInput
                        value={data[item._id]?.units}
                        onUpdate={({ value }: any) => {
                          handleUpdate('units', item._id, value)
                        }}
                      />
                    </GridTable.Cell>

                    <GridTable.Cell>
                      <AmountInput
                        value={data[item._id].unit_price}
                        onUpdate={({ value }: any) => handleUpdate('unit_price', item._id, value)}
                      />
                    </GridTable.Cell>

                    <GridTable.Cell css={{ lineHeight: isEditable ? '2.5rem' : '1.2rem' }}>
                      {niceAmount(data[item._id]?.unit_price * data[item._id]?.units)}
                    </GridTable.Cell>

                    {isEditable && (
                      <GridTable.Cell>
                        <Button hideLabel glyph="delete" type="minimal" color="red" onClick={() => remove(item._id)} />
                      </GridTable.Cell>
                    )}
                  </GridTable.Row>
                ))}
          </GridTable>
        </CardContent>
      </Card>
    </>
  )
}

const styles = {
  emptyState: {
    display: 'grid',
    alignItems: 'center',
    textAlign: 'center',
    padding: '1rem',
    color: COLORS.textMuted,
    opacity: 0.8,
  },

  cardTitle: {
    fontSize: '1rem',
  },
}

export default withFormContext(ProfessionalServiceLines)

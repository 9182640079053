import React from 'react'
import size from 'lodash/size'

import { niceAmount } from '../../utils/functions'

import { InvoiceFeesInfo } from './InvoiceFeesInfo'

export const InvoiceLinesAmounts = ({ invoice }: any) => {
  if (size(invoice?.calculated_amounts) === 0) return null

  const amounts = invoice?.calculated_amounts

  return (
    <div className="grid gap-2 grid-cols-[200px_80px] tabular-nums">
      <div className="text-text-muted pr-2 text-right">Subtotal (before discount)</div>
      <div>{niceAmount(amounts.before_discount)}</div>

      <div className="text-text-muted pr-2 text-right">Discount</div>
      <div>{niceAmount(amounts.discount)}</div>

      <div className="text-text-muted pr-2 text-right">Subtotal (after discount)</div>
      <div>{niceAmount(amounts.subtotal)}</div>

      <div className="text-text-muted pr-2 text-right">Tax</div>
      <div>{niceAmount(amounts.tax)}</div>

      <div className="font-[500] pr-2 text-right text-[1.12em]">Total</div>
      <div className="inline-flex items-center text-[1.12em]">
        <span className="pr-1.5">{niceAmount(amounts.paid_total || amounts.total)}</span>
        <InvoiceFeesInfo invoice={invoice} />
      </div>
    </div>
  )
}

import { COLORS as BH_COLORS } from '../../../theme'

export const FORM_CONTENT_WIDTHS = {
  narrow: '540px',
  regular: '768px',
  large: '1024px',
  x_large: '1200px',
  full_width: '100%',
}

export const FORM_CONTENT_WIDTHS_INT = {
  narrow: 540,
  regular: 768,
  large: 1024,
  x_large: 1200,
  full_width: '100%',
}

export const FORM_CONTENT_WIDTHS_LABELS = {
  narrow: 'Narrow',
  regular: 'Regular',
  large: 'Wide',
  x_large: 'Extra Wide',
  full_width: 'Full-Width',
}

export const ELEMENT_EDITOR_STATES = {
  auto: 'Auto Open/Close',
  open: 'Open',
  closed: 'Closed',
}

export const VARIABLE_SUPPORT_FIELDS = [
  'title',
  'label',
  'content',
  'default_value',
  'placeholder',
  'description',
  'tooltip',
  'prefix',
  'suffix',
  'multi_input_values',
  'single_select_input_values',
  'object_selector_options',
  'multi_object_selector_options',
  'grid_table_rows',
  'grid_table_columns',
]

export const HQ_ACCOUNT_VARIABLES = [
  {
    category: 'account',
    description: 'The name of the Current Facility',
    name: 'Company Name',
    variable_key: 'AV_COMPANY_NAME',
    variable_value: '',
  },
  {
    category: 'account',
    description: 'The timezone of the Current Facility',
    name: 'Company Timezone',
    variable_key: 'AV_COMPANY_TIMEZONE',
    variable_value: '',
  },
  {
    category: 'account',
    description: 'The timezone of the Current Facility',
    name: 'Company City',
    variable_key: 'AV_COMPANY_CITY',
    variable_value: '',
  },
  {
    category: 'account',
    description: 'The timezone of the Current Facility',
    name: 'Company State',
    variable_key: 'AV_COMPANY_STATE',
    variable_value: '',
  },
  {
    category: 'account',
    description: 'The Account Owner Phone Number of the Current Facility',
    name: 'Company Account Phone Number',
    variable_key: 'AV_COMPANY_OWNER_PHONE_NUMBER',
    variable_value: '',
  },
  {
    category: 'account',
    description: 'The Mailing Address of the Current Facility',
    name: 'Company Mailing Address',
    variable_key: 'AV_COMPANY_MAILING_ADDRESS',
    variable_value: '',
  },
  {
    category: 'account',
    description: 'The Default Application URL of the Current Facility',
    name: 'Company Default Application URL',
    variable_key: 'AV_COMPANY_DEFAULT_APPLICATION_URL',
    variable_value: '',
  },
  {
    category: 'account',
    description: 'The Account Owner Email Address of the Current Facility',
    name: 'Company Account Owner Email',
    variable_key: 'AV_COMPANY_OWNER_EMAIL',
    variable_value: '',
  },
]

export const INSERT_MENUS = [
  {
    title: 'Content',
    elements: [
      'heading',
      'paragraph',
      'accordion',
      'alert',
      'image',
      'video',
      'lottie_animation',
      'iframe',
      'documents',
      'divider',
      'group',
    ],
  },
  {
    title: 'Text & Number Inputs',
    elements: ['text_input', 'textarea', 'rich_text', 'number_input', 'amount_input', 'slider_input'],
  },
  {
    title: 'Conditionals',
    elements: ['conditional'],
  },
  {
    title: 'Tables',
    elements: ['grid_table', 'worksheet'],
  },
  {
    title: 'Selection Inputs',
    elements: [
      'checkbox_input',
      'single_select_input_text',
      'single_select_input_number',
      'single_select_input_boolean',
      'object_selector',
      'multi_object_selector',
      'date_time_input',
      'address_selector_input',
      'signature_input',
    ],
  },
  {
    title: 'Attachments',
    elements: ['single_attachment_input', 'multi_attachment_input'],
  },
  {
    title: 'Contact Inputs',
    elements: [
      'phone_input',
      'landline_input',
      'email_input',
      'whatsapp_input',
      'messenger_input',
      'skype_input',
      'zoom_input',
      'google_meet_input',
    ],
  },
  {
    title: 'BH Internal Only',
    isBehaveOnly: true,
    elements: ['mermaid', 'oembed', 'canva_oembed'],
  },
]

export const SMART_ELEMENTS_MENU = [
  {
    title: 'Selectors',
    elements: [
      'drugs_of_choice_selector',
      'level_of_care',
      'relationship_selector',
      'ethnicity_selector',
      'sex_assigned_at_birth_selector',
      'gender_identity_selector',
      'preferred_pronouns_selector',
      'highest_level_of_education_selector_selector',
      'religious_preference_selector',
      'primary_language_selector',
      'marital_status_selector',
      'state_select',
    ],
  },
]

export const FILING_UNDER_OPTIONS = [
  {
    label: 'Community',
    resources: [
      {
        label: 'Organizations',
        type: 'organizations',
        feature: 'organizations',
      },
      {
        label: 'Organization Contacts',
        type: 'organization_contacts',
        feature: 'contacts',
      },
    ],
  },
  {
    label: 'Clients',
    resources: [
      {
        label: 'Admissions',
        type: 'admissions',
        feature: 'admissions',
      },
      {
        label: 'Current Clients',
        type: 'current_clients',
        feature: 'clients',
      },
      {
        label: 'Past Clients',
        type: 'past_clients',
        feature: 'alumni',
      },
      {
        label: 'Client Contacts',
        type: 'client_contacts',
        feature: 'contacts',
      },
    ],
  },
  {
    label: 'Planning',
    resources: [
      {
        label: 'Programs',
        type: 'programs',
        feature: 'programs',
      },
    ],
  },
  {
    label: 'Company',
    resources: [
      {
        label: 'Staff',
        type: 'staff',
        feature: 'employees',
      },
      {
        label: 'Locations',
        type: 'locations',
        feature: 'places',
      },
    ],
  },
] as const

export const ALERT_CATEGORIES = {
  info: {
    label: 'Info',
    color: 'blue',
    glyph: 'info',
  },
  note: {
    label: 'Note',
    color: 'gray',
    glyph: 'note',
  },
  warning: {
    label: 'Warning',
    color: 'orange',
    glyph: 'warning',
  },
  alert: {
    label: 'Alert',
    color: 'red',
    glyph: 'circle_error',
  },
  custom: {
    label: 'Custom',
    color: 'text',
    glyph: 'question',
  },
}

export const FORM_ELEMENTS_VALUE_TYPES = {
  text_input: 'text',
  textarea: 'text',
  // rich_text: 'rich_text',
  number_input: 'number',
  phone_input: 'text',
  landline_input: 'text',
  email_input: 'text',
  amount_input: 'amount',
  checkbox_input: 'checkbox',
  single_select_input_text: 'radio',
  single_select_input_number: 'radio',
  single_select_input_boolean: 'radio',
  object_selector: 'object_selector',
  multi_object_selector: 'multi_object_selector',
  // address_selector_input: 'text',
  // signature_input: 'text',
  // date_time_input: 'date_time',
  whatsapp_input: 'text',
  skype_input: 'text',
  zoom_input: 'text',
  google_meet_input: 'text',
  messenger_input: 'text',
  // sex_select_input: 'text',
  // gender_identity_select_input: 'text',
  // preferred_pronouns_select_input: 'text',
}

export const ARRAY_CONDITIONS = ['is_within', 'is_not_within']
export const PRIMITIVE_VALUE_CONDITIONS = ['is', 'is_not', 'is_greater_than', 'is_less_than']

export const CONDITIONS_CONFIG = {
  // generic conditions:
  is: 'Is',
  is_not: 'Is not',
  is_within: 'Is any of',
  is_within_exact: 'Is all of',
  is_not_within: 'Is none of',
  is_greater_than: 'Is greater than',
  is_less_than: 'Is less than',

  // checkbox conditions:
  is_some_selected: 'Selected any of',
  is_some_not_selected: 'Did not select any of',
  is_all_selected: 'Selected all of',
  is_all_not_selected: 'Did not select all of',
}

export const COLORS = {
  text: {
    cssVariable: 'bh-forms-color-text',
    value: BH_COLORS.text,
  },
  textMuted: {
    cssVariable: 'bh-forms-color-text-muted',
    value: BH_COLORS.textMuted,
  },
  red: {
    cssVariable: 'bh-forms-color-red',
    value: BH_COLORS.red,
  },
  orange: {
    cssVariable: 'bh-forms-color-orange',
    value: BH_COLORS.orange,
  },
  yellow: {
    cssVariable: 'bh-forms-color-yellow',
    value: BH_COLORS.yellow,
  },
  purple: {
    cssVariable: 'bh-forms-color-purple',
    value: BH_COLORS.purple,
  },
  blue: {
    cssVariable: 'bh-forms-color-blue',
    value: BH_COLORS.blue,
  },
  vividBlue: {
    cssVariable: 'bh-forms-color-vivid-blue',
    value: BH_COLORS.vividBlue,
  },
  green: {
    cssVariable: 'bh-forms-color-green',
    value: BH_COLORS.green,
  },
  mintGreen: {
    cssVariable: 'bh-forms-color-mint-green',
    value: BH_COLORS.mintGreen,
  },
}

export const BREAKPOINTS = {
  '280px': {
    name: '280px',
    value: '(min-width: 280px)',
  },
  '320px': {
    name: '320px',
    value: '(min-width: 320px)',
  },
  '480px': {
    name: '480px',
    value: '(min-width: 480px)',
  },
  '560px': {
    name: '560px',
    value: '(min-width: 560px)',
  },
  '600px': {
    name: '600px',
    value: '(min-width: 600px)',
  },
  '768px': {
    name: '768px',
    value: '(min-width: 768px)',
  },
  '1024px': {
    name: '1024px',
    value: '(min-width: 1024px)',
  },
  '1200px': {
    name: '1200px',
    value: '(min-width: 1200px)',
  },
  '1400px': {
    name: '1400px',
    value: '(min-width: 1400px)',
  },
  '1600px': {
    name: '1600px',
    value: '(min-width: 1600px)',
  },
}

export const SPACE = {
  0: {
    name: '0',
    value: '0',
  },
  2: {
    name: '2',
    value: '0.125rem',
  },
  4: {
    name: '4',
    value: '0.25rem',
  },
  8: {
    name: '8',
    value: '0.5rem',
  },
  12: {
    name: '1',
    value: '0.75rem',
  },
  16: {
    name: '1',
    value: '1rem',
  },
  20: {
    name: '2',
    value: '1.25rem',
  },
  24: {
    name: '2',
    value: '1.5rem',
  },
  28: {
    name: '2',
    value: '1.75rem',
  },
  32: {
    name: '3',
    value: '2rem',
  },
  40: {
    name: '4',
    value: '2.5rem',
  },
  48: {
    name: '4',
    value: '3rem',
  },
  56: {
    name: '5',
    value: '3.5rem',
  },
  64: {
    name: '6',
    value: '4rem',
  },
  72: {
    name: '7',
    value: '4.5rem',
  },
  80: {
    name: '8',
    value: '5rem',
  },
  100: {
    name: '1',
    value: '6.25rem',
  },
  120: {
    name: '1',
    value: '7.5rem',
  },
  140: {
    name: '1',
    value: '8.75rem',
  },
  160: {
    name: '1',
    value: '10rem',
  },
  200: {
    name: '2',
    value: '12.5rem',
  },
}

export const TYPOGRAPHY = {
  heading_1: {
    name: 'Heading 1',
    className: 'typography-heading-1',
    value: {
      fontSize: '2rem',
      fontWeight: 700,
    },
  },
  heading_2: {
    name: 'Heading 2',
    className: 'typography-heading-2',
    value: {
      fontSize: '1.75rem',
      fontWeight: 700,
    },
  },
  heading_3: {
    name: 'Heading 3',
    className: 'typography-heading-3',
    value: {
      fontSize: '1.5rem',
      fontWeight: 700,
    },
  },
  heading_4: {
    name: 'Heading 4',
    className: 'typography-heading-4',
    value: {
      fontSize: '1.25rem',
      fontWeight: 600,
    },
  },
  heading_5: {
    name: 'Heading 5',
    className: 'typography-heading-5',
    value: {
      fontSize: '1.1rem',
      fontWeight: 600,
    },
  },
  heading_6: {
    name: 'Heading 6',
    className: 'typography-heading-6',
    value: {
      fontSize: '1rem',
      fontWeight: 600,
    },
  },
  text_small: {
    name: 'Text Small',
    className: 'typography-text-small',
    value: {
      fontSize: '0.95rem',
      fontWeight: 400,
    },
  },
  text_regular: {
    name: 'Text Regular',
    className: 'typography-text-regular',
    value: {
      fontSize: '1rem',
      fontWeight: 400,
    },
  },
  text_large: {
    name: 'Text Large',
    className: 'typography-text-large',
    value: {
      fontSize: '1.1rem',
      fontWeight: 400,
    },
  },
  caps_small: {
    name: 'Caps Small',
    className: 'typography-caps-small',
    value: {
      fontSize: '1rem',
      fontWeight: 600,
      letterSpacing: '1px',
      textTransform: 'uppercase',
    },
  },
  caps_regular: {
    name: 'Caps Regular',
    className: 'typography-caps-regular',
    value: {
      fontSize: '0.95rem',
      fontWeight: 600,
      letterSpacing: '1px',
      textTransform: 'uppercase',
    },
  },
  caps_large: {
    name: 'Caps Large',
    className: 'typography-caps-large',
    value: {
      fontSize: '1.1rem',
      fontWeight: 700,
      letterSpacing: '1px',
      textTransform: 'uppercase',
    },
  },
}

export const CONTENT_WIDTHS = {
  full_width: {
    name: 'Full Width',
    className: 'content-width-full',
    value: '100%',
  },
  '1400px': {
    name: '1400px',
    className: 'content-width-1400',
    value: '1400px',
  },
  '1200px': {
    name: '1200px',
    className: 'content-width-1200',
    value: '1200px',
  },
  '1000px': {
    name: '1000px',
    className: 'content-width-1000',
    value: '1000px',
  },
  '800px': {
    name: '800px',
    className: 'content-width-800',
    value: '800px',
  },
  '600px': {
    name: '600px',
    className: 'content-width-600',
    value: '600px',
  },
  '400px': {
    name: '400px',
    className: 'content-width-400',
    value: '400px',
  },
}

export const CARD_STYLES = {
  card_style_1: {
    name: 'Card Style 1',
    className: 'card-style-1',
    value: {
      border: '1px solid #eee',
      boxShadow: '0 0 0.25rem 0.25rem var(--color-shadow)',
      padding: '1rem',
      borderRadius: '0.25rem',
      color: 'var(--card-text-color)',
      backgroundColor: 'var(--color-background-1)',
    },
  },
}

export const BUTTON_VARIANTS = {
  primary: {
    name: 'Primary',
    className: 'button-variant-primary',
    value: {
      display: 'inline-flex',
      width: 'fit-content',
      color: 'white',
      backgroundColor: 'var(--color-accent-1)',
      borderRadius: '0.25rem',
      border: '1px solid #eee',
      boxShadow: '0 0 0.25rem 0.25rem var(--color-shadow)',
      padding: '0.5em 1em',
    },
  },
  secondary: {
    name: 'Secondary',
    className: 'button-variant-secondary',
    value: {
      display: 'inline-flex',
      width: 'fit-content',
      color: 'var(--color-accent-1)',
      backgroundColor: 'var(--color-background-1)',
      borderRadius: '0.25rem',
      border: '1px solid #eee',
      boxShadow: '0 0 0.25rem 0.25rem var(--color-accent-1)',
      padding: '0.5em 1em',
    },
  },
}

export const BUTTON_SIZES = {
  small: {
    name: 'Small',
    className: 'button-size-small',
    value: {
      fontSize: '0.9rem',
    },
  },
  medium: {
    name: 'Medium',
    className: 'button-size-medium',
    value: {
      fontSize: '1rem',
    },
  },
  large: {
    name: 'Large',
    className: 'button-size-large',
    value: {
      fontSize: '1.1rem',
    },
  },
}

import React from 'react'
import { Redirect, Switch, Route, useHistory, useParams, useLocation, useRouteMatch } from 'react-router-dom'
import { keyframes } from '@emotion/react'
import { Routes, Route as RouteV6 } from 'react-router-dom-v5-compat'

import { address } from '@behavehealth/utils/functions'
import { useGet, useUpdate, useDelete } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'
import Notifications from '@behavehealth/modules/notifications'
import withBackListener from '@behavehealth/hocs/withBackListener'
import { store } from '@behavehealth/setup/store'
import { clearData, updateData } from '@behavehealth/actions/data'

import Tooltip from '@behavehealth/components/Tooltip'
import AnimatedSwitch from '@behavehealth/components/AnimatedSwitch'
import Button from '@behavehealth/components/Button'
import ButtonGroup from '@behavehealth/components/ButtonGroup'
import Card from '@behavehealth/components/Card'
import Grid from '@behavehealth/components/Grid'
import Flex from '@behavehealth/components/Flex'
import ConfirmDialog from '@behavehealth/components/Dialogs/ConfirmDialog'
import Dropdown from '@behavehealth/components/Dropdown'
import DropdownItem from '@behavehealth/components/DropdownItem'
import Nav from '@behavehealth/components/Nav'
import Page from '@behavehealth/components/Page'
import PageGrid from '@behavehealth/components/PageGrid'
import PageLoader from '@behavehealth/components/Loaders/PageLoader'
import PageProfile from '@behavehealth/components/PageProfile'
import Permission from '@behavehealth/components/Permission'
import Portal from '@behavehealth/components/Portal'
import State from '@behavehealth/components/State'
import Status from '@behavehealth/components/Status'
import SummonOverlay from '@behavehealth/components/SummonOverlay'

// INTERNAL PAGES
import ActivityTimeline from './internal/activity_timeline'
import Agreements from './internal/agreements'
import Calendar from './internal/calendar'
import ClientFinancials from './internal/client_financials'
import Communications from './internal/communications'
import Contacts from './internal/contacts'
import CustomNotes from './internal/custom_notes'
import InternalFiles from './internal/files'
import Financials from './internal/financials/financials'
import FormSubmissions from './internal/form_submissions'
import GeneralInfo from './internal/general_info'
import Profile from './internal/profile'
import SendMessage from './internal/send_message'
import SendReferral from './internal/send_referral'
import Settings from './internal/settings'
import ShiftNotes from './internal/shift_notes'
import Todos from './internal/todos/todos'
import InternalForms from './internal/forms'

// SHARED PAGES
import { BedAvailability } from './shared/bed_availability'
import { CommunityProfile } from './shared/community_profile'
import { CommunitySetup } from './shared/community_setup'
import { ContactUs } from './shared/contact_us'
import { EVOBApplicationFormPage } from './shared/evob_application_form'
import { Forms } from './shared/forms'
import { FullApplication } from './shared/application_form'
import { Locations } from './shared/locations'
import { Messages } from './shared/messages'
import { Partners } from './shared/partners'
import { Referrals } from './shared/referrals'
import { Requests } from './shared/requests'
import { ShareSettings } from './shared/share_settings'
import { Staff } from './shared/staff'
import { Website } from './shared/website'
import { Files } from './shared/files'

import { AgreementForms } from './shared/agreement_forms'
import { CertificationForms } from './shared/certification_forms'
import { GrievanceForms } from './shared/grievance_forms'
import { IncidentReporting } from './shared/incident_reporting'
import { InspectionForms } from './shared/inspection_forms'
import { LeadApplicationForms } from './shared/lead_application_forms'
import { Studies } from './shared/studies'
import { Surveys } from './shared/surveys'

import { ContactsFormSubmissions } from './internal/contacts_form_submissions'

import { CommunityOrganizationInternalNavItems } from '@behavehealth/components/Navs/CommunityOrganizationInternalNavItems'
import { CommunityOrganizationSharedNavItems } from '@behavehealth/components/Navs/CommunityOrganizationSharedNavItems'

import { PartnershipRequestOverlay } from '@behavehealth/constructs/Partnerships/PartnershipRequestOverlay'
import { useCommunityPermissions } from '@behavehealth/constructs/Community/useCommunityPermissions'
import { OrganizationCommunityRequestOverlay } from '@behavehealth/constructs/Community/OrganizationCommunityRequestOverlay'
import { OrganizationInviteRequestOverlay } from '@behavehealth/constructs/Community/OrganizationInviteRequestOverlay'
import { OrganizationDirectInviteOverlay } from '@behavehealth/constructs/Community/OrganizationDirectInviteOverlay'
import { PartnershipStatus } from '@behavehealth/constructs/Partnerships/PartnershipStatus'
import { EHROrganizationStatus } from '@behavehealth/constructs/Organizations/EHROrganizationStatus'
import { ProtectedRoute } from '@behavehealth/components/ProtectedRoute'
import TagsSelector from '@behavehealth/components/TagsSelector'

import { OrganizationFileOverlay } from '@behavehealth/constructs/Organizations/OrganizationFileOverlay'
import { OrganizationStatusGroupStatus } from '@behavehealth/constructs/Organizations/OrganizationStatusGroupStatus'
import OrganizationQuickActions from '@behavehealth/components/QuickActions/Organization'

import { COLORS, TOKENS, HARD_SHADOW } from '@behavehealth/theme'
import { Heading } from '@behavehealth/components/Typography'
import { useSettings } from '@behavehealth/hooks/useSettings'
import { OrganizationCertifyOverlay } from '@behavehealth/constructs/NARRDemo/OrganizationCertifyOverlay'

const RootOrganization = () => {
  const match = useRouteMatch()
  const location = useLocation()
  const history = useHistory()
  const params: any = useParams()
  const { isBehave } = useSettings()

  const { resource_id: id } = params

  const isShared = location.pathname.includes('/shared')
  const isInternal = location.pathname.includes('/internal')

  const initialTab = isShared ? 'shared' : 'internal'
  const [tab, setTab] = React.useState(initialTab)

  const {
    canViewPartnerSharedFiles,
    canRevokeReactivatePartnerships,
    canViewPartnerReferrals,
    canViewPartnerShareSettings,
  } = useCommunityPermissions()

  const { data: organization, isLoading, isError, error }: any = useGet({
    name: ['organization', id],
    url: `/organizations/${id}`,
  })

  const { mutateAsync: updatePartnership }: any = useUpdate({
    name: ['update-organization'],
    url: `/organizations/${id}`,
    invalidate: ['organization', id],
    invalidateKeys: ['organizations'],
  })

  const basePath = `/community/organizations/${organization?.id}`

  const revokePartnership = async () => {
    await updatePartnership({ partnership_status: 'partnership_inactive' })
  }

  const reactivatePartnership = async () => {
    await updatePartnership({ partnership_status: 'partnership_active' })
  }

  const archiveOrganization = async () => {
    await updatePartnership({ status: 'archived' })
  }

  React.useEffect(() => {
    if (!isError) return

    console.error(error)
    Notifications.send(error?.error?.data?.errors?.[0], 'negative')

    // go back to the Community Organizations list if unauthorized
    if (error?.error?.status === 401) history.push('/community/organizations')
  }, [isError])

  React.useEffect(() => {
    store.dispatch(updateData(`organizations.data.${organization?.id}`, organization))

    return () => {
      store.dispatch(clearData('organizations'))
    }
  }, [organization])

  if (!organization || isLoading) return <PageLoader />

  const isPartner = organization?.status === 'partner' && organization?.partnership_status === 'partnership_active'

  return (
    <>
      <div css={styles}>
        <header className={`flex items-center max-h-[50px] is-${tab}`} css={headerStyles}>
          <div className="flex items-stretch mq600:absolute h-full left-0 z-[2]">
            <Button
              link={'/community/organizations/all'}
              type="list"
              glyph="long_arrow_left"
              color="gray"
              borderRadius="0"
              flex="0 0 56px"
              css={backStyles}
            />

            <Heading is="h1" size={300} className="ml-4 hidden self-center mq900:block">
              Organizations
            </Heading>
          </div>

          <Flex gap="0.5rem" className="my-4 !mx-auto">
            <Button
              label="Internal Data"
              icon="organizations"
              type={tab === 'internal' ? 'primary' : 'minimal'}
              color={tab === 'internal' ? 'gray' : 'text'}
              onClick={() => {
                setTab('internal')
                history.push(`${basePath}/internal/general-info`)
              }}
            />

            <Button
              label="Shared Data"
              icon="community"
              type={tab === 'shared' ? 'primary' : 'minimal'}
              color={tab === 'shared' ? 'blue' : 'text'}
              onClick={() => {
                setTab('shared')
                history.push(`${basePath}/shared/community-profile`)
              }}
            />
          </Flex>
        </header>

        <PageGrid breakpoint={3} columns="285px 1fr">
          {tab === 'internal' && (
            <Nav
              showBack={false}
              breakpoint={3}
              headingSize={400}
              record={organization}
              title={organization?.name}
              avatar={organization?.avatar}
              description={address(organization.address, '')}
              back="/community/organizations"
              desktopProps={{
                icon: 'organizations',
                title: 'Internal Data',
                headingSize: 300,
                avatar: undefined,
                description: undefined,
              }}
              profile={
                <>
                  <PageProfile
                    more
                    record={organization}
                    title={organization.name}
                    description={address(organization.address, '')}
                    actions={
                      <OrganizationQuickActions
                        data={organization}
                        onArchive={archiveOrganization}
                        canRevokeReactivatePartnerships={canRevokeReactivatePartnerships}
                        revokePartnership={revokePartnership}
                        reactivatePartnership={reactivatePartnership}
                      />
                    }
                    beforeActions={
                      <>
                        {isBehave && (
                          <SummonOverlay overlay={<OrganizationCertifyOverlay orgName={organization.name} />}>
                            <Button label="Certify" icon="community_partners" color="green" size={300} />
                          </SummonOverlay>
                        )}

                        {organization.internal_status === 'internal' && (
                          <SummonOverlay
                            overlay={<OrganizationCommunityRequestOverlay orgId={organization.id} orgName={organization.name} />}
                          >
                            <Button label="Submit Partnership Request…" icon="community_invites" color="blue" size={300} />
                          </SummonOverlay>
                        )}

                        {organization.internal_status === 'community_profile_request' && (
                          <Status label="Reviewing your Request" color="green" />
                        )}

                        {organization.internal_status === 'partner_invite_sent' && <Status label="Waiting for a Reply" color="green" />}

                        {organization.internal_status === 'ready_to_partner' && !organization.is_ready_for_direct_invite && (
                          <SummonOverlay overlay={<OrganizationInviteRequestOverlay orgId={organization.id} orgName={organization.name} />}>
                            <Button label="Submit Partnership Request…" icon="community_invites" color="text" size={300} />
                          </SummonOverlay>
                        )}

                        {organization.internal_status === 'ready_to_partner' && organization.is_ready_for_direct_invite && (
                          <SummonOverlay overlay={<OrganizationDirectInviteOverlay orgId={organization.id} orgName={organization.name} />}>
                            <Button label="Invite to Partner Up Now…" icon="community_invites" color="text" size={300} />
                          </SummonOverlay>
                        )}

                        {organization.internal_status === 'partner_invite_request' && (
                          <Tooltip
                            color="green"
                            glyph="info"
                            content="We are currently reviewing your request. We'll reach out to this Organization in your name and invite them to connect with you. We will let you know about the status of your request as soon as we hear back from them."
                          >
                            <Button label="Reviewing your Request" glyph="verified_shield" color="green" type="link" size={300} />
                          </Tooltip>
                        )}
                      </>
                    }
                    underActions={
                      <div className="w-full">
                        <TagsSelector
                          tags={organization?.tags}
                          onSave={(tagIDs: any) => updatePartnership({ tag_ids: tagIDs })}
                          editPermission="tags.edit"
                        />
                      </div>
                    }
                    status={
                      <>
                        <OrganizationStatusGroupStatus status={organization.status_group} />
                        {organization.status_group !== 'is_archived' && <EHROrganizationStatus status={organization.internal_status} />}
                      </>
                    }
                  />
                </>
              }
            >
              <CommunityOrganizationInternalNavItems organization={organization} isPartner={isPartner} />
            </Nav>
          )}

          {tab === 'shared' && (
            <Nav
              showBack={false}
              breakpoint={3}
              headingSize={400}
              record={organization}
              title={organization?.name}
              avatar={organization?.avatar}
              description={address(organization.address, '')}
              back="/community/organizations"
              desktopProps={{
                icon: 'community',
                title: 'Shared Data',
                headingSize: 300,
                avatar: undefined,
                description: undefined,
              }}
              profile={
                <>
                  <PageProfile
                    more
                    record={organization}
                    title={organization.name}
                    description={address(organization.address, '')}
                    actions={
                      <OrganizationQuickActions
                        data={organization}
                        onArchive={archiveOrganization}
                        canRevokeReactivatePartnerships={canRevokeReactivatePartnerships}
                        revokePartnership={revokePartnership}
                        reactivatePartnership={reactivatePartnership}
                      />
                    }
                    beforeActions={
                      <>
                        {isBehave && (
                          <SummonOverlay overlay={<OrganizationCertifyOverlay orgName={organization.name} />}>
                            <Button label="Certify" icon="community_partners" color="green" size={300} />
                          </SummonOverlay>
                        )}

                        {organization.internal_status === 'internal' && (
                          <SummonOverlay
                            overlay={<OrganizationCommunityRequestOverlay orgId={organization.id} orgName={organization.name} />}
                          >
                            <Button label="Submit Partnership Request…" icon="community_invites" color="blue" size={300} />
                          </SummonOverlay>
                        )}

                        {organization.internal_status === 'community_profile_request' && (
                          <Status label="Reviewing your Request" color="green" />
                        )}

                        {organization.internal_status === 'partner_invite_sent' && <Status label="Waiting for a Reply" color="green" />}

                        {organization.internal_status === 'ready_to_partner' && !organization.is_ready_for_direct_invite && (
                          <SummonOverlay overlay={<OrganizationInviteRequestOverlay orgId={organization.id} orgName={organization.name} />}>
                            <Button label="Submit Partnership Request…" icon="community_invites" color="text" size={300} />
                          </SummonOverlay>
                        )}

                        {organization.internal_status === 'ready_to_partner' && organization.is_ready_for_direct_invite && (
                          <SummonOverlay overlay={<OrganizationDirectInviteOverlay orgId={organization.id} orgName={organization.name} />}>
                            <Button label="Invite to Partner Up Now…" icon="community_invites" color="text" size={300} />
                          </SummonOverlay>
                        )}

                        {organization.internal_status === 'partner_invite_request' && (
                          <Tooltip
                            color="green"
                            glyph="info"
                            content="We are currently reviewing your request. We'll reach out to this Organization in your name and invite them to connect with you. We will let you know about the status of your request as soon as we hear back from them."
                          >
                            <Button label="Reviewing your Request" glyph="verified_shield" color="green" type="link" size={300} />
                          </Tooltip>
                        )}
                      </>
                    }
                    underActions={
                      <div className="w-full">
                        <TagsSelector
                          tags={organization?.tags}
                          onSave={(tagIDs: any) => updatePartnership({ tag_ids: tagIDs })}
                          editPermission="tags.edit"
                        />
                      </div>
                    }
                    status={
                      <>
                        <OrganizationStatusGroupStatus status={organization.status_group} />
                        {organization.status_group !== 'is_archived' && <EHROrganizationStatus status={organization.internal_status} />}
                      </>
                    }
                  />
                </>
              }
            >
              <CommunityOrganizationSharedNavItems organization={organization} isPartner={isPartner} />
            </Nav>
          )}

          <Routes>
            <RouteV6 path={`${match.path}/internal/forms/*`} element={<InternalForms />} />

            <RouteV6 path={`${match.path}/shared/grievance-forms/*`} element={<GrievanceForms />} />
            <RouteV6 path={`${match.path}/shared/incident-reporting/*`} element={<IncidentReporting />} />
            <RouteV6 path={`${match.path}/shared/studies/*`} element={<Studies />} />
            <RouteV6 path={`${match.path}/shared/surveys/*`} element={<Surveys />} />
            <RouteV6 path={`${match.path}/shared/lead-application-forms/*`} element={<LeadApplicationForms />} />
            <RouteV6 path={`${match.path}/shared/agreement-forms/*`} element={<AgreementForms />} />
            <RouteV6 path={`${match.path}/shared/inspection-forms/*`} element={<InspectionForms />} />
            <RouteV6 path={`${match.path}/shared/certification-forms/*`} element={<CertificationForms />} />
          </Routes>

          {/* INTERNAL PAGES */}
          <Switch>
            <Route path={`${match.path}/internal/general-info`} component={GeneralInfo} />
            <Route path={`${match.path}/internal/contacts`} component={Contacts} />
            <Route path={`${match.path}/internal/communications`} component={Communications} />
            <Route path={`${match.path}/internal/files`} component={InternalFiles} />
            <Route path={`${match.path}/internal/activity-timeline`} component={ActivityTimeline} />
            <Route path={`${match.path}/internal/shift-notes`} component={ShiftNotes} />
            <Route path={`${match.path}/internal/todo`} component={Todos} />
            <Route path={`${match.path}/internal/calendar`} component={Calendar} />
            <ProtectedRoute
              path={`${match.path}/internal/agreements`}
              component={Agreements}
              permission="organization_agreements.view"
              featureFlag="organization_agreements"
            />
            <Route path={`${match.path}/internal/client-financials`} component={ClientFinancials} />
            <Route path={`${match.path}/internal/financials`} component={Financials} />
            <Route path={`${match.path}/internal/locations`} component={Locations} />
            <Route path={`${match.path}/internal/custom-notes`} component={CustomNotes} />
            <Route path={`${match.path}/internal/form-submissions`} component={FormSubmissions} />
            <Route path={`${match.path}/internal/contacts-form-submissions`} component={ContactsFormSubmissions} />
            <Route path={`${match.path}/internal/settings`} component={Settings} />
            <Route path={`${match.path}/internal/profile`} component={Profile} />
            <Route path={`${match.path}/internal/website`} component={Website} />
            <Route path={`${match.path}/internal/send-referral`} component={SendReferral} />
            <Route path={`${match.path}/internal/send-message`} component={SendMessage} />
          </Switch>

          {/* SHARED PAGES */}
          <Switch key="partner-shared-pages">
            <Route path={`${match.path}/shared/community-setup`} component={CommunitySetup} />
            <Route path={`${match.path}/shared/application-form`} component={FullApplication} />
            <Route path={`${match.path}/shared/bed-availability`} component={BedAvailability} />
            <Route path={`${match.path}/shared/community-profile`} component={CommunityProfile} />
            <Route path={`${match.path}/shared/contact-us`} component={ContactUs} />
            <Route path={`${match.path}/shared/evob-application-form`} component={EVOBApplicationFormPage} />
            <Route path={`${match.path}/shared/forms`} component={Forms} />
            <Route path={`${match.path}/shared/locations`} component={Locations} />
            <Route path={`${match.path}/shared/staff`} component={Staff} />
            <Route path={`${match.path}/shared/website`} component={Website} />
            <Route path={`${match.path}/shared/agreements`} component={Website} />
            <Route path={`${match.path}/shared/contacts`} component={Website} />
            <Route path={`${match.path}/shared/communications`} component={Website} />
            <Route path={`${match.path}/shared/partners`} component={Partners} />
            <Route path={`${match.path}/shared/files`} component={Files} />

            <Route path={`${match.path}/shared/audit-log`}>
              <Page title="Audit Log" icon="reports">
                <Card>
                  <State isEmpty icon="reports" title="Audit Log" emptyDescription={<Status label="Coming Soon" color="blue" />} />
                </Card>
              </Page>
            </Route>

            <Route path={`${match.path}/shared/share-settings`}>
              <Permission permission={canViewPartnerShareSettings}>
                <ShareSettings />
              </Permission>
            </Route>

            <Route path={`${match.path}/shared/referrals`}>
              <Permission permission={canViewPartnerReferrals}>
                <Referrals />
              </Permission>
            </Route>

            <Route path={`${match.path}/shared/message-board`}>
              <Messages />
            </Route>

            <Route path={`${match.path}/shared/requests`}>
              <Requests />
            </Route>

            <Route path={`${match.path}/shared/calendar`}>
              <Page title="Calendar" icon="calendar">
                <Card>
                  <State isEmpty icon="calendar" title="Calendar" emptyDescription={<Status label="Coming Soon" color="blue" />} />
                </Card>
              </Page>
            </Route>
          </Switch>

          {tab === 'internal' && <Redirect exact from={match.url} to={`${match.url}/internal/general-info`} />}
        </PageGrid>
      </div>

      <Portal type="z90">
        <AnimatedSwitch className="overlays" animation="animation" timeout={{ enter: 400, exit: 400 }}>
          <Route exact path={`${match.path}/shared/files/:id`} component={OrganizationFileOverlay} />
          <Route exact path={`${match.path}/shared/requests/:request_type/:request_status/:id`} component={PartnershipRequestOverlay} />
        </AnimatedSwitch>
      </Portal>
    </>
  )
}

const SHARE_PAGE_SLUG_CONFIGS = {
  'share-settings': {
    title: 'Share Settings',
    icon: 'settings',
  },
  'community-profile': {
    title: 'Community Profile',
    icon: 'community_profile',
  },
  staff: {
    title: 'Staff',
    icon: 'employees',
  },
  locations: {
    title: 'Locations',
    icon: 'locations',
  },
  files: {
    title: 'Files',
    icon: 'files',
  },
  'bed-availability': {
    title: 'Bed Availability',
    icon: 'beds',
  },
  'application-form': {
    title: 'Application Form',
    icon: 'admissions',
  },
  'evob-application-form': {
    title: 'EVOB Application Form',
    icon: 'insurance',
  },
  'contact-us': {
    title: 'Contact Us',
    icon: 'communications',
  },
  referrals: {
    title: 'Referrals',
    icon: 'referrals',
  },
  requests: {
    title: 'Requests',
    icon: 'web_form',
  },
}

const styles = {
  display: 'grid',
  background: COLORS.lightBackground,
  gridTemplateRows: 'auto 1fr',
  overflow: 'hidden',
}

const animation = keyframes`
  0% {
    opacity: 0.5;
    transform: translateY(-3px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

const headerStyles = {
  background: 'white',
  minHeight: TOKENS.headerHeight,
  animation: `${animation} 320ms cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards`,

  boxShadow: HARD_SHADOW(2),
  zIndex: 2,

  '&::before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    width: '100%',
    left: 0,
    zIndex: 0,

    height: 2,
    bottom: 0,
    borderRadius: '0 3px 3px 0',

    transition: `
        transform 200ms cubic-bezier(0.39, 0.575, 0.565, 1),
        opacity 1400ms cubic-bezier(0.39, 0.575, 0.565, 1)
      `,
    transformOrigin: 'center left',
  },

  '&.is-sticky': {
    position: 'sticky',
    zIndex: 2,

    // paddingTop: '0.25rem !important',
    // paddingBottom: '0.25rem !important',

    borderBottom: `1px solid ${COLORS.divider}`,
    boxShadow: HARD_SHADOW(2),
    background: 'white',

    // boxShadow: `
    //   0 0.5px 0 ${COLORS.divider},
    //   0 2px 6px ${COLORS.shadow},
    //   0 4px 12px ${COLORS.shadow},
    //   0 8px 24px ${COLORS.shadow}
    // `,
  },

  '&.is-dropdown': {
    cursor: 'pointer',

    '&:hover': {
      background: 'white',

      'h1, h2, h3': {
        color: COLORS.link,
      },
    },
  },

  '&.is-internal': {
    borderBottom: `3px solid ${COLORS.gray}`,
  },

  '&.is-shared': {
    borderBottom: `3px solid ${COLORS.blue}`,
  },
}

const backStyles = {
  boxShadow: 'none',
  position: 'relative',
  margin: 0,

  '&::after': {
    content: '""',
    width: 1,
    height: '100%',
    position: 'absolute',
    top: 0,
    right: -1,
    background: `linear-gradient(${COLORS.transparent}, ${COLORS.divider}, ${COLORS.transparent})`,
  },

  svg: {
    transition: 'transform 100ms cubic-bezier(0.39, 0.575, 0.565, 1)',
  },

  '&:hover': {
    cursor: 'pointer',
    background: 'white',
    boxShadow: `
      2px 0  6px ${COLORS.shadow},
      4px 0  12px ${COLORS.shadow},
      8px 0  24px ${COLORS.shadow}
    `,

    svg: {
      fill: COLORS.blue,
      transform: 'translateX(-1px) scale3d(1.1, 1.1, 1.1)',
    },
  },
}

export const Organization = withBackListener(withPageError(RootOrganization))

import React from 'react'
import { Link, NavLink, Navigate, Route, Routes, useNavigate, useParams, useLocation } from 'react-router-dom-v5-compat'
import { tint } from 'polished'
import { useMedia } from 'use-media'
import * as HoverCard from '@radix-ui/react-hover-card'
import size from 'lodash/size'

import { getResourceLink, usDateTime } from '../../utils/functions'
import { css, COLORS, SHADOW } from '../../theme'
import { useSettings } from '../../hooks/useSettings'

import AdmissionQuickView from '../../components/Overlays/quick/AdmissionQuickView'
import Avatar from '../../components/Avatar'
import Button from '../../components/Button'
import CardHeader from '../../components/CardHeader'
import CardTitle from '../../components/CardTitle'
import ClientQuickView from '../../components/Overlays/quick/ClientQuickView'
import DataList from '../../components/DataList'
import DropdownItem from '../../components/DropdownItem'
import Flex from '../../components/Flex'
import Grid from '../../components/Grid'
import Icon from '../../components/Icon'
import ProgramAssignmentStatus from '../../components/Statuses/ProgramAssignmentStatus'
import Roadmap from '../../components/Roadmap/Roadmap'
import RoadmapBar from '../../components/Roadmap/RoadmapBar'
import RoadmapHeaderRow from '../../components/Roadmap/RoadmapHeaderRow'
import RoadmapRow from '../../components/Roadmap/RoadmapRow'
import Status from '../../components/Status'
import SummonOverlay from '../../components/SummonOverlay'
import Tooltip from '../../components/Tooltip'

const handleSummonOpen = (event: any) => {
  event.stopPropagation()
  event.nativeEvent.stopImmediatePropagation()
}

const buildNameFromObject = (object: any) => {
  if (!object) return null

  if (object.type === 'contact') {
    if (object.reference?.type === 'organization') {
      return `${object.name} (${object?.reference?.name})`
    } else {
      return object?.reference?.name
    }
  }

  return object.name
}

export const ProgramsTimeline: React.FC<any> = (props) => {
  const { applicants, className, dates, isLoading, isRefetching, occupanciesByPhaseIds, programs, setDates, unassigned } = props

  const [isAllOpen, setIsAllOpen] = React.useState(true)

  const incomingCount = size(applicants?.incoming)
  const newApplicantsCount = size(applicants?.new_applicants)
  const pendingCount = size(applicants?.pending)
  const waitlistCount = size(applicants?.waitlist)

  const applicantsCount = newApplicantsCount + pendingCount + incomingCount + waitlistCount

  return (
    <Roadmap showTodayLine className={className} isLoading={isLoading} isRefetching={isRefetching} dates={dates} onDatesChange={setDates}>
      {programs && (
        <>
          {/* Applicants */}
          {applicantsCount >= 1 && (
            <RoadmapRow hideGridLines isOpen level={1} icon="applicants" title={`Applicants (${applicantsCount})`}>
              <ApplicantsRoadmapRow title={`New (${newApplicantsCount})`} data={applicants.new_applicants} />
              <ApplicantsRoadmapRow title={`Pending (${pendingCount})`} data={applicants.pending} />
              <ApplicantsRoadmapRow title={`Incoming (${incomingCount})`} data={applicants.incoming} />
              <ApplicantsRoadmapRow title={`Waitlist (${waitlistCount})`} data={applicants.waitlist} />
            </RoadmapRow>
          )}

          {/* Unassigned Clients */}
          {unassigned && (
            <RoadmapRow
              hideGridLines
              level={1}
              icon="clients"
              title={`Currently Unassigned (${size(unassigned)})`}
              after={
                <Tooltip
                  css={{ marginLeft: 'auto', marginRight: '0.5rem' }}
                  content="Current clients who do not have an active program assignment today."
                />
              }
            >
              {unassigned?.map?.((client: any) => (
                <RoadmapRow
                  key={client.id}
                  level={3}
                  avatar={client.avatar}
                  title={client.name}
                  actions={
                    <>
                      <SummonOverlay overlay={<ClientQuickView client={client} />} onOpen={handleSummonOpen} portalType="iframe">
                        <DropdownItem label="Quick View" color="blue" glyph="quick_view" />
                      </SummonOverlay>

                      <DropdownItem
                        as={Link}
                        label="Assign Program"
                        icon="programs"
                        permission="programs.edit"
                        link="assignments/new"
                        state={{
                          data: { reference: client },
                        }}
                      />
                    </>
                  }
                />
              ))}
            </RoadmapRow>
          )}

          <div className={STYLES.divider()} />

          <RoadmapHeaderRow className={STYLES.headerRow().className}>
            <Flex stretchSelf centerY gap="0.5rem" justifyContent="space-between">
              <div>
                <Flex centerY gap="0.5rem">
                  <Icon icon="programs" size={18} />
                  <div className={STYLES.headerTitle().className}>Programs</div>
                </Flex>
              </div>

              <Button
                onClick={() => setIsAllOpen((c) => !c)}
                label={`${isAllOpen ? 'Close' : 'Open'} All`}
                size={100}
                glyph={isAllOpen ? 'close_all_rows' : 'open_all_rows'}
                display="inline-block"
                type="minimal"
              />
            </Flex>
          </RoadmapHeaderRow>

          {programs?.map?.((program: any) => {
            const hasPhases = size(program.phases) >= 1

            return (
              <RoadmapRow
                expandAsCard
                hideGridLines
                isOpen={isAllOpen}
                key={program.id}
                title={program.name}
                className={STYLES.clientRow().className}
                level={1}
                graphic={<Avatar src={program.avatar} initials={program.name} size={24} />}
              >
                {hasPhases &&
                  program.phases.map((phase: any) => {
                    const phaseOccupancies = occupanciesByPhaseIds[phase.id]

                    return (
                      <RoadmapRow isOpen level={2} title={phase.name} icon="program_lists">
                        {phaseOccupancies?.map((occupancy: any) => (
                          <RoadmapRow
                            key={occupancy.id}
                            level={2}
                            title={buildNameFromObject(occupancy.reference)}
                            graphic={<Avatar src={occupancy.reference.avatar} initials={occupancy.reference.name} size={22} />}
                            renderBars={() => <ProgramAssignmentBar data={occupancy} client={occupancy.reference} />}
                          />
                        ))}
                      </RoadmapRow>
                    )
                  })}
              </RoadmapRow>
            )
          })}
        </>
      )}
    </Roadmap>
  )
}

const ResourceStatus = ({ data }: any) => {
  if (!data) return null

  if (data.type === 'resident') {
    if (data.status === 'current') {
      return <Status label="Current Client" color="blue" />
    } else if (data.status === 'alumni') {
      return <Status label="Past Client" color="yellow" />
    } else {
      return <Status label="Applicant" color="green" />
    }
  } else if (data.type === 'employee') {
    return <Status label="Staff" color="paleBlue" />
  } else if (data.type === 'contact') {
    return <Status label="Contact" color="orange" />
  }

  return null
}

const ResourceHeader = ({ data }: any) => {
  if (!data) return null

  const link = getResourceLink(data)

  return (
    <Flex centerY nowrap gap="0.5rem" css={{ padding: '0.75rem 1rem 0.5rem' }}>
      <Avatar src={data.avatar} initials={data.name} size={40} />
      <CardHeader>
        {link ? (
          <Link to={link}>
            <CardTitle title={data.name} css={{ fontWeight: 700, fontSize: '1.2rem', color: COLORS.blue }} />
          </Link>
        ) : (
          <CardTitle title={data.name} css={{ fontWeight: 700, fontSize: '1.2rem', color: COLORS.blue }} />
        )}

        <ResourceStatus data={data} />

        {/* <CardSubtitle subtitle={`${startCase(data.sex) || '–'}, ${age(data.dob)} y/o, #${data.behave_id}`} /> */}
      </CardHeader>
    </Flex>
  )
}

const HoverCardRoot: React.FC = ({ children, ...rest }) => {
  return (
    <HoverCard.Root openDelay={100} closeDelay={100} {...rest}>
      {children}
    </HoverCard.Root>
  )
}

const HoverCardContent: React.FC = ({ children }) => {
  const isDesktop = useMedia({ minWidth: 600 })

  const container = document?.getElementById?.('portal-default')
  const collisionBoundary = document?.getElementById?.('roadmap')

  return (
    <HoverCard.Portal container={container}>
      <HoverCard.Content
        className={STYLES.hoverCard()}
        collisionBoundary={collisionBoundary}
        collisionPadding={{ left: isDesktop ? 200 : 20 }}
      >
        <HoverCard.Arrow width={14} height={8} className={STYLES.hoverCardArrow()} />
        {children}
      </HoverCard.Content>
    </HoverCard.Portal>
  )
}

const ApplicantsRoadmapRow = ({ title, data }: any) => {
  const { timezone } = useSettings()

  return (
    <>
      <RoadmapRow hideGridLines level={2} title={title}>
        {data?.map?.((applicant: any) => (
          <RoadmapRow
            key={applicant.id}
            level={3}
            avatar={applicant.avatar}
            title={applicant.name}
            renderBars={() => {
              if (!applicant.estimated_arrival) return null

              const noEndDate = !applicant.estimated_discharge
              const background = tint(0.7, noEndDate ? COLORS.orange : COLORS.green)

              return (
                <HoverCardRoot>
                  <HoverCard.Trigger asChild>
                    <RoadmapBar
                      asGradient={noEndDate}
                      background={background}
                      endDate={applicant.estimated_discharge}
                      startDate={applicant.estimated_arrival}
                      width={noEndDate && 100}
                    >
                      <div className={STYLES.roadmapBarContent()}>
                        <Avatar src={applicant.avatar} initials={applicant.name} size={20} />
                        <div className={STYLES.roadmapBarTitle()}>{applicant.name}</div>
                      </div>
                    </RoadmapBar>
                  </HoverCard.Trigger>

                  <HoverCardContent>
                    <ResourceHeader data={applicant} />

                    <DataList isCompact labelWidth={120} withPadding>
                      <DataList.Item label="Est. Arrival" value={usDateTime(applicant.estimated_arrival, timezone)} />
                      <DataList.Item label="Est. Discharge" value={usDateTime(applicant.estimated_discharge, timezone)} />
                    </DataList>
                  </HoverCardContent>
                </HoverCardRoot>
              )
            }}
            actions={
              <>
                <SummonOverlay overlay={<AdmissionQuickView client={applicant} />} onOpen={handleSummonOpen} portalType="iframe">
                  <DropdownItem label="Quick View" color="blue" glyph="quick_view" />
                </SummonOverlay>

                <DropdownItem
                  as={Link}
                  label="Assign Program"
                  icon="programs"
                  link="assignments/new"
                  state={{
                    data: {
                      reference: applicant,
                    },
                  }}
                  permission="programs.edit"
                />
              </>
            }
          />
        ))}
      </RoadmapRow>
    </>
  )
}

export const ProgramAssignmentBar = ({ data, client }: any) => {
  const [isTooltipOpen, setIsTooltipOpen] = React.useState(false)

  const { timezone } = useSettings()

  const openTooltip = () => {
    setIsTooltipOpen(true)
  }

  const closeTooltip = () => {
    setIsTooltipOpen(false)
  }

  if (!data || !client) return null

  return (
    <HoverCardRoot open={isTooltipOpen} onOpenChange={setIsTooltipOpen}>
      <HoverCard.Trigger asChild>
        <RoadmapBar
          minWidth={7}
          background={tint(0.7, COLORS.green)}
          endDate={data.ended_at}
          startDate={data.started_at}
          onClick={openTooltip}
        >
          <Avatar src={client.avatar} initials={client.name} size={20} />
        </RoadmapBar>
      </HoverCard.Trigger>

      <HoverCardContent>
        <ResourceHeader data={client} />

        <DataList isCompact withPadding labelWidth={120}>
          <DataList.Item label="Status" value={<ProgramAssignmentStatus status={data.status} />} />

          <DataList.Item label="Program List" value={data.place?.place?.name} />
          <DataList.Item label="Seat" value={data.place?.name} />

          <DataList.Item
            label={data.status === 'occupied' ? 'Occupied From' : 'Reserved From'}
            value={usDateTime(data.started_at, timezone)}
          />

          <DataList.Item
            label={data.status === 'occupied' ? 'Occupied Until' : 'Reserved Until'}
            value={data.period_type === 'indefinite' ? 'Discharged' : usDateTime(data.ended_at, timezone)}
          />
        </DataList>

        <Grid gap="0.75rem" css={{ padding: '0.2rem 1rem 0.4rem' }}>
          <Button
            as={Link}
            label="Edit Program Assignment"
            glyph="edit"
            size={200}
            link={`assignments/${data.id}`}
            onClick={closeTooltip}
            permission="programs.edit"
          />

          <Button
            as={Link}
            label="Move Program Lists"
            glyph="swap"
            color="green"
            size={200}
            link={`${data.id}/move`}
            onClick={closeTooltip}
            permission="programs.edit"
          />

          <Button
            as={Link}
            label="Remove from List"
            glyph="delete"
            color="red"
            size={200}
            link={`${data.id}/remove`}
            onClick={closeTooltip}
            permission="programs.edit"
          />
        </Grid>
      </HoverCardContent>
    </HoverCardRoot>
  )
}

export const STYLES = {
  hoverCard: css({
    minWidth: 280,
    maxWidth: 300,
    overflowY: 'auto',
    overflowX: 'hidden',
    WebkitOverflowScrolling: 'touch',
    borderRadius: 7,
    background: COLORS.white,
    boxShadow: SHADOW(10, COLORS.divider),
    border: 'none',
    paddingBottom: '0.5rem',

    '@media(min-width: 600px)': {
      minWidth: 360,
    },
  }),

  hoverCardArrow: css({
    color: COLORS.white,
    margin: '0 1rem',
  }),

  clientRow: css({
    borderTop: `1.5px solid ${COLORS.divider}`,

    '&:first-child': { borderTop: 'none' },
  }),

  headerRow: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  }),

  headerTitle: css({
    fontSize: '0.95rem',
    fontWeight: 700,
    color: COLORS.text,
    textTransform: 'uppercase',
    marginRight: 'auto',
  }),

  divider: css({
    borderTop: `1px solid ${COLORS.divider}`,
    marginTop: 4,
  }),

  roadmapBarContent: css({
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  }),

  roadmapBarTitle: css({
    fontSize: '0.9rem',
    fontWeight: 600,
    marginLeft: '0.4rem',
  }),
}

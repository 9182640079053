import React from 'react'
import { useMedia } from 'use-media'
import clsx from 'clsx'
import debounce from 'lodash/debounce'
import size from 'lodash/size'
import snakeCase from 'lodash/snakeCase'

import { PlainSwitch } from '../../components/Forms/Switch'
import { useOverlay } from '../../hooks/useOverlay'
import { withOverlayError } from '../../hocs/withOverlayError'

import Accordion from '../../components/Accordion'
import Button from '../../components/Button'
import Checkbox from '../../components/Forms/Checkbox'
import ContextShow from '../../components/Forms/ContextShow'
import Divider from '../../components/Divider'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import Label from '../../components/Label'
import Option from '../../components/Forms/Option'
import Overlay from '../../components/Overlay'
import Section from '../../components/Section'
import Select from '../../components/Forms/Select'
import State from '../../components/State'
import Status from '../../components/Status'
import Switch from '../../components/Forms/Switch'
import TableArrayForm from '../../components/Forms/TableArrayForm'

import { ORGANIZATION_SECTIONS, HOME_LOCATION_SECTIONS, CONTACT_SECTIONS } from './data'
import { Question, Questions } from './CertificationApplicationFormOverlay'

const SECTIONS = [
  {
    title: 'Organization',
    model: 'organization',
    data: ORGANIZATION_SECTIONS,
  },
  {
    title: 'Home/Location',
    model: 'home_location',
    data: HOME_LOCATION_SECTIONS,
  },
  {
    title: 'Contact',
    model: 'contact',
    data: CONTACT_SECTIONS,
  },
]

const RootNARRFormBuilder = (props: any) => {
  const {
    cancel,
    close,
    edit,
    form,
    id,
    initialModel,
    isEditable,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
    saveWithData,
  } = useOverlay({
    name: 'TODO',
    endpoint: '/TODO',
    invalidate: 'TODO',
    options: props,
  })

  const isDesktop = useMedia({ minWidth: 1024 })

  const [formData, setFormData] = React.useState({})
  const [isPreviewVisible, setIsPreviewVisible] = React.useState(false)

  const handlePreviewChange = () => {
    setIsPreviewVisible((c) => !c)
  }

  const debouncedSetFormData = React.useMemo(() => {
    return debounce((value) => {
      setFormData?.(value)
    }, 300)
  }, [])

  return (
    <Overlay onClose={close} showBackdrop={isEditable} position="right" maxWidth={isDesktop && isPreviewVisible ? 100 : 50}>
      <Overlay.Header icon="web_form" title="Organization Form" />

      {isDesktop && (
        <Overlay.SubHeader className="flex items-center justify-end flex-nowrap px-2 !py-1.5">
          <Label isCompact label="Show Preview" onClick={handlePreviewChange} className="!flex-[0_0_auto] cursor-pointer" />
          <PlainSwitch size={200} isChecked={isDesktop && isPreviewVisible} onCheckedChange={handlePreviewChange} />
        </Overlay.SubHeader>
      )}

      <div
        className={clsx(
          'grid grid-rows-[100%] overflow-hidden flex-[1_1_auto]',
          isDesktop && isPreviewVisible ? 'grid-cols-[50rem_1fr]' : 'grid-cols-1',
        )}
      >
        <Overlay.Content className={isDesktop && isPreviewVisible ? 'border-r border-0 border-solid border-divider' : ''}>
          <Form
            isCompact
            getForm={form}
            initialModel={initialModel}
            onValidationUpdate={onValidationUpdate}
            isEditable={isEditable}
            onUpdate={debouncedSetFormData}
          >
            <Section>
              <Accordion isOpen glyph="form_builder_smart_sections" title="Form Sections">
                <div className="grid gap-3">
                  {SECTIONS.map(({ title, data: sections, model }, index) => {
                    return (
                      <React.Fragment key={index}>
                        {index > 0 && <Divider />}

                        <Status label={title} color="blue" />

                        {sections.map((section) => {
                          const sectionModel = snakeCase(section.title)

                          return (
                            <DataFormSection
                              isOpen
                              key={model}
                              title={section.title}
                              isEditable={isEditable}
                              model={`schema.${model}`}
                              sectionId={model}
                              isPreviewVisible={isDesktop && isPreviewVisible}
                            >
                              <div className="grid gap-1.5 mt-3">
                                <Checkbox
                                  label="Custom Questions"
                                  model={`schema.${model}.custom_questions_before`}
                                  trueIcon="check"
                                  falseIcon="empty_checkbox"
                                  falseStyle="faded"
                                />

                                <ContextShow when={`schema.${model}.custom_questions_before`} is={true}>
                                  <div className="pl-5 mt-1.5 mb-2">
                                    <TableArrayForm
                                      isCompact
                                      name="Custom Questions"
                                      model={`schema.${model}.custom_questions_before`}
                                      inputs={INPUTS}
                                    />
                                  </div>
                                </ContextShow>

                                {section?.questions?.map?.((question: any) => {
                                  const questionModel = snakeCase(question)

                                  return (
                                    <div key={`${model}`} className="grid grid-cols-1 gap-3">
                                      <Checkbox
                                        defaultChecked
                                        key={model}
                                        label={question}
                                        description={question.description}
                                        model={`schema.${model}.${questionModel}`}
                                        isDisabled={question.isDisabled}
                                        // value={question.isChecked}
                                        trueIcon="check"
                                        falseIcon="empty_checkbox"
                                        falseStyle="faded"
                                      />
                                    </div>
                                  )
                                })}

                                <Checkbox
                                  label="Custom Questions"
                                  model={`schema.${model}.custom_questions_after`}
                                  trueIcon="check"
                                  falseIcon="empty_checkbox"
                                  falseStyle="faded"
                                />

                                <ContextShow when={`schema.${model}.custom_questions_after`} is={true}>
                                  <div className="pl-5 mt-1.5">
                                    <TableArrayForm
                                      isCompact
                                      name="Custom Questions"
                                      model={`schema.${model}.custom_questions_after`}
                                      inputs={INPUTS}
                                    />
                                  </div>
                                </ContextShow>
                              </div>
                            </DataFormSection>
                          )
                        })}
                      </React.Fragment>
                    )
                  })}
                </div>
              </Accordion>
            </Section>
          </Form>
        </Overlay.Content>

        {isDesktop && isPreviewVisible && formData && (
          <Overlay.Content>
            {SECTIONS.map((mainSection) => (
              <React.Fragment key={mainSection.title}>
                <h2 className="px-5 mt-4">{mainSection.title}</h2>

                {mainSection.data?.map?.((section) => {
                  const questions = section?.questions?.filter?.((o) => {
                    return formData?.schema?.[mainSection.model]?.[snakeCase(o)]
                  })

                  if (size(questions) === 0) return null

                  return (
                    <Section key={section.title} title={section.title} headingType="h3">
                      <FormSection maxWidth="100%" className="pb-5">
                        {questions?.map?.((question, index) => (
                          <Question key={question} index={index} question={question} showComments={false} disableComments />
                        ))}
                      </FormSection>
                    </Section>
                  )
                })}
              </React.Fragment>
            ))}

            {/* <Questions
              title="Organization"
              questions={ORGANIZATION_QUESTIONS.filter((o) => {
                return formData?.schema?.organization?.[snakeCase(o)]
              })}
              disableComments
            />
            <Questions
              title="Home / Location"
              questions={HOME_LOCATION_QUESTIONS.filter((o) => {
                return formData?.schema?.home_location?.[snakeCase(o)]
              })}
              disableComments
            />
            <Questions
              title="Contact"
              questions={CONTACT_QUESTIONS.filter((o) => {
                return formData?.schema?.contact?.[snakeCase(o)]
              })}
              disableComments
            /> */}
          </Overlay.Content>
        )}
      </div>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button label="Save" glyph="check" type="primary" color="green" isLoading={isSaving} onClick={save} flex="100 1 auto" />
            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {!isEditable && (
          <>
            <Button
              glyph="edit"
              label="Edit Form"
              type="default"
              // isDisabled={isLoading}
              onClick={edit}
              flex="100 1 auto"
            />
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

const DataFormPreview = ({ sections, schema }) => {
  const previewForm = React.useRef(null)

  const [isLoading, setIsLoading] = React.useState(true)

  React.useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 500)
  }, [])

  if (isLoading) return <State isLoading />

  return (
    <Form getForm={previewForm}>
      <DataFormRenderer sections={sections} schema={schema} />
    </Form>
  )
}

const DataFormSection = (props: any) => {
  const { title, children, isRequired, isEditable, model, isOpen, sectionId, isPreviewVisible } = props
  const [open, setOpen] = React.useState(isOpen)

  const scrollToPreviewSection = () => {
    const element = document?.getElementById(sectionId)

    if (!element) return

    element.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <section className="[&+&]:mt-4">
      <div className="flex items-center">
        <div className="flex items-center">
          <h3>{title}</h3>

          {isPreviewVisible && (
            <Button label="Preview" glyph="view" size={100} type="minimal" className="mx-1.5" onClick={scrollToPreviewSection} />
          )}
        </div>

        {isEditable && isRequired && <Status label="Required" color="red" css={{ marginLeft: 'auto' }} />}

        <div css={{ marginLeft: 'auto', display: isRequired || !isEditable ? 'none' : 'block' }}>
          <Switch
            withHover={false}
            size={100}
            label={false}
            value={open}
            model={`${model}.show`}
            onUpdate={(state) => setOpen(state.value)}
          />
        </div>
      </div>

      <div style={{ overflow: open ? 'visible' : 'hidden', height: open ? 'auto' : 0, opacity: open ? 1 : 0 }}>{children}</div>
    </section>
  )
}

const INPUTS = [
  {
    name: 'Label',
    width: '2fr',
    element: (id: string) => {
      return (
        <>
          <Input withHover={false} model={`${id}.label`} placeholder="Enter your question here…" />
          <Input value={id} model={`${id}._id`} className="!hidden" />
        </>
      )
    },
  },
  {
    name: 'Type',
    width: '1fr',
    element: (id: string) => (
      <Select isCompact withHover={false} defaultValue="textarea" model={`${id}.category`}>
        <Option label="Multi-Line Text" value="textarea" />
        <Option label="Single-Line Text" value="input" />
        <Option label="Yes/No" value="true_false" />
      </Select>
    ),
  },
  {
    name: 'Required',
    width: '1fr',
    element: (id: string) => (
      <Checkbox isCompact label="Required" model={`${id}.is_required`} trueIcon="check" falseIcon="empty_checkbox" falseStyle="faded" />
    ),
  },
]

export const NARRFormBuilder = withOverlayError(RootNARRFormBuilder)

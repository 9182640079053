import React from 'react'
import { persist } from 'zustand/middleware'
import create from 'zustand'
import size from 'lodash/size'
import sortBy from 'lodash/sortBy'
import { NavLink } from 'react-router-dom-v5-compat'

import { COLORS } from '@behavehealth/theme'
import { useSettings } from '@behavehealth/hooks/useSettings'
import { usePermissions } from '@behavehealth/hooks/usePermissions'
import useStore from '@behavehealth/modules/store'

import Alert from '@behavehealth/components/Alert'
import AppNavGroup from '@behavehealth/components/AppNavGroup'
import AppNavItem from '@behavehealth/components/AppNavItem'
import Divider from '@behavehealth/components/Divider'
import Link from '@behavehealth/components/Link'
import Status from '@behavehealth/components/Status'

import { getCustomPageIcon } from '@behavehealth/constructs/CustomPages/functions'
import { PoweredByBehave } from '@behavehealth/components/PoweredByBehave'

export const EHRAppNavItems = () => {
  const blocks = useStore((state: any) => sortBy(state.blocks || [], 'order'))
  const ehrBlocks = useStore((state: any) => sortBy(state.ehrBlocks || [], 'order'))
  const user = useStore((state: any) => state.user)

  const { isBehave, isManagement, isOwner, hasAccountingAccess } = useSettings()

  const hasBlocks = size(blocks) > 0
  const hasEHRBlocks = size(ehrBlocks) > 0

  const dismissSettingsBanner = useSettingsBannerMoveStore((store) => store.dismissSettingsBanner)
  const didDismissSettingsBanner = useSettingsBannerMoveStore((store) => store.didDismissSettingsBanner)

  const { blockGroups, remainingBlocks } = React.useMemo(() => {
    let blockGroups = {}
    let remainingBlocks = []

    if (size(blocks) > 0) {
      for (let i = 0; i < blocks.length; i++) {
        const element = blocks[i]

        if (!element.nav_group) {
          remainingBlocks.push(element)
          continue
        }

        // if there is nothing in the nav_group, initialize it as an empty array
        if (size(blockGroups[element.nav_group]) === 0) blockGroups[element.nav_group] = []

        // push the element into the nav_group array
        blockGroups[element.nav_group].push(element)
      }
    }

    return { blockGroups, remainingBlocks }
  }, [blocks])

  const { ehrBlockGroups, remainingEhrBlocks } = React.useMemo(() => {
    let ehrBlockGroups = {}
    let remainingEhrBlocks = []

    if (size(ehrBlocks) > 0) {
      for (let i = 0; i < ehrBlocks.length; i++) {
        const element = ehrBlocks[i]

        if (!element.nav_group) {
          remainingEhrBlocks.push(element)
          continue
        }

        // if there is nothing in the nav_group, initialize it as an empty array
        if (size(ehrBlockGroups[element.nav_group]) === 0) ehrBlockGroups[element.nav_group] = []

        // push the element into the nav_group array
        ehrBlockGroups[element.nav_group].push(element)
      }
    }

    return { ehrBlockGroups, remainingEhrBlocks }
  }, [ehrBlocks])

  return (
    <>
      <AppNavItem showForCommunity label="Dashboard" feature="dashboard" to="/dashboard" />
      {/* <AppNavItem label="Console" feature="console" to="/console" permission={isBehave} /> */}
      <AppNavItem showForCommunity feature="user_invites" label="User Invites" to="/user-invites" permission="user_invites.view" />
      <AppNavItem
        label="Form Inbox"
        to="/form-submissions"
        feature="form_submissions"
        featureFlagV2="form_submissions"
        permission="form_submissions.view"
      />
      <AppNavItem showForCommunity label="My Profile" feature="employees" to={`/me/${user.id}`} />

      {(hasBlocks || hasEHRBlocks) && (
        <>
          {Object.keys(ehrBlockGroups).map((group: any) => (
            <AppNavGroup title={group}>
              {ehrBlockGroups[group].map((block: any) => (
                <AppNavItem
                  showForCommunity
                  key={block.id}
                  label={block.name}
                  to={`/behave-forms/${block.id}`}
                  icon={getCustomPageIcon(block)}
                />
              ))}
            </AppNavGroup>
          ))}

          {size(remainingEhrBlocks) > 0 && (
            <AppNavGroup>
              {remainingEhrBlocks.map((block: any) => (
                <AppNavItem
                  showForCommunity
                  key={block.id}
                  label={block.name}
                  to={`/behave-forms/${block.id}`}
                  icon={getCustomPageIcon(block)}
                />
              ))}
            </AppNavGroup>
          )}

          {Object.keys(blockGroups).map((group: any) => (
            <AppNavGroup title={group}>
              {blockGroups[group].map((block: any) => (
                <AppNavItem
                  showForCommunity
                  key={block.id}
                  label={block.name}
                  to={`/behave-forms/${block.id}`}
                  icon={getCustomPageIcon(block)}
                />
              ))}
            </AppNavGroup>
          ))}

          {size(remainingBlocks) > 0 && (
            <AppNavGroup>
              {remainingBlocks.map((block: any) => (
                <AppNavItem
                  showForCommunity
                  key={block.id}
                  label={block.name}
                  to={`/behave-forms/${block.id}`}
                  icon={getCustomPageIcon(block)}
                />
              ))}
            </AppNavGroup>
          )}
        </>
      )}

      {/* <AppNavItem label="Analytics" icon="reports" to="/analytics" /> */}
      {/* <AppNavItem showForCommunity label="Updates" icon="default" to="/updates" /> */}

      <AppNavGroup
        title={
          <div className="flex flex-nowrap items-center">
            <div className="mr-auto">Community</div>
            <Status small label="Beta" color="blue" className="ml-2" />
          </div>
        }
      >
        <AppNavItem
          showForCommunity
          label="Explore"
          icon="community"
          to="/community/explore"
          featureFlagV2="organizations"
          permission="organizations.view"
        />
        <AppNavItem
          showForCommunity
          label="Inbox"
          icon="community_invites"
          to="/community/invites"
          color={COLORS.orange}
          featureFlagV2="organizations"
          permission="community_invites.view"
        />
        <AppNavItem
          showForCommunity
          label="My Profiles"
          icon="community_profile"
          to="/community/profiles"
          featureFlagV2="organizations"
          permission="community_profiles.view"
        />
        <AppNavItem
          showForCommunity
          label="Organizations"
          feature="organizations"
          to="/community/organizations"
          permission="organizations.view"
        />
      </AppNavGroup>

      <AppNavGroup title="Clients">
        <AppNavItem
          showForCommunity
          label="Admissions"
          to="/admissions"
          feature="admissions"
          featureFlagV2="admissions"
          permission="applications.view"
        />
        <AppNavItem
          showForCommunity
          label="Current Clients"
          to="/clients"
          featureFlagV2="clients"
          feature="clients"
          permission="clients.view"
        />
        <AppNavItem showForCommunity label="Past Clients" to="/alumni" feature="alumni" featureFlagV2="alumni" permission="clients.view" />
      </AppNavGroup>

      <AppNavGroup title="Planning">
        {isBehave && (
          <AppNavItem
            feature="clients_journey"
            label="Clients Journey"
            to="/clients-journey"
            permission={true} // TODO
            featureFlagV2="client_journey"
          />
        )}
        <AppNavItem
          showForCommunity
          feature="bed_management"
          label="Bed Management"
          to="/bed-management"
          permission="bed_management.view"
          featureFlagV2="bed_management"
        />
        <AppNavItem showMarketing label="Programs" to="/programs" feature="programs" permission="programs.view" featureFlagV2="programs" />
        <AppNavItem showForCommunity showMarketing label="To-Do" to="/todo" feature="tasks" featureFlagV2="todos" permission="todos.view" />
        <AppNavItem
          showForCommunity
          showMarketing
          label="Calendar"
          to="/calendar"
          feature="calendar"
          permission="events.view"
          featureFlagV2="calendar"
        />

        <AppNavItem label="Rounds" to="/rounds" feature="rounds" permission="rounds.view" featureFlagV2="rounds" />

        <AppNavItem
          label="Meds Pass"
          feature="medication_logs"
          to="/meds-pass"
          permission={isBehave ? true : 'med_pass.view'}
          featureFlagV2="med_pass"
        />
      </AppNavGroup>

      <AppNavGroup title="Chat">
        <AppNavItem
          showMarketing
          label="Staff Chat"
          feature="staff_chat"
          to="/company-chat"
          permission="company_chat.view"
          featureFlagV2="staff_chat"
        />
        <AppNavItem
          showMarketing
          label="Clients Chat"
          feature="clients_chat"
          to="/clients-chat"
          permission="clients_chat.view"
          featureFlagV2="clients_chat"
        />
      </AppNavGroup>

      <AppNavGroup title="Company">
        <AppNavItem showForCommunity label="Staff" to="/employees" feature="employees" permission="employees.view" featureFlagV2="staff" />
        <AppNavItem
          showForCommunity
          feature="places"
          label="Locations"
          to="/locations"
          permission="properties.view"
          featureFlagV2="properties"
        />

        <AppNavItem
          as={NavLink}
          showMarketing
          label="Reports"
          feature="live_reports"
          permission="reports.view"
          to="/reports"
          featureFlagV2="live_reports"
        />

        {(isManagement || isBehave) && (
          <AppNavItem showForCommunity label="Finance Hub" feature="financial_hub" to="/financial-hub" featureFlagV2="finance_hub" />
        )}

        <AppNavItem
          label="Accounting"
          feature="accounting"
          permission={isBehave || isOwner || isManagement || hasAccountingAccess}
          to="/accounting"
          featureFlagV2="accounting"
        />

        <AppNavItem
          showForCommunity
          showMarketing
          label="Files"
          to="/files"
          feature="files"
          featureFlagV2="files"
          permission="companies.files.view"
        />
      </AppNavGroup>

      <AppNavGroup title="Insurance">
        <AppNavItem
          feature="insurance_payers"
          label="Insurance Payers"
          to="/insurance-payers"
          permission="insurance_local_payers.view"
          featureFlagV2="insurance_payers"
        />
        <AppNavItem label="Claims" to="/claims" feature="claims" featureFlagV2="insurance_claims" permission="insurance_claims.view" />
        <AppNavItem label="Posting" to="/posting" feature="posting" featureFlagV2="insurance_posting" permission="insurance_posting.view" />
        <AppNavItem
          label="Statements"
          to="/statements"
          feature="client_statements"
          featureFlagV2="insurance_invoicing"
          // permission="insurance_invoicing.view"
          permission={isBehave}
        />
        <AppNavItem
          label="Clearinghouse"
          to="/clearing-house"
          feature="clearinghouse"
          featureFlagV2="insurance_clearinghouse"
          permission="insurance_clearinghouse.view"
        />

        <AppNavItem
          label="Reports & Files"
          to="/rcm"
          feature="reports"
          permission={isBehave || isManagement}
          addon="rcm_insurance"
          featureFlagV2="insurance_reports_files"
        />
      </AppNavGroup>

      {!didDismissSettingsBanner && (
        <div css={STYLES.settingsAlertWrapper}>
          <Divider />

          <Link to="/settings" css={STYLES.settingsAlert} onClick={() => dismissSettingsBanner()}>
            <Alert glyph="settings" type="neutral">
              <div>
                <b>Please note:</b> Settings link has been moved to the new top navigation bar
              </div>
            </Alert>
          </Link>
        </div>
      )}

      <PoweredByBehave />
    </>
  )
}

const useSettingsBannerMoveStore = create(
  persist(
    (set: any, get: any) => ({
      didDismissSettingsBanner: false,
      dismissSettingsBanner: () => set({ didDismissSettingsBanner: true }),
    }),
    { name: 'bh.settings-move-banner' },
  ),
)

const STYLES = {
  settingsAlertWrapper: {
    display: 'none',

    '@media (min-width: 1024px)': {
      display: 'block',
    },
  },

  settingsAlert: {
    display: 'block',
    color: COLORS.text,
    margin: '-0.4rem 0',
  },
}

import React from 'react'
import { useLocation } from 'react-router-dom'

import Card from '../../components/Card'
import CardTitle from '../../components/CardTitle'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import Divider from '../../components/Divider'
import Grid from '../../components/Grid'
import Label from '../../components/Label'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'

import Score from './common/Score'

import { DataFormOverlay } from '../DataFormOverlay'
import { withOverlayError } from '../../hocs/withOverlayError'
import { useSettings } from '../../hooks/useSettings'

const INITIAL_STATE = {
  q1: 0,
  q2: 0,
  q3: 0,
  q4: 0,
  q5: 0,
  q6: 0,
  // q7 not used
  q8: 0,
  q9: 0,
  q10: 0,
  q11: 0,
  q12: 0,
  q13: 0,
  q14: 0,
  q15: 0,
  q16: 0,
  q17: 0,
}

const USE_SCORE = {
  3.99: 'Not severe',
  6.99: 'Moderate Severity',
  9.99: 'Severe',
  12: 'Very Severe',
}

const RISK_SCORE = {
  6: 'Not severe',
  12: 'Moderate Severity',
  18: 'Severe',
  24: 'Very Severe',
}

const PROTECTIVE_SCORE = {
  6.99: 'Not severe',
  12.99: 'Moderate Severity',
  18.99: 'Severe',
  24: 'Very Severe',
}

const SATISFACTION_SCORE = {
  0.99: 'Not at all',
  1.99: 'Slightly',
  2.99: 'Moderately',
  3.99: 'Considerably',
  4.99: 'Extremely',
}

const RootBAMIOPOverlay = (props: any) => {
  return (
    <DataFormOverlay
      clientPortalCanEdit
      minimizeEnabled
      signoffWithoutSupervisor
      enableTreatmentPlanSelector
      title="BAM – IOP"
      category="clinical_measurement"
      subcategory="bam_iop"
      icon="clinical_measurements"
      requestClientSignature={false}
      requestAuthorSignature={false}
      requireSupervisor={false}
      {...props}
    >
      <Section
        title="Instructions"
        headingType="h2"
        scrollview={{
          id: 'instructions',
          name: 'Instructions',
        }}
      >
        <p>
          This is a standard set of questions about several areas of your life such as your health, alcohol and drug use, etc. The questions
          generally ask about the past 7 days. Please consider each question and answer as accurately as possible.
        </p>
      </Section>

      <Divider />

      <Section
        title="Questionnaire & Scoring"
        headingType="h2"
        scrollview={{
          id: 'questionnaire_scoring',
          name: 'Questionnaire & Scoring',
        }}
        commentsModel="data.questionnaire_scoring"
      >
        <Questionnaire />
      </Section>
    </DataFormOverlay>
  )
}

const Questionnaire = (props: any) => {
  const [score, setScore] = React.useState(INITIAL_STATE)

  const { isPortal } = useSettings()

  const useScore = [score.q4, score.q5, score.q6].reduce((a, b) => a + b)
  const riskScore = [score.q1, score.q2, score.q3, score.q8, score.q11, score.q15].reduce((a, b) => a + b)
  const protectiveScore = [score.q9, score.q10, score.q12, score.q13, score.q14, score.q16].reduce((a, b) => a + b)
  const satisfactionScore = score.q17

  return (
    <>
      <Grid gap="1.5rem">
        <RadioGroup
          label="1. In the past 7 days, would you say your physical health has been:"
          model="data.questionnaire.q1"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q1: state.value }))}
        >
          <Radio label="Excellent (0)" value={0} />
          <Radio label="Very Good (1)" value={1} />
          <Radio label="Good (2)" value={2} />
          <Radio label="Fair (3)" value={3} />
          <Radio label="Poor (4)" value={4} />
        </RadioGroup>

        <RadioGroup
          label="2. In the past 7 days, how many nights did you have trouble falling asleep or staying asleep?"
          model="data.questionnaire.q2"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q2: state.value }))}
        >
          <Radio label="None (0)" value={0} />
          <Radio label="1 day (1)" value={1} />
          <Radio label="2 days (2)" value={2} />
          <Radio label="3 days (3)" value={3} />
          <Radio label="4 or more days (4)" value={4} />
        </RadioGroup>

        <RadioGroup
          label="3. In the past 7 days, how many days have you felt depressed, anxious, angry, or very upset throughout the most of the day"
          model="data.questionnaire.q3"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q3: state.value }))}
        >
          <Radio label="None (0)" value={0} />
          <Radio label="1 day (1)" value={1} />
          <Radio label="2 days (2)" value={2} />
          <Radio label="3 days (3)" value={3} />
          <Radio label="4 or more days (4)" value={4} />
        </RadioGroup>

        <RadioGroup
          label="4. In the past 7 days, how many days did you drink ANY alcohol?"
          model="data.questionnaire.q4"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q4: state.value }))}
        >
          <Radio label="None (0)" value={0} />
          <Radio label="1 day (1)" value={1} />
          <Radio label="2 days (2)" value={2} />
          <Radio label="3 days (3)" value={3} />
          <Radio label="4 or more days (4)" value={4} />
        </RadioGroup>

        <RadioGroup
          label="5. In the past 7 days, how many days did you have at least 5 drinks (if you are a man) or at least four drinks (if you are a woman)? [One drink is considered one shot of hard liquor (1.5 oz.) or 12-ounce can/bottle of beer or 5 ounce glass of wine.]"
          model="data.questionnaire.q5"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q5: state.value }))}
        >
          <Radio label="None (0)" value={0} />
          <Radio label="1 day (1)" value={1} />
          <Radio label="2 days (2)" value={2} />
          <Radio label="3 days (3)" value={3} />
          <Radio label="4 or more days (4)" value={4} />
        </RadioGroup>

        <RadioGroup
          label="6. In the past 7 days, how many days did you use any illegal/street drugs or abuse any prescription medications?"
          model="data.questionnaire.q6"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q6: state.value }))}
        >
          <Radio label="None (0)" value={0} />
          <Radio label="1 day (1)" value={1} />
          <Radio label="2 days (2)" value={2} />
          <Radio label="3 days (3)" value={3} />
          <Radio label="4 or more days (4)" value={4} />
        </RadioGroup>

        <Label label="7. In the past 7 days, how many days did you use any of the following drugs:" />

        <Grid gap="1rem" className="!pl-[1.75rem]">
          <RadioGroup label="7A. Marijuana (cannabis, pot, weed)?" model="data.questionnaire.q7a" layout="vertical-dense">
            <Radio label="None (0)" value={0} />
            <Radio label="1 day (1)" value={1} />
            <Radio label="2 days (2)" value={2} />
            <Radio label="3 days (3)" value={3} />
            <Radio label="4 or more days (4)" value={4} />
          </RadioGroup>

          <RadioGroup
            label={`7B. Sedatives/Tranquilizers (e.g., "benzos", Valium, Xanax, Ativan, Ambien, "barbs", Phenobarbital, downers, etc.)?`}
            model="data.questionnaire.q7b"
            layout="vertical-dense"
          >
            <Radio label="None (0)" value={0} />
            <Radio label="1 day (1)" value={1} />
            <Radio label="2 days (2)" value={2} />
            <Radio label="3 days (3)" value={3} />
            <Radio label="4 or more days (4)" value={4} />
          </RadioGroup>

          <RadioGroup label="7C. Cocaine/Crack?" model="data.questionnaire.q7c" layout="vertical-dense">
            <Radio label="None (0)" value={0} />
            <Radio label="1 day (1)" value={1} />
            <Radio label="2 days (2)" value={2} />
            <Radio label="3 days (3)" value={3} />
            <Radio label="4 or more days (4)" value={4} />
          </RadioGroup>

          <RadioGroup
            label={`7D. Other Stimulants (e.g., amphetamine, methamphetamine, Dexedrine, Ritalin, Adderall, speed", "crystal meth", "ice", etc.)?`}
            model="data.questionnaire.q7d"
            layout="vertical-dense"
          >
            <Radio label="None (0)" value={0} />
            <Radio label="1 day (1)" value={1} />
            <Radio label="2 days (2)" value={2} />
            <Radio label="3 days (3)" value={3} />
            <Radio label="4 or more days (4)" value={4} />
          </RadioGroup>

          <RadioGroup
            label="7E. Opiates (e.g., Heroin, Morphine, Dilaudid, Demerol, Oxycontin, oxy, codeine (Tylenol 2,3,4), Percocet, Vicodin, Fentanyl, etc.)?"
            model="data.questionnaire.q7e"
            layout="vertical-dense"
          >
            <Radio label="None (0)" value={0} />
            <Radio label="1 day (1)" value={1} />
            <Radio label="2 days (2)" value={2} />
            <Radio label="3 days (3)" value={3} />
            <Radio label="4 or more days (4)" value={4} />
          </RadioGroup>

          <RadioGroup
            label="7F. Inhalants (glues/adhesives, nail polish remover, paint thinner, etc.)?"
            model="data.questionnaire.q7f"
            layout="vertical-dense"
          >
            <Radio label="None (0)" value={0} />
            <Radio label="1 day (1)" value={1} />
            <Radio label="2 days (2)" value={2} />
            <Radio label="3 days (3)" value={3} />
            <Radio label="4 or more days (4)" value={4} />
          </RadioGroup>

          <RadioGroup
            label="7G. Other drugs (steroids, non-prescription sleep/diet pills, Benadryl, Ephedra, other overthe-counter/unknown medications)?"
            model="data.questionnaire.q7g"
            layout="vertical-dense"
          >
            <Radio label="None (0)" value={0} />
            <Radio label="1 day (1)" value={1} />
            <Radio label="2 days (2)" value={2} />
            <Radio label="3 days (3)" value={3} />
            <Radio label="4 or more days (4)" value={4} />
          </RadioGroup>
        </Grid>

        <RadioGroup
          label="8. In the past 7 days, how much were you bothered by cravings or urges to drink alcohol or use drugs?"
          model="data.questionnaire.q8"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q8: state.value }))}
        >
          <Radio label="Not at all (0)" value={0} />
          <Radio label="Slightly (1)" value={1} />
          <Radio label="Moderately (2)" value={2} />
          <Radio label="Considerably (3)" value={3} />
          <Radio label="Extremely (4)" value={4} />
        </RadioGroup>

        <RadioGroup
          label="9. How confident are you in your ability to be completely abstinent (clean) from alcohol and drugs In the next 7 days?"
          model="data.questionnaire.q9"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q9: state.value }))}
        >
          <Radio label="Not at all (0)" value={0} />
          <Radio label="Slightly (1)" value={1} />
          <Radio label="Moderately (2)" value={2} />
          <Radio label="Considerably (3)" value={3} />
          <Radio label="Extremely (4)" value={4} />
        </RadioGroup>

        <RadioGroup
          label="10. In the past 7 days, how many days did you attend self-help meetings like AA or NA to support your recovery?"
          model="data.questionnaire.q10"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q10: state.value }))}
        >
          <Radio label="None (0)" value={0} />
          <Radio label="1 day (1)" value={1} />
          <Radio label="2 days (2)" value={2} />
          <Radio label="3 days (3)" value={3} />
          <Radio label="4 or more days (4)" value={4} />
        </RadioGroup>

        <RadioGroup
          label="11. In the past 7 days, how many days were you in any situations or with any people that might put you at an increased risk for using Alcohol or drugs (i.e., around risky people, places or things)?"
          model="data.questionnaire.q11"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q11: state.value }))}
        >
          <Radio label="None (0)" value={0} />
          <Radio label="1 day (1)" value={1} />
          <Radio label="2 days (2)" value={2} />
          <Radio label="3 days (3)" value={3} />
          <Radio label="4 or more days (4)" value={4} />
        </RadioGroup>

        <RadioGroup
          label="12. Does your religion or spiritually help support your recovery?"
          model="data.questionnaire.q12"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q12: state.value }))}
        >
          <Radio label="Not at all (0)" value={0} />
          <Radio label="Slightly (1)" value={1} />
          <Radio label="Moderately (2)" value={2} />
          <Radio label="Considerably (3)" value={3} />
          <Radio label="Extremely (4)" value={4} />
        </RadioGroup>

        <RadioGroup
          label="13. In the past 7 days, how many days did you spend much of the time at work, school, or doing volunteer work?"
          model="data.questionnaire.q13"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q13: state.value }))}
        >
          <Radio label="None (0)" value={0} />
          <Radio label="1 day (1)" value={1} />
          <Radio label="2 days (2)" value={2} />
          <Radio label="3 days (3)" value={3} />
          <Radio label="4 or more days (4)" value={4} />
        </RadioGroup>

        <RadioGroup
          label="14. Do you have enough income (from legal sources) to pay for necessities such as housing, transportation, food and clothing for Yourself and your dependents?"
          model="data.questionnaire.q14"
          layout="horizontal"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q14: state.value }))}
        >
          <Radio label="No (0)" value={0} />
          <Radio label="Yes (4)" value={4} />
        </RadioGroup>

        <RadioGroup
          label="15. In the past 7 days, how much have you been bothered by arguments or problems getting along with any family members or friends?"
          model="data.questionnaire.q15"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q15: state.value }))}
        >
          <Radio label="Not at all (0)" value={0} />
          <Radio label="Slightly (1)" value={1} />
          <Radio label="Moderately (2)" value={2} />
          <Radio label="Considerably (3)" value={3} />
          <Radio label="Extremely (4)" value={4} />
        </RadioGroup>

        <RadioGroup
          label="16. In the past 7 days, how many days were you in contact or spent time with any family members or friends who are supportive of your recovery?"
          model="data.questionnaire.q16"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q16: state.value }))}
        >
          <Radio label="None (0)" value={0} />
          <Radio label="1 day (1)" value={1} />
          <Radio label="2 days (2)" value={2} />
          <Radio label="3 days (3)" value={3} />
          <Radio label="4 or more days (4)" value={4} />
        </RadioGroup>

        <RadioGroup
          label="17. How satisfied are you with your progress toward achieving your recovery goals?"
          model="data.questionnaire.q17"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q17: state.value }))}
        >
          <Radio label="Not at all (4)" value={4} />
          <Radio label="Slightly (3)" value={3} />
          <Radio label="Moderately (2)" value={2} />
          <Radio label="Considerably (1)" value={1} />
          <Radio label="Extremely (0)" value={0} />
        </RadioGroup>
      </Grid>

      <Divider />

      <Grid gap="1rem">
        <DateTimeInput defaultToNow label="Time Started" model="data.started_at" />
        <DateTimeInput label="Time Ended" model="data.ended_at" />
      </Grid>

      {!isPortal && (
        <Grid gap="1rem">
          <Divider />

          <p className="m-0">
            The scoring details below are for information only. The scoring for this survey happens on our side. Please do not provide
            survey scores.
          </p>

          <Score title="Use Score" finalScore={useScore} scoring={USE_SCORE} />
          <Score title="Risk Score" finalScore={riskScore} scoring={RISK_SCORE} />
          <Score title="Protective Score" finalScore={protectiveScore} scoring={PROTECTIVE_SCORE} />
          <Score title="Satisfaction Score" finalScore={satisfactionScore} scoring={SATISFACTION_SCORE} />
        </Grid>
      )}
    </>
  )
}

const ScoreListItem = ({ children, isActive }: any) => {
  return (
    <li css={styles.scoreListItem} className={isActive ? 'is-active' : ''}>
      {children}
    </li>
  )
}

const styles = {
  scoreListItem: {
    opacity: 0.4,

    '&.is-active': {
      opacity: 1,
      fontWeight: 700,
    },
  },
}

export const BAMIOPOverlay = withOverlayError(RootBAMIOPOverlay)

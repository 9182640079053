import React from 'react'
import { NavLink, Route } from 'react-router-dom-v5-compat'
import kebabCase from 'lodash/kebabCase'
import size from 'lodash/size'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'
import { withPageError } from '@behavehealth/hocs/withPageError'

import Grid from '@behavehealth/components/Grid'
import Page from '@behavehealth/components/Page'
import Status from '@behavehealth/components/Status'

import { NARR_FORMS } from '@behavehealth/constructs/NARRDemo/data'
import { NARRFormBuilder } from '@behavehealth/constructs/NARRDemo/NARRFormBuilder'

const RootOrganizationForms = () => {
  return (
    <>
      <OrganizationFormsIndex />

      <AnimatedRoutes>
        <Route path=":id" element={<NARRFormBuilder useV6Router />} />
      </AnimatedRoutes>
    </>
  )
}

const OrganizationFormsIndex = () => {
  const to = React.useCallback((data) => data.id, [])

  const columns = React.useMemo(
    () => [
      {
        width: 260,
        id: 'name',
        model: 'name',
        title: 'Name',
        formatValue: ({ value, data }: any) => <MainCell as={NavLink} to={kebabCase(value)} value={value} />,
      },
      {
        width: 200,
        model: 'status',
        title: 'Status',
        formatValue: () => <Status label="Active" color="green" />,
      },
      {
        title: 'Tags',
        model: 'tags',
        type: 'tags',
        featureFlag: 'tags',
        editPermission: 'form_submissions.edit',
        disableSort: true,
      },
      {
        title: 'Date Created',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Last Edited',
        model: 'updated_at',
        type: 'date_time',
      },
    ],
    [],
  )

  return (
    <Page title="Organization Forms" icon="web_form">
      <Grid>
        <DataTable
          asCard
          to={to}
          columns={columns}
          title="Organization Forms"
          data={NARR_FORMS.map((form) => ({ id: Math.random(), name: form }))}
          meta={{ count: size(NARR_FORMS) }}
        />
      </Grid>
    </Page>
  )
}

export const OrganizationForms = withPageError(RootOrganizationForms)

import React from 'react'

import { beautifulAmount } from '../../utils/functions'

import Card from '../Card'
import CardHeader from '../CardHeader'
import CardSubtitle from '../CardSubtitle'
import CardTitle from '../CardTitle'

type Props = {
  actions?: any
  testKey?: any
  sign?: any
  prefix?: any
  title: any
  value?: any
  valueColor?: any
  renderValue?: any
}

const FinancialCard = ({ prefix, value, valueColor, actions, title, testKey, sign, renderValue }: Props) => {
  let finalSign = sign || prefix || ''
  let color = valueColor

  if (!finalSign && !prefix && value > 0) finalSign = '+'
  else if (!finalSign && value < 0) finalSign = '-'

  if (!valueColor) color = value >= 0 ? 'green' : 'red'

  return (
    <Card testKey={testKey}>
      <CardHeader actions={actions}>
        <CardTitle
          title={renderValue ? renderValue() : `${finalSign}${beautifulAmount(value)}`}
          color={color}
          className="!text-[1.2rem] !font-[700]"
        />
        <CardSubtitle subtitle={title} className="!text-[1rem] !font-[600]" />
      </CardHeader>
    </Card>
  )
}

export default FinancialCard

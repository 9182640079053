import React from 'react'

import CardLink from '../CardLink'
import CardMeta from '../CardMeta'
import CardTitle from '../CardTitle'
import SigneeStatus from '../Statuses/SigneeStatus'
import Status from '../Status'

const SigneeCard = ({ signee, to, variant }) => {
  const contractsSigned = signee?.contracts?.filter((o: any) => o.status === 'signed').length || 0
  const contractsLength = signee?.contracts?.length || 0

  return (
    <CardLink to={to} variant={variant}>
      <CardTitle title={signee?.signer?.name} />
      <CardMeta>
        <SigneeStatus signee={signee?.signer} />
        {contractsLength ? (
          <Status label={`${contractsSigned} / ${contractsLength} Agreements Signed`} color="green" />
        ) : (
          <Status label="0 Agreements" color="green" />
        )}
      </CardMeta>
    </CardLink>
  )
}

export default SigneeCard

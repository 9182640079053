import React from 'react'
import { useParams, useRouteMatch } from 'react-router-dom'

import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { useSettings } from '@behavehealth/hooks/useSettings'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { ClientCommunicationsDataTable } from '@behavehealth/constructs/Communications/ClientCommunicationsDataTable'
import { Dropdown, DropdownItem, Grid, HelpTagIframe, Page } from '@behavehealth/components'

type Props = {
  canCreate: boolean
}

const pageConfig = {
  feature: 'communications',
  title: 'Communications',
}

const RootCommunications: React.FC<Props> = ({ canCreate = true }) => {
  const match = useRouteMatch()
  const { user } = useSettings()
  const { resource_id: clientId }: any = useParams()

  const tableProps = useDataTable({
    name: ['client', clientId, 'communications'],
    endpoint: `/residents/${clientId}/communications`,
    updateDeleteEndpoint: '/communications',
    enabled: !!clientId,
    localStorageKey: 'client_communications_v1',
  })

  const actions = canCreate && (
    <Dropdown label="Add Communication Logs…" glyph="add" buttonType="primary" permission="communications.create">
      <DropdownItem
        label="Add Communication Log"
        icon="communications"
        color="orange"
        link={{
          pathname: `${match.url}/new`,
          data: {
            employee: user,
          },
        }}
      />

      <DropdownItem
        label="Add Many Communication Logs…"
        glyph="stack"
        color="orange"
        featureFlagV2="bulk_add"
        link={{
          pathname: `${match.url}/new-batch`,
        }}
      />
    </Dropdown>
  )

  return (
    <Page
      title="Communications"
      feature="communications"
      plan="lite"
      help={<HelpTagIframe id="client_contacts_and_communications" />}
      actions={actions}
    >
      <Grid gap="1rem" columns="100%">
        <ClientCommunicationsDataTable
          {...tableProps}
          to={({ id }) => ({
            pathname: `${match.url}/${id}`,
            parent: match,
          })}
          headerLinksConfig={[
            {
              type: 'report',
              to: '/reports/clients-communications',
            },
          ]}
          batchActionsConfig={[
            {
              type: 'delete',
              permission: 'communications.delete',
              action: async ({ ids }: any) => {
                await tableProps.deleteRecords(ids.join(','))
              },
            },
          ]}
        />
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(RootCommunications, pageConfig))

import React from 'react'
import { useRouteMatch, useParams } from 'react-router-dom'

import { useGet } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'
import Dropdown from '@behavehealth/components/Dropdown'
import DropdownItem from '@behavehealth/components/DropdownItem'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Grid, Page, HelpTagIframe } from '@behavehealth/components'
import { ShiftNotesDataTable } from '@behavehealth/constructs/ShiftNotes/ShiftNotesDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const pageConfig = {
  feature: 'shift_notes',
  help: <HelpTagIframe id="shift_notes" />,
  marketingID: 'shift_notes',
}

const ShiftNotes = ({ canCreate = true }) => {
  const match = useRouteMatch()
  const { resource_id: clientId }: any = useParams()

  const { data: client }: any = useGet({
    name: ['client', clientId],
    url: `/residents/${clientId}`,
  })

  const tableProps = useDataTable({
    name: ['client', clientId, 'shift-notes'],
    endpoint: `/residents/${clientId}/housing_notes`,
    updateDeleteEndpoint: '/housing_notes',
    enabled: !!clientId,
    localStorageKey: 'client_shift_notes_v1',
  })

  const actions = (
    <>
      <Dropdown label="Add Shift Notes…" glyph="add" buttonType="primary" permission="shift_notes.create">
        <DropdownItem
          label="Add Shift Note"
          icon="housing_notes"
          color="blue"
          link={{
            pathname: `${match.url}/new`,
            data: {
              residents: [client],
            },
          }}
        />
        <DropdownItem label="Add Many Shift Notes…" glyph="stack" color="blue" link={`${match.url}/new-batch`} featureFlagV2="bulk_add" />
      </Dropdown>
    </>
  )

  return (
    <Page actions={canCreate && actions} {...pageConfig}>
      <Grid gap="1rem" columns="100%">
        <ShiftNotesDataTable
          {...tableProps}
          headerLinksConfig={[
            {
              type: 'report',
              to: '/reports/shift-notes',
            },
          ]}
          to={({ id }: any) => ({
            pathname: `${match.url}/${id}`,
            parent: match,
          })}
          batchActionsConfig={[
            {
              type: 'delete',
              permission: 'shift_notes.delete',
              action: async ({ ids }: any) => {
                await tableProps.deleteRecords(ids.join(','))
              },
            },
          ]}
        />
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(ShiftNotes, pageConfig))

import React from 'react'
import { Link, NavLink, Route } from 'react-router-dom-v5-compat'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { ClientTrackOverlay } from '@behavehealth/constructs/ClientTracks/ClientTrackOverlay'
import { ClientTracksDataTable } from '@behavehealth/constructs/ClientTracks/ClientTracksDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { withPageError } from '@behavehealth/hocs/withPageError'

import Alert from '@behavehealth/components/Alert'
import Button from '@behavehealth/components/Button'
import Page from '@behavehealth/components/Page'

const ClientTracks = () => {
  const tableProps = useDataTable({
    name: ['client_tracks'],
    endpoint: `/client_tracks`,
    localStorageKey: 'client_tracks_v1',
  })

  return (
    <>
      <Page
        breakpoint={3}
        title="Client Tracks"
        feature="client_tracks"
        actions={<Button as={Link} label="Add Client Track" type="primary" glyph="add" link="new" permission="client_tracks.create" />}
      >
        <div className="grid gap-3">
          <Alert small contrast glyph="info">
            <div>
              <b>Client Tracks</b>
            </div>

            <ul className="pl-5">
              <li>Each client track has a corresponding workflow</li>
              <li>Configure the steps and instructions for each track</li>
              <li>Create multiple tracks and assign them to clients as needed</li>
            </ul>
          </Alert>

          <ClientTracksDataTable to={(track) => track.id} {...tableProps} />
        </div>
      </Page>

      <AnimatedRoutes>
        <Route path=":id" element={<ClientTrackOverlay useV6Router />} />
      </AnimatedRoutes>
    </>
  )
}

export default withPageError(ClientTracks)

import React from 'react'
import size from 'lodash/size'
import sortBy from 'lodash/sortBy'

import { COLORS, HARD_SHADOW } from '../../theme'
import { useGet } from '../../hooks/useNewAPI'

import Button from '../Button'
import Flex from '../Flex'
import Markup from '../Markup'
import State from '../State'
import Tooltip from '../Tooltip'
import Card from '../Card'
import CardTitle from '../CardTitle'
import CardHeader from '../CardHeader'
import Type from '../Typography/Type'

import { AppSidebarContent } from './AppSidebarContent'
import { AppSidebarHeader } from './AppSidebarHeader'
import { AppSidebarView } from './AppSidebarView'

import useCopyToClipboard from '../../hooks/useCopyToClipboard'

const groupData = (data: any) => {
  const groups: any = {}
  const other: any = []

  if (!data) return { groups, other }

  for (const item of data) {
    if (item.subcategory) {
      if (!groups[item.subcategory]) groups[item.subcategory] = []

      groups[item.subcategory].push(item)
    } else {
      other.push(item)
    }
  }

  return { groups, other }
}

const QuickTextView: React.FC<any> = () => {
  const { data, isLoading }: any = useGet({
    name: ['quick_text'],
    url: `/quick_paragraphs`,
  })

  const isEmpty = size(data) === 0

  const personalQuickText = sortBy(
    data?.filter((item: any) => item.category === 'personal'),
    'order',
  )
  const companyQuickText = sortBy(
    data?.filter((item: any) => item.category === 'company'),
    'order',
  )

  const { groups: groupedPersonal, other: otherPersonal } = groupData(personalQuickText)
  const { groups: groupedCompany, other: otherCompany } = groupData(companyQuickText)

  const hasPersonalQuickText = size(personalQuickText) > 0
  const hasCompanyQuickText = size(companyQuickText) > 0

  return (
    <AppSidebarView>
      <AppSidebarHeader
        title="Quick Text"
        icon="quick_text"
        actions={!isEmpty && <Button hideLabel color="text" glyph="settings" size={200} link="/settings/quick-text" />}
      />

      <AppSidebarContent css={styles.sidebarContent}>
        {(isLoading || isEmpty) && (
          <State
            isLoading={isLoading}
            isEmpty={isEmpty}
            icon="quick_text"
            title="Quick Text"
            emptyDescription="Manage quick texts from settings"
            emptyActions={
              <Button label="Manage Quick Texts" color="blue" type="default" glyph="settings" size={200} link="/settings/quick-text" />
            }
          />
        )}

        {hasPersonalQuickText && (
          <>
            <Type as="h3" variant="CARD_TITLE" children="Personal Quick Texts" css={styles.heading} />

            {Object.keys(groupedPersonal).map((groupName: any) => {
              const items = groupedPersonal[groupName]

              return (
                <div key={groupName}>
                  <div css={styles.content}>
                    <div css={styles.subheading}>{groupName}</div>
                    {items.map((item: any) => (
                      <QuickTextItem key={item.id} data={item} />
                    ))}
                  </div>
                </div>
              )
            })}

            {size(otherPersonal) > 0 && (
              <>
                <div css={styles.content}>
                  {size(groupedPersonal) > 0 && <div css={styles.subheading}>Other</div>}

                  {otherPersonal.map((item: any) => (
                    <QuickTextItem key={item.id} data={item} />
                  ))}
                </div>
              </>
            )}
          </>
        )}

        {hasCompanyQuickText && (
          <>
            <Type as="h3" variant="CARD_TITLE" children="Company Quick Texts" css={styles.heading} />

            {Object.keys(groupedCompany).map((groupName: any) => {
              const items = groupedCompany[groupName]

              return (
                <div key={groupName}>
                  <div css={styles.content}>
                    <div css={styles.subheading}>{groupName}</div>
                    {items.map((item: any) => (
                      <QuickTextItem key={item.id} data={item} />
                    ))}
                  </div>
                </div>
              )
            })}

            {size(otherCompany) > 0 && (
              <>
                <div css={styles.content}>
                  {size(groupedCompany) > 0 && <div css={styles.subheading}>Other</div>}

                  {otherCompany.map((item: any) => (
                    <QuickTextItem key={item.id} data={item} />
                  ))}
                </div>
              </>
            )}
          </>
        )}
      </AppSidebarContent>
    </AppSidebarView>
  )
}

const QuickTextItem = ({ data }: any) => {
  const { copy } = useCopyToClipboard({
    notification: 'Quick text copied!',
  })

  if (!data) return null

  return (
    <Card css={styles.card}>
      <CardHeader>
        <Flex nowrap gap="0.75rem" justifyContent="space-between">
          <CardTitle title={data.name} />

          <div>
            <Flex nowrap gap="0.1rem">
              {/* <Tooltip content="Edit">
                <Button hideLabel glyph="edit" type="minimal" link={`/settings/quick-text/${data.id}`} css={styles.actionButton} />
              </Tooltip> */}

              <Tooltip content="Copy">
                <Button
                  hideLabel
                  size={100}
                  glyph="copy"
                  color="blue"
                  type="minimal"
                  display="inline-flex"
                  css={styles.actionButton}
                  onClick={() => copy(data.plain_notes)}
                />
              </Tooltip>
            </Flex>
          </div>
        </Flex>

        <Markup value={data.notes} />
      </CardHeader>
    </Card>
  )
}

const styles = {
  sidebarContent: {
    padding: 0,
    background: '#F7F8FB',
  },

  heading: {
    padding: '0.5rem 1rem',
    fontSize: '1rem',
    fontWeight: 700,
    borderBottom: `1px solid ${COLORS.divider}`,
    background: COLORS.white,
    boxShadow: HARD_SHADOW(3),
    position: 'sticky',
    top: 0,
  },

  subheading: {
    fontSize: '0.9rem',
    fontWeight: 700,
    textTransform: 'uppercase',
    letterSpacing: 1,
    color: COLORS.textMuted,
  },

  content: {
    padding: '1rem',
    display: 'grid',
    gridGap: '0.5rem',
  },

  card: {
    display: 'flex',
    flexWrap: 'nowrap',

    '.ProseMirror': {
      outline: 'none !important',
    },

    '.ProseMirror >*:first-child': {
      marginTop: 0,
    },

    '.ProseMirror >*:last-child': {
      marginBottom: 0,
    },
  },

  cardActions: {
    flex: '0 0 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderLeft: `1px solid ${COLORS.divider}`,
  },

  item: {
    borderBottom: `1px solid ${COLORS.divider}`,
    padding: '0.3rem 1rem',
    display: 'block',
    textDecoration: 'none',
    color: COLORS.textMuted,
    fontSize: '0.9rem',

    '.ProseMirror >*:first-child': {
      marginTop: 0,
    },

    '.ProseMirror >*:last-child': {
      marginBottom: 0,
    },
  },

  label: {
    fontSize: '0.95rem',
    fontWeight: 500,
    color: COLORS.text,
  },

  actionButton: {
    width: '28px',
    minWidth: '28px',

    height: '28px',
    minHeight: '28px',

    svg: {
      width: 14,
      height: 14,
    },
  },
}

export default QuickTextView

import React from 'react'

import { titleCase, usDateTime } from '../../utils/functions'
import withSettings from '../../hocs/withSettings'

import Table from '../Table/Table'
import TableCell from '../Table/TableCell'

const columns = ({ to, moveLink, removeLink, timezone }: any) => [
  {
    width: 220,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'started_at',
    Header: 'From',
    Cell: ({ row, value }: any) => <TableCell.MainLink to={to?.(row.original.id)} label={usDateTime(value, timezone)} />,
  },
  {
    width: 200,
    accessor: 'ended_at',
    Header: 'Until',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
  },
  {
    width: 160,
    accessor: 'status',
    Header: 'Occupied / Reserved',
    Cell: ({ value }: any) => <TableCell value={value && titleCase(value)} />,
  },
  {
    width: 240,
    accessor: 'place.program.name',
    Header: 'Program',
    Cell: ({ value, row }: any) => <TableCell.MainLink to={`/programs/${row.original.place?.program?.id}`} label={value} avatar={null} />,
  },
  {
    width: 160,
    accessor: 'place.phase.name',
    Header: 'List',
  },
  {
    width: 160,
    accessor: 'place.name',
    Header: 'Seat',
  },
  {
    width: 200,
    accessor: 'created_at',
    Header: 'Date Added',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
  },
  {
    width: 200,
    accessor: 'updated_at',
    Header: 'Date Updated',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
  },
  // {
  //   id: 'actions',
  //   width: 160,
  //   accessor: 'id',
  //   disableFilters: true,
  //   canToggleVisible: false,
  //   Header: 'Actions',
  //   Cell: ({ row }) => {
  //     if (row.original.until) return <TableCell.Empty />

  //     return (
  //       <Dropdown buttonSize={200} label="Actions">
  //         <DropdownItem label="Move…" glyph="check_in" color="blue" link={moveLink?.(row.original.id)} />
  //         <DropdownItem label="Remove…" glyph="delete" color="red" link={removeLink?.(row.original.id)} />
  //       </Dropdown>
  //     )
  //   },
  // },
]

type Props = {
  batchActionsConfig?: Object
  data: Object
  emptyActions?: React.ReactNode
  emptyDescription: string
  icon: string
  isLoading: boolean
  localStorageKey: string
  moveLink?: any
  removeLink?: any
  timezone: string
  title: string
  titleAfter?: React.ReactNode
  to?: Function
}

const ClientProgramsTable = (props: Props) => {
  const {
    batchActionsConfig,
    data,
    emptyActions,
    emptyDescription,
    icon,
    isLoading,
    localStorageKey,
    moveLink,
    removeLink,
    timezone,
    title,
    titleAfter,
    to,
  } = props

  return (
    <>
      <Table
        title={title}
        titleAfter={titleAfter}
        icon={icon}
        data={data}
        columns={columns({ to, moveLink, removeLink, timezone })}
        isLoading={isLoading}
        emptyActions={emptyActions}
        emptyDescription={emptyDescription}
        batchActionsConfig={batchActionsConfig}
        localStorageKey={localStorageKey}
      />
    </>
  )
}

ClientProgramsTable.defaultProps = {
  title: 'Programs',
  icon: 'checklist',
  emptyDescription: 'No programs added yet',
  localStorageKey: 'client_programs',
}

export default withSettings(ClientProgramsTable)

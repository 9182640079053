import React from 'react'
import size from 'lodash/size'
import startCase from 'lodash/startCase'

import { countWord, address } from '../../utils/functions'
import { useUpdate } from '../../hooks/useNewAPI'
import { withOverlayError } from '../../hocs/withOverlayError'

import Alert from '../../components/Alert'
import Button from '../../components/Button'
import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import ContextShow from '../../components/Forms/ContextShow'
import DateInput from '../../components/Forms/DateInput'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Label from '../../components/Label'
import MultiOverlaySelector from '../../components/Forms/Selectors/MultiOverlaySelector/MultiOverlaySelector'
import Option from '../../components/Forms/Option'
import Overlay from '../../components/Overlay'
import Section from '../../components/Section'
import Select from '../../components/Forms/Select'

import EventCategorySelect from '../../components/Elements/EventCategorySelect'
import ColorSelector from '../../components/Forms/ColorSelector'
import TimeInputSelector from '../../components/Forms/TimeInputSelector/TimeInputSelector'
import MiniRichTextEditor from '../../components/Forms/MiniRichTextEditor'
import URLInput from '../../components/Forms/URLInput'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Links from '../../components/Forms/Links'
import Accordions from '../../components/Accordions'

import { ICONS } from '../../theme'

const RootEventsBatchEditOverlay = (props: any) => {
  const { onClose, data, afterSave } = props

  const dataCount = size(data)
  const [formData, setFormData]: any = React.useState({})
  const [formValid, setFormValid]: any = React.useState(true)

  const { mutateAsync: batchUpdate, isLoading }: any = useUpdate({
    name: ['smart-batch-update-events'],
    url: `/events/batch`,
    invalidate: ['events'],
  })

  const handleSave = async () => {
    try {
      await batchUpdate(
        data.map((o: any) => {
          return {
            id: o.id,
            ...(formData?.batch_update?.event_category && { category: formData?.category }),
            ...(formData?.batch_update?.color && { color: formData?.color }),
            ...(formData?.batch_update?.date && { started_at: formData?.started_at }),
            ...(formData?.batch_update?.start_time && { start_time: formData?.start_time }),
            ...(formData?.batch_update?.end_time && { end_time: formData?.end_time }),
            ...(formData?.batch_update?.public_description && { public_description: formData?.public_description }),
            ...(formData?.batch_update?.staff && { employee_ids: formData?.employee_ids }),
            ...(formData?.batch_update?.place && {
              meeting_place: formData?.meeting_place,
              place_details: formData?.place_details,
              ...(formData?.meeting_place === 'online' && { meeting_url: formData?.meeting_url }),
              ...((formData?.meeting_place === 'property' || formData?.meeting_place === 'office') && {
                house_id: formData?.house_id,
                room_id: formData?.room_id,
              }),
              ...(formData?.meeting_place === 'organization' && { organization_id: formData?.organization_id }),
            }),
            ...(formData?.batch_update?.links && { urls: formData?.urls }),
            ...(formData?.batch_update?.notifications && {
              should_send_reminders: formData?.should_send_reminders,
              send_reminders: formData?.send_reminders,
            }),
            ...(formData?.batch_update?.notes && { internal_notes: formData?.internal_notes }),
          }
        }),
      )

      if (afterSave) afterSave()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Overlay showBackdrop onClose={onClose}>
      <Overlay.Header title="Events Batch Edit" glyph="edit" />

      <Overlay.Content>
        <Form onUpdate={setFormData} onValidationUpdate={setFormValid}>
          <Section>
            <FormSection maxWidth="100%">
              <Alert contrast glyph="info">
                Batch Editing {countWord('Events', dataCount)}
              </Alert>

              <Alert contrast glyph="warning" type="negative">
                Using Batch Edit to update will <strong>replace</strong> the existing data across all chosen events.
              </Alert>

              <CheckboxGroup label="What fields would you like to Batch Update?" layout="vertical-dense">
                <Checkbox label="Event Category" model="batch_update.event_category" />
                <Checkbox label="Color" model="batch_update.color" />
                <Checkbox label="Date" model="batch_update.date" />
                <Checkbox label="Start Time" model="batch_update.start_time" />
                <Checkbox label="End Time" model="batch_update.end_time" />
                <Checkbox label="Public Description" model="batch_update.public_description" />
                <Checkbox label="Staff" model="batch_update.staff" />
                <Checkbox label="Place" model="batch_update.place" />
                <Checkbox label="Links" model="batch_update.links" />
                <Checkbox label="Notifications" model="batch_update.notifications" />
                <Checkbox label="Notes" model="batch_update.notes" />
              </CheckboxGroup>

              <Alert contrast glyph="info" type="warning">
                You cannot Batch Edit these fields: <i>Title</i>, <i>Clients</i>, <i>Attachments</i>
              </Alert>
            </FormSection>
          </Section>

          <Section>
            <FormSection maxWidth="100%">
              <Accordions showToggle={false}>
                <FormSection horizontal>
                  <ContextShow when="batch_update.event_category" is={true}>
                    <div className="!flex-auto">
                      <EventCategorySelect label="Event Category" model="category" defaultValue="general" />
                    </div>
                  </ContextShow>

                  <ContextShow when="batch_update.color" is={true}>
                    <ColorSelector label="Color" model="color" />
                  </ContextShow>
                </FormSection>

                <ContextShow when="batch_update.date" is={true}>
                  <DateInput
                    label="Date"
                    model="started_at"
                    validations={{
                      presence: {
                        message: 'Please select an event date',
                      },
                    }}
                  />
                </ContextShow>

                <FormSection horizontal>
                  <ContextShow when="batch_update.start_time" is={true}>
                    <TimeInputSelector
                      grow
                      label="Start Time"
                      model="start_time"
                      validations={{
                        presence: {
                          message: 'Please select when the event starts',
                        },
                      }}
                    />
                  </ContextShow>

                  <ContextShow when="batch_update.end_time" is={true}>
                    <TimeInputSelector
                      grow
                      label="End Time"
                      model="end_time"
                      validations={{
                        presence: {
                          message: 'Please select when the event ends',
                        },
                      }}
                    />
                  </ContextShow>
                </FormSection>

                <ContextShow when="batch_update.public_description" is={true}>
                  <MiniRichTextEditor useDictation useQuickText label="Public Description" model="public_description" />
                </ContextShow>

                <ContextShow when="batch_update.staff" is={true}>
                  <Accordions.Item isOpen title="Staff">
                    <FormSection>
                      <MultiOverlaySelector
                        model="employees"
                        type="employees.active"
                        icon={ICONS.employees}
                        selectTitle={(data: any) => data?.name}
                        selectDescription={(data: any) => startCase(data?.position)}
                      />
                    </FormSection>
                  </Accordions.Item>
                </ContextShow>

                <ContextShow when="batch_update.place" is={true}>
                  <Accordions.Item isOpen title="Place">
                    <FormSection>
                      <Select label="Meeting Place" model="meeting_place" defaultValue="none" className="!flex-1">
                        <Option label="None" value="none" />
                        <Option label="Online" value="online" />
                        <Option label="Housing" value="property" />
                        <Option label="Office" value="office" />
                        <Option label="Organization" value="organization" />
                        <Option label="Other" value="other" />
                      </Select>

                      <ContextShow when="meeting_place" is="online">
                        <URLInput
                          label="Meeting Link"
                          model="meeting_url"
                          validations={{
                            presence: {
                              message: 'Please enter a meeting link',
                            },
                          }}
                        />
                      </ContextShow>

                      <ContextShow when="meeting_place" is="property">
                        <ObjectSelector
                          icon={ICONS.properties}
                          label="Housing"
                          type="properties"
                          model="house"
                          selectTitle={(data: any) => data?.name}
                          selectDescription={(data: any) => address(data?.address)}
                          validations={{
                            presence: {
                              message: 'Please select a property',
                            },
                          }}
                        />

                        <ObjectSelector
                          icon={ICONS.rooms}
                          label="Room (optional)"
                          type="property.rooms"
                          model="room"
                          dependent="house_id"
                          disableUnless="house_id"
                          selectTitle={(data: any) => data?.name}
                        />
                      </ContextShow>

                      <ContextShow when="meeting_place" is="office">
                        <ObjectSelector
                          icon={ICONS.properties}
                          label="Office"
                          type="offices"
                          model="house"
                          selectTitle={(data: any) => data?.name}
                          selectDescription={(data: any) => address(data?.address)}
                          validations={{
                            presence: {
                              message: 'Please select a property',
                            },
                          }}
                        />

                        <ObjectSelector
                          icon={ICONS.rooms}
                          label="Room (optional)"
                          type="property.rooms"
                          model="room"
                          dependent="house_id"
                          disableUnless="house_id"
                          selectTitle={(data: any) => data?.name}
                        />
                      </ContextShow>

                      <ContextShow when="meeting_place" is="organization">
                        <ObjectSelector
                          label="Organization"
                          model="organization"
                          type="organizations"
                          icon={ICONS.organizations}
                          selectTitle={(data: any) => data?.name}
                          selectDescription={(data: any) => address(data?.address)}
                          validations={{
                            presence: {
                              message: 'Please select an organization',
                            },
                          }}
                        />
                      </ContextShow>

                      <ContextShow when="meeting_place" within={['property', 'organization', 'online', 'other']}>
                        <MiniRichTextEditor useQuickText label="More Details" model="place_details" />
                      </ContextShow>
                    </FormSection>
                  </Accordions.Item>
                </ContextShow>

                <ContextShow when="batch_update.links" is={true}>
                  <Accordions.Item isOpen title="Links">
                    <Links model="urls" />
                  </Accordions.Item>
                </ContextShow>

                <ContextShow when="batch_update.notifications" is={true}>
                  <Accordions.Item isOpen title="Notifications">
                    <FormSection>
                      <Alert glyph="info" type="positive">
                        Notifications are being sent to the above <b>Staff & Clients</b>
                      </Alert>

                      <Label label="Time-based Reminders:" className="!pb-0" />

                      <Checkbox
                        label="Send SMS and Email Reminders"
                        model="should_send_reminders"
                        trueIcon="check"
                        falseIcon="cross"
                        falseStyle="faded-linethrough"
                      />

                      <ContextShow when="should_send_reminders" is={true}>
                        <div className="!pl-6">
                          <CheckboxGroup
                            label="Send Reminders Options"
                            layout="vertical-dense"
                            validations={{
                              presence: {
                                message: 'Please select at least one option',
                              },
                            }}
                          >
                            <Checkbox label="Ninety days before the event" model="send_reminders.ninety_days" />
                            <Checkbox label="Sixty days before the event" model="send_reminders.sixty_days" />
                            <Checkbox label="Thirty days before the event" model="send_reminders.thirty_days" />
                            <Checkbox label="Three days before the event" model="send_reminders.three_days" />
                            <Checkbox label="Two days before the event" model="send_reminders.two_days" />
                            <Checkbox label="One day before the event" model="send_reminders.one_day" />
                            <Checkbox label="Three hours before the event" model="send_reminders.three_hours" />
                            <Checkbox label="Two hours before the event" model="send_reminders.two_hours" />
                            <Checkbox label="One hour before the event" model="send_reminders.one_hour" />
                            <Checkbox label="15 min before event" model="send_reminders.15_minutes" />
                          </CheckboxGroup>
                        </div>
                      </ContextShow>
                    </FormSection>
                  </Accordions.Item>
                </ContextShow>

                <ContextShow when="batch_update.notes" is={true}>
                  <Accordions.Item isOpen title="Notes">
                    <MiniRichTextEditor useQuickText label="Internal Notes" model="internal_notes" />
                  </Accordions.Item>
                </ContextShow>
              </Accordions>
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label="Save"
          glyph="check"
          type="primary"
          color="green"
          onClick={handleSave}
          isDisabled={!formValid}
          isLoading={isLoading}
          flex="100 1 auto"
        />
      </Overlay.Footer>
    </Overlay>
  )
}

export const EventsBatchEditOverlay = withOverlayError(RootEventsBatchEditOverlay)

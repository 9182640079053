import React from 'react'
import size from 'lodash/size'
import { v4 as uuid } from 'uuid'

import { apiGet } from '../../modules/api'
import { DataTable } from '../../components/DataTable/DataTable'
import { encodeObjectToURL, isPast, countWord } from '../../utils/functions'
import { RECURRING_DAYS, RECURRING_FREQUENCIES } from '../../utils/recurrence'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Button from '../../components/Button'
import Card from '../../components/Card'
import Status from '../../components/Status'
import ContextShow from '../../components/Forms/ContextShow'
import DateInput from '../../components/Forms/DateInput'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Label from '../../components/Label'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Option from '../../components/Forms/Option'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import PageSection from '../../components/PageSection/PageSection'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Select from '../../components/Forms/Select'
import State from '../../components/State'
import Textarea from '../../components/Forms/Textarea'

import Table from '../../components/Table/Table'
import TableCell from '../../components/Table/TableCell'

import { InvoiceBilledBySection } from '../InvoiceElements/InvoiceBilledBySection'
import { InvoiceBilledToSection } from '../InvoiceElements/InvoiceBilledToSection'
import { InvoiceLinesEditTable } from '../InvoiceElements/InvoiceLinesEditTable'
import { InvoiceLinesTable } from '../InvoiceElements/InvoiceLinesTable'
import { InvoiceSettingsSections } from '../InvoiceElements/InvoiceSettingsOverlay'
import { SectionCard } from '../../components/SectionCard'

import { PaymentMethod, PaymentMethodTitle, PaymentMethodIcon, PaymentMethodDescription } from '../BillingElements/PaymentMethodElements'

const INITIAL_DATA = {
  status: 'draft',
  billed_by_company_name: 'Behave Health Corp',
  billed_by_email: 'contact@behavehealth.com',
  billed_by_phone_no: '+1 650-338-4113',
  billed_by_address: {
    address_line_1: '135 Main Street',
    address_line_2: 'Suite #1140',
    city: 'San Francisco',
    state: 'CA',
    zip_code: '94105',
  },
}

const RootEHRInvoiceBuilderOverlay = (props: any) => {
  const { close, data, form, initialModel, isEditable, isInvalid, isNew, isOverlayLoading, isSaving, onValidationUpdate, saveWithData } =
    useOverlay({
      name: 'invoices',
      endpoint: '/invoices/builder',
      options: props,
    })

  const [invoiceLines, setInvoiceLines]: any = React.useState(data?.invoice_lines || [])
  const [deletedInvoiceLines, setDeletedInvoiceLines]: any = React.useState([])

  const { tenant, isBehave, timezone } = useSettings()

  const [dates, setDates]: any = React.useState([])
  const [formData, setFormData] = React.useState(initialModel)
  const [paymentMethod, setPaymentMethod] = React.useState(null)

  const facilityId = tenant?.id

  const invoicesCount = size(dates)

  const save = async () => {
    const formData = form.current.getFormValue()

    const invoice_lines_attributes: any = []

    for (let i = 0; i < invoiceLines.length; i++) {
      const line = invoiceLines[i]

      invoice_lines_attributes.push({ ...line, order: i })
    }

    for (const line of deletedInvoiceLines) {
      invoice_lines_attributes.push(line)
    }

    try {
      const result = await saveWithData({
        ...formData,
        facility_id: tenant?.id,
        invoice_lines_attributes,
        auto_collection_payment_method_id: paymentMethod?.id || null,
      })

      if (result.invoice_lines) {
        setInvoiceLines(result.invoice_lines)
      }

      setDeletedInvoiceLines([])
    } catch (error) {
      console.error(error)
    }
  }

  if (isOverlayLoading) {
    return <OverlayLoader position="right" maxWidth={140} />
  }

  return (
    <Overlay onClose={close} position="right" maxWidth={140} showBackdrop={isEditable} closeOnEscape={false}>
      <Overlay.Header title="Batch Create Invoices" icon="invoices" />

      <Overlay.Content>
        <Form
          isCompact
          getForm={form}
          initialModel={{
            ...initialModel,
            ...(isNew && INITIAL_DATA),
            ...(isNew && { pass_fee_to_payer: !!tenant?.pass_behave_internal_billing_fee_to_payer }),
          }}
          isEditable={isEditable}
          timezone={timezone}
          onUpdate={setFormData}
          onValidationUpdate={onValidationUpdate}
        >
          <FormSection maxWidth="100%" className="px-5 pt-4 pb-12">
            <PageSection>
              <PageSection.Header>
                <PageSection.Title title="Batch Generation" className="!text-[1.5rem]" />
              </PageSection.Header>

              <div className="grid gap-4 grid-cols-1 mq1024:grid-cols-[1fr_2fr]">
                <SectionCard title="Generate Invoices" className="!p-3">
                  <div className="grid gap-4">
                    <Flex gap="1rem">
                      <DateInput
                        defaultToNow
                        label="First Due Date"
                        model="from"
                        validations={{
                          presence: {
                            message: 'Please add the first due date',
                          },
                        }}
                      />

                      {/* <DateInput
                      defaultToNow
                      label="Starting From"
                      model="started_at"
                      validations={{
                        presence: {
                          message: 'Please add a start date',
                        },
                      }}
                    /> */}

                      <DateInput
                        defaultInOneMonth
                        label="Generate Until"
                        model="until"
                        validations={{
                          presence: {
                            message: 'Please add an end date',
                          },
                        }}
                      />
                    </Flex>

                    <Flex gap="1rem">
                      <RadioGroup
                        label="Repeating Frequency"
                        model="frequency"
                        layout="horizontal-dense"
                        defaultValue="monthly"
                        validations={{
                          presence: {
                            message: 'Please select a billing frequency',
                          },
                        }}
                      >
                        {Object.keys(RECURRING_FREQUENCIES).map((key) => (
                          <Radio testKey={`frequency_selector_${key}`} key={key} label={RECURRING_FREQUENCIES[key].label} value={key} />
                        ))}
                      </RadioGroup>

                      <ContextShow when="frequency" is="weekly">
                        <Select
                          asNumber
                          label="Weekly Due Day"
                          model="recurring_weekly_due_day"
                          defaultValue={0}
                          validations={{
                            presence: {
                              message: 'Please select a due day',
                            },
                          }}
                        >
                          {RECURRING_DAYS.weekly.map((key, idx) => (
                            <Option key={idx} label={`Every ${key}`} value={idx} />
                          ))}
                        </Select>
                      </ContextShow>

                      <ContextShow when="frequency" is="biweekly">
                        <Select
                          asNumber
                          label="Biweekly Due Day"
                          model="recurring_biweekly_due_day"
                          defaultValue={0}
                          validations={{
                            presence: {
                              message: 'Please select a due day',
                            },
                          }}
                        >
                          {RECURRING_DAYS.biweekly.map((key, idx) => (
                            <Option key={idx} label={`Every other ${key}`} value={idx} />
                          ))}
                        </Select>
                      </ContextShow>

                      <ContextShow when="frequency" is="monthly">
                        <Select
                          asNumber
                          label="Monthly Due Day"
                          model="recurring_monthly_due_day"
                          defaultValue={1}
                          validations={{
                            presence: {
                              message: 'Please select a due day',
                            },
                          }}
                        >
                          {RECURRING_DAYS.monthly.map((key, idx) => (
                            <Option key={idx} label={key} value={idx + 1} />
                          ))}
                        </Select>
                      </ContextShow>
                    </Flex>
                  </div>
                </SectionCard>

                <SectionCard
                  title={invoicesCount > 0 ? `Preview ${countWord('Invoices', invoicesCount)}` : 'Preview Invoices'}
                  className="!p-3"
                >
                  <div className="-mx-3 -mb-3 overflow-hidden max-h-[300px] grid grid-cols-[100%] grid-rows-[100%]">
                    <PreviewInvoicesTable
                      from={formData?.from}
                      until={formData?.until}
                      paymentMethod={paymentMethod}
                      frequency={formData?.frequency}
                      recurringMonthlyDueDay={formData?.recurring_monthly_due_day}
                      recurringBiweeklyDueDay={formData?.recurring_biweekly_due_day}
                      recurringWeeklyDueDay={formData?.recurring_weekly_due_day}
                      dates={dates}
                      setDates={setDates}
                    />
                  </div>
                </SectionCard>
              </div>
            </PageSection>

            <PageSection className="mt-4">
              <PageSection.Header>
                <PageSection.Title title="Invoice Details" className="!text-[1.5rem]" />
              </PageSection.Header>

              <div className="grid gap-4 grid-cols-1 mq1024:grid-cols-[1fr_2fr]">
                <SectionCard title="Invoice Details" className="!p-3">
                  <div className="grid gap-4">
                    <Textarea label="Description" minRows={2} model="description" />
                    <Textarea label="Instructions" minRows={2} model="instructions" />
                    <Textarea label="Notes" minRows={2} model="notes" />

                    {isBehave && (
                      <>
                        <RadioGroup label="Payment Processing Fee" model="pass_fee_to_payer" layout="vertical-dense">
                          <Radio label="Behave Health pays the fee" value={false} />
                          <Radio label="Invoice Payer pays the fee" value={true} />
                        </RadioGroup>

                        {isEditable && facilityId && !data?.charged_payment_method ? (
                          <ObjectSelector
                            label="Auto-Collection Payment Method"
                            tooltip="When a payment method is added, we will attempt to automatically charge it on the Due Date selected above"
                            value={paymentMethod}
                            onUpdate={({ value }) => setPaymentMethod(value)}
                            icon="online_card_payment"
                            type="admin.facility.global_payment_methods"
                            dependentValue={facilityId}
                            key={`facility-${facilityId}`}
                            selectTitle={(paymentMethod: any) => <PaymentMethodTitle paymentMethod={paymentMethod} />}
                            selectGraphic={(paymentMethod: any) => <PaymentMethodIcon paymentMethod={paymentMethod} />}
                            selectDescription={(paymentMethod: any) => <PaymentMethodDescription paymentMethod={paymentMethod} />}
                          />
                        ) : (
                          <div>
                            <Label isCompact label="Auto-Collection Payment Method" />

                            {data?.auto_collection_payment_method ? (
                              <PaymentMethod paymentMethod={data?.auto_collection_payment_method} />
                            ) : (
                              <div>–</div>
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </SectionCard>

                <div className="grid gap-4 grid-cols-1 mq1024:grid-cols-2">
                  <InvoiceBilledToSection />
                  <InvoiceBilledBySection />
                </div>
              </div>
            </PageSection>

            <PageSection className="mt-4">
              <PageSection.Header>
                <PageSection.Title title="Invoice Lines" className="!text-[1.5rem]" />
              </PageSection.Header>

              <PageSection.Content className="grid gap-4">
                <Card className="px-4 py-3">
                  {isNew ? (
                    <InvoiceLinesEditTable
                      invoiceLines={invoiceLines}
                      setInvoiceLines={setInvoiceLines}
                      deletedInvoiceLines={deletedInvoiceLines}
                      setDeletedInvoiceLines={setDeletedInvoiceLines}
                    />
                  ) : (
                    <InvoiceLinesTable invoice={data} />
                  )}
                </Card>
              </PageSection.Content>
            </PageSection>
          </FormSection>
        </Form>
      </Overlay.Content>

      {isBehave && (
        <Overlay.Footer>
          <Button
            label={invoicesCount === 0 ? 'No invoices to create yet' : `Create ${countWord('Invoices', invoicesCount)}`}
            glyph={invoicesCount === 0 ? 'info' : 'check'}
            type="primary"
            color={invoicesCount > 0 ? 'green' : 'gray'}
            isLoading={isSaving}
            onClick={save}
            isDisabled={isInvalid || invoicesCount === 0}
            flex="100 1 auto"
          />
        </Overlay.Footer>
      )}
    </Overlay>
  )
}

const PreviewInvoicesTable = (props: any) => {
  const { from, until, paymentMethod, frequency, recurringMonthlyDueDay, recurringBiweeklyDueDay, recurringWeeklyDueDay, dates, setDates } =
    props

  const { timezone } = useSettings()

  const [loading, setLoading] = React.useState(false)

  const isValid = !!from && !!until && !!frequency && !!timezone

  React.useEffect(() => {
    if (!isValid) return

    const getRecurringCharges = async (from, until) => {
      try {
        setLoading(true)

        const encoded = encodeObjectToURL({
          from: from,
          until: until,
          frequency: frequency,
          recurring_monthly_due_day: recurringMonthlyDueDay,
          recurring_biweekly_due_day: recurringBiweeklyDueDay,
          recurring_weekly_due_day: recurringWeeklyDueDay,
          timezone: timezone,
        })

        const result = await apiGet({
          url: `/apps/utils/recurring_dates?${encoded}`,
        })

        if (result?.data?.data?.dates) {
          setDates(result.data.data.dates)
        }
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }

    getRecurringCharges(from, until)
  }, [from, until, timezone, frequency, recurringMonthlyDueDay, recurringBiweeklyDueDay, recurringWeeklyDueDay, isValid])

  const columns = React.useMemo(
    () => [
      {
        Header: 'Auto-Collection',
        accessor: 'payment_method',
        width: 140,
        Cell: ({ row: { original: data } }: any) => {
          if (!data?.payment_method) {
            return <Status small label="None" color="gray" />
          }

          return (
            <div className="flex items-center">
              {data?.is_past_date ? (
                <Status small label="On Invoice Create" color="green" className="mr-2" />
              ) : (
                <Status small label="On Due Date" color="blue" className="mr-2" />
              )}

              {data?.payment_method && <PaymentMethod paymentMethod={data?.payment_method} />}
            </div>
          )
        },
      },
      {
        Header: 'Invoice Due Date',
        accessor: 'from',
        Cell: ({ value }) => <TableCell.UsDate value={value} />,
      },
    ],
    [],
  )

  const data = React.useMemo(() => {
    const result: any = []

    if (!dates) return result

    for (const dueDate of dates) {
      result.push({
        id: uuid(),
        from: dueDate,
        is_past_date: isPast(dueDate, timezone),
        payment_method: paymentMethod,
      })
    }

    return result
  }, [dates, paymentMethod, timezone])

  if (!isValid || loading) {
    return (
      <State
        isEmpty={!isValid}
        isLoading={loading}
        icon="invoices"
        title="Preview Invoices"
        emptyDescription="Enter the first due date and generate until date to preview invoices"
        minHeight={140}
      />
    )
  }

  return (
    <>
      <Table
        title="Preview Invoices"
        icon="invoices"
        columns={columns}
        data={data}
        pageSize={5}
        showFilters={false}
        showSettings={false}
        showSorting={false}
        className="!overflow-auto border-t border-0 border-solid border-divider"
      />
    </>
  )
}

export const EHRInvoiceBuilderOverlay = withOverlayError(RootEHRInvoiceBuilderOverlay)

import React from 'react'
import { v4 as uuid } from 'uuid'
import size from 'lodash/size'

import { withFormContext } from './context'

import Field from './Field'
import Grid from '../Grid'
import Flex from '../Flex'
import Button from '../Button'
import DataList from '../DataList'
import SummonOverlay from '../SummonOverlay'

import AddressSelectorOverlay from '../Overlays/actions/AddressSelectorOverlay'
import ManualAddressEditOverlay from '../Overlays/actions/ManualAddressEditOverlay'

import withSettings from '../../hocs/withSettings'
import FieldBase from './FieldBase'

const DEFAULT_EMPTY_VALUE = null

class AddressSelectorInput extends FieldBase {
  constructor(props) {
    super(props)

    let value = props.value
    if (!value) {
      const modelVal = props.form?.getField(props.model)
      const initialModelVal = props.form?.getInitialInputFieldValue(props.model)

      if (initialModelVal) value = initialModelVal
      if (size(modelVal) > 0) value = modelVal
    }

    this.state = {
      type: 'ADDRESSSELECTORINPUT',
      id: `${props.model}-${uuid()}`,
      model: props.model,
      value: value || DEFAULT_EMPTY_VALUE,
      reset: this.onReset,
      isValid: true,
      isInvalid: false,
      errors: [],
      validate: this.onValidate,
    }

    this.initialData = {
      value: value || DEFAULT_EMPTY_VALUE,
    }

    this.updateType = 'DATA'
  }

  processAddress = (address: any) => ({
    address_line_1: `${address.address} ${address.text}`,
    address_line_2: address.addressLine2,
    city: address?.context?.place?.text,
    county: address?.context?.district?.text,
    state: address?.context?.region?.short_code.split('-')[1],
    zip_code: address?.context?.postcode?.text,
    country: 'USA',
    coords: {
      lat: address.center[1],
      lon: address.center[0],
    },
  })

  afterAddressSelect = (address: any) => {
    const processedAddress = this.processAddress(address)

    this.changeValue(processedAddress)
    if (this.props.afterAddressSelect) this.props.afterAddressSelect(processedAddress)
  }

  manuallyUpdateAddress = (address: any) => {
    this.changeValue(address)
    if (this.props.afterAddressSelect) this.props.afterAddressSelect(address)
  }

  onUpdated = (state: any) => {
    if (state.value?.coords?.lat) {
      this.props.form?.setFieldValue('lat', state.value.coords.lat)
    }

    if (state.value?.coords?.lon) {
      this.props.form?.setFieldValue('lon', state.value.coords.lon)
    }
  }

  render = () => {
    const { className, layout, actionLabel, tenant, glyph, isEditable, coords, permission, ...rest } = this.props
    const { id, isRequired, value } = this.state

    return (
      <Field {...rest} id={id} isRequired={isRequired} layout={layout} className={className} isEditable={isEditable}>
        <Grid gap={20}>
          <DataList>
            <DataList.Item label="Address Line 1" value={value?.address_line_1} />
            <DataList.Item label="Address Line 2" value={value?.address_line_2} />
            <DataList.Item label="City" value={value?.city} />
            <DataList.Item label="County" value={value?.county} />
            <DataList.Item label="State" value={value?.state} />
            <DataList.Item label="Zip Code" value={value?.zip_code} />
          </DataList>

          {isEditable && (
            <Flex gap="1rem">
              <SummonOverlay
                overlay={
                  <AddressSelectorOverlay
                    lat={coords?.lat}
                    lon={coords?.lon}
                    afterSelect={this.afterAddressSelect}
                    centerOnState={tenant?.mailing_address?.state}
                  />
                }
              >
                <Button flex="1 1 auto" type="default" glyph={glyph} label={actionLabel} permission={permission} size={200} />
              </SummonOverlay>

              <SummonOverlay overlay={<ManualAddressEditOverlay address={value} onUpdate={this.manuallyUpdateAddress} />}>
                <Button type="default" color="gray" glyph="edit" label="Edit Manually" permission={permission} size={200} />
              </SummonOverlay>
            </Flex>
          )}
        </Grid>
      </Field>
    )
  }
}

AddressSelectorInput.defaultProps = {
  isEditable: true,
  actionLabel: 'Find Address',
  glyph: 'map',
  validateOn: 'blur-change',
}

export default withFormContext(withSettings(AddressSelectorInput))

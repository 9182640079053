import React from 'react'
import { Link, Route, useParams } from 'react-router-dom-v5-compat'

import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { Button, Grid, Page, HelpTagIframe } from '@behavehealth/components'
import { PhysicianOrderOverlay } from '@behavehealth/constructs/PhysicianOrders/PhysicianOrderOverlay'
import { PhysicianOrdersDataTable } from '@behavehealth/constructs/PhysicianOrders/PhysicianOrdersDataTable'

const pageConfig = {
  feature: 'physician_orders',
  help: <HelpTagIframe id="physician_orders" />,
  marketingID: 'physician_orders',
}

type Props = {
  canCreate: boolean
}

const PhysicianOrders = () => {
  return (
    <>
      <PhysicianOrdersIndex />

      <AnimatedRoutes>
        <Route path=":id" element={<PhysicianOrderOverlay useV6Router />} />
      </AnimatedRoutes>
    </>
  )
}

const PhysicianOrdersIndex: React.FC<Props> = ({ canCreate = true }) => {
  const { resource_id }: any = useParams()

  const tableProps = useDataTable({
    name: ['client', resource_id, 'data_forms'],
    endpoint: `/residents/${resource_id}/data_forms`,
    updateDeleteEndpoint: '/data_forms',
    params: { category: 'physician_order' },
    enabled: !!resource_id,
    localStorageKey: 'client_physician_orders_v1',
  })

  const actions = (
    <Button as={Link} label="Add Physician Order" type="primary" glyph="add" link="new" permission="physician_orders.create" />
  )

  return (
    <Page actions={canCreate && actions} {...pageConfig}>
      <Grid gap="1rem">
        <PhysicianOrdersDataTable
          to={(data: any) => data?.id}
          batchActionsConfig={[
            {
              type: 'delete',
              permission: 'physician_orders.delete',
              action: async ({ ids }: any) => {
                await tableProps.deleteRecords(ids.join(','))
              },
            },
          ]}
          {...tableProps}
        />
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(PhysicianOrders, pageConfig))

import React from 'react'

import SmartStatus from '../../components/SmartStatus'

export const STATUSES = {
  draft: {
    label: 'Draft',
    color: 'gray',
  },
  updates_required: {
    label: 'Updates Required',
    color: 'red',
  },
  pending_review: {
    label: 'Pending Review',
    color: 'yellow',
  },
  signed_off: {
    label: 'Signed Off',
    color: 'green',
  },
  completed: {
    label: 'Completed',
    color: 'green',
  },
  closed: {
    label: 'Closed',
    color: 'green',
  },
}

export const ShiftNoteStatus = (props) => {
  return <SmartStatus statuses={STATUSES} {...props} />
}

import React from 'react'
import { motion } from 'framer-motion'
import { tint } from 'polished'
import * as Popover from '@radix-ui/react-popover'
import clsx from 'clsx'
import produce from 'immer'
import size from 'lodash/size'

import { address, age, titleCase, countWord } from '../../utils/functions'
import { css, keyframes, COLORS, SHADOW, HARD_SHADOW } from '../../theme'
import { PROGRAM_ASSIGNEE_CATEGORIES } from '../../utils/constants'
import { getPrefix, useCreate, useGet } from '../../hooks/useNewAPI'
import { usePortal } from '../../hooks/usePortal'

import Avatar from '../../components/Avatar'
import Button from '../../components/Button'
import Card from '../../components/Card'
import Flex from '../../components/Flex'
import Glyph from '../../components/Glyph'
import GridTable from '../../components/GridTable'
import Icon from '../../components/Icon'
import Loader from '../../components/Loader'
import RadioCheckElement from '../../components/Forms/RadioCheckElement'
import State from '../../components/State'
import Status from '../../components/Status'
import Overlay from '../../components/Overlay'
import SummonOverlay from '../../components/SummonOverlay'

import { AllClientsTable } from '../../components/Forms/Selectors/tables/AllClientsTable'
import { AlumniDataTable } from '../../constructs/Clients/AlumniDataTable'
import { ApplicantsDataTable } from '../../constructs/Clients/ApplicantsDataTable'
import { ClientContactsDataTable } from '../../constructs/Contacts/ClientContactsDataTable'
import { ClientsDataTable } from '../../constructs/Clients/ClientsDataTable'
import { EmployeesDataTable } from '../../constructs/Employees/EmployeesDataTable'
import { OrganizationContactsDataTable } from '../../constructs/Contacts/OrganizationContactsDataTable'
import { OrganizationsTable } from '../../components/Forms/Selectors/tables/OrganizationsTable'

import { MainCell } from '../DataTable/cells/MainCell'
import { useDataTable } from '../DataTable/useDataTable'

const SeatsAssignmentSelector = ({ programId, phaseId, allowedAssignees = [], onUpdate, startDate, endDate }: any) => {
  const [category, setCategory]: any = React.useState(
    allowedAssignees?.includes('current_clients') ? 'current_clients' : allowedAssignees[0],
  )
  // const [pendingAssignee, setPendingAssignee]: any = React.useState(null)
  const [assigneesBySeatId, setAssigneesBySeatId]: any = React.useState({})
  const [selectedAssignees, setSelectedAssignees]: any = React.useState([])

  const selectedAssigneeIds: any = React.useMemo(() => {
    const result = []

    for (const assignee of selectedAssignees) {
      result.push(assignee.id)
    }

    return result
  }, [selectedAssignees])

  const AssigneesListTag = ASSIGNEE_LISTS[category]

  const portalContainer = usePortal('default')

  const [isMenuOpen, setIsMenuOpen] = React.useState(false)

  const selectedAssigneesCount = size(selectedAssignees)
  const pendingAssignee = selectedAssignees[0]

  const closeMenu = () => {
    setIsMenuOpen(false)
  }

  const hasDates = !!(startDate && endDate)
  const seatsParams = hasDates ? { from: startDate, until: endDate } : {}

  const {
    data: seats,
    isLoading: isSeatsLoading,
    isRefetching: isSeatsRefetching,
  }: any = useGet({
    name: ['phase', phaseId, 'seats', seatsParams],
    url: `/phases/${phaseId}/seats`,
    params: seatsParams,
    options: { enabled: !!phaseId },
  })

  const { mutateAsync: batchCreateSeats, isLoading: isCreatingSeats }: any = useCreate({
    name: ['phase', phaseId, 'bulk-create-seats'],
    url: `/phases/${phaseId}/bulk_create_seats`,
    invalidate: ['phase', phaseId, 'seats'],
    invalidateKeys: ['programs-timeline', 'program-timeline', ['program', programId, 'phases-with-residents']],
    onMutate: (variables: any, queryClient: any) => {
      if (!variables) return

      const prefix = getPrefix()
      const newSeats: any = []
      const newSeatsCount = variables.amount

      for (let i = 0; i < newSeatsCount; i++) {
        newSeats.push({ name: 'Adding Seat…', isCreating: true })
      }

      queryClient.setQueryData([prefix, 'phase', phaseId, 'seats'], (current: any) => {
        return { ...current, data: [...newSeats, ...current.data] }
      })
    },
  })

  const addedAssigneeIds = React.useMemo(() => {
    const result: any = []

    for (const key in assigneesBySeatId) {
      const assignee = assigneesBySeatId[key]

      if (assignee) result.push(`${assignee.type}-${assignee.id}`)
    }

    return result
  }, [assigneesBySeatId])

  const clearSeat = (id: string) => {
    setAssigneesBySeatId(
      produce(assigneesBySeatId, (draft: any) => {
        if (!draft[id]) return

        delete draft[id]
      }),
    )
  }

  const handleBatchAdd = async (assignees: any) => {
    if (!assignees) return

    const assigneesCount = size(assignees)
    const freeSeatsCount = size(seats) - size(assigneesBySeatId)

    const freeSeatIds: any = []
    const newAssignments: any = {}

    for (const seat of seats) {
      if (assigneesBySeatId.hasOwnProperty(seat.id)) continue
      freeSeatIds.push(seat.id)
    }

    if (freeSeatsCount < assigneesCount) {
      try {
        const newSeats = await batchCreateSeats({ amount: assigneesCount - freeSeatsCount })

        for (const seat of newSeats.data) {
          freeSeatIds.push(seat.id)
        }
      } catch (error) {
        console.error(error)
      }
    }

    for (let i = 0; i < assigneesCount; i++) {
      const seatId = freeSeatIds[i]
      const assignee = assignees[i]

      newAssignments[seatId] = assignee
    }

    // add new seat assignments
    setAssigneesBySeatId((current: any) => ({ ...current, ...newAssignments }))

    // reset selected assignees
    setSelectedAssignees([])
  }

  React.useEffect(() => {
    setSelectedAssignees([])
  }, [category])

  React.useEffect(() => {
    if (onUpdate && assigneesBySeatId) {
      const result: any = {}

      for (const id in assigneesBySeatId) {
        if (assigneesBySeatId[id]) result[id] = assigneesBySeatId[id]
      }

      onUpdate(result)
    }
  }, [assigneesBySeatId])

  if (isSeatsLoading && !isSeatsRefetching) {
    return (
      <Card>
        <State isLoading minHeight={420} />
      </Card>
    )
  }

  if (size(allowedAssignees) === 0) {
    return (
      <Card>
        <State
          isEmpty
          icon="housing_shifts"
          title="No Allowed Assignees"
          emptyDescription="This program list doesn't have any allowed assignee categories"
        />
      </Card>
    )
  }

  return (
    <>
      <div className={STYLES.root().className}>
        <Card className={STYLES.card().className}>
          <Column className={STYLES.assigneesColumn().className}>
            <div className={STYLES.assigneesColumnHeader().className}>
              <Popover.Root open={isMenuOpen} onOpenChange={setIsMenuOpen}>
                <Popover.Trigger asChild>
                  <div className={STYLES.categoriesMenuButton()}>
                    <Icon icon={PROGRAM_ASSIGNEE_CATEGORIES[category].icon} size={16} />
                    <div>{PROGRAM_ASSIGNEE_CATEGORIES[category].name}</div>
                    <Glyph glyph="triangle_down" size={10} />
                  </div>
                </Popover.Trigger>

                <Popover.Portal container={portalContainer}>
                  <Popover.Content asChild align="start" sideOffset={10}>
                    <div className={STYLES.categoriesMenu()}>
                      {Object.values(PROGRAM_ASSIGNEE_CATEGORIES).map((config: any) => {
                        if (!allowedAssignees.includes(config.model)) return null

                        return (
                          <div
                            key={config.model}
                            className={clsx(STYLES.categoriesMenuItem().className, config.model === category && 'is-active')}
                            onClick={() => {
                              setCategory(config.model)
                              closeMenu()
                            }}
                          >
                            <Icon icon={config.icon} size={18} />
                            <div>{config.name}</div>
                          </div>
                        )
                      })}
                    </div>
                  </Popover.Content>
                </Popover.Portal>
              </Popover.Root>
            </div>

            {AssigneesListTag && (
              <AssigneesListTag
                addedAssigneeIds={addedAssigneeIds}
                selectedAssigneeIds={selectedAssigneeIds}
                onSelect={setSelectedAssignees}
                onBatchAdd={handleBatchAdd}
              />
            )}
          </Column>
        </Card>

        <Card className={STYLES.card().className}>
          <SeatsColumn
            data={seats}
            isCreatingSeats={isCreatingSeats}
            pendingAssignee={pendingAssignee}
            assigneesBySeatId={assigneesBySeatId}
            selectedAssignees={selectedAssignees}
            onBatchAdd={handleBatchAdd}
            batchCreateSeats={batchCreateSeats}
            freeSeatsCount={size(seats) - size(assigneesBySeatId)}
            onClear={clearSeat}
            onSelect={(seatId: any) => {
              if (!pendingAssignee) return

              setAssigneesBySeatId((current: any) => ({ ...current, [seatId]: pendingAssignee }))

              setSelectedAssignees(
                produce(selectedAssignees, (draft: any) => {
                  const index = draft.findIndex((o) => o.id === pendingAssignee?.id)
                  draft.splice(index, 1)
                }),
              )
            }}
          />
        </Card>
      </div>
    </>
  )
}

const SeatsColumn = ({
  data,
  assigneesBySeatId,
  onSelect,
  onClear,
  isCreatingSeats,
  pendingAssignee,
  onBatchAdd,
  selectedAssignees,
  freeSeatsCount,
  batchCreateSeats,
}: any) => {
  if (!data) return null

  const isEmpty = size(data) === 0

  const selectedCount = size(selectedAssignees)

  return (
    <Column>
      {isEmpty ? (
        <State
          isEmpty
          title="Seats"
          glyph="chair"
          emptyDescription="This Program List doesn't have any seats"
          emptyActions={
            <Button
              label={selectedCount >= 2 ? `Create ${selectedCount} Seats` : 'Add Seat'}
              glyph="add"
              type="primary"
              size={200}
              onClick={() => batchCreateSeats({ amount: selectedCount || 1 })}
              isLoading={isCreatingSeats}
            />
          }
        />
      ) : (
        <>
          {pendingAssignee && (
            <div className="bg-white shadow-soft-3 border-b border-0 border-solid border-divider overflow-hidden sticky top-0 z-[10]">
              <Flex nowrap centerY gap="0.5rem" css={{ padding: '0.4rem 0.65rem', minHeight: 52 }}>
                <Avatar size={24} src={pendingAssignee.avatar} initials={pendingAssignee.name} />

                {freeSeatsCount >= 1 ? (
                  <div>
                    Select a seat for <span className="!font-[600]">{pendingAssignee?.name}</span>
                    {selectedCount > 1 && `, or auto-assign seats for all selected`}
                  </div>
                ) : (
                  <div>
                    Create and assign <span className="!font-[600]">{countWord('seats', size(selectedAssignees))}</span>
                  </div>
                )}

                {size(selectedAssignees) >= 1 && (
                  <div className="pl-2 !ml-auto">
                    <Button
                      label={isCreatingSeats ? `Adding New Seats…` : `Auto Assign ${countWord('Seats', size(selectedAssignees))}`}
                      isDisabled={isCreatingSeats}
                      type="primary"
                      glyph="chair"
                      size={200}
                      onClick={() => {
                        onBatchAdd(selectedAssignees)
                      }}
                    />
                  </div>
                )}
              </Flex>
            </div>
          )}

          <GridTable
            useBanding={false}
            useRowHover={false}
            templateColumns="minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) 40px"
            className={STYLES.gridTable().className}
          >
            <GridTable.Header className={STYLES.gridTableHeader().className}>
              <GridTable.Cell centerY>Seat</GridTable.Cell>
              <GridTable.Cell centerY>Status</GridTable.Cell>
              <GridTable.Cell centerY>Assignee</GridTable.Cell>
              <GridTable.Cell centerY>Type</GridTable.Cell>
              <GridTable.Cell centerY></GridTable.Cell>
            </GridTable.Header>

            {data.map((seat: any) => (
              <SeatRow
                key={seat.id}
                data={seat}
                pendingAssignee={pendingAssignee}
                assignee={assigneesBySeatId?.[seat.id]}
                onSelect={onSelect}
                onClear={onClear}
              />
            ))}

            <GridTable.Row>
              <div>
                <Button
                  label="Add Seat"
                  glyph="add"
                  type="link"
                  size={200}
                  onClick={() => batchCreateSeats({ amount: 1 })}
                  isLoading={isCreatingSeats}
                  display="inline-flex"
                />
              </div>
            </GridTable.Row>
          </GridTable>
        </>
      )}
    </Column>
  )
}

const SeatRow = ({ data, onSelect, onClear, assignee, pendingAssignee }: any) => {
  const isAssigning = !!pendingAssignee
  const canAssign = isAssigning && !assignee

  const rowClasses = clsx(STYLES.seat().className, isAssigning && 'is-assigning')

  return (
    <>
      <GridTable.Row>
        <GridTable.Cell>
          <Flex centerY nowrap>
            <Glyph
              glyph={!!assignee ? 'tick_circle' : 'chair'}
              color={!!assignee ? COLORS.green : COLORS.text}
              size={16}
              css={{ marginRight: '0.4rem' }}
            />

            <div>{data.name}</div>
          </Flex>
        </GridTable.Cell>

        <GridTable.Cell>
          {data.status === 'occupied' ? <Status small label="Occupied" color="red" /> : <Status small label="Empty" color="gray" />}
        </GridTable.Cell>

        {assignee ? (
          <>
            <GridTable.Cell centerY className={STYLES.assigneeCell().className}>
              <Avatar src={assignee.avatar} initials={assignee.name} size={18} css={{ marginRight: '0.2rem' }} />
              <div>{assignee.name}</div>
            </GridTable.Cell>

            <GridTable.Cell centerY>
              <AssigneeStatus data={assignee} />
            </GridTable.Cell>

            <GridTable.Cell centerX centerY onClick={() => onClear(data.id)} className={STYLES.clearCell().className}>
              <Glyph glyph="close" color="red" size={12} />
            </GridTable.Cell>
          </>
        ) : (
          <>
            <GridTable.Cell>–</GridTable.Cell>
            <GridTable.Cell>–</GridTable.Cell>

            {data.isCreating && (
              <GridTable.Cell centerX centerY>
                <Loader />
              </GridTable.Cell>
            )}

            {canAssign && !data.isCreating && (
              <>
                <GridTable.Cell
                  centerX
                  centerY
                  onClick={() => {
                    onSelect(data.id)
                  }}
                  className="!relative"
                >
                  <Button
                    label="Assign Seat"
                    glyph="chair"
                    type="default"
                    size={100}
                    css={{
                      position: 'absolute',
                      top: '50%',
                      right: '1rem',
                      transform: 'translateY(-50%)',
                      whiteSpace: 'nowrap',
                      flexWrap: 'nowrap',
                    }}
                  />
                </GridTable.Cell>
              </>
            )}
          </>
        )}
      </GridTable.Row>
    </>
  )
}

const RowItem: React.FC<any> = (props) => {
  const { aside, title, subtitle, graphic, onClick, isActive, isDisabled, style, className } = props

  const rootClasses = clsx(STYLES.item().className, isActive && 'is-active', isDisabled && 'is-disabled', className)

  return (
    <div className={rootClasses} onClick={!isDisabled && onClick} style={style}>
      {graphic && <div className={STYLES.itemGraphic()}>{graphic}</div>}

      <div className={STYLES.itemContent()}>
        <div className={STYLES.itemTitle()}>{title}</div>
        {subtitle && <div className={STYLES.itemSubtitle()}>{subtitle}</div>}
      </div>

      {aside}
    </div>
  )
}

const AssigneesList = (props: any) => {
  const {
    addedAssigneeIds,
    data,
    emptyDescription,
    headerIcon,
    headerTitle,
    icon,
    isLoading,
    onCheckboxClick,
    pendingAssignee,
    renderSubtitle,
    renderTitle = (record: any) => record.name,
    selectedAssigneeIds,
    title,
  } = props

  const isEmpty = props.isEmpty || size(data) === 0

  return (
    <Column title={headerTitle} icon={headerIcon}>
      {!data || isLoading || isEmpty ? (
        <State isLoading={isLoading} isEmpty={isEmpty} title={title} icon={icon} emptyDescription={emptyDescription} />
      ) : (
        data.map((record: any) => {
          const isActive = record.id === pendingAssignee?.id
          const isAdded = addedAssigneeIds?.includes?.(`${record.type}-${record.id}`)
          const isSelected = selectedAssigneeIds?.includes?.(record.id)

          return (
            <AssigneeRow
              key={record.id}
              title={renderTitle(record)}
              subtitle={renderSubtitle?.(record)}
              avatar={record.avatar}
              isActive={isActive}
              isAdded={isAdded}
              isSelected={isSelected}
              onCheckboxClick={() => {
                onCheckboxClick?.(record)
              }}
            />
          )
        })
      )}
    </Column>
  )
}

const AssigneeRow = (props: any) => {
  const { avatar, isActive, isAdded, subtitle, title, onCheckboxClick, isSelected } = props

  const classes = clsx(STYLES.assigneeRow().className, isActive && 'is-active', isSelected && 'is-selected')

  return (
    <motion.div
      css={{ overflow: 'hidden' }}
      animate={{
        height: isAdded ? 0 : 'auto',
        opacity: isAdded ? 0 : 1,
        x: isAdded ? '100%' : '0%',
      }}
      onClick={onCheckboxClick}
      transition={{
        height: animationProps,
        opacity: animationProps,
      }}
    >
      <div className={classes}>
        <RadioCheckElement type="checkbox" className={STYLES.checkbox().className} isChecked={isSelected} />
        <Avatar src={avatar} initials={title} size={20} className={STYLES.itemGraphic().className} />
        <div className={STYLES.itemContent()}>
          <div className={STYLES.itemTitle()}>{title}</div>
          <div className={STYLES.itemSubtitle()}>{subtitle}</div>
        </div>
      </div>
    </motion.div>
  )
}

const AssigneeStatus = ({ data }: any) => {
  if (!data) return null

  const { type, status }: any = data

  if (type === 'resident') {
    if (status === 'current') return <Status small label="Current Client" color="vividBlue" />
    if (status === 'alumni') return <Status small label="Past Client" color="yellow" />
    if (status === 'lead' || status === 'intake') return <Status small label="Applicant" color="green" />
  }

  if (type === 'employee') {
    return <Status small label="Staff" color="blue" />
  }

  if (type === 'contact') {
    return <Status small label="Contact" color="orange" />
  }

  return null
}

const ApplicantsList = ({ onSelect, addedAssigneeIds }: any) => {
  const addedIds = React.useMemo(() => {
    const result: any = []

    if (!addedAssigneeIds) return result

    for (const typeId of addedAssigneeIds) {
      if (typeId.includes('resident')) {
        result.push(typeId.split('-')[1])
      }
    }

    return result
  }, [addedAssigneeIds])

  return (
    <ApplicantsDataTable
      canBatchSelect
      asCard={false}
      cannotSelectIds={addedIds}
      selectedIds={addedIds}
      onRowSelectionUpdate={onSelect}
      getCanSelect={(row: any) => ({
        canSelect: !addedIds.includes(row.id),
        message: 'Already assigned to a seat',
      })}
      localStorageKey="applicants_seat_selector_v1"
      headerLinksConfig={null}
      renderMainCell={(row: any) => (
        <MainCell
          id={row.id}
          value={row.name}
          avatar={row.avatar}
          after={addedIds.includes(row.id) && <Status small label="Added" color="blue" />}
        />
      )}
    />
  )
}

const CurrentClientsList = ({ addedAssigneeIds, onSelect }: any) => {
  const addedIds = React.useMemo(() => {
    const result: any = []

    if (!addedAssigneeIds) return result

    for (const typeId of addedAssigneeIds) {
      if (typeId.includes('resident')) {
        result.push(typeId.split('-')[1])
      }
    }

    return result
  }, [addedAssigneeIds])

  return (
    <ClientsDataTable
      canBatchSelect
      asCard={false}
      cannotSelectIds={addedIds}
      onRowSelectionUpdate={onSelect}
      getCanSelect={(row: any) => ({
        canSelect: !addedIds.includes(row.id),
        message: 'Already assigned to a seat',
      })}
      localStorageKey="clients_seat_selector_v1"
      headerLinksConfig={null}
      renderMainCell={(row: any) => (
        <MainCell
          id={row.id}
          value={row.name}
          avatar={row.avatar}
          after={addedIds.includes(row.id) && <Status small label="Added" color="blue" />}
        />
      )}
    />
  )
}

const PastClientsList = ({ onSelect, addedAssigneeIds }: any) => {
  const addedIds = React.useMemo(() => {
    const result: any = []

    if (!addedAssigneeIds) return result

    for (const typeId of addedAssigneeIds) {
      if (typeId.includes('resident')) {
        result.push(typeId.split('-')[1])
      }
    }

    return result
  }, [addedAssigneeIds])

  return (
    <AlumniDataTable
      canBatchSelect
      asCard={false}
      cannotSelectIds={addedIds}
      selectedIds={addedIds}
      onRowSelectionUpdate={onSelect}
      getCanSelect={(row: any) => ({
        canSelect: !addedIds.includes(row.id),
        message: 'Already assigned to a seat',
      })}
      localStorageKey="past_clients_seat_selector_v1"
      headerLinksConfig={null}
      renderMainCell={(row: any) => (
        <MainCell
          id={row.id}
          value={row.name}
          avatar={row.avatar}
          after={addedIds.includes(row.id) && <Status small label="Added" color="blue" />}
        />
      )}
    />
  )
}

const StaffList = ({ onSelect, addedAssigneeIds }: any) => {
  const tableProps = useDataTable({
    name: ['employees'],
    endpoint: '/employees',
    params: { status: 'active' },
    localStorageKey: 'employees_seat_selector_v1',
  })

  const addedIds = React.useMemo(() => {
    const result: any = []

    if (!addedAssigneeIds) return result

    for (const typeId of addedAssigneeIds) {
      if (typeId.includes('employee')) {
        result.push(typeId.split('-')[1])
      }
    }

    return result
  }, [addedAssigneeIds])

  return (
    <EmployeesDataTable
      {...tableProps}
      canBatchSelect
      asCard={false}
      cannotSelectIds={addedIds}
      selectedIds={addedIds}
      onRowSelectionUpdate={onSelect}
      getCanSelect={(row: any) => ({
        canSelect: !addedIds.includes(row.id),
        message: 'Already assigned to a seat',
      })}
      headerLinksConfig={null}
      renderMainCell={(row: any) => (
        <MainCell
          id={row.id}
          value={row.name}
          avatar={row.avatar}
          after={addedIds.includes(row.id) && <Status small label="Added" color="blue" />}
        />
      )}
    />
  )
}

const ClientContactsList = ({ onSelect, addedAssigneeIds }: any) => {
  const [client, setClient]: any = React.useState(null)

  const tableProps = useDataTable({
    name: ['client', client?.id, 'contacts'],
    endpoint: `/residents/${client?.id}/contacts`,
    localStorageKey: 'client_contacts_seat_selector_v1',
    options: { enabled: !!client?.id },
  })

  const addedIds = React.useMemo(() => {
    const result: any = []

    if (!addedAssigneeIds) return result

    for (const typeId of addedAssigneeIds) {
      if (typeId.includes('contact')) {
        result.push(typeId.split('-')[1])
      }
    }

    return result
  }, [addedAssigneeIds])

  return (
    <>
      {client && (
        <div className="flex items-center px-3 py-1.5 border-b border-0 border-divider border-solid bg-white cursor-pointer">
          <SummonOverlay overlay={<ClientOverlaySelector onSelect={setClient} />}>
            <div className="flex items-center flex-nowrap flex-[1_1_auto] pr-4">
              <Avatar size={20} initials={client.name} src={client.avatar} />
              <div className="font-[600] ml-1.5 mr-2 text-blue-500">{client.name}</div>
              <Glyph glyph="edit" size={10} />
            </div>
          </SummonOverlay>
        </div>
      )}

      {client?.id ? (
        <ClientContactsDataTable
          {...tableProps}
          key={`client_contacts_${client.id}`}
          canBatchSelect
          asCard={false}
          cannotSelectIds={addedIds}
          selectedIds={addedIds}
          onRowSelectionUpdate={onSelect}
          getCanSelect={(row: any) => ({
            canSelect: !addedIds.includes(row.id),
            message: 'Already assigned to a seat',
          })}
          headerLinksConfig={null}
          renderMainCell={(row: any) => (
            <MainCell
              id={row.id}
              value={row.name}
              avatar={row.avatar}
              after={addedIds.includes(row.id) && <Status small label="Added" color="blue" />}
            />
          )}
        />
      ) : (
        <State
          isEmpty
          title="Client"
          icon="clients"
          emptyDescription="Select a client to view their contacts"
          emptyActions={
            <SummonOverlay overlay={<ClientOverlaySelector onSelect={setClient} />}>
              <Button label={client?.name || 'Select Client'} size={200} glyph="user_group" type="primary" />
            </SummonOverlay>
          }
        />
      )}
    </>
  )
}

const ClientOverlaySelector = ({ onClose, onSelect }) => {
  return (
    <Overlay showBackdrop closeOnBackdrop onClose={onClose} maxWidth={80}>
      <Overlay.Header title="Select Client" />

      <Overlay.Content className="py-2 px-5">
        <AllClientsTable
          asCard
          defaultTab="current_clients"
          tabsListClassName="!ml-0 mb-3"
          onSelect={(record) => {
            onSelect?.(record)
            onClose?.()
          }}
        />
      </Overlay.Content>
    </Overlay>
  )
}

const OrganizationContactsList = ({ onSelect, addedAssigneeIds }: any) => {
  const [organization, setOrganization]: any = React.useState(null)

  const tableProps = useDataTable({
    name: ['organization', organization?.id, 'contacts'],
    endpoint: `/organizations/${organization?.id}/contacts`,
    localStorageKey: 'organization_contacts_seat_selector_v1',
    options: { enabled: !!organization?.id },
  })

  const addedIds = React.useMemo(() => {
    const result: any = []

    if (!addedAssigneeIds) return result

    for (const typeId of addedAssigneeIds) {
      if (typeId.includes('contact')) {
        result.push(typeId.split('-')[1])
      }
    }

    return result
  }, [addedAssigneeIds])

  return (
    <>
      {organization && (
        <div className="flex items-center px-3 py-1.5 border-b border-0 border-divider border-solid bg-white cursor-pointer">
          <SummonOverlay overlay={<OrganizationOverlaySelector onSelect={setOrganization} />}>
            <div className="flex items-center flex-nowrap flex-[1_1_auto] pr-4">
              <Avatar size={20} initials={organization.name} src={organization.avatar} />
              <div className="font-[600] ml-1.5 mr-2 text-blue-500">{organization.name}</div>
              <Glyph glyph="edit" size={10} />
            </div>
          </SummonOverlay>
        </div>
      )}

      {organization?.id ? (
        <OrganizationContactsDataTable
          {...tableProps}
          key={`organization_contacts_${organization.id}`}
          canBatchSelect
          asCard={false}
          cannotSelectIds={addedIds}
          selectedIds={addedIds}
          onRowSelectionUpdate={onSelect}
          getCanSelect={(row: any) => ({
            canSelect: !addedIds.includes(row.id),
            message: 'Already assigned to a seat',
          })}
          headerLinksConfig={null}
          renderMainCell={(row: any) => (
            <MainCell
              id={row.id}
              value={row.name}
              avatar={row.avatar}
              after={addedIds.includes(row.id) && <Status small label="Added" color="blue" />}
            />
          )}
        />
      ) : (
        <State
          isEmpty
          title="Organization"
          icon="organizations"
          emptyDescription="Select an Organization to view their contacts"
          emptyActions={
            <SummonOverlay overlay={<OrganizationOverlaySelector onSelect={setOrganization} />}>
              <Button label={organization?.name || 'Select Organization'} size={200} glyph="organizations" type="primary" />
            </SummonOverlay>
          }
        />
      )}
    </>
  )
}

const OrganizationOverlaySelector = ({ onClose, onSelect }) => {
  return (
    <Overlay showBackdrop closeOnBackdrop onClose={onClose} maxWidth={80}>
      <Overlay.Header title="Select Organization" />

      <Overlay.Content className="py-2 px-5">
        <OrganizationsTable
          asCard
          onSelect={(record) => {
            onSelect?.(record)
            onClose?.()
          }}
        />
      </Overlay.Content>
    </Overlay>
  )
}

const Column: React.FC<any> = (props) => {
  const { children, title, icon, className } = props

  const rootClasses = clsx(STYLES.column().className, className)

  return (
    <div className={rootClasses}>
      {title && (
        <header className={STYLES.columnHeader()}>
          {icon && <Icon icon={icon} size={18} className={STYLES.columnHeaderIcon()} />}
          {title}
        </header>
      )}

      {children}
    </div>
  )
}

const animationProps = {
  type: 'spring',
  stiffness: 1000,
  damping: 100,
}

const ASSIGNEE_LISTS: any = {
  applicants: ApplicantsList,
  current_clients: CurrentClientsList,
  past_clients: PastClientsList,
  staff: StaffList,
  client_contacts: ClientContactsList,
  organization_contacts: OrganizationContactsList,
}

const menuAnimation = keyframes({
  '0%': {
    opacity: 0,
    transform: 'scale3d(0.97, 0.97, 0.97) translateY(-16px)',
  },
  '100%': {
    opacity: 1,
    transform: 'scale3d(1, 1, 1) translateY(0)',
  },
})

const assignCellAnimation = keyframes({
  '0%': {
    opacity: 0,
    transform: 'translateX(-16px)',
  },
  '100%': {
    opacity: 1,
    transform: 'translateX(0)',
  },
})

const assignCellGlyphAnimation = keyframes({
  '0%': {
    opacity: 0,
    transform: 'scale3d(0.4, 0.4, 0.4) rotate(90deg)',
  },
  '100%': {
    opacity: 1,
    transform: 'scale3d(1, 1, 1) rotate(0deg)',
  },
})

const STYLES = {
  root: css({
    display: 'grid',
    alignItems: 'stretch',
    fontSize: '0.9rem',
    gridGap: '1rem',
    gridTemplateColumns: '1fr',

    '@media (min-width: 800px)': {
      gridTemplateColumns: '1fr 1fr',
    },

    '--header-min-height': '36px',
  }),

  card: css({
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: 'minmax(200px, 1fr)',
    overflow: 'auto !important',
    height: 450,
    background: `${tint(0.3, '#F7F8FB')} !important`,

    '@media (min-height: 1000px)': {
      height: 700,
    },
  }),

  column: css({
    overflowY: 'auto',
    borderRight: `1px solid ${COLORS.divider}`,

    '&:last-child': {
      borderRight: 'none',
    },
  }),

  columnHeaderIcon: css({
    marginRight: '0.4rem',
  }),

  columnHeader: css({
    display: 'flex',
    alignItems: 'center',
    padding: '0.4rem 0.75rem',
    fontWeight: 600,
    borderBottom: `1px solid ${COLORS.divider}`,
    boxShadow: HARD_SHADOW(2),
    position: 'sticky',
    top: 0,
    zIndex: 1,
    background: COLORS.white,
  }),

  item: css({
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    padding: '0.3rem 0.75rem',
    cursor: 'pointer',
    borderBottom: `1px solid ${COLORS.divider}`,
    transition: 'all 150ms cubic-bezier(0.39, 0.575, 0.565, 1)',

    '&:hover': {
      background: COLORS.hover,
    },

    '&.is-added': {
      height: 0,
      opacity: 0,
      padding: 0,
    },

    '&.is-active': {
      color: COLORS.white,
      background: COLORS.blue,
    },

    '&.is-disabled': {
      background: COLORS.hover,
      cursor: 'not-allowed',
      color: tint(0.1, COLORS.textMuted),
      opacity: 0.6,

      img: {
        opacity: 0.5,
        filter: 'grayscale(100%)',
      },
    },
  }),

  itemContent: css({
    flex: '1 1 auto',
  }),

  itemGraphic: css({
    marginRight: '0.4rem',
  }),

  itemTitle: css({
    fontWeight: 600,
  }),

  itemSubtitle: css({
    fontSize: '0.86rem',
    fontWeight: 400,
    color: COLORS.textMuted,
    marginTop: '-0.12em',

    '.is-active &': {
      color: COLORS.white,
    },
  }),

  seat: css({}),

  assigneesColumn: css({
    display: 'grid',
    alignContent: 'flex-start',
    overflow: 'hidden !important',
  }),

  assigneesColumnHeader: css({
    display: 'flex',
    alignItems: 'center',
    padding: '0.25rem 0.75rem',
    borderBottom: `1px solid ${COLORS.divider}`,
    boxShadow: `0 3px 0 ${COLORS.shadow}`,
    minHeight: 'var(--header-min-height)',
    background: COLORS.white,
  }),

  addButton: css({
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    border: `1px solid ${COLORS.divider}`,
    boxShadow: HARD_SHADOW(2),
    padding: '0.2em 0.8em',
    fontWeight: 600,
    color: COLORS.white,
    background: COLORS.blue,
    borderRadius: '100px',
  }),

  twoColumnGrid: css({
    display: 'grid',
    overflow: 'hidden',
    gridAutoFlow: 'column',
    gridAutoColumns: '1fr',
  }),

  assigneeRow: css({
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    padding: '0.3rem 0.75rem',
    cursor: 'pointer',
    borderBottom: `1px solid ${COLORS.divider}`,

    '&:hover': {
      background: COLORS.hover,
    },

    '&.is-active': {
      color: COLORS.white,
      background: COLORS.blue,
    },

    '&.is-selected': {
      // color: COLORS.white,
      background: tint(0.9, COLORS.green),
    },
  }),

  categoriesMenu: css({
    border: `1px solid ${COLORS.divider}`,
    boxShadow: SHADOW(5, COLORS.divider),
    background: COLORS.white,
    width: 220,
    borderRadius: 5,
    outline: 'none',
    maxHeight: '80vh',
    overflowY: 'auto',
    zIndex: 3,
    position: 'relative',
    animation: `${menuAnimation} 100ms cubic-bezier(0.39, 0.575, 0.565, 1)`,
  }),

  categoriesMenuButton: css({
    display: 'flex',
    flexWrap: 'nowrap',
    fontWeight: 600,
    alignItems: 'center',
    color: COLORS.blue,
    lineHeight: 'normal',
    flex: '1 1 auto',
    width: '100%',

    img: { marginRight: '0.4rem' },
    svg: { marginLeft: '0.25rem' },
  }),

  categoriesMenuItem: css({
    display: 'flex',
    alignItems: 'center',
    padding: '0.4rem 0.7rem',
    cursor: 'pointer',
    fontWeight: 500,
    fontSize: '0.92rem',

    '&:hover': {
      color: COLORS.blue,
      background: COLORS.hover,
    },

    '&.is-active': {
      backgroundColor: tint(0.92, COLORS.blue),

      '&:hover': {
        color: COLORS.text,
        backgroundColor: tint(0.92, COLORS.blue),
      },
    },

    '& > img': { marginRight: '0.5rem' },
  }),

  assignCell: css({
    display: 'flex',
    alignItems: 'center',
    color: COLORS.blue,
    gridColumn: 'span 3',
    fontWeight: 600,
    cursor: 'pointer',
    animation: `${assignCellAnimation} 100ms cubic-bezier(0.39, 0.575, 0.565, 1)`,

    '&:hover': {
      background: tint(0.92, COLORS.blue),
    },

    svg: {
      marginRight: '0.4rem',
      animation: `${assignCellGlyphAnimation} 100ms cubic-bezier(0.39, 0.575, 0.565, 1)`,
    },
  }),

  assigneeCell: css({
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    '& > div': {
      flex: '1 1 auto',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  }),

  clearCell: css({
    cursor: 'pointer',

    '&:hover': {
      background: tint(0.92, COLORS.red),
    },

    '& > svg': {
      transition: 'transform 100ms cubic-bezier(0.39, 0.575, 0.565, 1)',
    },

    '&:hover > svg': {
      transform: 'scale3d(1.15, 1.15, 1.15)',
    },
  }),

  emptyCell: css({
    gridColumn: 'span 2',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    textStyle: 'italic',
    color: tint(0.2, COLORS.textMuted),
  }),

  gridTable: css({
    borderBottom: `1px solid ${COLORS.divider}`,
  }),

  gridTableHeader: css({
    minHeight: 'var(--header-min-height)',
  }),

  checkbox: css({
    marginRight: '0.4rem',
  }),

  batchAddButton: css({
    marginLeft: 'auto',
  }),

  creatingSeats: css({
    gridColumn: '1 / -1',
    color: COLORS.textMuted,

    '& > *:first-child': {
      marginRight: '0.4rem',
    },
  }),
}

export default SeatsAssignmentSelector

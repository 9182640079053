import React from 'react'
import { produce } from 'immer'
import clsx from 'clsx'
import Zoom from 'react-medium-image-zoom'

import { FEATURES } from '../../theme'
import { useSettings } from '../../hooks/useSettings'
import useCopyToClipboard from '../../hooks/useCopyToClipboard'

import Accordions from '../../components/Accordions'
import Alert from '../../components/Alert'
import Button from '../../components/Button'
import Card from '../../components/Card'
import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import Input from '../../components/Forms/Input'
import Label from '../../components/Label'
import MiniRichTextEditor from '../../components/Forms/MiniRichTextEditor'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import TreeItem from '../../components/TreeItem'

import { FILING_UNDER_OPTIONS } from '../FormBuilder/utils/constants'

import form_submissions_addendums from '../../assets/images/form_submissions_addendums.png'
import form_submissions_client_portal_1 from '../../assets/images/form_submissions_client_portal_1.png'
import form_submissions_file_under_1 from '../../assets/images/form_submissions_file_under_1.png'
import form_submissions_file_under_2 from '../../assets/images/form_submissions_file_under_2.png'
import form_submissions_pdf_export from '../../assets/images/form_submissions_pdf_export.png'
import form_submissions_review_signoff_actions from '../../assets/images/form_submissions_review_signoff_actions.png'
import form_submissions_review_statuses from '../../assets/images/form_submissions_review_statuses.png'
import form_submissions_signatures from '../../assets/images/form_submissions_signatures.png'

export const FormSubmissionSettings = ({
  isFinalStatus,
  isEditable,
  submissionSettings = {},
  setSubmissionSettings = () => {},
  initialModel,
  externalId,
}: any) => {
  const form = React.useRef(null)
  const { isBehave, tenant, isEHRApp } = useSettings()

  const link = tenant && process.env.BH_APP_FORM_URL && `${process.env.BH_APP_FORM_URL}/${tenant.subdomain}/submission/${externalId}`

  const { copy, didCopy: linkCopied } = useCopyToClipboard({
    text: link,
    notification: 'Link copied!',
  })

  return (
    <Form
      isCompact
      getForm={form}
      isEditable={isEditable}
      initialModel={initialModel}
      onUpdate={(values) => {
        const updatedValues = produce(values, (draft) => {
          if (!draft.use_portal_permissions) {
            draft.portal_permissions_settings = 'no_access'
          }
        })

        setSubmissionSettings(updatedValues)
      }}
      css={STYLES.form}
    >
      <Accordions>
        {isEHRApp && (
          <SettingsSection
            isEditable={isEditable}
            title="Notifications"
            glyph="notification"
            isApplicable={submissionSettings.use_notifications}
            applicableModel="use_notifications"
            notApplicableContent={
              <Alert small contrast glyph="info">
                No notifications are enabled for this form submission
              </Alert>
            }
            applicableContent={
              <CheckboxGroup
                isCompact
                withHover={false}
                label="Send Notification When:"
                trueIcon="check"
                falseIcon="cross"
                falseStyle="faded"
                layout="vertical"
              >
                <Checkbox
                  label="Public Form Link Opened"
                  model="notification_settings.notify_on_public_form_view"
                  tooltip="When checked, the emails and phone numbers below will be notified when the public link of this form is opened"
                />
                {submissionSettings?.notification_settings?.notify_on_public_form_view && (
                  <div className="grid gap-3 grid-cols-[100%] pl-6 pt-1 pb-3">
                    <Input
                      alwaysShowDescription
                      model="notification_settings.notify_on_public_form_view_contacts.email"
                      type="email"
                      label="Email Addresses"
                      description="Emails support multiple recipients, separated by a comma (,)"
                      defaultValue={tenant?.contacts?.form_submissions?.email || ''}
                    />

                    <Input
                      alwaysShowDescription
                      model="notification_settings.notify_on_public_form_view_contacts.phone_no"
                      type="phone"
                      label="Phone Number"
                      description="SMS supports only a single recipient"
                      defaultValue={tenant?.contacts?.form_submissions?.phone_no || ''}
                    />
                  </div>
                )}

                <Checkbox
                  label="Form Submission Updated"
                  model="notification_settings.notify_on_update"
                  tooltip="When checked, the emails and phone numbers below will be notified when a form submission is updated"
                />
                {submissionSettings?.notification_settings?.notify_on_update && (
                  <div className="grid gap-3 grid-cols-[100%] pl-6 pt-1 pb-3">
                    <Input
                      alwaysShowDescription
                      model="notification_settings.notify_on_update_contacts.email"
                      type="email"
                      label="Email Address"
                      description="Emails support multiple recipients, separated by a comma (,)"
                      defaultValue={tenant?.contacts?.form_submissions?.email || ''}
                    />

                    <Input
                      alwaysShowDescription
                      model="notification_settings.notify_on_update_contacts.phone_no"
                      type="phone"
                      label="Phone Number"
                      description="SMS supports only a single recipient"
                      defaultValue={tenant?.contacts?.form_submissions?.phone_no || ''}
                    />
                  </div>
                )}

                <Checkbox
                  label="Form Submission Signature Added"
                  model="notification_settings.notify_on_add_signature"
                  tooltip="When checked, the emails and phone numbers below will be notified when a new signature is added to a form submission"
                />
                {submissionSettings?.notification_settings?.notify_on_add_signature && (
                  <div className="grid gap-3 grid-cols-[100%] pl-6 pt-1 pb-3">
                    <Input
                      alwaysShowDescription
                      model="notification_settings.notify_on_add_signature_contacts.email"
                      type="email"
                      label="Email Addresses"
                      description="Emails support multiple recipients, separated by a comma (,)"
                      defaultValue={tenant?.contacts?.form_submissions?.email || ''}
                    />

                    <Input
                      alwaysShowDescription
                      model="notification_settings.notify_on_add_signature_contacts.phone_no"
                      type="phone"
                      label="Phone Number"
                      description="SMS supports only a single recipient"
                      defaultValue={tenant?.contacts?.form_submissions?.phone_no || ''}
                    />
                  </div>
                )}

                <Checkbox
                  label="Form Submission Closed / Signed-Off"
                  model="notification_settings.notify_on_final_status"
                  tooltip="When checked, the emails and phone numbers below will be notified when a form submission is closed or signed-off"
                />

                {submissionSettings?.notification_settings?.notify_on_final_status && (
                  <div className="grid gap-3 grid-cols-[100%] pl-6 pt-1 pb-3">
                    <Input
                      alwaysShowDescription
                      model="notification_settings.notify_on_final_status_contacts.email"
                      type="email"
                      label="Email Address"
                      description="Emails support multiple recipients, separated by a comma (,)"
                      defaultValue={tenant?.contacts?.form_submissions?.email || ''}
                    />

                    <Input
                      alwaysShowDescription
                      model="notification_settings.notify_on_final_status_contacts.phone_no"
                      type="phone"
                      label="Phone Number"
                      description="SMS supports only a single recipient"
                      defaultValue={tenant?.contacts?.form_submissions?.phone_no || ''}
                    />
                  </div>
                )}
              </CheckboxGroup>
            }
          />
        )}

        {!isFinalStatus && (
          <SettingsSection
            isEditable={isEditable}
            title="Secure Public Sharing"
            glyph="website"
            isApplicable={submissionSettings.use_public_sharing}
            applicableModel="use_public_sharing"
            notApplicableContent={
              <Alert small contrast glyph="info">
                This Form Submission is private and can only be accessed in-app by authorized users
              </Alert>
            }
            applicableContent={
              <>
                <Alert small contrast glyph="info">
                  {isEditable
                    ? 'After saving this Form Submission, a link will be generated and displayed below that you can share with anyone.'
                    : 'This Form Submission is public and anyone with the link below can access it and submit updates'}
                </Alert>

                {!isEditable && (
                  <div className="grid truncate">
                    <div className="mt-3 mb-3 truncate">
                      <Label isCompact label="Public Form Link:" />
                      <a
                        href={link}
                        target="_blank"
                        rel="noreferrer"
                        className="truncate"
                        css={{
                          display: 'inline-flex',
                          alignItems: 'center',
                          fontSize: '0.88rem',
                          svg: { marginLeft: '0.25em' },
                          '&:hover': { textDecoration: 'underline' },
                          minWidth: 0,
                          whiteSpace: 'break-all',
                          flex: '1 1 auto',
                        }}
                      >
                        {link}
                      </a>
                    </div>

                    <Flex gap="0.5rem">
                      <Button
                        glyph={linkCopied ? 'check' : 'copy'}
                        label={linkCopied ? 'Link Copied!' : 'Copy Link'}
                        onClick={copy}
                        type={linkCopied ? 'default' : 'primary'}
                        size={100}
                        display="inline-flex"
                      />

                      <Button
                        useGlyphForTarget
                        label="Open in New Tab"
                        href={link}
                        type="default"
                        size={100}
                        display="inline-flex"
                        target="_blank"
                      />
                    </Flex>
                  </div>
                )}

                <CheckboxGroup
                  isCompact
                  label="Public Form Submission Options:"
                  trueIcon="check"
                  falseIcon="cross"
                  falseStyle="faded"
                  layout="vertical-dense"
                  className="mt-4"
                >
                  <Checkbox
                    label="Enable Table of Contents"
                    model="public_sharing_settings.table_of_contents"
                    tooltip="People will see a pages and headings table of contents on the form to navigate between sections"
                  />
                  <Checkbox
                    label="Enable Preview Page"
                    model="public_sharing_settings.preview_page"
                    tooltip="People will see a preview page with all their answers before submitting the form"
                  />
                  <Checkbox
                    label="Show company logo in form header"
                    model="public_sharing_settings.header"
                    tooltip="Display page header with company logo on publicly-shared forms"
                  />
                  <Checkbox label="Request contact details from submitter" model="public_sharing_settings.require_submitter_info" />
                  {isBehave && (
                    <>
                      <Checkbox label="Show Behave Health badge" model="public_sharing_settings.show_behave_badge" />
                      {submissionSettings?.public_sharing_settings?.show_behave_badge && (
                        <Checkbox label="Enable Behave Health badge link" model="public_sharing_settings.use_behave_badge_link" />
                      )}
                    </>
                  )}
                </CheckboxGroup>

                {submissionSettings?.public_sharing_settings?.require_submitter_info && (
                  <>
                    <CheckboxGroup
                      isCompact
                      label="Public Submitter Info Required:"
                      trueIcon="check"
                      falseIcon="cross"
                      falseStyle="faded"
                      layout="vertical-dense"
                      className="mt-4"
                    >
                      <Checkbox defaultChecked label="Title" model="public_sharing_settings.submitter_info.require_title" />
                      <Checkbox defaultChecked label="Full Name" model="public_sharing_settings.submitter_info.require_name" />
                      <Checkbox label="Email" model="public_sharing_settings.submitter_info.require_email" />
                      <Checkbox label="Phone Number" model="public_sharing_settings.submitter_info.require_phone_no" />
                      <Checkbox label="Notes" model="public_sharing_settings.submitter_info.require_notes" />
                      <Checkbox label="Organization Name" model="public_sharing_settings.submitter_info.require_organization_name" />
                      <Checkbox label="Organization Address" model="public_sharing_settings.submitter_info.require_organization_address" />
                    </CheckboxGroup>

                    <div className="mt-4">
                      <MiniRichTextEditor
                        label={`Public Submission Message`}
                        model="public_sharing_settings.submitter_info.public_submission_message"
                        description="Enter a custom message to display to the submitter when they are required to enter their information"
                      />
                    </div>
                  </>
                )}
              </>
            }
          />
        )}

        <SettingsSection
          isEditable={isEditable}
          glyph="attachment"
          title="Filing Options"
          isApplicable={submissionSettings.use_reference}
          applicableModel="use_reference"
          notApplicableContent={
            <Alert small contrast glyph="info">
              Filing Options are not enabled for this Form Submission
            </Alert>
          }
          applicableContent={
            submissionSettings.use_reference && (
              <div className="grid gap-5">
                <Alert small contrast glyph="info">
                  {isEditable
                    ? 'Select where this Form Submission can be filed under'
                    : 'This Form Submission can be filed under the selected categories below'}
                </Alert>

                {FILING_UNDER_OPTIONS.map((group) => (
                  <CheckboxGroup
                    key={group.label}
                    label={group.label}
                    trueIcon="check"
                    falseIcon="cross"
                    falseStyle="faded"
                    layout="vertical-dense"
                  >
                    {group.resources?.map?.((resource) => {
                      const icon = FEATURES[resource.feature]?.icon

                      return (
                        <Checkbox key={resource.type} icon={icon} label={resource.label} model={`reference_settings.${resource.type}`} />
                      )
                    })}
                  </CheckboxGroup>
                ))}
              </div>
            )
          }
          moreInfo={
            <div className="grid text-[0.9rem] text-text [&_img]:mb-6 [&_p]:mb-2 [&_p]:mt-0">
              <ImageSection src={form_submissions_file_under_1}>
                <p>
                  When <b>"Filing Options"</b> are enabled and any of the options checked, you will see the <b>"Filed Under"</b> selector in
                  Form Submissions created using this form
                </p>
              </ImageSection>

              <ImageSection src={form_submissions_file_under_2}>
                <p>The categories you select will determine which entities you can file the form submission under in this overlay</p>
              </ImageSection>
            </div>
          }
        />

        <SettingsSection
          isEditable={isEditable}
          glyph="portal"
          title="Client Portal Permissions"
          isApplicable={submissionSettings.use_portal_permissions}
          applicableModel="use_portal_permissions"
          notApplicableContent={
            <>
              <Alert small contrast glyph="info">
                If filed under a client, this Form Submissions will <b>not be visible</b> in their Client Portal app
              </Alert>
            </>
          }
          applicableContent={
            <div className="grid gap-4">
              <Alert small contrast glyph="info">
                <div>
                  {submissionSettings.portal_permissions_settings === 'no_access' && (
                    <>
                      If filed under a client, this Form Submissions will <b>not be visible</b> in their Client Portal app
                    </>
                  )}

                  {submissionSettings.portal_permissions_settings === 'can_view' && (
                    <>
                      If filed under a client, this Form Submission will <b>be visible</b> in their Client Portal app, but{' '}
                      <b>not editable</b>
                    </>
                  )}

                  {submissionSettings.portal_permissions_settings === 'can_view_edit' && (
                    <>
                      If filed under a client, this Form Submission will <b>be visible and editable</b> in their Client Portal app
                    </>
                  )}
                </div>
              </Alert>

              <RadioGroup
                label={`When Form Submissions are "Filed-Under" a Client:`}
                defaultValue="no_access"
                trueIcon="check"
                falseIcon="cross"
                falseStyle="faded"
                layout="vertical-dense"
                model="portal_permissions_settings"
              >
                <Radio label={`Client CANNOT view this Form Submission`} value="no_access" />
                <Radio label={`Client can view this Form Submissions`} value="can_view" />
                <Radio label={`Client can view, and edit this Form Submission`} value="can_view_edit" />
              </RadioGroup>
            </div>
          }
          moreInfo={
            <div className="grid text-[0.9rem] text-text [&_img]:mb-6 [&_p]:mb-2 [&_p]:mt-0">
              <ImageSection src={form_submissions_client_portal_1}>
                <p>
                  <b>"Clients can only view the Form Submissions"</b> option will enable the Form Inbox page the the client it's filed
                  under.
                </p>
              </ImageSection>
            </div>
          }
        />

        <SettingsSection
          isEditable={isEditable}
          glyph="merge"
          title="Review & Sign-Off Workflows"
          isApplicable={submissionSettings.use_review_and_signoff_workflow}
          applicableModel="use_review_and_signoff_workflow"
          notApplicableContent={
            <Alert small contrast glyph="info">
              Review & Sign-Off Workflows are <b>not enabled</b> for this Form Submission
            </Alert>
          }
          applicableContent={
            <div className="grid gap-4">
              {isEditable && (
                <Alert small contrast glyph="info">
                  Select the workflows you'd like to enable for this Form Submission
                </Alert>
              )}

              <CheckboxGroup trueIcon="check" falseIcon="cross" falseStyle="faded" layout="vertical-dense">
                <Checkbox
                  label="Enable Supervisor Review Workflow"
                  model="review_and_signoff_workflow_settings.enable_supervisor_review"
                  tooltip="Submissions using this form can be sent to a supervisor for review. The supervisor can then mark the form submission as 'Approved', 'Rejected', or 'Updates Required'"
                />

                <Checkbox
                  label="Enable Sign-Off Workflow"
                  model="review_and_signoff_workflow_settings.enable_signoff"
                  tooltip="When checked, submissions to this form can be marked as signed-off."
                />
              </CheckboxGroup>

              {submissionSettings?.review_and_signoff_workflow_settings?.enable_signoff && (
                <>
                  <CheckboxGroup
                    label="Sign-Off Requirements:"
                    trueIcon="check"
                    falseIcon="cross"
                    falseStyle="faded"
                    layout="vertical-dense"
                  >
                    <Checkbox
                      label="All Staff Members must sign"
                      model="review_and_signoff_workflow_settings.signoff_settings.staff_must_sign"
                      tooltip="All staff members added to the form submissions will be required to sign before the form can be signed-off."
                    />
                    <Checkbox
                      label="All Supervisors must sign"
                      model="review_and_signoff_workflow_settings.signoff_settings.supervisors_must_sign"
                      tooltip="All supervisors added to the form submissions will be required to sign before the form can be signed-off."
                    />

                    {submissionSettings?.review_and_signoff_workflow_settings?.enable_supervisor_review && (
                      <Checkbox
                        label="A Supervisor must approve the form"
                        model="review_and_signoff_workflow_settings.signoff_settings.supervisor_must_approve"
                        tooltip="Any supervisor added to the form submissions will be required to approve the form before it can be signed-off."
                      />
                    )}
                  </CheckboxGroup>

                  {/* <CheckboxGroup label="After Sign-Off:" trueIcon="check" falseIcon="cross" falseStyle="faded" layout="vertical-dense">
                    <Checkbox
                      label="Allow addendums to be added"
                      model="review_and_signoff_workflow_settings.after_signoff.allow_addendums"
                      tooltip="Any updates to the form after sign-off can be added as addendums."
                    />
                    <Checkbox
                      label="Lock the form from further updates"
                      model="review_and_signoff_workflow_settings.after_signoff.lock_form"
                      tooltip="The form will be locked from further updates after it's been signed-off."
                    />
                  </CheckboxGroup> */}

                  {!submissionSettings?.review_and_signoff_workflow_settings?.after_signoff?.lock_form && (
                    <CheckboxGroup
                      label="On Updates After Sign-Off Requirements:"
                      trueIcon="check"
                      falseIcon="cross"
                      falseStyle="faded"
                      layout="vertical-dense"
                    >
                      <Checkbox
                        label="All Staff Members must add their signatures again"
                        model="review_and_signoff_workflow_settings.updates_after_signoff.staff_must_sign"
                        tooltip="All staff members added to the form submissions will be required to add their signature again after a signed-off form has been updated"
                      />
                      <Checkbox
                        label="All Supervisors must add their signatures again"
                        model="review_and_signoff_workflow_settings.updates_after_signoff.supervisors_must_sign"
                        tooltip="All supervisors added to the form submissions will be required to add their signature again after a signed-off form has been updated"
                      />
                      {submissionSettings?.review_and_signoff_workflow_settings?.enable_supervisor_review && (
                        <Checkbox
                          label="A Supervisor must approve the form again"
                          model="review_and_signoff_workflow_settings.updates_after_signoff.supervisor_must_approve"
                          tooltip="Any supervisor added to the form submissions will be required to approve the form again after a signed-off form has been updated."
                        />
                      )}
                    </CheckboxGroup>
                  )}

                  {(submissionSettings?.review_and_signoff_workflow_settings?.signoff_settings?.reference_must_sign ||
                    submissionSettings?.review_and_signoff_workflow_settings?.signoff_settings?.staff_must_sign ||
                    submissionSettings?.review_and_signoff_workflow_settings?.signoff_settings?.supervisors_must_sign) && (
                    <TreeItem isOpen withHover={false} title="Signatures Legal Copy">
                      <div className="grid gap-3 pt-3 pr-3 pb-4">
                        {isEditable && (
                          <Alert small contrast glyph="info">
                            Customize the legal copy for each signature type below.
                          </Alert>
                        )}

                        {submissionSettings?.review_and_signoff_workflow_settings?.signoff_settings?.reference_must_sign && (
                          <MiniRichTextEditor
                            useQuickText
                            label="Filed-Under Entity Signature Legal Copy"
                            model="review_and_signoff_workflow_settings.signatures_legal_text.reference"
                          />
                        )}

                        {submissionSettings?.review_and_signoff_workflow_settings?.signoff_settings?.staff_must_sign && (
                          <MiniRichTextEditor
                            useQuickText
                            label="Staff Member Signatures Legal Copy"
                            model="review_and_signoff_workflow_settings.signatures_legal_text.staff"
                          />
                        )}

                        {submissionSettings?.review_and_signoff_workflow_settings?.signoff_settings?.supervisors_must_sign && (
                          <MiniRichTextEditor
                            useQuickText
                            label="Supervisors Signatures Legal Copy"
                            model="review_and_signoff_workflow_settings.signatures_legal_text.supervisors"
                          />
                        )}
                      </div>
                    </TreeItem>
                  )}
                </>
              )}
            </div>
          }
          moreInfo={
            <div className="grid text-[0.9rem] text-text [&_img]:mb-6 [&_p]:mb-2 [&_p]:mt-0">
              <ImageSection src={form_submissions_review_signoff_actions}>
                <p>
                  <b>"Enable Sign-Off Workflow"</b> and <b>"Enable Supervisor Review Workflow"</b> options will enable the corresponding
                  actions below on the Form Submission overlay.
                </p>
              </ImageSection>

              <ImageSection src={form_submissions_review_statuses}>
                <p>
                  Additionally, the <b>"Enable Supervisor Review Workflow"</b> option will enable the <b>"Review Status"</b> selector on the
                  Form Submission overlay.
                </p>
              </ImageSection>

              <ImageSection src={form_submissions_signatures}>
                <p>
                  <b>"Sign-Off Requirements"</b> signatures options will enable the signatures page where the filed-under entity, staff
                  members, and supervisors can add their signatures if required.
                </p>
              </ImageSection>

              <ImageSection src={form_submissions_addendums}>
                <p>
                  <b>"Allow addendums to be added"</b> option will enable the Addendums page on the Form Submission overlay. Addendums can
                  be added after the Form Submission is signed-off.
                </p>
              </ImageSection>
            </div>
          }
        />

        <SettingsSection
          isEditable={isEditable}
          title="PDF Export Settings"
          notApplicableDescription="PDF Export is not enabled for this form"
          glyph="file_pdf"
          isApplicable={submissionSettings.use_pdf_export}
          applicableModel="use_pdf_export"
          notApplicableContent={
            <Alert small contrast glyph="info">
              PDF Exports are <b>not enabled</b> for this Form Submission
            </Alert>
          }
          applicableContent={
            <div className="grid gap-6">
              <CheckboxGroup trueIcon="check" falseIcon="cross" falseStyle="faded" layout="vertical-dense">
                <Checkbox
                  defaultChecked
                  label="Enable Form Submission PDF Export"
                  model="pdf_export_settings.form_submission_pdf_export"
                  tooltip="When checked, anyone who has access to the submissions made using this form will be able to download a readonly PDF version."
                />
              </CheckboxGroup>
            </div>
          }
          moreInfo={
            <div className="grid text-[0.9rem] text-text [&_img]:mb-6 [&_p]:mb-2 [&_p]:mt-0">
              <ImageSection src={form_submissions_pdf_export}>
                <p>
                  <b>"Enable Form Submission PDF Export"</b> will enable the PDF export action on this Form Submission overlay.
                </p>
              </ImageSection>
            </div>
          }
        />
      </Accordions>
    </Form>
  )
}

const SettingsSection = (props: any) => {
  const { isEditable, applicableContent, applicableModel, children, glyph, isApplicable, moreInfo, notApplicableContent, title } = props

  return (
    <Accordions.Item isOpen minimal size={200} glyph={glyph} title={title}>
      <div className="p-4">
        {applicableModel && (
          <div className={isEditable ? 'mb-4' : 'hidden'}>
            <CheckboxGroup trueIcon="check" falseIcon="cross" falseStyle="faded">
              <Checkbox label={`Enable ${title}`} size={100} model={applicableModel} value={isApplicable} />
            </CheckboxGroup>
          </div>
        )}

        {isApplicable && applicableContent && <div className="pb-2">{applicableContent}</div>}
        {!isApplicable && notApplicableContent && <div className="pb-2">{notApplicableContent}</div>}

        {children}
      </div>

      <div>
        {isEditable && moreInfo && (
          <div className="border-t border-0 border-solid border-divider">
            <TreeItem withHover={false} title={<span className="text-text-muted text-[0.95rem]">More Info</span>}>
              <div className="pb-3 pr-3">{moreInfo}</div>
            </TreeItem>
          </div>
        )}
      </div>
    </Accordions.Item>
  )
}

const ImageContext = React.createContext({})

const ImageSection = ({ src, children }: any) => {
  return (
    <ImageContext.Provider value={{ children }}>
      {children}

      <Zoom ZoomContent={CustomZoomContent} zoomMargin={20}>
        <img src={src} width={580} className="block w-full h-auto shadow-soft-2 rounded-md overflow-hidden" />
      </Zoom>
    </ImageContext.Provider>
  )
}

const CustomZoomContent = ({ buttonUnzoom, modalState, img }: any) => {
  const { children }: any = React.useContext(ImageContext)

  return (
    <>
      {buttonUnzoom}

      {img}

      {children && (
        <div
          className={clsx(
            'absolute bottom-12 left-0 w-full px-5 py-4 flex justify-center opacity-0',
            modalState === 'LOADED'
              ? 'opacity-100 animate-slideInFromBottom'
              : modalState === 'UNLOADING'
              ? 'animate-slideOutToBottom'
              : '',
          )}
        >
          <Card className="px-3 py-2 w-full max-w-[600px] [&_p]:m-0">{children}</Card>
        </div>
      )}
    </>
  )
}

const STYLES = {
  form: {
    display: 'grid',
    gridGap: '0.5rem',
  },
}

import React from 'react'
import { v4 as uuid } from 'uuid'
import pluralize from 'pluralize'
import produce from 'immer'

import get from 'lodash/get'
import map from 'lodash/map'
import remove from 'lodash/remove'

import { DEFAULT_EMPTY_VALUE } from '../../../../utils/constants'
import { encodeObjectToURL } from '../../../../utils/functions'
import { apiGet } from '../../../../modules/api'

import { withFormContext } from '../../context'
import { validate } from '../../validators'

import Grid from '../../../Grid'
import SelectorBase from '../SelectorBase'
import SmartPortal from '../../../SmartPortal'
import Value from '../../../Value'
import Search from '../../../Search'

import SingleSearchMenu from './SingleSearchMenu'

import { LINKS, getDefaultGroupBy } from '../SelectorBase'

class SingleSearchSelector extends SelectorBase {
  constructor(props) {
    super(props)

    let errors = []
    let vs = props.validations

    const object = props.value || get(props.form?.getInitialModel(), props.model, {})
    if (vs) errors = validate(object, vs)

    this.state = {
      type: 'OBJECT_SEARCH',
      id: `${props.model}-${uuid()}`,
      originalModel: props.model,
      model: props.model,
      object: object,
      value: object,
      apiData: [],
      search: '',
      isRelation: props.isRelation,
      includeObject: props.includeObject,
      modelSelector: props.modelSelector,
      isLoading: false,
      isOpen: props.isOpen,
      isValid: errors.length ? false : true,
      isInvalid: errors.length ? true : false,
      isPristine: true,
      isDirty: false,
      isTouched: false,
      isUntouched: true,
      isValidations: props.validations,
      isRequired: props.validations && props.validations.hasOwnProperty('presence'),
      isNested: props.isNested || false,
      errors: [],
      reset: this.onReset,
      validate: this.onValidate,
    }

    this.initialData = {
      object: object,
      value: object,
      apiData: [],
      modelSelector: props.modelSelector,
      search: '',
      isLoading: false,
      isOpen: props.isOpen,
      isValid: errors.length ? false : true,
      isInvalid: errors.length ? true : false,
    }

    this.updateType = 'DATA'
    this.fieldRef = null
    this.referenceRef = null
  }

  /*
    CUSTOM FUNCTIONS
  */
  queryData = async (query = '') => {
    const types = this.props.type.split('.')

    const params = {
      q: query,
      type: types[0],
      subtype: types[1],
      excluded: this.state.object?.id,
    }

    const response = await apiGet({ url: `/search_by?${encodeObjectToURL(params)}` })

    return response.data.data
  }

  processChangeValue = (item: any) => {
    if (this.props.isDisabled) return

    const newState = produce(this.state, (draft: any) => {
      draft.object = item
      draft.value = item
      draft.isOpen = false
      draft.isDirty = true
      draft.isPristine = false
    })

    this.setState({
      object: newState.object,
      value: newState.value,
      isOpen: newState.isOpen,
      isDirty: newState.isDirty,
      isPristine: newState.isPristine,
    })

    return newState
  }

  onSearch = async (search: string) => {
    this.setState({ isLoading: true, search: search })

    try {
      const result = await this.queryData(search)
      this.setState({ isLoading: false, apiData: result })
    } catch (error) {
      console.error(error)
      this.setState({ isLoading: false })
    }
  }

  /*
    RENDER
  */
  renderEdit = () => (
    <Search
      showLoader
      showGraphic={false}
      placeholder={this.props.placeholder}
      onChange={this.onSearch}
      onFocus={this.open}
      // onClear={this.clear}
      isLoading={this.state.isLoading}
      value={this.props.selectTitle?.(this.state.object)}
    />
  )

  renderReadOnly = () => {
    const { object } = this.state
    const { disableLink } = this.props

    if (object) {
      return (
        <Grid gap={10}>
          <Value
            avatarMagnify
            avatarMagnifyScale={2.5}
            key={object.id}
            value={this.props.selectTitle?.(object)}
            description={object.description}
            icon={this.props.icon}
            avatar={object.avatar}
            link={!disableLink && LINKS[this.props.type]?.(object.id)}
          />
        </Grid>
      )
    } else return DEFAULT_EMPTY_VALUE
  }

  renderSelector = () => {
    const { icon, type, selectTitle, selectDescription, showAvatars, emptyActions, groupBy } = this.props
    const { apiData } = this.state

    const groupByConfig = groupBy || getDefaultGroupBy(type)

    return (
      <SingleSearchMenu
        icon={icon}
        type={type}
        items={apiData}
        showAvatars={showAvatars}
        selectTitle={selectTitle}
        selectDescription={selectDescription}
        emptyActions={emptyActions}
        onSelect={this.changeValue}
        onClose={this.close}
        groupBy={groupByConfig}
      />
      // <SmartPortal useReferenceWidth portal="selector" position="bottom-start">
      // </SmartPortal>
    )
  }
}

SingleSearchSelector.defaultProps = {
  showAs: 'inline',
  modelSelector: 'id',
  showAvatars: true,
  prefetch: false,
  isEditable: true,
  isDisabled: false,
  initialModelRequired: false,
  layout: 'vertical-dense',
  isOpen: false,
  placeholder: 'Search…',
}

export default withFormContext(SingleSearchSelector)

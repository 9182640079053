import React from 'react'
import { connect } from 'react-redux'
import { transparentize } from 'polished'
import withRouter from '../../../../hocs/withRouter'

import { COLORS, MEDIA_QUERY } from '../../../../theme'

import Alert from '../../../Alert'
import Button from '../../../Button'
import Card from '../../../Card'
import CardContent from '../../../CardContent'
import CardHeader from '../../../CardHeader'
import CardTitle from '../../../CardTitle'
import Dialog from '../../../Dialog'
import Divider from '../../../Divider'
import Form from '../../../Forms/Form'
import FormSection from '../../../Forms/FormSection'
import Glyph from '../../../Glyph'
import Input from '../../../Forms/Input'
import Nav from '../../../Nav'
import Overlay from '../../../Overlay'
import PageGrid from '../../../PageGrid'
import RichTextEditor from '../../../Forms/RichTextEditor'
import ScrollMenu from '../../../ScrollMenu'
import ScrollView from '../../../ScrollView'
import Section from '../../../Section'
import SignaturePad from '../../../Forms/SignaturePad'
import State from '../../../State'
import Status from '../../../Status'

import { OverlayBase, defaultMapStateToProps, defaultMapDispatchToProps } from '../OverlayBase'
import { apiUpdate } from '../../../../modules/api'
import { withOverlayError } from '../../../../hocs/withOverlayError'
import snakeCase from 'lodash/snakeCase'

type SectionConfirmationProps = {
  id: string
  title: string
  isConfirmed: boolean
  className?: string
}

const SectionConfirmation = (props: SectionConfirmationProps) => {
  const { title, id, className, isConfirmed } = props

  return (
    <Card
      className={className}
      css={
        !isConfirmed && {
          boxShadow: `
            0 0 0 1px ${COLORS.orange},
            0 0 0 4px ${transparentize(0.75, COLORS.orange)}
          `,
        }
      }
    >
      <CardHeader graphic={<Glyph glyph={isConfirmed ? 'check' : 'warning'} />}>
        <CardTitle title={isConfirmed ? 'Confirmed' : 'Confirmation Required'} />
      </CardHeader>

      <CardContent className="!p-4">
        <p css={{ margin: '0 0 0.5rem' }}>Please enter your initials below to confirm reading "{title}":</p>
        <Input
          placeholder="Add your initials here…"
          model={`confirmations.${id}`}
          size={12}
          validations={{
            presence: {
              message: 'Please add your initials to confirm',
            },
          }}
        />
      </CardContent>
    </Card>
  )
}

const SectionStatus = ({ isCompleted, completedLabel, notCompletedLabel }: any) => {
  return (
    <Status
      testKey={`${snakeCase(isCompleted ? completedLabel : notCompletedLabel)}_section_status`}
      label={isCompleted ? completedLabel : notCompletedLabel}
      color={isCompleted ? 'green' : 'orange'}
      css={{ color: COLORS.black }}
    />
  )
}

class SignAgreementOverlay extends OverlayBase {
  signAgreement = async () => {
    const { contract, type, onSignedSuccessfully } = this.props

    let model = this.form.current.getFormValue()
    this.setState({ $loading: true })

    await apiUpdate({
      url: `/apps/agreements/sign/${contract?.id}`,
      params: model,
    })

    if (onSignedSuccessfully) await onSignedSuccessfully()
    if (type === 'summon') this.close()
  }

  render = () => {
    const { isInvalid, $loading, formData } = this.state
    const { agreement, contract, timezone, online, isPreviewMode, loading, onClose } = this.props

    const signee = contract?.signee

    const isSigned = contract?.signed_at
    const shouldSign = agreement?.should_sign
    const isEditable = isPreviewMode || (!isPreviewMode && !isSigned)
    const hasSections = agreement?.sections?.length > 0

    return (
      <Overlay
        showBackdrop
        onClose={onClose}
        position="center"
        maxWidth={hasSections ? 80 : 32}
        // isLoading={loading}
        fullheight={!isPreviewMode}
        closeOnBackdrop={isPreviewMode}
        closeWrapper={
          isSigned || isPreviewMode || !shouldSign
            ? null
            : (element: any) => (
                <Dialog
                  glyph="signature"
                  title="Close Without Signing?"
                  message="This agreement has not yet been signed."
                  onYes={this.close}
                  yesColor="red"
                  yesLabel="Close Without Signing"
                  noLabel="Go Back"
                >
                  {element}
                </Dialog>
              )
        }
      >
        <Overlay.Header
          icon="legal_agreement_alt"
          title={agreement?.name || 'Agreement'}
          description={(isPreviewMode && <Status label="Preview" color="orange" />) || (signee && <>{signee?.signer?.name} Signature</>)}
        />

        <ScrollView>
          {!hasSections && (
            <State isEmpty icon="legal_agreement_alt" title="Agreement" emptyDescription="This agreement has no content yet" />
          )}

          {hasSections && (
            <PageGrid scroll breakpoint={3} css={STYLES.pageGrid}>
              <Nav
                top="0"
                headingSize={300}
                title="Table of Contents"
                breakpoint={3}
                background={transparentize(0.4, 'white')}
                css={STYLES.nav}
              >
                <ScrollMenu />
              </Nav>

              <Overlay.Content>
                <Form
                  getForm={this.form}
                  timezone={timezone}
                  initialModel={contract}
                  onValidationUpdate={this.onValidationUpdate}
                  isEditable={isEditable}
                  onUpdate={(value: any) => this.setState({ formData: value })}
                >
                  {agreement.should_sign && !isPreviewMode && (
                    <>
                      <Section>
                        <Alert glyph="info" className="!mb-4">
                          {isSigned
                            ? 'This agreement has been signed.'
                            : 'Please read through this agreement and scroll to the bottom to sign it.'}
                        </Alert>
                      </Section>

                      <Divider />
                    </>
                  )}

                  {agreement.sections?.map((section: any, index: number) => (
                    <div key={section.id}>
                      <Section
                        scrollview={{
                          id: section.id,
                          name: section.name || `Section #${index + 1}`,
                          description: section.should_confirm && (
                            <SectionStatus
                              isCompleted={contract?.confirmations?.[section.id] || formData?.confirmations[section.id]}
                              completedLabel="Confirmed"
                              notCompletedLabel="To Confirm"
                            />
                          ),
                        }}
                      >
                        <h1
                          css={{
                            display: 'inline-block',
                            marginBottom: '1rem',
                            padding: '0.24em 0.6em',

                            fontSize: '0.9rem',
                            letterSpacing: 1,
                            textTransform: 'uppercase',

                            background: COLORS.text,
                            color: COLORS.white,
                            borderRadius: 5,
                          }}
                        >
                          {section.name || `Section #${index + 1}`}
                        </h1>
                        <RichTextEditor isEditable={false} value={section.content} />

                        {section.should_confirm && (
                          <SectionConfirmation
                            id={section.id}
                            title={section.name}
                            className="!mt-4"
                            isConfirmed={contract?.confirmations?.[section.id] || formData?.confirmations[section.id]}
                          />
                        )}
                      </Section>
                      <Divider />
                    </div>
                  ))}

                  {(agreement.should_sign || agreement.should_author_sign) && (
                    <Section
                      title="Signature"
                      scrollview={{
                        id: 'signature',
                        name: 'Signature',
                        description: (
                          <SectionStatus
                            isCompleted={isSigned || formData?.signature_data}
                            completedLabel="Signed"
                            notCompletedLabel="To Sign"
                          />
                        ),
                      }}
                    >
                      <FormSection>
                        <SignaturePad
                          label="Signee Signature"
                          model="signature"
                          signedAtModel="signed_at"
                          person={signee?.signer}
                          allowPin={false}
                          onUpdate={(o) => {
                            this.setState({ signatureValue: o.value })
                          }}
                          validations={{
                            presence: {
                              message: 'Please draw your signature here',
                            },
                          }}
                        />
                      </FormSection>
                    </Section>
                  )}
                </Form>
              </Overlay.Content>
            </PageGrid>
          )}
        </ScrollView>

        {!isPreviewMode && !isSigned && shouldSign && (
          <Overlay.Footer online={online}>
            <Button
              label={signee ? `Sign Agreement (${signee?.signer?.name})` : 'Sign Agreement'}
              glyph="check"
              type="primary"
              color="green"
              onClick={this.signAgreement}
              isLoading={$loading || loading}
              isDisabled={isInvalid || !this.state.signatureValue}
            />
          </Overlay.Footer>
        )}
      </Overlay>
    )
  }
}

const mapDispatchToProps = (dispatch) => defaultMapDispatchToProps(dispatch)
const mapStateToProps = (state, props) => defaultMapStateToProps(state, props.match, 'agreements')

const STYLES = {
  pageGrid: {
    flex: '1 1 auto',
  },

  nav: {
    [MEDIA_QUERY[3]]: {
      maxWidth: 300,
    },
  },
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withOverlayError(SignAgreementOverlay)))

import React from 'react'

import { DataTable } from '../../components/DataTable/DataTable'
import { InvoiceStatus } from '../InvoiceElements/InvoiceStatus'
import { MainCell } from '../../components/DataTable/cells/MainCell'

export const InsuranceInvoicesDataTable = (props: any) => {
  const { to, ...rest } = props

  const columns = React.useMemo(
    () => [
      {
        title: 'Statement #',
        model: 'identifier',
        width: 300,
        disableHide: true,
        disableSelect: true,
        formatValue: ({ data, value }: any) => <MainCell testKey="main_cell" id={data.id} value={value} to={to ? to(data) : undefined} />,
      },
      {
        width: 160,
        title: 'Status',
        model: 'status',
        formatValue: ({ value }: any) => <InvoiceStatus status={value} />,
      },
      {
        title: 'Billed To',
        model: 'billed_to',
        type: 'profile',
      },
      {
        title: 'Billed By',
        model: 'billed_by',
        type: 'profile',
      },
      {
        title: 'Total Balance',
        model: 'invoice_total_amount',
        type: 'amount',
        width: 120,
      },
      {
        title: 'Balance Due',
        model: 'invoice_due_amount',
        type: 'amount',
        width: 120,
      },
      {
        title: 'Billed To Name',
        model: 'billed_to_name',
      },
      {
        title: 'Billed To Email',
        model: 'billed_to_email',
        type: 'email',
      },
      {
        title: 'Billed To Phone',
        model: 'billed_to_phone_no',
        type: 'phone',
      },
      {
        title: 'Billed By Name',
        model: 'billed_by_name',
      },
      {
        title: 'Billed By Email',
        model: 'billed_by_email',
        type: 'email',
      },
      {
        title: 'Billed By Phone',
        model: 'billed_by_phone_no',
        type: 'phone',
      },
      {
        title: 'Invoice Period Start',
        model: 'period_started_at',
        type: 'date',
        width: 160,
      },
      {
        title: 'Invoice Period End',
        model: 'period_ended_at',
        type: 'date',
        width: 160,
      },
      {
        title: 'Due Date',
        model: 'due_date',
        type: 'date',
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
      },
      {
        title: 'Added By',
        model: 'author',
        type: 'profile',
        disableSort: true,
      },
    ],
    [to],
  )

  return <DataTable asCard title="Client Statements" icon="client_statements" columns={columns} {...rest} />
}

import React from 'react'
import { produce } from 'immer'
import { v4 as uuid } from 'uuid'
import clsx from 'clsx'
import isFinite from 'lodash/isFinite'
import size from 'lodash/size'

import { DragAndDrop } from '../../components/DragAndDrop'
import { isDefined } from '../../utils/functions'
import { useDataTable } from '../../components/DataTable/useDataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import { DataTable } from '../../components/DataTable/DataTable'
import { useSettings } from '../../hooks/useSettings'

import AmountInput from '../../components/Forms/AmountInput'
import Button from '../../components/Button'
import Card from '../../components/Card'
import Glyph from '../../components/Glyph'
import Icon from '../../components/Icon'
import Input from '../../components/Forms/Input'
import NumberInput from '../../components/Forms/NumberInput'
import Option from '../../components/Forms/Option'
import Overlay from '../../components/Overlay'
import Select from '../../components/Forms/Select'
import State from '../../components/State'
import SummonOverlay from '../../components/SummonOverlay'
import Tooltip from '../../components/Tooltip'

import { SERVICE_CATEGORIES } from '../../utils/constants'
import { RECURRING_DAYS, RECURRING_FREQUENCIES } from '../../utils/recurrence'

export const ClientInvoiceLinesEditTable = (props: any) => {
  const { invoiceLines, setInvoiceLines, setDeletedInvoiceLines } = props

  const { isBehave } = useSettings()

  const columns = React.useMemo(
    () => [
      {
        title: '#',
        width: '60px',
        textAlign: 'center',
        render: () => {
          return null
        },
      },
      {
        title: 'Service',
        width: 'minmax(250px, 1fr)',
        render: (line: any, index: number) => {
          return (
            <div className="grid grid-cols-[1fr_auto] gap-1">
              <Input
                isEditable
                withHover={false}
                value={line.service_name}
                onUpdate={({ value }: any) => {
                  setInvoiceLines((prev: any) => {
                    return produce(prev, (draft: any) => {
                      draft[index].service_name = value
                    })
                  })
                }}
              />

              <SummonOverlay
                className="flex-[0_0_auto]"
                overlay={
                  <PreFillServiceImportOverlay
                    onSelect={(clientService) => {
                      if (!clientService) return

                      setInvoiceLines((prev: any) => {
                        return produce(prev, (draft: any) => {
                          draft[index].service_name = clientService.name
                          draft[index].unit_price = clientService.amount
                          draft[index].service_description = SERVICE_CATEGORIES?.[clientService?.category]?.label || ''
                        })
                      })
                    }}
                  />
                }
              >
                <Button
                  label="Pre-Fill"
                  size={100}
                  type="minimal"
                  glyph="add_file"
                  display="inline-flex"
                  className="normal-case tracking-[0] !font-[600] !text-[0.9rem] self-center"
                />
              </SummonOverlay>
            </div>
          )
        },
      },
      {
        title: 'Service SKU',
        width: '150px',
        render: (line: any, index: number) => {
          return (
            <Input
              isEditable
              withHover={false}
              value={line.service_sku}
              onUpdate={({ value }: any) => {
                setInvoiceLines((prev: any) => {
                  return produce(prev, (draft: any) => {
                    draft[index].service_sku = value
                  })
                })
              }}
            />
          )
        },
      },
      {
        title: 'Description',
        width: 'minmax(250px, 1fr)',
        render: (line: any, index: number) => {
          return (
            <Input
              isEditable
              withHover={false}
              value={line.service_description}
              onUpdate={({ value }: any) => {
                setInvoiceLines((prev: any) => {
                  return produce(prev, (draft: any) => {
                    draft[index].service_description = value
                  })
                })
              }}
            />
          )
        },
      },
      {
        title: 'Units',
        width: '80px',
        render: (line: any, index: number) => {
          return (
            <NumberInput
              isEditable
              withHover={false}
              value={line.quantity}
              onUpdate={({ value }: any) => {
                setInvoiceLines((prev: any) => {
                  return produce(prev, (draft: any) => {
                    draft[index].quantity = value
                  })
                })
              }}
            />
          )
        },
      },
      {
        title: 'Unit Amount',
        width: '120px',
        render: (line: any, index: number) => {
          return (
            <AmountInput
              isEditable
              withHover={false}
              value={line.unit_price}
              onUpdate={({ value }: any) => {
                setInvoiceLines((prev: any) => {
                  return produce(prev, (draft: any) => {
                    draft[index].unit_price = value
                  })
                })
              }}
            />
          )
        },
      },
      {
        title: 'Discount',
        width: '320px',
        render: (line: any, index: number) => {
          return (
            <div className="grid grid-cols-[1fr_auto] gap-1">
              <div className={clsx(line?.discount_type && 'grid grid-cols-[120px_1fr] gap-3')}>
                <Select
                  allowEmpty
                  withHover={false}
                  value={line.discount_type}
                  onUpdate={({ value }: any) => {
                    setInvoiceLines((prev: any) => {
                      return produce(prev, (draft: any) => {
                        draft[index].discount_type = value
                      })
                    })
                  }}
                >
                  <Option label="Fixed Amount" value={'fixed'} />
                  <Option label="Percentage" value={'percentage'} />
                </Select>

                {line.discount_type === 'fixed' ? (
                  <div className="">
                    <NumberInput
                      prefix="$"
                      withHover={false}
                      value={line.amount_off}
                      onUpdate={({ value }: any) => {
                        setInvoiceLines((prev: any) => {
                          return produce(prev, (draft: any) => {
                            draft[index].amount_off = value
                          })
                        })
                      }}
                      className="!w-full"
                    />
                  </div>
                ) : line.discount_type === 'percentage' ? (
                  <div className="flex items-center flex-nowrap">
                    <NumberInput
                      suffix="%"
                      step={1}
                      withHover={false}
                      value={line.percent_off}
                      onUpdate={({ value }: any) => {
                        const formatted = parseInt(value)

                        if (!isFinite(formatted)) return

                        setInvoiceLines((prev: any) => {
                          return produce(prev, (draft: any) => {
                            draft[index].percent_off = formatted
                          })
                        })
                      }}
                      className="!w-full"
                    />

                    <Tooltip
                      className="ml-2"
                      content={
                        <>
                          <b>Please note: </b> percentages must be whole numbers to ensure discounted amounts are precise.
                        </>
                      }
                    />
                  </div>
                ) : null}
              </div>
            </div>
          )
        },
      },
      {
        title: 'Discount SKU',
        width: '150px',
        render: (line: any, index: number) => {
          return (
            <Input
              isEditable
              withHover={false}
              value={line.discount_sku}
              onUpdate={({ value }: any) => {
                setInvoiceLines((prev: any) => {
                  return produce(prev, (draft: any) => {
                    draft[index].discount_sku = value
                  })
                })
              }}
            />
          )
        },
      },
      {
        title: '',
        width: '40px',
        isBehaveOnly: true,
        render: (line: any, index: number) => {
          return (
            <Button
              hideLabel
              glyph="delete"
              color="red"
              type="minimal"
              size={100}
              className="!w-7 !h-7"
              onClick={() => {
                // if server item, mark for deletion and remove from state
                if (isDefined(line.id)) {
                  setDeletedInvoiceLines((prev: any) => [...prev, { id: line.id, _destroy: 1 }])

                  setInvoiceLines((prev: any) => {
                    return produce(prev, (draft: any) => {
                      const foundIndex = draft.findIndex((o: any) => o.id === line.id)

                      if (foundIndex !== -1) {
                        draft.splice(foundIndex, 1)
                      }
                    })
                  })

                  return
                }

                // if local item, remove from state
                setInvoiceLines((prev: any) => {
                  return produce(prev, (draft: any) => {
                    const foundIndex = draft.findIndex((o: any) => o._id === line._id)

                    if (foundIndex !== -1) {
                      draft.splice(foundIndex, 1)
                    }
                  })
                })
              }}
            />
          )
        },
      },
    ],
    [isBehave],
  )

  const gridTemplateColumns = React.useMemo(() => {
    const finalColumns: any = []

    for (const column of columns) {
      if (column.isBehaveOnly && !isBehave) continue

      finalColumns.push(column.width)
    }

    return finalColumns.join(' ')
  }, [columns, isBehave])

  const isEmpty = size(invoiceLines) === 0

  const addAction = (
    <Button
      label="Add Invoice Line"
      size={200}
      type="primary"
      glyph="add"
      display="inline-flex"
      onClick={() => {
        setInvoiceLines((prev: any) => [
          ...prev,
          {
            _id: uuid(),
            quantity: 1,
            service_name: '',
            service_description: '',
            use_custom_service: true,
          },
        ])
      }}
    />
  )

  if (isEmpty) {
    return (
      <State
        isEmpty
        icon="invoices"
        title="Invoice Lines"
        emptyDescription="No invoice lines added to this invoice yet"
        emptyActions={addAction}
        minHeight={200}
      />
    )
  }

  return (
    <div>
      <div className="grid !overflow-x-auto pb-8">
        <div
          className="grid gap-4 items-center text-[0.84rem] tracking-[1px] uppercase text-text-muted font-[700] opacity-[0.85] pt-1 pb-1.5"
          style={{ gridTemplateColumns }}
        >
          {columns.map((column) => {
            if (column.isBehaveOnly && !isBehave) return null

            return (
              <div key={column.title} style={{ textAlign: column.textAlign }}>
                {column.title}
              </div>
            )
          })}
        </div>

        <div>
          <DragAndDrop
            items={invoiceLines}
            onUpdate={setInvoiceLines}
            getId={(item) => item._id || item.id}
            renderOverlay={({ item, index }) => {
              const name = item?.custom_service?.name

              return (
                <Card className="w-fit px-3 py-2 flex items-center flex-nowrap !select-none">
                  <Glyph glyph="drag_and_drop" size={14} />
                  <div className="ml-2 mr-4 font-[500] text-text-muted">{index + 1}.</div>
                  <Icon icon="financials" size={20} className="mr-2" />
                  <div className="font-[600]">{name}</div>
                </Card>
              )
            }}
            renderItem={({ renderDragElement, item: invoiceLine, index }: any) => {
              const amount = parseFloat(invoiceLine.amount)
              const quantity = parseInt(invoiceLine.quantity)
              const lineAmount = amount * quantity

              return (
                <div
                  key={`item-${invoiceLine.id || invoiceLine._id}`}
                  className="grid gap-4 items-center py-0.5"
                  style={{ gridTemplateColumns }}
                >
                  <div className="grid grid-cols-1 items-start">
                    {renderDragElement({
                      children: <div className="ml-2 font-[500] text-text-muted">{index + 1}.</div>,
                      className: '!justify-start !pl-2 !py-2',
                    })}
                  </div>

                  {columns.map((column) => {
                    if (column.isBehaveOnly && !isBehave) return null

                    return column?.render?.(invoiceLine, index) || null
                  })}
                </div>
              )
            }}
          />
        </div>
      </div>

      <div className="mt-2">{addAction}</div>
    </div>
  )
}

const PreFillServiceImportOverlay = ({ onClose, onSelect }) => {
  const tableProps = useDataTable({
    name: ['client_services_import'],
    endpoint: '/services',
    params: { service_type: 'non_healthcare', status: 'active' },
    localStorageKey: 'client_services_import_v1',
  })

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        formatValue: ({ value, data }: any) => (
          <MainCell
            id={data.id}
            value={value}
            onClick={
              onSelect
                ? () => {
                    onSelect(data)
                    onClose?.()
                  }
                : undefined
            }
          />
        ),
      },
      {
        title: 'Category',
        model: 'category',
        formatValue: ({ value }) => SERVICE_CATEGORIES?.[value]?.label || '–',
      },
      {
        title: 'Amount',
        model: 'amount',
        type: 'amount',
      },
      {
        title: 'Order',
        model: 'order',
        type: 'number',
      },
      {
        title: 'Repeating',
        model: 'is_recurring',
        formatValue: ({ value: isRecurring, data }: any) => {
          if (!isRecurring) return null

          const frequency = data.recurring_frequency
          let dueDay = data[`recurring_${frequency}_due_day`]

          const label = RECURRING_FREQUENCIES?.[frequency].label

          if (frequency === 'daily') return label
          if (frequency === 'monthly') dueDay -= 1

          const dueDayLabel = RECURRING_DAYS?.[frequency][dueDay]

          return `${label} (${dueDayLabel})`
        },
      },
    ],
    [],
  )

  return (
    <Overlay showBackdrop position="center" maxWidth={90} onClose={onClose}>
      <Overlay.Header icon="financials" title="Pre-Fill from Client Services" />

      <Overlay.Content className="p-5">
        <DataTable
          {...tableProps}
          columns={columns}
          title="Client Services"
          icon="financials"
          onClick={(selected) => {
            onSelect?.(selected)
            onClose?.()
          }}
        />
      </Overlay.Content>
    </Overlay>
  )
}

import React from 'react'
import { useParams, useRouteMatch } from 'react-router-dom'

import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { useGet } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Button, Page, Grid, HelpTagIframe } from '@behavehealth/components'
import { ClientConnectionsDataTable } from '@behavehealth/constructs/ClientConnection/ClientConnectionsDataTable'

const pageConfig = {
  feature: 'care_team',
  help: <HelpTagIframe id="client_care_team" />,
  marketingID: 'client_care_team',
}

type Props = {}

const CareTeam: React.FC<Props> = ({ canCreate = true }) => {
  const match = useRouteMatch()
  const { resource_id: clientId }: any = useParams()

  const { data: client }: any = useGet({
    name: ['client', clientId],
    url: `/residents/${clientId}`,
  })

  const tableProps = useDataTable({
    name: ['client', clientId, 'connections'],
    endpoint: `/residents/${clientId}/connections`,
    updateDeleteEndpoint: '/connections',
    enabled: !!clientId,
    localStorageKey: 'client_connections_v1',
  })

  const actions = (
    <Button
      glyph="add"
      type="primary"
      label="Add Connection"
      link={{
        pathname: `${match.url}/new`,
        data: {
          residents: [client],
        },
      }}
      permission="connections.create"
    />
  )

  return (
    <Page emptyDescription="There are no connections created yet" actions={canCreate && actions} {...pageConfig}>
      <Grid gap="1rem">
        <ClientConnectionsDataTable
          {...tableProps}
          to={({ id }) => ({
            pathname: `${match.url}/${id}`,
            parent: match,
          })}
          batchActionsConfig={[
            {
              type: 'delete',
              permission: 'connections.delete',
              action: async ({ ids }: any) => {
                await tableProps.deleteRecords(ids.join(','))
              },
            },
          ]}
        />
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(CareTeam, pageConfig))

import React from 'react'
import { Route, useRouteMatch } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import compact from 'lodash/compact'
import size from 'lodash/size'

import { APP_URLS } from '../MyAccount/utils'
import { COLORS } from '../../theme'
import { niceAmount, titleCase } from '../../utils/functions'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'
import useCopyToClipboard from '../../hooks/useCopyToClipboard'

import AddressSelectorInput from '../../components/Forms/AddressSelectorInput'
import Alert from '../../components/Alert'
import AnimatedSwitch from '../../components/AnimatedSwitch'
import Avatar from '../../components/Avatar'
import Button from '../../components/Button'
import Card from '../../components/Card'
import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import DataList from '../../components/DataList'
import DateInput from '../../components/Forms/DateInput'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import FinancialCard from '../../components/Cards/FinancialCard'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Glyph from '../../components/Glyph'
import Grid from '../../components/Grid'
import Icon from '../../components/Icon'
import Input from '../../components/Forms/Input'
import Label from '../../components/Label'
import Link from '../../components/Link'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Option from '../../components/Forms/Option'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import OverlaySelector from '../../components/Forms/Selectors/OverlaySelector/OverlaySelector'
import PageSection from '../../components/PageSection/PageSection'
import Portal from '../../components/Portal'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Select from '../../components/Forms/Select'
import State from '../../components/State'
import StripePaymentOverlay from '../../components/Stripe/StripePaymentOverlay'
import SummonOverlay from '../../components/SummonOverlay'
import Textarea from '../../components/Forms/Textarea'
import Tooltip from '../../components/Tooltip'

import { DataTable } from '../../components/DataTable/DataTable'
import { InvoiceStatus, STATUSES } from '../InvoiceElements/InvoiceStatus'
import { PaymentMethod, PaymentMethodTitle, PaymentMethodIcon, PaymentMethodDescription } from '../BillingElements/PaymentMethodElements'
import { PaymentServiceLinesImportOverlay } from './PaymentServiceLinesImportOverlay'
import { SectionCard } from '../../components/SectionCard'

import { InvoicePaymentOverlay } from './InvoicePaymentOverlay'
import { InvoicePaymentsDataTable } from './InvoicePaymentsDataTable'
import { InvoiceWriteOffOverlay } from './InvoiceWriteOffOverlay'
import { InvoiceWriteOffsDataTable } from './InvoiceWriteOffsDataTable'

export const RootInsuranceInvoiceOverlay = (props: any) => {
  const overlayProps = useOverlay({
    name: 'invoices',
    endpoint: '/invoices',
    invalidate: 'invoices',
    options: props,
  })

  if (overlayProps.isOverlayLoading) {
    return <OverlayLoader position="right" maxWidth={140} />
  }

  return <InnerInsuranceInvoiceOverlay key={`updated-${overlayProps.data?.updated_at}`} {...overlayProps} />
}

const InnerInsuranceInvoiceOverlay = (props: any) => {
  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    id,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    saveWithData,
  } = props

  const match = useRouteMatch()

  const { tenant, timezone } = useSettings()

  const [billToCategory, setBillToCategory] = React.useState(initialModel?.billed_to_category || 'client')

  const [client, setClient] = React.useState<any>(initialModel?.billed_to)
  const [contact, setContact] = React.useState<any>(null)
  const [payer, setPayer] = React.useState<any>(null)
  const [paymentMethod, setPaymentMethod] = React.useState(initialModel?.auto_collection_payment_method || null)

  const [isCustomClient, setIsCustomClient] = React.useState(!!initialModel?.use_custom_billed_to)
  const [isCustomContact, setIsCustomContact] = React.useState(false)
  const [isCustomFacility, setIsCustomFacility] = React.useState(!!initialModel?.use_custom_billed_by)

  const [serviceLines, setServiceLines] = React.useState<any>([])

  const serviceLinesCount = size(serviceLines)
  const isServiceLinesEmpty = serviceLinesCount === 0

  const save = () => {
    const formData = form.current.getFormValue()

    saveWithData({
      ...formData,
      facility_id: tenant?.id,
      billed_by_id: tenant?.id,
      billed_by_type: tenant?.type,
    })
  }

  const addServiceLine = () => {
    setServiceLines((c) => [...c, { _id: uuid() }])
  }

  const paymentLink = `${APP_URLS.mini_apps}/rcm-invoices/${tenant.subdomain}/pay/${uuid()}`
  const invoiceLink = `${APP_URLS.mini_apps}/rcm-invoices/${tenant.subdomain}/invoice/${uuid()}`

  const { copy: copyPaymentLink, didCopy: paymentLinkCopied } = useCopyToClipboard({
    text: paymentLink,
    notification: 'Payment Link copied!',
  })

  const { copy: copyInvoiceLink, didCopy: invoiceLinkCopied } = useCopyToClipboard({
    text: invoiceLink,
    notification: 'Invoice Link copied!',
  })

  const columns = React.useMemo(
    () => [
      {
        title: 'Service Description',
        model: 'insurance_claim_service_line.insurance_new_code.description',
      },
      {
        title: 'Start Date',
        model: 'insurance_claim_service_line.service_date_start',
        type: 'date',
        width: 140,
      },
      {
        title: 'End Date',
        model: 'insurance_claim_service_line.service_date_end',
        type: 'date',
        width: 140,
      },
      {
        title: 'Billed Amount',
        model: 'balance_billed_amount',
        type: 'amount',
      },
      {
        title: 'Insurance Paid',
        model: 'insurance_paid_amount',
        type: 'amount',
      },
      {
        title: 'Adjustment Amount',
        model: 'adjustment_amount',
        type: 'amount',
      },
      {
        title: 'Client Balance',
        model: 'balance_client_due_amount',
        type: 'amount',
      },
    ],
    [],
  )

  const importAction = isEditable && (
    <>
      {!!client?.id ? (
        <SummonOverlay
          overlay={
            <PaymentServiceLinesImportOverlay
              onSelect={(selectedClaims: any) => {
                setServiceLines((prevServiceLines: any) => {
                  return [...prevServiceLines, ...selectedClaims]
                })
              }}
            />
          }
        >
          <Button label="Import Claim Service Lines" glyph="add_file" type="primary" size={200} />
        </SummonOverlay>
      ) : (
        <Alert small glyph="info">
          Select a client to import service lines
        </Alert>
      )}
    </>
  )

  return (
    <>
      <Overlay showBackdrop={isEditable} onClose={close} position="right" maxWidth={140}>
        <Overlay.Header title="Client Statement" icon="client_statements" />

        <Overlay.Content className="!p-4">
          <Form
            isCompact
            maxWidth="100%"
            getForm={form}
            isEditable={isEditable}
            timezone={timezone}
            initialModel={initialModel}
            onValidationUpdate={onValidationUpdate}
            linked={{ category: 'insurance' }}
            key={`updated-${data?.updated_at}`}
          >
            <Grid gap="1rem" className="!pb-3">
              <Card className="flex items-center justify-between px-2 py-2">
                <div className="flex items-center">
                  <InvoiceStatus status={data?.status || 'draft'} className="mr-2" />
                  {data?.identifier && <div className="font-[600] mr-2">{data.identifier}</div>}
                  {data?.invoice_number && <div className="text-text-muted mr-2">{data.invoice_number}</div>}
                </div>

                {!isEditable && (
                  <SummonOverlay
                    overlay={
                      <StripePaymentOverlay
                        stripeConnectID={tenant?.stripe_account_id}
                        passFees={tenant?.financial_prefs?.pass_fees_to_payers}
                        currentBalance={payer?.current_balance}
                        customer={payer?.reference}
                        source="EHR"
                      />
                    }
                  >
                    <Button label="Make a Payment" type="primary" glyph="dollar" color="green" isLoading={false} size={200} />
                  </SummonOverlay>
                )}
              </Card>

              {!isEditable && (
                <div className="grid gap-4 grid-cols-1 mq600:grid-cols-2">
                  <Card css={{ padding: '1rem', height: '100%' }}>
                    <Grid gap="1rem">
                      <Flex gap="1rem" justifyContent="space-between">
                        <h3>
                          <Flex gap="0.5rem" alignItems="center">
                            <Glyph glyph="cash_hand" />
                            <span>Online Payments</span>
                          </Flex>
                        </h3>
                      </Flex>

                      <span>
                        Use the <strong>Online Payments</strong> to securely collect Payments <strong>without sharing</strong> any Invoice
                        details.
                      </span>

                      <Flex gap="0.5rem">
                        <Button
                          glyph={paymentLinkCopied ? 'check' : 'copy'}
                          label={paymentLinkCopied ? 'Link Copied!' : 'Copy Payments Link'}
                          onClick={copyPaymentLink}
                          size={200}
                        />

                        <Button useGlyphForTarget type="link" label="Open in New Tab" target="_blank" size={200} href={paymentLink} />
                      </Flex>
                    </Grid>
                  </Card>

                  <Card css={{ padding: '1rem', height: '100%' }}>
                    <Grid gap="1rem">
                      <Flex gap="1rem" justifyContent="space-between">
                        <h3>
                          <Flex gap="0.5rem" alignItems="center">
                            <Glyph glyph="ledger" />
                            <span>Full Online Invoice</span>
                          </Flex>
                        </h3>
                      </Flex>

                      <span>
                        Use the <strong>Full Online Invoice</strong> to securely <strong>share</strong> some of the invoice details and
                        collect Payments.
                      </span>

                      <Flex gap="0.5rem">
                        <Button
                          glyph={invoiceLinkCopied ? 'check' : 'copy'}
                          label={invoiceLinkCopied ? 'Link Copied!' : 'Copy Payments Link'}
                          onClick={copyInvoiceLink}
                          size={200}
                        />

                        <Button useGlyphForTarget type="link" label="Open in New Tab" target="_blank" size={200} href={invoiceLink} />
                      </Flex>
                    </Grid>
                  </Card>
                </div>
              )}

              <div className="grid gap-5 grid-cols-1 mq1024:grid-cols-[1.5fr_1fr_1fr]">
                <SectionCard title="Details" className="!p-3">
                  <FormSection maxWidth="100%">
                    <Flex gap="1rem">
                      <Input label="Invoice Number" model="invoice_number" />

                      <Select allowEmpty label="Status" model="status" defaultValue="draft">
                        {Object.keys(STATUSES).map((key) => (
                          <Option key={key} label={STATUSES[key].label} value={key} />
                        ))}
                      </Select>

                      <DateInput label="Due Date" model="due_date" />
                    </Flex>

                    <Flex gap="1rem">
                      <DateInput label="Invoice Period Start" model="period_started_at" />
                      <DateInput label="Invoice Period End" model="period_ended_at" />
                    </Flex>

                    <Textarea label="Description" minRows={2} model="description" />
                    <Textarea label="Instructions" minRows={2} model="instructions" />
                    <Textarea label="Notes" minRows={2} model="notes" />

                    <Input label="Sales Tax" model="tax_percentage" suffix="%" size={4} />

                    {isEditable && !data?.charged_payment_method ? (
                      <ObjectSelector
                        label="Auto-Collection Payment Method"
                        tooltip="When a payment method is added, we will attempt to automatically charge it on the Due Date selected above"
                        value={paymentMethod}
                        onUpdate={({ value }) => setPaymentMethod(value)}
                        icon="online_card_payment"
                        type="admin.facility.global_payment_methods"
                        isDisabled={!client?.id}
                        dependentValue={client?.id}
                        key={`client-${client?.id}`}
                        selectTitle={(paymentMethod: any) => <PaymentMethodTitle paymentMethod={paymentMethod} />}
                        selectGraphic={(paymentMethod: any) => <PaymentMethodIcon paymentMethod={paymentMethod} />}
                        selectDescription={(paymentMethod: any) => <PaymentMethodDescription paymentMethod={paymentMethod} />}
                      />
                    ) : (
                      <div>
                        <Label isCompact label="Auto-Collection Payment Method" />

                        {data?.auto_collection_payment_method ? (
                          <PaymentMethod paymentMethod={data?.auto_collection_payment_method} />
                        ) : (
                          <div>–</div>
                        )}
                      </div>
                    )}

                    {!isNew && (
                      <div>
                        <Label isCompact label="Charged Payment Method" />

                        {data?.charged_payment_method ? <PaymentMethod paymentMethod={data?.charged_payment_method} /> : <div>–</div>}
                      </div>
                    )}

                    <RadioGroup
                      label="Payment Processing Fee"
                      model="pass_fee_to_payer"
                      layout="horizontal"
                      isEditable={isEditable && data?.status !== 'paid' && data?.status !== 'processing'}
                    >
                      <Radio label="Facility pays the fee" value={false} />
                      <Radio label="Invoice Payer pays the fee" value={true} />
                    </RadioGroup>

                    <CheckboxGroup label="QuickBooks Integration">
                      <Checkbox defaultChecked label="Sync with QuickBooks" model="sync" />
                    </CheckboxGroup>
                  </FormSection>
                </SectionCard>

                <SectionCard title="Billed To" className="!p-3">
                  <FormSection maxWidth="100%">
                    <RadioGroup
                      label="Bill To"
                      value={billToCategory}
                      defaultValue="client"
                      layout="horizontal-dense"
                      onUpdate={({ value }: any) => setBillToCategory(value)}
                    >
                      <Radio defaultChecked label="Client" icon="clients" value="client" />
                      <Radio label="Contact" icon="contacts" value="contact" />
                    </RadioGroup>

                    <div>
                      <OverlaySelector
                        isPolymorphic
                        label="Client"
                        icon="clients"
                        type="clients.all"
                        model="billed_to"
                        value={client}
                        onUpdate={({ value }) => {
                          setClient(value)
                        }}
                      />

                      {client?.id && (
                        <DataList isCompact centerLabelsY layout="horizontal" labelWidth={140} className="!mt-2">
                          <DataList.Item
                            label="Billing Details"
                            className={isEditable ? '' : '!hidden'}
                            value={
                              <>
                                <Checkbox
                                  label="Custom Billing Details"
                                  variant="skinny"
                                  model="use_custom_billed_to"
                                  value={isCustomClient}
                                  onUpdate={({ value }) => setIsCustomClient(value)}
                                />

                                <div className="ml-auto">
                                  <Tooltip
                                    content={
                                      <div>
                                        You can customize the default billing details from the client's{' '}
                                        <Link to={`/clients/${client.id}/general-info`} className="font-[600]" target="_blank">
                                          <Icon icon="general_info" size={12} className="relative top-[-2px]" />
                                          <span className="inline-block mx-1">General Info</span>
                                          <Glyph glyph="external_link" size={12} color={COLORS.blue} />
                                        </Link>{' '}
                                        page
                                      </div>
                                    }
                                  />
                                </div>
                              </>
                              // <RadioGroup layout="horizontal-dense" value={isCustomClient} onUpdate={({ value }) => setIsCustomClient(value)}>
                              //   <Radio label="Default" value={false} />
                              //   <Radio label="Custom" value={true} />
                              // </RadioGroup>
                            }
                          />

                          <DataList.Item
                            label="Full Name"
                            value={
                              isCustomClient ? (
                                <Input key={`client-${client?.id}`} className="w-full" model="billed_to_name" defaultValue={client?.name} />
                              ) : (
                                client?.name
                              )
                            }
                          />

                          <DataList.Item
                            label="Email"
                            value={
                              isCustomClient ? (
                                <Input
                                  key={`client-${client?.id}`}
                                  className="w-full"
                                  model="billed_to_email"
                                  defaultValue={client?.email}
                                />
                              ) : (
                                client?.email
                              )
                            }
                          />

                          <DataList.Item
                            label="Phone"
                            value={
                              isCustomClient ? (
                                <Input
                                  key={`client-${client?.id}`}
                                  className="w-full"
                                  model="billed_to_phone_no"
                                  defaultValue={client?.phone_no}
                                />
                              ) : (
                                client?.phone_no
                              )
                            }
                          />

                          <DataList.Item
                            label="Address"
                            value={
                              isCustomClient ? (
                                <AddressSelectorInput className="w-full" />
                              ) : (
                                size(client?.address) > 0 && (
                                  <div>
                                    <div>{client.address.address_line_1}</div>
                                    <div>
                                      {compact([
                                        client.address.address_line_2,
                                        client.address.city,
                                        client.address.state,
                                        client.address.zip_code,
                                      ]).join(', ')}
                                    </div>
                                  </div>
                                )
                              )
                            }
                          />
                        </DataList>
                      )}
                    </div>

                    {billToCategory === 'contact' && (
                      <div>
                        <ObjectSelector
                          key={`client-${client?.id}`}
                          label="Contact"
                          icon="contacts"
                          type="client.contacts"
                          value={contact}
                          dependentValue={client?.id}
                          isDisabled={!client?.id}
                          selectTitle={(data: any) => data.name}
                          onUpdate={({ value }) => {
                            setContact(value)
                          }}
                        />

                        {client?.id && contact?.id && (
                          <DataList isCompact centerLabelsY layout="horizontal" labelWidth={140} className="!mt-2">
                            <DataList.Item
                              label="Billing Details"
                              className={isEditable ? '' : '!hidden'}
                              value={
                                <>
                                  <Checkbox
                                    label="Custom Billing Details"
                                    variant="skinny"
                                    value={isCustomContact}
                                    onUpdate={({ value }) => setIsCustomContact(value)}
                                  />

                                  <div className="ml-auto">
                                    <Tooltip
                                      content={
                                        <div>
                                          You can customize the default billing details from the{' '}
                                          <Link
                                            to={`/clients/${client?.id}/contacts/${contact?.id}`}
                                            className="font-[600]"
                                            target="_blank"
                                          >
                                            <Icon icon="contacts" size={12} className="relative top-[-2px]" />
                                            <span className="inline-block mx-1">Contact's Profile</span>
                                            <Glyph glyph="external_link" size={12} color={COLORS.blue} />
                                          </Link>{' '}
                                          page
                                        </div>
                                      }
                                    />
                                  </div>
                                </>

                                // <RadioGroup
                                //   layout="horizontal-dense"
                                //   value={isCustomContact}
                                //   onUpdate={({ value }) => setIsCustomContact(value)}
                                // >
                                //   <Radio label="Default" value={false} />
                                //   <Radio label="Custom" value={true} />
                                // </RadioGroup>
                              }
                            />

                            <DataList.Item
                              label="Full Name"
                              value={isCustomContact ? <Input className="w-full" defaultValue={contact?.name} /> : contact?.name}
                            />

                            <DataList.Item
                              label="Email"
                              value={isCustomContact ? <Input className="w-full" defaultValue={contact?.email} /> : contact?.email}
                            />

                            <DataList.Item
                              label="Phone"
                              value={isCustomContact ? <Input className="w-full" defaultValue={contact?.phone_no} /> : contact?.phone_no}
                            />

                            <DataList.Item
                              label="Address"
                              value={
                                isCustomContact ? (
                                  <AddressSelectorInput className="w-full" />
                                ) : (
                                  size(contact?.address) > 0 && (
                                    <div>
                                      <div>{contact.address.address_line_1}</div>
                                      <div>
                                        {compact([
                                          contact.address.address_line_2,
                                          contact.address.city,
                                          contact.address.state,
                                          contact.address.zip_code,
                                        ]).join(', ')}
                                      </div>
                                    </div>
                                  )
                                )
                              }
                            />
                          </DataList>
                        )}
                      </div>
                    )}

                    <ObjectSelector
                      label="Insurance Provider"
                      icon="bank_building"
                      type="insurance_local_payers"
                      selectTitle={(o) => o.name}
                      onUpdate={({ value }) => {
                        setPayer(value)
                      }}
                    />

                    {payer?.id && (
                      <DataList isCompact>
                        <DataList.Item label="Payer Name" value={payer?.name} />
                        <DataList.Item label="Network Status" value={titleCase(payer?.network_status)} />
                      </DataList>
                    )}
                  </FormSection>
                </SectionCard>

                <SectionCard title="Billed By" className="!p-3">
                  <FormSection maxWidth="100%">
                    <div>
                      <Label isCompact label="Facility" />

                      <div className="flex flex-nowrap items-center">
                        <Avatar src="" initials={tenant?.name} size={20} className="mr-1.5" />
                        {tenant?.name}
                      </div>
                    </div>

                    <DataList isCompact centerLabelsY layout="horizontal" labelWidth={140}>
                      <DataList.Item
                        label="Billing Details"
                        className={isEditable ? '' : '!hidden'}
                        value={
                          <>
                            <Checkbox
                              label="Custom Billing Details"
                              variant="skinny"
                              model="use_custom_billed_by"
                              value={isCustomFacility}
                              onUpdate={({ value }) => setIsCustomFacility(value)}
                            />

                            <div className="ml-auto">
                              <Tooltip
                                content={
                                  <div>
                                    You can customize the default billing address from the{' '}
                                    <Link to={`/settings/company-profile`} className="font-[600]" target="_blank">
                                      <Icon icon="organizations_providers" size={12} className="relative top-[-2px]" />
                                      <span className="inline-block mx-1">Company Settings</span>
                                      <Glyph glyph="external_link" size={12} color={COLORS.blue} />
                                    </Link>
                                    , <br />
                                    page and the default billing contact from the{' '}
                                    <Link to={`/settings/notifications`} className="font-[600]" target="_blank">
                                      <Icon icon="care_level_4" size={12} className="relative top-[-2px]" />
                                      <span className="inline-block mx-1">Notifications</span>
                                      <Glyph glyph="external_link" size={12} color={COLORS.blue} />
                                    </Link>{' '}
                                    page
                                  </div>
                                }
                              />
                            </div>
                          </>
                          // <RadioGroup
                          //   layout="horizontal-dense"
                          //   value={isCustomFacility}
                          //   onUpdate={({ value }) => setIsCustomFacility(value)}
                          // >
                          //   <Radio label="Default" value={false} />
                          //   <Radio label="Custom" value={true} />
                          // </RadioGroup>
                        }
                      />

                      <DataList.Item
                        label="Name"
                        value={
                          isCustomFacility ? <Input className="w-full" model="billed_by_name" defaultValue={tenant?.name} /> : tenant?.name
                        }
                      />

                      <DataList.Item
                        label="Email"
                        value={
                          isCustomFacility ? (
                            <Input className="w-full" model="billed_by_email" defaultValue={tenant?.email} />
                          ) : (
                            tenant?.email
                          )
                        }
                      />

                      <DataList.Item
                        label="Phone"
                        value={
                          isCustomFacility ? (
                            <Input className="w-full" model="billed_by_phone_no" defaultValue={tenant?.phone_no} />
                          ) : (
                            tenant?.phone_no
                          )
                        }
                      />

                      <DataList.Item
                        label="Address"
                        value={
                          isCustomFacility ? (
                            <AddressSelectorInput className="w-full" />
                          ) : (
                            size(tenant?.address) > 0 && (
                              <div>
                                <div>{tenant.address.address_line_1}</div>
                                <div>
                                  {compact([
                                    tenant.address.address_line_2,
                                    tenant.address.city,
                                    tenant.address.state,
                                    tenant.address.zip_code,
                                  ]).join(', ')}
                                </div>
                              </div>
                            )
                          )
                        }
                      />
                    </DataList>
                  </FormSection>
                </SectionCard>
              </div>

              <PageSection className="mt-4">
                <PageSection.Header>
                  <PageSection.Title title="Service Lines" />
                </PageSection.Header>

                <PageSection.Content>
                  {!isEditable && (
                    <div>
                      <Flex gap="1rem" stretchChildrenX>
                        <FinancialCard title="Current Balance" value={500} valueColor="red" />
                        <FinancialCard title="Total Charges" value={1500} valueColor="red" prefix="-" />
                        <FinancialCard title="Total Successful Payments" value={1000} valueColor="green" prefix="+" />
                        <FinancialCard title="Total Write-Off's" value={50} valueColor="paleGreen" prefix="+" />
                      </Flex>
                    </div>
                  )}

                  <div className="grid grid-cols-[100%] grid-rows-[100%] mt-4">
                    {isServiceLinesEmpty ? (
                      <Card>
                        <State
                          isEmpty
                          key="empty-state"
                          title="Service Lines"
                          icon="online_card_payment"
                          emptyDescription="No Service Lines created yet"
                          emptyActions={importAction}
                        />
                      </Card>
                    ) : (
                      <Card>
                        <DataTable
                          minimal
                          asCard={false}
                          canBatchSelect
                          footerHidden
                          key={Object.keys(serviceLines).join('-')}
                          headerAfter={<div className="ml-auto">{importAction}</div>}
                          data={serviceLines}
                          columns={columns}
                          meta={{ count: size(serviceLines) }}
                          title="Service Lines"
                          icon="online_card_payment"
                          renderBatchActions={({ selectedRows, selectNone }: any) => {
                            return (
                              <div>
                                <Flex gap="1rem">
                                  <Button
                                    label="Add Payment"
                                    glyph="add"
                                    type="primary"
                                    size={100}
                                    link={`${match.url}/payments/new`}
                                    // onClick={selectNone}
                                    isDisabled={size(selectedRows) === 0}
                                  />
                                  <Button
                                    label="Add Write-Off"
                                    glyph="add"
                                    type="minimal"
                                    size={100}
                                    // onClick={selectNone}
                                    link={`${match.url}/write-offs/new`}
                                    isDisabled={size(selectedRows) === 0}
                                  />
                                </Flex>
                              </div>
                            )
                          }}
                        />

                        <div className="py-3 border-t border-0 border-solid border-divider">
                          <div className="grid grid-cols-[1fr_160px] py-2 font-[600]">
                            <div className="flex items-center justify-end pr-4">Subtotal:</div>
                            <div>{niceAmount(1500)}</div>
                          </div>

                          <div className="grid grid-cols-[1fr_160px] py-2 font-[600]">
                            <div className="flex items-center justify-end pr-4">Sales Tax:</div>
                            <div>10%</div>
                          </div>

                          <div className="grid grid-cols-[1fr_160px] py-2 font-[600]">
                            <div className="flex items-center justify-end pr-4">Total Tax:</div>
                            <div>{niceAmount(150)}</div>
                          </div>

                          <div className="grid grid-cols-[1fr_160px] py-2 font-[600]">
                            <div className="flex items-center justify-end pr-4 font-[700]">TOTAL:</div>
                            <div>{niceAmount(1650)}</div>
                          </div>
                        </div>
                      </Card>
                    )}
                  </div>
                </PageSection.Content>
              </PageSection>

              <PageSection className="mt-4">
                <PageSection.Header
                  after={<Button label="Add Payment" glyph="add" type="primary" size={200} link={`${match.url}/payments/new`} />}
                >
                  <PageSection.Title title="Payments" />
                </PageSection.Header>

                <PageSection.Content>
                  <InvoicePaymentsDataTable data={PAYMENTS} to={(rowData: any) => `${match.url}/payments/${rowData.id}`} />
                </PageSection.Content>
              </PageSection>

              {/* <PageSection className="mt-4">
                <PageSection.Header
                  after={<Button label="Add Write-Off" glyph="add" type="primary" size={200} link={`${match.url}/write-offs/new`} />}
                >
                  <PageSection.Title title="Write-Off's" />
                </PageSection.Header>

                <PageSection.Content>
                  <InvoiceWriteOffsDataTable data={WRITE_OFFS} to={(rowData: any) => `${match.url}/write-offs/${rowData.id}`} />
                </PageSection.Content>
              </PageSection> */}

              {/* <PageSection className="mt-4">
                <PageSection.Header>
                  <PageSection.Title title="Updates" />
                </PageSection.Header>

                <PageSection.Content>
                  <Card>
                    <State isEmpty title="Updates" icon="online_card_payment" emptyDescription="No updates to display" />
                  </Card>
                </PageSection.Content>
              </PageSection> */}
            </Grid>
          </Form>
        </Overlay.Content>

        <Overlay.Footer>
          {isEditable && (
            <>
              <Button
                label="Save Invoice"
                glyph="check"
                type="primary"
                color="green"
                isLoading={isSaving}
                onClick={save}
                flex="100 1 auto"
              />
              {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
            </>
          )}

          {!isEditable && (
            <>
              <Button
                glyph="edit"
                label="Edit"
                type="default"
                isDisabled={isLoading}
                onClick={edit}
                flex="100 1 auto"
                permission="insurance_invoicing.edit"
              />

              <DeleteDialog
                title="Delete Invoice?"
                message="Are you sure you want to delete this Invoice? This action cannot be undone."
                onYes={deleteRecord}
              >
                <Button
                  glyph="delete"
                  label="Delete"
                  type="default"
                  color="red"
                  isLoading={isDeleting}
                  fullWidth
                  permission="insurance_invoicing.delete"
                />
              </DeleteDialog>
            </>
          )}
        </Overlay.Footer>
      </Overlay>

      <Portal type="z90">
        <AnimatedSwitch className="overlays" animation="animation" timeout={{ enter: 400, exit: 400 }}>
          <Route path={`${match.url}/payments/:payment_id`}>
            {({ match: routeMatch }) => (
              <InvoicePaymentOverlay
                dataID={routeMatch?.params.payment_id}
                initialData={PAYMENTS.find((o) => o.id === routeMatch?.params.payment_id)}
                client={null}
              />
            )}
          </Route>

          <Route path={`${match.url}/write-offs/:write_off_id`}>
            {({ match: routeMatch }) => (
              <InvoiceWriteOffOverlay
                dataID={routeMatch?.params.write_off_id}
                initialData={WRITE_OFFS.find((o) => o.id === routeMatch?.params.write_off_id)}
                client={null}
              />
            )}
          </Route>
        </AnimatedSwitch>
      </Portal>
    </>
  )
}

export const InsuranceInvoiceOverlay = withOverlayError(RootInsuranceInvoiceOverlay)

const PAYMENTS = [
  {
    id: 'Oglw6y',
    amount: '123.0',
    author: {
      id: 'wgWOgG',
      avatar: '',
      middle_name: null,
      name: 'Robert Eles',
    },
    category: 'payment',
    charge_category: null,
    client: {
      id: 'vNwNjp2',
      avatar: '',
      name: 'Abbigail Villarreal',
    },
    name: 'Card Payment',
    notes: 'Payment Notes',
    payed_with: 'online',
    payment_reference: '12345678',
    transactioned_at: '2023-11-15T03:34:42-05:00',
    created_at: '2023-11-15T03:34:45-05:00',
    updated_at: '2023-11-15T03:34:45-05:00',
  },
  {
    id: 'B7sJ2x',
    amount: '250.75',
    author: {
      id: 'kL3dZ8',
      avatar: '',
      middle_name: 'James',
      name: 'Emily Clarke',
    },
    category: 'payment',
    charge_category: null,
    client: {
      id: 'u6Hv9z1',
      avatar: '',
      name: 'Marcus Trenton',
    },
    name: 'Online Transfer',
    notes: 'Monthly premium payment',
    payed_with: 'online_apps',
    payment_reference: '87456321',
    transactioned_at: '2023-11-15T03:34:41-05:00',
    created_at: '2023-11-15T03:34:35-05:00',
    updated_at: '2023-11-15T03:34:35-05:00',
  },
  {
    id: 'QzT5vR',
    amount: '500.00',
    author: {
      id: 's9GvD2',
      avatar: '',
      middle_name: null,
      name: 'Alex Johnson',
    },
    category: 'payment',
    charge_category: null,
    client: {
      id: 'p3Rk8s3',
      avatar: '',
      name: 'Linda Gates',
    },
    name: 'Cheque Payment',
    notes: 'Annual insurance payment',
    payed_with: 'card',
    payment_reference: '99887766',
    transactioned_at: '2023-11-15T03:34:55-05:00',
    created_at: '2023-11-15T03:34:46-05:00',
    updated_at: '2023-11-15T03:34:46-05:00',
  },
  {
    id: 'W3pH7n',
    amount: '75.20',
    author: {
      id: 'oL2wH1',
      avatar: '',
      middle_name: 'Anne',
      name: 'Kevin Bright',
    },
    category: 'payment',
    charge_category: null,
    client: {
      id: 'x6Ty2m4',
      avatar: '',
      name: 'Sarah Connor',
    },
    name: 'Direct Debit',
    notes: 'Quarterly health insurance',
    payed_with: 'cash',
    payment_reference: '11223344',
    transactioned_at: '2023-11-15T03:34:41-05:00',
    created_at: '2023-11-15T03:34:47-05:00',
    updated_at: '2023-11-15T03:34:47-05:00',
  },
  {
    id: 'J8kL5d',
    amount: '300.50',
    author: {
      id: 'bD9zC5',
      avatar: '',
      middle_name: null,
      name: 'Daniel Ruiz',
    },
    category: 'payment',
    charge_category: null,
    client: {
      id: 'r5Uj9n5',
      avatar: '',
      name: 'Rebecca Norman',
    },
    name: 'PayPal Payment',
    notes: 'Bi-annual car insurance',
    payed_with: 'check',
    payment_reference: '55667788',
    transactioned_at: '2023-11-15T03:34:37-05:00',
    created_at: '2023-11-15T03:34:44-05:00',
    updated_at: '2023-11-15T03:34:44-05:00',
  },
  {
    id: 'G6vK2b',
    amount: '150.30',
    author: {
      id: 'pH7wM3',
      avatar: '',
      middle_name: 'Marie',
      name: 'Oscar Wilde',
    },
    category: 'payment',
    charge_category: null,
    client: {
      id: 'm8Zk6n6',
      avatar: '',
      name: 'George Orwell',
    },
    name: 'E-Wallet Payment',
    notes: 'Home insurance installment',
    payed_with: 'ach',
    payment_reference: '22334455',
    transactioned_at: '2023-11-15T03:34:43-05:00',
    created_at: '2023-11-15T03:34:36-05:00',
    updated_at: '2023-11-15T03:34:36-05:00',
  },
]

const WRITE_OFFS = [
  {
    id: 'Oglw6y',
    author: {
      id: 'wgWOgG',
      avatar: '',
      middle_name: null,
      name: 'Robert Eles',
    },
    client: {
      id: 'vNwNjp2',
      avatar: '',
      name: 'Abbigail Villarreal',
    },
    name: 'Unrecoverable Debt Write-off',
    amount: 150,
    notes: 'Client insolvency leading to unrecoverable debt write-off.',
    transactioned_at: '2023-11-15T03:34:42-05:00',
    created_at: '2023-11-15T03:34:45-05:00',
    updated_at: '2023-11-15T03:34:45-05:00',
  },
  {
    id: 'B7sJ2x',
    author: {
      id: 'kL3dZ8',
      avatar: '',
      middle_name: 'James',
      name: 'Emily Clarke',
    },
    client: {
      id: 'u6Hv9z1',
      avatar: '',
      name: 'Marcus Trenton',
    },
    name: 'Damage Claim Write-off',
    amount: 200,
    notes: 'Write-off for damaged goods claim, unable to reclaim costs.',
    transactioned_at: '2023-11-15T03:34:41-05:00',
    created_at: '2023-11-15T03:34:35-05:00',
    updated_at: '2023-11-15T03:34:35-05:00',
  },
  {
    id: 'QzT5vR',
    author: {
      id: 's9GvD2',
      avatar: '',
      middle_name: null,
      name: 'Alex Johnson',
    },
    client: {
      id: 'p3Rk8s3',
      avatar: '',
      name: 'Linda Gates',
    },
    name: 'Expired Service Contract Write-off',
    amount: 75,
    notes: 'Service contract expired without renewal, writing off remaining balance.',
    transactioned_at: '2023-11-15T03:34:55-05:00',
    created_at: '2023-11-15T03:34:46-05:00',
    updated_at: '2023-11-15T03:34:46-05:00',
  },
  {
    id: 'W3pH7n',
    author: {
      id: 'oL2wH1',
      avatar: '',
      middle_name: 'Anne',
      name: 'Kevin Bright',
    },
    client: {
      id: 'x6Ty2m4',
      avatar: '',
      name: 'Sarah Connor',
    },
    name: 'Bad Debt Relief Write-off',
    amount: 300,
    notes: 'Bad debt relief for long overdue invoices beyond recovery period.',
    transactioned_at: '2023-11-15T03:34:41-05:00',
    created_at: '2023-11-15T03:34:47-05:00',
    updated_at: '2023-11-15T03:34:47-05:00',
  },
  {
    id: 'J8kL5d',
    author: {
      id: 'bD9zC5',
      avatar: '',
      middle_name: null,
      name: 'Daniel Ruiz',
    },
    client: {
      id: 'r5Uj9n5',
      avatar: '',
      name: 'Rebecca Norman',
    },
    name: 'Charity Donation Write-off',
    amount: 500,
    notes: 'Charitable donation of services, recorded as write-off for tax purposes.',
    transactioned_at: '2023-11-15T03:34:37-05:00',
    created_at: '2023-11-15T03:34:44-05:00',
    updated_at: '2023-11-15T03:34:44-05:00',
  },
]

import React from 'react'

import SmartStatus from '../SmartStatus'

const statuses = {
  pending: {
    label: 'Pending',
    color: 'yellow',
  },
  incoming: {
    label: 'Incoming',
    color: 'green',
  },
  waitlist: {
    label: 'Waitlisted',
    color: 'red',
  },
  planned: {
    label: 'Planned',
    color: 'yellow',
  },
  no_show: {
    label: 'No-show',
    color: 'gray',
  },
  declined: {
    label: 'Declined',
    color: 'red',
  },
  admitted: {
    label: 'Admitted',
    color: 'blue',
  },
  discharged: {
    label: 'Discharged',
    color: 'orange',
  },
  closed: {
    label: 'Closed',
    color: 'red',
  },
}

const Status = ({ ...rest }) => {
  return <SmartStatus statuses={statuses} {...rest} />
}

export default Status

import React from 'react'
import { Global } from '@emotion/react'
import { Link, Route, Redirect, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import clsx from 'clsx'
import produce from 'immer'
import size from 'lodash/size'

import { COLORS, FEATURES } from '../../theme'
import { countWord, getClientLink, usDate } from '../../utils/functions'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Badge from '../../components/Badge'
import Card from '../../components/Card'
import ClaimFlagStatus from './ClaimFlagStatus'
import ClaimStatus from './ClaimStatus'
import ClaimTypeStatus from '../../components/Statuses/ClaimTypeStatus'
import ClientProfileHeader from '../../components/ClientProfileHeader'
import DataList from '../../components/DataList'
import Dialog from '../../components/Dialog'
import Divider from '../../components/Divider'
import Flex from '../../components/Flex'
import Grid from '../../components/Grid'
import NavItem from '../../components/NavItem'
import Overlay from '../../components/Overlay'
import State from '../../components/State'
import Status from '../../components/Status'
import Tabs from '../../components/Tabs'
import Text from '../../components/Typography/Text'
import TreeItem from '../../components/TreeItem'

import { BatchClaimOverlayForm } from './ClaimOverlay/BatchClaimOverlayForm'
import { ClaimOverlayElectronicStatuses } from './ClaimOverlay/ClaimOverlayElectronicStatuses'
import { ClaimOverlayLogs } from './ClaimOverlay/ClaimOverlayLogs'
import { ClaimOverlayNotes } from './ClaimOverlay/ClaimOverlayNotes'
import { ClaimOverlaySummary } from './ClaimOverlay/ClaimOverlaySummary'
import { ClaimOverlayTasks } from './ClaimOverlay/ClaimOverlayTasks'
import { ClientTagSelector } from '../Tags/ClientTagSelector'
import { NavGroupLabel } from '../../components/NavGroup'

const RootBatchClaimBuilderOverlay = (props: any) => {
  const match = useRouteMatch()
  const location: any = useLocation()
  const history: any = useHistory()

  const { isBehave, timezone } = useSettings()

  const [batchData, setBatchData] = React.useState<any | null>(location?.data?.batchData || [])
  const [activeClaimId, setActiveClaimId] = React.useState<string | null>(batchData?.[0]?._id || batchData?.[0]?.id || null)
  const [skippedIds, setSkippedIds] = React.useState<string[]>([])

  const activeClaim = React.useMemo(() => {
    if (!batchData || !activeClaimId) return null

    return batchData.find((o) => o?.id === activeClaimId || o?._id === activeClaimId) || null
  }, [batchData, activeClaimId])

  const isNew = !activeClaim?.id

  const [tab, setTab] = React.useState(isNew ? 'claim_form' : 'claim_info')

  const skipped = React.useMemo(() => batchData.filter((o) => skippedIds.includes(o._id)), [skippedIds, batchData])
  const skippedCount = size(skipped)

  const updateActiveClaim = (data: any) => {
    if (!activeClaimId || size(data) === 0) return

    setBatchData(
      produce(batchData, (draft) => {
        const index = draft.findIndex((o: any) => o.id === activeClaimId || o._id === activeClaimId)

        if (index === -1) return

        for (const key in data) {
          draft[index][key] = data[key]
        }
      }),
    )
  }

  const setActiveClaimStatus = (status: any) => {
    if (!activeClaimId) return

    setBatchData(
      produce(batchData, (draft) => {
        const index = draft.findIndex((o: any) => o.id === activeClaimId || o._id === activeClaimId)

        if (index === -1) return

        draft[index].status = status
      }),
    )
  }

  const toggleSkipped = () => {
    const id = activeClaim?._id

    if (!id) return

    if (skippedIds.includes(id)) {
      setSkippedIds(skippedIds.filter((o) => o !== id))
    } else {
      setSkippedIds([...skippedIds, id])
    }
  }

  const close = () => {
    if (props.back) {
      history.push(props.back)
      return
    }

    if (!!location?.parent) {
      history.push(location.parent)
    } else {
      const rootPath = match.url.substring(0, match.url.lastIndexOf('/')).substring(0, match.url.lastIndexOf('/'))
      history.push(rootPath)
    }
  }

  return (
    <Overlay
      fullheight={!!activeClaim}
      showBackdrop
      onClose={close}
      maxWidth={activeClaim ? 135 : 50}
      position={activeClaim ? 'right' : 'center'}
      closeOnBackdrop={false}
      closeWrapper={(closeElement: any) => {
        if (!activeClaim) return <div onClick={close}>{closeElement}</div>

        return (
          <Dialog
            glyph="delete"
            title="Close Batch Claims?"
            message="You will need to select the clients again to enter the Batch Claims Builder"
            yesColor="red"
            yesLabel="Yes, Close Batch Claims"
            onYes={close}
          >
            {closeElement}
          </Dialog>
        )
      }}
    >
      <Overlay.Header icon={FEATURES.claims.icon} title="Batch Create Claims" />

      <div
        className={clsx(
          'grid grid-rows-[100%] flex-[1_1_auto] overflow-hidden',
          activeClaim ? 'grid-cols-[320px_1fr]' : 'grid-cols-[100%]',
        )}
      >
        {activeClaim && (
          <aside className="border-r border-0 border-solid border-divider shadow-right-hard-2 relative z-[3] overflow-hidden flex flex-col flex-nowrap">
            <div className="p-4 overflow-y-auto flex-[1_1_auto] w-full">
              <NavGroupLabel label="Selected Clients" className="mb-1.5" />

              {batchData.map((claim) => {
                const client = claim?.resident
                const isSkipped = skippedIds.includes(claim?._id)

                if (!client || !!isSkipped) return null

                const isActive = claim.id === activeClaimId || claim._id === activeClaimId
                const isSaved = !!claim?.id

                const lines = claim?.insurance_claim_service_lines_attributes || claim?.insurance_claim_service_lines
                const linesCount = size(lines)

                const periodStart = claim?.covering_from
                const periodEnd = claim?.covering_until

                return (
                  <div>
                    <NavItem
                      showAvatarInitials
                      key={`claim-${claim.id}-${claim._id}-${claim?.updated_at}-${claim?.status}`}
                      after={
                        <div className="w-full pl-10 pt-1 mb-2">
                          <Grid gap="0.35rem" className="text-[0.95rem]">
                            {claim?.identifier && <Text glyph="link" glyphColor={COLORS.text} description={claim?.identifier} />}

                            <Text
                              glyph="table"
                              description={countWord(
                                'Service Lines',
                                size(claim?.insurance_claim_service_lines_attributes || claim?.insurance_claim_service_lines),
                              )}
                            />

                            {(periodStart || periodEnd) && (
                              <Text
                                glyph="date"
                                description={`${periodStart ? usDate(periodStart, timezone) : '…'} → ${
                                  periodEnd ? usDate(periodEnd, timezone) : '…'
                                }`}
                              />
                            )}
                          </Grid>
                        </div>
                      }
                      label={
                        <>
                          <div className="font-[600]">{client.name}</div>
                        </>
                      }
                      avatar={client.avatar}
                      avatarInitials={client.name}
                      isActive={isActive}
                      onClick={() => {
                        setActiveClaimId(claim.id || claim._id)

                        if (!claim?.id) {
                          history.replace(`${match.url}/form`)
                        }
                      }}
                      color={isSaved ? COLORS.green : COLORS.vividBlue}
                      className="!my-1.5 !flex-wrap"
                      aside={
                        isSaved ? (
                          <div className="flex items-center flex-nowrap">
                            <Status small label="Saved" glyph="tick_circle" color="green" className={isActive ? '!bg-transparent' : ''} />
                            <ClaimStatus small status={claim?.status} className="ml-1" />
                          </div>
                        ) : (
                          <Status small label="New" color="vividBlue" className={isActive ? '!bg-transparent' : ''} />
                        )
                      }
                    />
                  </div>
                )
              })}
            </div>

            {skippedCount > 0 && (
              <div className="px-4 pb-2">
                <Divider className="!my-0" />

                <TreeItem
                  isOpen
                  title={
                    <div className="flex items-center justify-center w-full">
                      <span className="flex-[1_1_auto]">Show Skipped</span>
                      <Badge size={16}>{skippedCount}</Badge>
                    </div>
                  }
                  className="!-mx-4"
                  headerClassName="!px-4 !py-3 !text-[red] !text-[0.94rem]"
                  shouldIndent={false}
                >
                  <div className="px-4">
                    {skipped?.map?.((claim) => {
                      const client = claim?.resident

                      const isActive = claim.id === activeClaimId || claim._id === activeClaimId
                      const isSaved = !!claim?.id

                      return (
                        <NavItem
                          showAvatarInitials
                          key={`claim-${claim.id}-${claim._id}-${claim?.updated_at}-${claim?.status}`}
                          label={client.name}
                          // label={
                          //   <>
                          //     <div>{client.name}</div>

                          //     <div className="font-[400] text-[0.94em] text-text-muted">
                          //       {countWord(
                          //         'Service Lines',
                          //         size(claim?.insurance_claim_service_lines_attributes || claim?.insurance_claim_service_lines),
                          //       )}
                          //     </div>
                          //   </>
                          // }
                          avatar={client.avatar}
                          avatarInitials={client.name}
                          isActive={isActive}
                          onClick={() => {
                            setActiveClaimId(claim.id || claim._id)

                            if (!claim?.id) {
                              history.replace(`${match.url}/form`)
                            }
                          }}
                          color={COLORS.textStronglyMuted}
                          className="!my-1"
                          aside={<Status small label="Skipped" color="text" className={isActive ? '!bg-transparent' : ''} />}
                        />
                      )
                    })}
                  </div>
                </TreeItem>
              </div>
            )}
          </aside>
        )}

        <main className="flex flex-col flex-nowrap bg-[#F7F8FB]">
          {activeClaim && (
            <Overlay.SubHeader className="px-3 !py-2 bg-white shadow-hard-2 relative z-[1]">
              {activeClaim?.id && (
                <Flex alignItems="center" gap="0.5rem">
                  <h3>Claim #{activeClaim?.identifier}</h3>

                  {activeClaim?.id && (
                    <>
                      <ClaimTypeStatus status={activeClaim?.category} />
                      <ClaimStatus status={activeClaim?.status} />
                      <ClaimFlagStatus status={activeClaim?.flagged_status} />
                    </>
                  )}
                </Flex>
              )}

              {activeClaim?.resident && (
                <div className="mt-1">
                  <Flex gap="0.5rem" alignItems="center">
                    <Link to={getClientLink(activeClaim?.resident)}>
                      <ClientProfileHeader client={activeClaim?.resident} textClassName="!text-blue-500" />
                    </Link>

                    <div>
                      <ClientTagSelector client={activeClaim?.resident} />
                    </div>
                  </Flex>
                </div>
              )}
            </Overlay.SubHeader>
          )}

          {!activeClaim && (
            <div className="p-4">
              <Card>
                <State isEmpty icon="roster" title="Batch Create Claims" emptyDescription="Select Roster Items to batch create Claims" />
              </Card>
            </div>
          )}

          {activeClaim && (
            <>
              <Tabs activeTab={tab} onChange={setTab}>
                {!isNew && (
                  <Tabs.List css={styles.tabsList}>
                    <Tabs.Item label="Form" icon="web_form" to={`${match.url}/form`} />
                    <Tabs.Item label="Summary" icon="dashboard" to={`${match.url}/summary`} />
                    <Tabs.Item label="Electronic Statuses" icon="intake_forms" to={`${match.url}/statuses`} />
                    <Tabs.Item label="Notes" icon="clinical_assessments" to={`${match.url}/notes`} />
                    <Tabs.Item label="Tasks" icon="tasks" to={`${match.url}/tasks`} />

                    {isBehave && <Tabs.Item label="🧬 Logs" to={`${match.url}/logs`} />}
                  </Tabs.List>
                )}
              </Tabs>

              <Switch>
                <Route path={`${match.path}/form`}>
                  <BatchClaimOverlayForm
                    disableClientEdit
                    key={`claim-${activeClaim?.id}-${activeClaim?._id}-${activeClaim?.updated_at}`}
                    id={activeClaim?.id}
                    initialData={batchData.find((o) => o.id === activeClaimId || o._id === activeClaimId)}
                    onUpdate={updateActiveClaim}
                    setActiveClaimId={setActiveClaimId}
                    setActiveClaimStatus={setActiveClaimStatus}
                    toggleSkipped={toggleSkipped}
                    isSkipped={skippedIds.includes(activeClaim?._id)}
                  />
                </Route>

                <Route path={`${match.path}/summary`}>
                  <ClaimOverlaySummary id={activeClaim?.id || null} />
                </Route>

                <Route path={`${match.path}/statuses`}>
                  <ClaimOverlayElectronicStatuses id={activeClaim?.id || null} />
                </Route>

                <Route path={`${match.path}/notes`}>
                  <ClaimOverlayNotes id={activeClaim?.id || null} />
                </Route>

                <Route path={`${match.path}/tasks`}>
                  <ClaimOverlayTasks id={activeClaim?.id || null} />
                </Route>

                {isBehave && (
                  <Route path={`${match.path}/logs`}>
                    <ClaimOverlayLogs id={activeClaim?.id || null} />
                  </Route>
                )}

                <Redirect from={match.url} to={`${match.url}/form`} />
              </Switch>
            </>
          )}
        </main>
      </div>

      <Global styles={GLOBAL_STYLES} />
    </Overlay>
  )
}

const GLOBAL_STYLES = { '.NotificationsContainer': { top: `7.5rem !important` } }

const styles = {
  wrapper: {
    padding: '1rem',
  },

  tabsList: {
    padding: '0 1rem',
  },

  claimGrid: {
    display: 'grid',
    gridGap: '1rem',
    gridTemplateColumns: '1fr',

    '@media (min-width: 1000px)': {
      gridTemplateColumns: '360px 1fr',
    },

    '@media (min-width: 1500px)': {
      gridTemplateColumns: '360px 1fr 460px',
    },
  },

  gridColumn: {
    display: 'grid',
    gridGap: '1rem',
    gridTemplateColumns: '1fr',

    '@media (min-width: 1000px)': {
      gridColumn: '-1 / 1',
      gridTemplateColumns: '1fr 1fr',
    },

    '@media (min-width: 1500px)': {
      gridTemplateColumns: '1fr',
      gridColumn: '3',
    },
  },
}

export const BatchClaimBuilderOverlay = withOverlayError(RootBatchClaimBuilderOverlay)

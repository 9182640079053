import React from 'react'
import size from 'lodash/size'
import { useParams, useRouteMatch, useHistory, useLocation } from 'react-router-dom'
import pluralize from 'pluralize'

import AccordionAlert from '../../../components/AccordionAlert'
import Alert from '../../../components/Alert'
import ContextShow from '../../../components/Forms/ContextShow'
import Flex from '../../../components/Flex'
import Grid from '../../../components/Grid'
import Radio from '../../../components/Forms/Radio'
import RadioGroup from '../../../components/Forms/RadioGroup'
import Form from '../../../components/Forms/Form'
import Overlay from '../../../components/Overlay'
import Button from '../../../components/Button'
import DeleteDialog from '../../../components/Dialogs/DeleteDialog'
import PageLoader from '../../../components/Loaders/PageLoader'

import Tabs from '../../../components/Tabs'
import PillTab from '../../../components/PillTab'
import PillTabList from '../../../components/PillTabList'
import TabPanels from '../../../components/TabPanels'
import TabPanel from '../../../components/TabPanel'

import ClaimGeneralDetails from '../components/ClaimGeneralDetails'
import ClaimDependent from '../components/ClaimDependent'
import ClaimDetails from '../components/ClaimDetails'
import ClaimInstitutionalProviders from '../components/ClaimInstitutionalProviders'
import ClaimInstitutionalValueCodes from '../components/ClaimInstitutionalValueCodes'
import ClaimInsurancePolicies from '../components/ClaimInsurancePolicies'
import ClaimPayerDetails from '../components/ClaimPayerDetails'
import ClaimProfessionalProviders from '../components/ClaimProfessionalProviders'
import InstitutionalServiceLines from '../components/InstitutionalServiceLines'
import ProfessionalServiceLines from '../components/ProfessionalServiceLines'
import { ClaimStatuses } from '../components/ClaimStatuses'

import { useSettings } from '../../../hooks/useSettings'
import { useGet, useCreate, useUpdate, useDelete } from '../../../hooks/useNewAPI'
import { get } from '../../../modules/api/requests'
import { countWord } from '../../../utils/functions'

import { Text } from '../../../components/Typography'

import ClaimStatus from '../ClaimStatus'
import Notifications from '../../../modules/notifications'
import { SectionCard } from '../../../components/SectionCard'

import ConfirmDialog from '../../../components/Dialogs/ConfirmDialog'
import Json from '../../../components/Json'

export const BatchClaimOverlayForm = (props: any) => {
  const form = React.useRef(null)

  const { id, initialData, onUpdate, setActiveClaimId, setActiveClaimStatus, isSkipped, toggleSkipped } = props

  const history: any = useHistory()
  const location: any = useLocation()
  const match = useRouteMatch()

  const isNew = id === 'new' || !id

  const [client, setClient] = React.useState(initialData?.resident || null)
  const [isEditable, setIsEditable] = React.useState(isNew)
  const [isDeleted, setIsDeleted] = React.useState(false)

  const [assignee, setAssignee] = React.useState(initialData?.assignee || null)
  const [validationErrors, setValidationErrors] = React.useState([])
  const [validationClearingHouseErrors, setValidationClearingHouseErrors] = React.useState([])

  const clientId = React.useRef(null)

  const { timezone, tenant, isBehave } = useSettings()

  const [isValid, setIsValid] = React.useState(false)
  const [claimCategory, setClaimCategory] = React.useState(initialData?.category || null)
  const [feeSchedule, setFeeSchedule] = React.useState(initialData?.insurance_new_fee_schedule || null)

  const [insuranceProvider, setInsuranceProvider] = React.useState(initialData?.insurance_local_payer || null)

  const { data: claim, isLoading } = useGet({
    name: ['insurance_claims', id],
    url: `/insurance_claims/${id}`,
    options: { enabled: !isNew && !isDeleted },
  })

  const { mutateAsync: createAsync, isLoading: isCreating } = useCreate({
    name: ['insurance_claims', id, 'create'],
    url: `/insurance_claims`,
    invalidate: 'insurance_claims',
    invalidateKeys: ['insurance_roster_items'],
    onSuccess: () => {
      Notifications.send('Claim Created Successfully', 'positive')
    },
  })

  const { mutateAsync: updateAsync, isLoading: isUpdating } = useUpdate({
    name: ['insurance_claims', id, 'update'],
    url: `/insurance_claims/${id}`,
    invalidate: ['insurance_claims'],
    onSuccess: () => {
      Notifications.send('Claim Updated Successfully', 'positive')
    },
  })

  const { mutateAsync: validateClaim, isLoading: isValidating }: any = useUpdate({
    name: ['insurance_claims', id, 'validate'],
    url: `/insurance_claims/${claim?.id}/validate`,
    invalidate: ['insurance_claims'],
    invalidateKeys: ['insurance_claims'],
    onSuccess: () => {
      Notifications.send('Validation Check Ran Successfully', 'positive')
    },
    onError: (error: any) => {
      setValidationErrors(error?.data?.errors)
    },
  })

  const { mutateAsync: submitClaim, isLoading: isSubmitting }: any = useUpdate({
    name: ['insurance_claims', id, 'submit'],
    url: `/insurance_claims/${claim?.id}/submit`,
    invalidate: ['insurance_claims'],
    invalidateKeys: ['insurance_claims'],
    onSuccess: () => {
      Notifications.send('Submission Ran Successfully', 'positive')
    },
  })

  const { mutateAsync: devValidateClaim, isLoading: isDevValidating }: any = useUpdate({
    name: ['insurance_claims', id, 'validate'],
    url: `/insurance_claims/${claim?.id}/dev_validate`,
    invalidate: ['insurance_claims'],
    invalidateKeys: ['insurance_claims'],
    onSuccess: () => {
      Notifications.send('Validation Check Ran Successfully', 'positive')
    },
    onError: (error: any) => {
      setValidationErrors(error?.data?.errors)
    },
  })

  const { mutateAsync: devSubmitClaim, isLoading: isDevSubmitting }: any = useUpdate({
    name: ['insurance_claims', id, 'submit'],
    url: `/insurance_claims/${claim?.id}/dev_submit`,
    invalidate: ['insurance_claims'],
    invalidateKeys: ['insurance_claims'],
    onSuccess: () => {
      Notifications.send('Submission Ran Successfully', 'positive')
    },
  })

  const { mutateAsync: deleteAsync, isLoading: isDeleting } = useDelete({
    name: ['insurance_claims', id, 'delete'],
    url: `/insurance_claims/${claim?.id}`,
    invalidate: ['insurance_claims'],
    invalidateKeys: ['insurance_claims'],
    onSuccess: () => {
      setIsDeleted(true)
      close()
    },
  })

  const [primaryInsurance, setPrimaryInsurance] = React.useState(initialData?.primary_insurance)
  const [secondaryInsurance, setSecondaryInsurance] = React.useState(initialData?.secondary_insurance)
  const [tertiaryInsurance, setTertiaryInsurance] = React.useState(initialData?.tertiary_insurance)

  const [insuranceAuthorization, setInsuranceAuthorization] = React.useState(initialData?.insurance_authorization)
  const [treatmentEpisode, setTreatmentEpisode] = React.useState(initialData?.treatment_episode)

  const [admittingDiagnosis, setAdmittingDiagnosis] = React.useState(initialData?.admitting_diagnosis)
  const [principalDiagnosis, setPrincipalDiagnosis] = React.useState(initialData?.principal_diagnosis)

  React.useEffect(() => {
    if (!claim?.status) return

    if (initialData?.status !== claim?.status) {
      setActiveClaimStatus?.(claim?.status)
    }
  }, [claim?.status, initialData?.status])

  React.useEffect(() => {
    if (!claim) return

    // setClient(claim?.resident)

    // if (!primaryInsurance && !!claim?.primary_insurance) setPrimaryInsurance(claim?.primary_insurance)
    // if (!secondaryInsurance && !!claim?.secondary_insurance) setSecondaryInsurance(claim?.secondary_insurance)
    // if (!tertiaryInsurance && !!claim?.tertiary_insurance) setTertiaryInsurance(claim?.tertiary_insurance)
    // if (!insuranceAuthorization && !!claim?.insurance_authorization) setInsuranceAuthorization(claim?.insurance_authorization)
    // if (!treatmentEpisode && !!claim?.treatment_episode) setTreatmentEpisode(claim?.treatment_episode)
    // if (!admittingDiagnosis && !!claim?.admitting_diagnosis) setAdmittingDiagnosis(claim?.admitting_diagnosis)
    // if (!principalDiagnosis && !!claim?.principal_diagnosis) setPrincipalDiagnosis(claim?.principal_diagnosis)

    // set clearing house validation errors
    if (claim.validation && size(claim.validation) > 0) {
      if (Array.isArray(claim.validation)) setValidationClearingHouseErrors(claim.validation)
    }
  }, [claim])

  React.useEffect(() => {
    if (client?.id !== clientId?.current) {
      clientId.current = client?.id
    }
  }, [clientId?.current, client])

  React.useEffect(() => {
    if (isNew) setIsEditable(true)
  }, [isNew])

  const close = () => {
    if (!!location?.parent) {
      history.push(location.parent)
    } else {
      const rootPath = match.url.substring(0, match.url.lastIndexOf('/')).substring(0, match.url.lastIndexOf('/'))
      history.push(rootPath)
    }
  }

  const cancel = () => {
    form.current?.resetForm()
    setIsEditable(false)
  }

  const save = async () => {
    const data = {
      primary_insurance_id: primaryInsurance ? primaryInsurance.id : null,
      secondary_insurance_id: secondaryInsurance ? secondaryInsurance.id : null,
      tertiary_insurance_id: tertiaryInsurance ? tertiaryInsurance.id : null,
      admitting_diagnosis_id: admittingDiagnosis ? admittingDiagnosis.id : null,
      principal_diagnosis_id: principalDiagnosis ? principalDiagnosis.id : null,
      insurance_authorization_id: insuranceAuthorization ? insuranceAuthorization.id : null,
      treatment_episode_id: treatmentEpisode ? treatmentEpisode.id : null,
      ...form.current.getFormValue(),
    }

    if (isNew) {
      const record = await createAsync(data)
      onUpdate?.({
        ...record.data,
        insurance_claim_service_lines_attributes: record.data?.insurance_claim_service_lines,
      })
      setIsEditable(false)

      if (record.data?.id) {
        setActiveClaimId(record.data?.id)
      }
    } else {
      const record = await updateAsync(data)
      onUpdate?.({
        ...record.data,
        insurance_claim_service_lines_attributes: record.data?.insurance_claim_service_lines,
      })
      setIsEditable(false)

      if (record.data?.id) {
        setActiveClaimId(record.data?.id)
      }
    }
  }

  const processClientForExtraData = async (client: any) => {
    if (initialData?._did_view) return

    if (client?.hasOwnProperty('current_primary_insurance')) {
      setPrimaryInsurance(client?.current_primary_insurance)
      setSecondaryInsurance(client?.current_secondary_insurance)
      setTertiaryInsurance(client?.current_tertiary_insurance)

      setInsuranceAuthorization(client?.current_insurance_authorization)
      setTreatmentEpisode(client?.current_treatment_episode)

      setAdmittingDiagnosis(client?.current_admitting_diagnosis || client?.current_primary_diagnosis)
      setPrincipalDiagnosis(client?.current_principal_diagnosis || client?.current_primary_diagnosis)
    } else if (isNew && isEditable) {
      const clientData = await get(`/residents/billable/${client?.id}`)

      setPrimaryInsurance(clientData?.data?.current_primary_insurance)
      setSecondaryInsurance(clientData?.data?.current_secondary_insurance)
      setTertiaryInsurance(clientData?.data?.current_tertiary_insurance)

      setInsuranceAuthorization(clientData?.data?.current_insurance_authorization)
      setTreatmentEpisode(clientData?.data?.current_treatment_episode)

      setAdmittingDiagnosis(clientData?.data?.current_admitting_diagnosis || clientData?.data?.current_primary_diagnosis)
      setPrincipalDiagnosis(clientData?.data?.current_principal_diagnosis || clientData?.data?.current_primary_diagnosis)
    }
  }

  React.useEffect(
    () => {
      onUpdate?.({
        // assignee: assignee || null,
        // fee_schedule: feeSchedule || null,
        // insurance_local_payer: insuranceProvider || null,
        // primary_insurance: primaryInsurance || null,
        // secondary_insurance: secondaryInsurance || null,
        // tertiary_insurance: tertiaryInsurance || null,
        // insurance_authorization: insuranceAuthorization || null,
        // treatment_episode: treatmentEpisode || null,
        // admitting_diagnosis: admittingDiagnosis || null,
        // principal_diagnosis: principalDiagnosis || null,
        _did_view: true,
      })
    },
    [
      // assignee,
      // feeSchedule,
      // insuranceProvider,
      // primaryInsurance,
      // secondaryInsurance,
      // tertiaryInsurance,
      // insuranceAuthorization,
      // treatmentEpisode,
      // admittingDiagnosis,
      // principalDiagnosis,
    ],
  )

  const hasWarnings = size(claim?.warnings) > 0
  const hasValidationErrors = size(validationErrors) > 0
  const hasClearingHouseValidationErrors = size(validationClearingHouseErrors) > 0

  if (isLoading) return <PageLoader message="Loading Claim Data…" />

  const isSaving = isCreating || isUpdating

  return (
    <>
      <Overlay.Content key={id} css={{ zIndex: 0 }}>
        <Form
          isCompact
          useFullModel
          getForm={form}
          initialModel={initialData}
          isEditable={isEditable}
          timezone={timezone}
          onValidationUpdate={setIsValid}
          linked={{ version: 'v2' }}
          maxWidth="100%"
          key={`updated-${claim?.updated_at}`}
          onUpdate={onUpdate}
        >
          <Grid gap="0.6rem" css={styles.wrapper} className={isNew ? '' : '!pt-0'}>
            <Flex alignItems="center" justifyContent="space-between">
              <RadioGroup
                model="category"
                layout="horizontal"
                defaultValue="professional"
                maxWidth="100%"
                withHover={false}
                isEditable={isNew}
                className={`${isNew ? '' : 'is-hidden'} w-[500px]`}
                onUpdate={(state: any) => {
                  setClaimCategory(state.value)
                }}
              >
                <Radio label="Professional Claim (CMS-1500)" value="professional" />
                <Radio label="Institutional Claim (UB-04)" value="institutional" />
              </RadioGroup>

              {/* {isNew && (
                <Alert small contrast glyph="info">
                  <b>Tip: </b>use Tab to navigate the form and Enter to open the selector inputs.
                </Alert>
              )} */}
            </Flex>

            <Grid gap="0.4rem" horizontal templateColumns="1fr 1fr">
              {hasClearingHouseValidationErrors && (
                <AccordionAlert glyph="warning" type="negative" header={countWord('Issues', size(validationClearingHouseErrors))}>
                  The Clearing House reported the following issues on Validation:
                  <ul>
                    {validationClearingHouseErrors?.map((error: any) => (
                      <li>
                        <strong>{error.field}</strong>: {error.description}
                      </li>
                    ))}
                  </ul>
                </AccordionAlert>
              )}

              {hasWarnings && (
                <AccordionAlert glyph="warning" type="warning" header={`${size(claim?.warnings)} Warnings`}>
                  The next items could have a negative impact on getting your Insurance Claim approved:
                  <ul>
                    {claim?.warnings?.map((warning: any) => (
                      <li>{warning.message}</li>
                    ))}
                  </ul>
                </AccordionAlert>
              )}

              {hasValidationErrors && (
                <AccordionAlert glyph="warning" type="negative" header={countWord('Validation Error', size(validationErrors))}>
                  Please fix the next {pluralize('error', size(validationErrors))} and validate again:
                  <ul>
                    {validationErrors?.map((error: any) => (
                      <li>{error}</li>
                    ))}
                  </ul>
                </AccordionAlert>
              )}
            </Grid>

            {claim?.status === 'error_submission' && (
              <Alert type="negative">
                The Clearing House reported the following issues with the submission:
                <ul>
                  {claim?.response?.errors?.map((error: any) => (
                    <li>
                      <strong>{error.field}</strong>: {error.description}
                    </li>
                  ))}
                </ul>
              </Alert>
            )}

            <div>
              <Grid gap="0.4rem">
                <ClaimStatuses statuses={claim?.insurance_claim_statuses} />

                {!isNew && (
                  <SectionCard>
                    <Flex justifyContent="space-between">
                      <Flex gap="0.5rem" horizontal>
                        <Text label="Current Status: " description={<ClaimStatus status={claim?.status} />} />
                      </Flex>

                      <Flex gap="0.5rem" horizontal>
                        <Button
                          size={200}
                          label={claim?.status === 'error_validation' ? 'Revalidate Claim' : 'Validate Claim'}
                          glyph="check"
                          type="default"
                          color="green"
                          flex="1 1 auto"
                          isLoading={isValidating}
                          isDisabled={claim?.status === 'validated'}
                          onClick={async () => {
                            await validateClaim()
                          }}
                        />

                        <Button
                          size={200}
                          label="Submit Claim"
                          glyph="check"
                          type="primary"
                          color="blue"
                          flex="1 1 auto"
                          isLoading={isSubmitting}
                          isDisabled={claim?.status !== 'validated'}
                          onClick={async () => {
                            await submitClaim()
                            close()
                          }}
                        />

                        {isBehave && (
                          <>
                            <Button
                              size={200}
                              label={claim?.status === 'error_validation' ? 'Revalidate Claim (Test Only)' : 'Validate Claim (Test Only)'}
                              glyph="check"
                              type="default"
                              color="green"
                              flex="1 1 auto"
                              isLoading={isDevValidating}
                              isDisabled={claim?.status === 'validated'}
                              onClick={async () => {
                                await devValidateClaim()
                              }}
                            />

                            <Button
                              size={200}
                              label="Submit Claim (Test Only)"
                              glyph="check"
                              type="primary"
                              color="blue"
                              flex="1 1 auto"
                              isLoading={isDevSubmitting}
                              isDisabled={claim?.status !== 'validated'}
                              onClick={async () => {
                                await devSubmitClaim()
                                close()
                              }}
                            />
                          </>
                        )}

                        {/* <Button
                          size={200}
                          label="Create Secondary Claim"
                          glyph="add_circle"
                          type="primary"
                          color="green"
                          flex="1 1 auto"
                          isLoading={isSubmitting}
                          // isDisabled={claim?.status !== 'validated'}
                          onClick={async () => {
                            close()

                            history.push({
                              pathname: `/claims/claims/new/form`,
                              parent: location.pathname,
                              data: {
                                resident: client,
                                insurance_new_fee_schedule: feeSchedule,

                                // service lines
                                category: claim.category,
                                // claim data
                                assignee: claim.assignee,
                                insurance_local_payer: claim.insurance_local_payer,
                                claim_filing_code: claim.claim_filing_code,
                                claim_frequency_code: claim.claim_frequency_code,
                                place_of_service_code: claim.place_of_service_code,
                                bill_type: claim.bill_type,
                                admission_type_code: claim.admission_type_code,
                                admission_source_code: claim.admission_source_code,
                                condition_codes: claim.condition_codes,
                                patient_status_code: claim.patient_status_code,
                                // providers
                                billing_provider: claim.billing_provider,
                                rendering_provider: claim.rendering_provider,
                                supervising_provider: claim.supervising_provider,
                                ordering_provider: claim.ordering_provider,
                                referring_provider: claim.referring_provider,
                                attending_provider: claim.attending_provider,

                                // insurance_claim_service_lines: serviceLines,
                              },
                            })
                          }}
                        /> */}
                      </Flex>
                    </Flex>
                  </SectionCard>
                )}

                <Flex gap="0.5rem" alignItems="normal">
                  <ClaimGeneralDetails includeObject isNew={isNew} claimID={claim?.id} setAssignee={setAssignee} assignee={assignee} />

                  <ClaimDependent
                    disableEdit={props.disableClientEdit}
                    className="!flex-grow-2"
                    client={client}
                    onClientUpdate={(state: any) => {
                      if (!isEditable) return

                      if (!state?.object) {
                        setClient(null)
                        setPrimaryInsurance(null)
                        setSecondaryInsurance(null)
                        setTertiaryInsurance(null)

                        return
                      }

                      setClient(state.object)
                      processClientForExtraData(state.object)
                    }}
                    insurancePolicy={primaryInsurance}
                  />

                  <ClaimInsurancePolicies
                    includeObject
                    className="!flex-grow-4"
                    client={client}
                    onClientUpdate={setClient}
                    primaryInsurance={primaryInsurance}
                    onPrimaryInsuranceUpdate={setPrimaryInsurance}
                    secondaryInsurance={secondaryInsurance}
                    tertiaryInsurance={tertiaryInsurance}
                    onSecondaryInsuranceUpdate={setSecondaryInsurance}
                    onTertiaryInsuranceUpdate={setTertiaryInsurance}
                    onUpdate={onUpdate}
                  />

                  <ClaimPayerDetails includeObject insuranceProvider={insuranceProvider} onInsuranceProviderUpdate={setInsuranceProvider} />
                </Flex>

                <ClaimDetails
                  includeObject
                  client={client}
                  feeSchedule={feeSchedule}
                  onUpdate={setFeeSchedule}
                  insuranceAuthorization={insuranceAuthorization}
                  onInsuranceAuthorizationUpdate={setInsuranceAuthorization}
                  treatmentEpisode={treatmentEpisode}
                  onTreatmentEpisodeUpdate={setTreatmentEpisode}
                  admittingDiagnosis={admittingDiagnosis}
                  onAdmittingDiagnosisUpdate={setAdmittingDiagnosis}
                  principalDiagnosis={principalDiagnosis}
                  onPrincipalDiagnosisUpdate={setPrincipalDiagnosis}
                  onSelectorsUpdate={onUpdate}
                />

                <ContextShow when="category" is="professional">
                  <ClaimProfessionalProviders includeObject tenant={tenant} />
                </ContextShow>

                <ContextShow when="category" is="institutional">
                  <Tabs defaultTab="providers">
                    <PillTabList css={{ marginTop: '0.25rem', marginBottom: '0.5rem' }}>
                      <PillTab label="Providers" name="providers" />
                      <PillTab label="Value Codes" name="value_codes" />
                    </PillTabList>

                    <TabPanels>
                      <TabPanel name="providers">
                        <ClaimInstitutionalProviders includeObject tenant={tenant} />
                      </TabPanel>

                      <TabPanel name="value_codes">
                        <ClaimInstitutionalValueCodes includeObject tenant={tenant} />
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </ContextShow>
              </Grid>
            </div>

            {claimCategory === 'professional' && (
              <ProfessionalServiceLines
                includeObject
                key={`updated-${claim?.updated_at}`}
                value={initialData?.insurance_claim_service_lines_attributes || initialData?.insurance_claim_service_lines}
                model="insurance_claim_service_lines"
                isNew={isNew}
                client={client}
                tenant={tenant}
                feeSchedule={feeSchedule}
              />
            )}

            {claimCategory === 'institutional' && (
              <InstitutionalServiceLines
                includeObject
                key={`updated-${claim?.updated_at}`}
                value={initialData?.insurance_claim_service_lines_attributes || initialData?.insurance_claim_service_lines}
                model="insurance_claim_service_lines"
                isNew={isNew}
                client={client}
                tenant={tenant}
                feeSchedule={feeSchedule}
              />
            )}
          </Grid>
        </Form>

        {/* <Json data={initialData} /> */}
      </Overlay.Content>

      <Overlay.Footer className="!px-2">
        {isEditable && (
          <Flex stretchChildrenX gap={8}>
            {claim?.status === 'validated' ? (
              <ConfirmDialog
                title="Save Changes?"
                message="Are you sure you want to save these changes? The Claim Status will be changed to Draft and you will be required to Re-Validate."
                glyph="check"
                yesColor="green"
                yesLabel="Yes, Save Changes"
                onYes={save}
                isLoading={isSaving}
              >
                <Button
                  label="Save"
                  glyph="check"
                  type="primary"
                  color="green"
                  isLoading={isSaving}
                  flex="3 1 auto"
                  permission="insurance_claims.create"
                />
              </ConfirmDialog>
            ) : (
              <Button
                label="Save"
                glyph="check"
                type="primary"
                color="green"
                isLoading={isSaving}
                onClick={save}
                flex="3 1 auto"
                permission="insurance_claims.create"
              />
            )}

            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} flex="0 1 auto" />}

            {isSkipped ? (
              <Button
                key="unskip"
                label="Un-skip…"
                type="default"
                display="inline-flex"
                glyph="undo"
                color="text"
                size={300}
                className="!mx-auto"
                flex="0 0 140px"
                onClick={() => toggleSkipped()}
              />
            ) : (
              <Button
                key="skip"
                label="Skip…"
                type="default"
                display="inline-flex"
                glyph="redo"
                color="text"
                size={300}
                className="!mx-auto"
                flex="0 0 140px"
                onClick={() => toggleSkipped()}
              />
            )}
          </Flex>
        )}

        {!isEditable && (
          <Flex gap={8}>
            <Button
              glyph="edit"
              label="Edit"
              type="default"
              isDisabled={isSaving}
              onClick={() => setIsEditable(true)}
              flex="1 1 auto"
              permission="insurance_claims.edit"
            />

            {/* <DeleteDialog
              title="Delete Claim?"
              message="Are you sure you want to delete this Claim? This action cannot be undone."
              onYes={deleteAsync}
              permission="insurance_claims.delete"
            >
              <Button
                fullWidth
                glyph="delete"
                label="Delete"
                type="default"
                color="red"
                isLoading={isDeleting}
                css={{ minWidth: 250 }}
                permission="insurance_claims.delete"
              />
            </DeleteDialog> */}
          </Flex>
        )}
      </Overlay.Footer>
    </>
  )
}

const styles = {
  wrapper: {
    padding: '1rem',
  },

  tabsList: {
    padding: '0 1rem',
  },

  claimGrid: {
    display: 'grid',
    gridGap: '1rem',
    gridTemplateColumns: '1fr',

    '@media (min-width: 1000px)': {
      gridTemplateColumns: '360px 1fr',
    },

    '@media (min-width: 1500px)': {
      gridTemplateColumns: '360px 1fr 460px',
    },
  },

  gridColumn: {
    display: 'grid',
    gridGap: '1rem',
    gridTemplateColumns: '1fr',

    '@media (min-width: 1000px)': {
      gridColumn: '-1 / 1',
      gridTemplateColumns: '1fr 1fr',
    },

    '@media (min-width: 1500px)': {
      gridTemplateColumns: '1fr',
      gridColumn: '3',
    },
  },
}

import React from 'react'
import { useParams } from 'react-router-dom'

import { APP_URLS } from '../MyAccount/utils'
import { useGet } from '../../hooks/useNewAPI'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import AddressInput from '../../components/Forms/AddressInput'
import Button from '../../components/Button'
import Card from '../../components/Card'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Icon from '../../components/Icon'
import Input from '../../components/Forms/Input'
import Label from '../../components/Label'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Overlay from '../../components/Overlay'
import OverlaySelector from '../../components/Forms/Selectors/OverlaySelector/OverlaySelector'
import PageSection from '../../components/PageSection/PageSection'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import SummonOverlay from '../../components/SummonOverlay'
import Tabs from '../../components/Tabs'

import { ExportPDFButton } from '../../components/Buttons/ExportPDFButton'
import { InvoiceDetailsInputs } from '../InvoiceElements/InvoiceDetailsInputs'
import { InvoiceLinesAmounts } from '../InvoiceElements/InvoiceLinesAmounts'
import { InvoiceSettingsOverlay } from '../InvoiceElements/InvoiceSettingsOverlay'
import { InvoiceStatus } from '../Invoices/InvoiceStatus'
import { NotificationLogsDataTable } from '../Notifications/NotificationLogsDataTable'
import { PaymentMethod, PaymentMethodIcon } from '../BillingElements/PaymentMethodElements'
import { SectionCard } from '../../components/SectionCard'

import { ClientInvoiceLinesEditTable } from './ClientInvoiceLinesEditTable'
import { ClientInvoiceLinesTable } from './ClientInvoiceLinesTable'
import { ClientInvoicePayOverlay } from './ClientInvoicePayOverlay'
import { ClientInvoiceShareOverlay } from './ClientInvoiceShareOverlay'
import { ClientInvoiceShareStatus } from './ClientInvoiceShareStatus'

const RootClientInvoiceOverlay = (props: any) => {
  const overlayProps = useOverlay({
    name: 'invoices',
    endpoint: props.isPublic ? '/apps/invoices' : '/invoices',
    options: props,
  })

  const params: any = useParams()
  const clientId = params?.resource_id

  const [shareOverlayOpen, setShareOverlayOpen] = React.useState(false)

  const { data: client }: any = useGet({
    name: ['client', clientId],
    url: `/residents/${clientId}`,
    options: { enabled: !!clientId },
  })

  // 🚧 to uncomment:
  // if (overlayProps.isOverlayLoading || !client) {
  //   return <OverlayLoader position="right" maxWidth={120} />
  // }

  return (
    <>
      <NewInvoiceOverlay
        {...overlayProps}
        clientId={clientId}
        client={client}
        setShareOverlayOpen={setShareOverlayOpen}
        // 🚧 to update:
        initialModel={overlayProps.isNew ? {} : TEMP_DATA}
        data={overlayProps.isNew ? {} : TEMP_DATA}
      />

      {!overlayProps.isNew && (
        <SummonOverlay
          isOpen={shareOverlayOpen}
          onClose={() => {
            setShareOverlayOpen(false)
          }}
          overlay={<ClientInvoiceShareOverlay dataID={overlayProps?.id} />}
        />
      )}
    </>
  )
}

const NewInvoiceOverlay = (props: any) => {
  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    id,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isSaving,
    onValidationUpdate,
    saveWithData,
    //
    client,
    clientId,
    setShareOverlayOpen,
  } = props

  const [tab, setTab] = React.useState('invoice_details')
  const [invoiceLines, setInvoiceLines]: any = React.useState(data?.invoice_lines || [])
  const [deletedInvoiceLines, setDeletedInvoiceLines]: any = React.useState([])

  const { tenant, user, isBehave, timezone, isEHRApp, isHQApp } = useSettings()

  const [notificationConfig, setNotificationConfig] = React.useState(data?.notification_config || null)
  const [paymentMethod, setPaymentMethod] = React.useState(data?.auto_collection_payment_method || null)

  const [isPaymentOverlayOpen, setIsPaymentOverlayOpen] = React.useState(false)

  const canEditInvoiceLines = isBehave && data?.status !== 'paid' && data?.status !== 'processing'

  const save = async () => {
    const formData = form.current.getFormValue()

    close()
    return

    const invoice_lines_attributes: any = []

    for (let i = 0; i < invoiceLines.length; i++) {
      const line = invoiceLines[i]

      invoice_lines_attributes.push({ ...line, order: i })
    }

    for (const line of deletedInvoiceLines) {
      invoice_lines_attributes.push(line)
    }

    try {
      const result = await saveWithData({
        ...formData,
        facility_id: tenant?.id,
        ...(canEditInvoiceLines && { invoice_lines_attributes }),
        ...(!data?.charged_payment_method && { auto_collection_payment_method_id: paymentMethod?.id || null }),
      })

      if (result.invoice_lines) {
        setInvoiceLines(result.invoice_lines)
      }

      setDeletedInvoiceLines([])
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Overlay onClose={close} position="right" maxWidth={120} showBackdrop={isEditable} closeOnEscape={false}>
      <Overlay.Header
        title="Invoice"
        icon="invoices"
        titleAside={
          <>
            <ClientInvoiceShareStatus status="private" />

            {!isNew && (
              <div>
                <Button
                  label={data?.use_public_sharing ? 'Share Link' : 'Get Share Link'}
                  glyph="share"
                  type="link"
                  size={200}
                  onClick={() => {
                    setShareOverlayOpen(true)
                  }}
                />
              </div>
            )}

            {data?.external_id && (
              <Button
                useGlyphForTarget
                label="Open Public Invoice"
                glyph="link"
                target="_blank"
                className="!ml-3"
                size={100}
                href={`${APP_URLS.mini_apps}/invoices/${data.external_id}`}
              />
            )}

            {data?.hosted_receipt_url && (
              <Button
                useGlyphForTarget
                label="Open Public Receipt"
                glyph="link"
                target="_blank"
                className="!ml-3"
                size={100}
                href={data.hosted_receipt_url}
              />
            )}

            {!isEditable && <ExportPDFButton url={`/invoices/${id}/pdf`} tenant="public" />}
          </>
        }
      />

      <Overlay.SubHeader className="!py-0 relative z-[3]">
        <Tabs activeTab={tab} onChange={setTab}>
          <Tabs.List>
            <Tabs.Item label="Invoice Details" name="invoice_details" icon="invoices" />
            <Tabs.Item label="Notification Logs 🚧" name="notification_logs" icon="notification_logs" />
          </Tabs.List>
        </Tabs>
      </Overlay.SubHeader>

      <Overlay.Content className={tab === 'invoice_details' ? 'block' : 'hidden'}>
        <Form
          isCompact
          getForm={form}
          initialModel={{
            ...initialModel,
            ...(isNew && {
              billed_to_name: client?.name,
              billed_to_email: client?.email,
              billed_to_phone_no: client?.phone_no,
              billed_to_address: client?.address,

              billed_by_company_name: tenant?.name,
              billed_by_name: user?.name,
              billed_by_email: tenant?.email,
              billed_by_phone_no: tenant?.phone_no,
              billed_by_address: tenant?.address,
            }),
          }}
          isEditable={isEditable}
          timezone={timezone}
          onValidationUpdate={onValidationUpdate}
          key={`updated-${data?.updated_at}`}
        >
          <FormSection maxWidth="100%" className="px-5 pt-4 pb-12">
            {data?.id && (
              <Card className="flex items-center justify-between px-3 py-1">
                <div className="flex items-center">
                  {data.status && <InvoiceStatus status={data.status} className="mr-2" />}
                  {data.identifier && <div className="font-[600] mr-2">{data.identifier}</div>}
                  {data.invoice_number && <div className="text-text-muted mr-2">{data.invoice_number}</div>}
                </div>

                <Flex gap="0.75rem">
                  {isBehave && data?.id && !isEditable && (
                    <SummonOverlay overlay={<InvoiceSettingsOverlay dataID={data.id} />}>
                      <Button label="Invoice Settings" glyph="settings" type="minimal" size={200} />
                    </SummonOverlay>
                  )}

                  <SummonOverlay
                    isOpen={isPaymentOverlayOpen}
                    onClose={() => {
                      setIsPaymentOverlayOpen(false)
                    }}
                    overlay={<ClientInvoicePayOverlay dataID={data.id} paymentMethodId={paymentMethod?.id} />}
                  />

                  {!isEditable && data?.status !== 'paid' && data?.status !== 'processing' && data?.calculated_amounts?.total > 0 && (
                    <Button
                      label="Pay Invoice"
                      glyph="tick_circle"
                      type="primary"
                      color="green"
                      size={200}
                      onClick={() => setIsPaymentOverlayOpen(true)}
                    />
                  )}
                </Flex>
              </Card>
            )}

            <PageSection>
              <div className="mt-2 grid gap-5 grid-cols-1 mq1024:grid-cols-[1.5fr_1fr_1fr]">
                <SectionCard title="Details" className="!p-3">
                  <div className="grid gap-4">
                    <InvoiceDetailsInputs />

                    <div className="flex flex-nowrap items-end">
                      <div className="flex-[1_1_auto]">
                        <OverlaySelector
                          label="Notifications Config 🚧"
                          tooltip="Select a configuration to use for sending notifications for this invoice"
                          icon="notification_configs"
                          type="admin.notification_configs"
                          model="notification_config"
                          selectTitle={(config: any) => config.name}
                          selectGraphic={() => <Icon icon="notification_configs" size={20} />}
                          onUpdate={({ object }) => {
                            setNotificationConfig(object)
                          }}
                          labelAfter={
                            isBehave &&
                            notificationConfig?.id && (
                              <div className="pl-2">
                                <Button
                                  label="Open in HQ"
                                  size={100}
                                  href={`${APP_URLS.hq}/notification-configs/${notificationConfig.id}`}
                                  target="_blank"
                                />
                              </div>
                            )
                          }
                        />
                      </div>
                    </div>

                    {isEditable && clientId && !data?.charged_payment_method ? (
                      <ObjectSelector
                        label="Auto-Collection Payment Method"
                        tooltip="When a payment method is added, we will attempt to automatically charge it on the Due Date selected above"
                        value={paymentMethod}
                        onUpdate={({ value }) => setPaymentMethod(value)}
                        icon="online_card_payment"
                        type="client.payment_methods"
                        dependentValue={clientId}
                        key={`facility-${clientId}`}
                        selectTitle={(data: any) => data?.name}
                        selectDescription={(data: any) => data?.reference?.name}
                        selectGraphic={(paymentMethod: any) => <PaymentMethodIcon paymentMethod={paymentMethod} />}
                      />
                    ) : (
                      <div>
                        <Label isCompact label="Auto-Collection Payment Method" />

                        {data?.auto_collection_payment_method ? (
                          <PaymentMethod paymentMethod={data?.auto_collection_payment_method} />
                        ) : (
                          <div>–</div>
                        )}
                      </div>
                    )}

                    {!isNew && (
                      <div>
                        <Label isCompact label="Charged Payment Method" />

                        {data?.charged_payment_method ? <PaymentMethod paymentMethod={data?.charged_payment_method} /> : <div>–</div>}
                      </div>
                    )}

                    <RadioGroup
                      label="Payment Processing Fee"
                      model="pass_fee_to_payer"
                      layout="vertical-dense"
                      isEditable={isEditable && data?.status !== 'paid' && data?.status !== 'processing'}
                    >
                      <Radio label={`${tenant?.name || 'Facility'} pays the fee`} value={false} />
                      <Radio label="Client / Invoice Payer pays the fee" value={true} />
                    </RadioGroup>
                  </div>
                </SectionCard>

                <SectionCard title="Billed To" className="!p-3">
                  <FormSection maxWidth="100%" className="!gap-1">
                    <div className="grid gap-4">
                      <Input label="Name" model="billed_to_name" />
                      <Input
                        label="Email"
                        model="billed_to_email"
                        validations={{
                          presence: {
                            message: 'Please add an email',
                          },
                        }}
                      />
                      <Input label="Phone" model="billed_to_phone_no" />
                      <AddressInput actionLabel="Find Address" model="billed_to_address" />
                    </div>
                  </FormSection>
                </SectionCard>

                <SectionCard title="Billed By" className="!p-3">
                  <FormSection maxWidth="100%" className="!gap-1">
                    <div className="grid gap-4">
                      <Input label="Company" model="billed_by_company_name" />
                      <Input label="Name" model="billed_by_name" />
                      <Input label="Email" model="billed_by_email" />
                      <Input label="Phone" model="billed_by_phone_no" />
                      <AddressInput actionLabel="Find Address" model="billed_by_address" />
                    </div>
                  </FormSection>
                </SectionCard>
              </div>
            </PageSection>

            <PageSection className="mt-4">
              <PageSection.Header>
                <PageSection.Title title="Invoice Lines" className="!text-[1.5rem]" />
              </PageSection.Header>

              <PageSection.Content className="grid gap-4">
                <Card className="px-4 py-3">
                  {isEditable && canEditInvoiceLines ? (
                    <ClientInvoiceLinesEditTable
                      invoiceLines={invoiceLines}
                      setInvoiceLines={setInvoiceLines}
                      deletedInvoiceLines={deletedInvoiceLines}
                      setDeletedInvoiceLines={setDeletedInvoiceLines}
                    />
                  ) : (
                    <ClientInvoiceLinesTable invoice={data} />
                  )}

                  {data && !isEditable && (
                    <div>
                      <div className="flex justify-end mt-4">
                        <InvoiceLinesAmounts invoice={data} />
                      </div>
                    </div>
                  )}
                </Card>
              </PageSection.Content>
            </PageSection>
          </FormSection>
        </Form>
      </Overlay.Content>

      {tab === 'notification_logs' && (
        <Overlay.Content className="p-4">
          <PageSection>
            <PageSection.Header
              graphic={
                <div className="flex items-center justify-center w-6 h-6">
                  <Icon icon="notification_logs" size={18} />
                </div>
              }
            >
              <PageSection.Title title="Notification Logs" />
            </PageSection.Header>

            <NotificationLogsDataTable data={[]} />
          </PageSection>
        </Overlay.Content>
      )}

      {isBehave && (
        <Overlay.Footer>
          {isEditable && (
            <>
              <Button
                label="Save 🚧"
                glyph="check"
                type="primary"
                color="green"
                isLoading={isSaving}
                onClick={save}
                isDisabled={isInvalid}
                flex="100 1 auto"
              />
              {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
            </>
          )}

          {!isEditable && (
            <>
              <Button glyph="edit" label="Edit Invoice" type="default" isDisabled={isLoading} onClick={edit} flex="100 1 auto" />

              <DeleteDialog
                title="Delete Invoice?"
                message="Are you sure you want to delete this Invoice? This action cannot be undone."
                onYes={deleteRecord}
              >
                <Button glyph="delete" label="Delete" type="default" color="red" isLoading={isDeleting} fullWidth />
              </DeleteDialog>
            </>
          )}
        </Overlay.Footer>
      )}
    </Overlay>
  )
}

export const ClientInvoiceOverlay = withOverlayError(RootClientInvoiceOverlay)

export const TEMP_DATA = {
  identifier: 'DEMO_INV_1234',
  status: 'draft',
  due_date: null,
  period_started_at: null,
  period_ended_at: null,
  description: 'Description',
  instructions: 'Instructions',
  notes: 'Notes',
  notification_config_id: 'Oglw6y',
  pass_fee_to_payer: true,
  billed_to_name: 'Abbigail Villarreal',
  billed_to_email: 'abbigail.villarreal@getnada.com',
  billed_to_phone_no: '948-372-6150',
  state: '',
  billed_by_company_name: 'Behave Health',
  billed_by_name: 'Robert Eles',
  billed_by_email: 'owner@behavehealth.com',
  billed_by_phone_no: '+447468481867',
  billed_to_address: {
    address_line_1: '',
    address_line_2: '',
    city: '',
    state: '',
    zip_code: '',
  },
  billed_by_address: {
    address_line_1: '',
    address_line_2: '',
    city: '',
    state: '',
    zip_code: '',
  },
  invoice_lines: [
    {
      _id: 'f8482c4c-269d-4600-8093-7df431a4e30d',
      quantity: 1,
      service_name: 'Intake Fee',
      service_description: 'Admission/Intake',
      use_custom_service: true,
      service_sku: '',
      unit_price: 150,
      discount_type: null,
      discount_sku: '',
      amount: 150,
    },
    {
      _id: '79a2bb67-9736-4a99-88cc-cf0607230470',
      quantity: 1,
      service_name: 'Rent',
      service_description: 'Housing',
      use_custom_service: true,
      service_sku: '',
      unit_price: 200,
      discount_type: null,
      discount_sku: '',
      amount: 200,
    },
  ],
}

import React from 'react'
import { darken } from 'polished'
import size from 'lodash/size'

import { COLORS } from '../../theme'
import { useSettings } from '../../hooks/useSettings'

import Dropdown from '../../components/Dropdown'
import DropdownItem from '../../components/DropdownItem'
import Flex from '../../components/Flex'
import Glyph from '../../components/Glyph'
import Loader from '../../components/Loader'
import Tooltip from '../../components/Tooltip'
import Status from '../../components/Status'
import SmartStatus from '../../components/SmartStatus'

export const NARRFormStatus = ({ value, statuses }) => {
  const [status, setStatus] = React.useState('draft')

  return (
    <Dropdown
      position="bottom-start"
      minWidth={200}
      maxWidth={250}
      trigger={
        <div>
          <SmartStatus
            inline
            small
            status={status}
            statuses={statuses}
            after={<Glyph glyph="triangle_down" color="text" size={10} css={styles.glyph} />}
          />
        </div>
      }
    >
      {Object.keys(statuses).map((statusKey) => {
        return (
          <DropdownItem
            key={statusKey}
            label={statuses[statusKey]?.label}
            isActive={status === statusKey}
            css={styles.dropdownItem}
            onClick={() => setStatus(statusKey)}
            glyphSize={16}
            className="w-full [&>*]:flex-[1_1_auto] [&.is-disabled_.button-label]!opacity-80"
          />
        )
      })}
    </Dropdown>
  )
}

const styles = {
  triggerLabel: {
    fontWeight: 600,
    marginRight: '0.5rem',
    display: 'inline-flex',
    whiteSpace: 'nowrap',
  },

  glyph: {
    marginRight: '0.5rem',
  },

  dropdownItem: {
    minHeight: 32,
  },

  loader: {
    marginLeft: '0.3rem',
  },
}

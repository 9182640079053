import { FEATURES } from '../../theme'

export const GENERAL_FEATURES = [
  { model: 'onboarding', ...FEATURES['onboarding_checklist'], title: 'Onboarding' },
  { model: 'admissions', ...FEATURES['admissions'] },
  { model: 'clients', ...FEATURES['clients'] },
  { model: 'alumni', ...FEATURES['alumni'] },
  { model: 'staff', ...FEATURES['employees'] },
  { model: 'places', ...FEATURES['places'] },
  { model: 'properties', ...FEATURES['properties'] },
  { model: 'bed_management', ...FEATURES['bed_management'] },
  { model: 'client_journey', ...FEATURES['client_journey'], title: 'Client Workflows' },
  { model: 'staff_journey', ...FEATURES['staff_journey'], title: 'Staff Timeline' },
  { model: 'staff_credentials', ...FEATURES['staff_credentials'], title: 'Staff Credentials' },
  { model: 'activity_timeline', ...FEATURES['billed_by_behave'], title: 'Activity Timeline' },
  { model: 'short_links', ...FEATURES['short_links'] },
  { model: 'assessments_builder', ...FEATURES['assessments_builder'] },
  { model: 'discharges_builder', ...FEATURES['discharges_builder'] },
  { model: 'forms_builder', ...FEATURES['forms_builder'] },
  { model: 'forms_builder_pdf_export', ...FEATURES['forms_builder_pdf_export'] },
  { model: 'form_submissions', ...FEATURES['form_submissions'] },
  { model: 'form_submissions_pdf_export', ...FEATURES['form_submissions_pdf_export'] },
  { model: 'variables', ...FEATURES['variables'] },
  { model: 'client_tracks', ...FEATURES['client_tracks'] },
  { model: 'client_workflows', ...FEATURES['client_workflows'] },
  { model: 'client_workflows_message_templates', ...FEATURES['client_workflows_message_templates'] },
  { model: 'team_notes', ...FEATURES['team_notes'] },
  { model: 'data_export', ...FEATURES['billed_by_behave'], title: 'Data Export' },
  { model: 'dictation', glyph: 'microphone', title: 'Dictation' },
  { model: 'voice_memos', icon: 'microphone', title: 'Voice Memos' },
  { model: 'behave_ai', icon: 'behave_ai', title: 'Behave AI' },
]

export const ERP_FEATURES = [
  { model: 'todos', ...FEATURES['todos'] },
  { model: 'todo_templates', ...FEATURES['todo_templates'] },
  { model: 'calendar', ...FEATURES['calendar'] },
  { model: 'calendar_templates', ...FEATURES['calendar_templates'] },
  { model: 'live_reports', ...FEATURES['live_reports'], title: 'Live Reports' },
  { model: 'files', ...FEATURES['files'] },
  { model: 'excel_reports', ...FEATURES['live_reports'], title: 'Excel Reports' },
  { model: 'offices', ...FEATURES['offices'] },
  { model: 'organizations', ...FEATURES['organizations'] },
  { model: 'organization_agreements', ...FEATURES['agreements'], title: 'Organization Agreements' },
  { model: 'organization_agreement_templates', ...FEATURES['agreements'], title: 'Organization Agreement Templates' },
  { model: 'staff_agreements', ...FEATURES['agreements'], title: 'Staff Agreements' },
  { model: 'staff_agreement_templates', ...FEATURES['agreements'], title: 'Staff Agreement Templates' },

  { is_dev: true, model: 'budget_expense_ledger', ...FEATURES['behave'], title: 'Budget Expense Ledger' },
  { is_dev: true, model: 'staff_time_tracking', ...FEATURES['behave'], title: 'Staff Time Tracking' },
]

export const CONTACTS_FEATURES = [
  { model: 'contacts', ...FEATURES['contacts'] },
  { model: 'communication_logs', ...FEATURES['communications'] },

  { is_dev: true, model: 'zoom_phone', ...FEATURES['behave'], title: 'Zoom Phone Connect' },
]

export const CHAT_FEATURES = [
  { model: 'staff_chat', ...FEATURES['staff_chat'] },
  { model: 'staff_message_blasts', ...FEATURES['staff_chat'], title: 'Staff Message Blasts' },
  { model: 'clients_chat', ...FEATURES['clients_chat'] },
  { model: 'clients_message_blasts', ...FEATURES['clients_chat'], title: 'Clients Message Blasts' },

  { is_dev: true, model: 'admissions_chat', ...FEATURES['behave'], title: 'Admissions Chat' },
  { is_dev: true, model: 'organizations_chat', ...FEATURES['behave'], title: 'Organizations Chat' },
]

export const CARE_COORDINATION_FEATURES = [
  { model: 'risk_alerts', glyph: 'warning', color: 'red', title: 'Risk Alerts' },
  { model: 'custom_application_form', ...FEATURES['admissions'], title: 'Custom Application Form' },
  { model: 'discharges', ...FEATURES['discharges'] },
  { model: 'client_agreements', ...FEATURES['client_agreements'] },
  { model: 'client_agreement_templates', ...FEATURES['agreement_templates'], title: 'Client Agreement Templates' },

  { model: 'programs', ...FEATURES['programs'] },
  { model: 'care_team', ...FEATURES['care_team'] },
  { model: 'location_gps_check_in', ...FEATURES['location_checkin'] },
  { model: 'manual_location_gps_check_in', ...FEATURES['location_checkin'], title: 'Manual Location Check-In' },
  { model: 'attendance', ...FEATURES['attendance'], title: 'Attendance' },
  { model: 'telehealth', ...FEATURES['telehealth'], title: 'Telehealth' },

  { is_dev: true, model: 'scheduler', ...FEATURES['behave'], title: 'Scheduler' },
  { is_dev: true, model: 'organization_portal', ...FEATURES['behave'], title: 'Organization Portal' },
]

export const MARKETING_AND_ADMISSIONS_FEATURES = [
  { is_dev: true, model: 'marketing_analytics', ...FEATURES['behave'], title: 'Analytics' },
  { is_dev: true, model: 'web_analytics_basic', ...FEATURES['behave'], title: 'Web Analytics Basic' },
  { is_dev: true, model: 'web_analytics_pro', ...FEATURES['behave'], title: 'Web Analytics Pro' },
  { is_dev: true, model: 'organization_portal', ...FEATURES['behave'], title: 'Organization Portal' },
]

export const RCM_FEATURES = [
  { model: 'rcm', ...FEATURES['financials'], title: 'RCM' },
  { model: 'services_book', ...FEATURES['financials'], title: 'Client Services' },
  { model: 'organization_services', ...FEATURES['financials'], title: 'Organization Services' },
  { model: 'financials', ...FEATURES['financials'] },
  { model: 'organization_financials', ...FEATURES['financials'], title: 'Organization Financials' },
  { model: 'online_payments_collection_with_stripe', ...FEATURES['online_payments'], title: 'Online Payments – Cards (Stripe)' },
  { model: 'online_payments_stripe_ach', ...FEATURES['online_payments'], title: 'Online Payments - ACH' },
  { model: 'financials_pass_rcm_fees', ...FEATURES['online_payments'], title: 'Financials - Pass Client RCM Fees' },
  { model: 'finance_hub', ...FEATURES['insurance'], title: 'Finance Hub' },
  { model: 'accounting', ...FEATURES['accounting'], title: 'Accounting' },

  { is_dev: true, model: 'tax_bot', ...FEATURES['behave'], title: 'Tax Bot' },
]

export const INSURANCE_FEATURES = [
  // Settings
  { model: 'company_credentials', ...FEATURES['company_credentials'], title: 'Providers' },
  { model: 'insurance_services', icon: 'insurance', title: 'Insurance Codes' },
  { model: 'fee_schedules', icon: 'insurance', title: 'Fee Schedules' },

  // Client
  { model: 'insurance_policies', icon: 'insurance', title: 'Policies' },
  { model: 'evobs', icon: 'insurance', title: 'eVOBs' },
  { model: 'full_vobs', icon: 'insurance', title: 'VOBs' },
  { model: 'insurance_authorizations', ...FEATURES['insurance_authorizations'], title: 'Authorizations' },
  { model: 'service_episodes', ...FEATURES['service_episodes'], title: 'Service Episodes' },

  // Menu
  { model: 'insurance_payers', icon: 'financials', title: 'Insurance Payers' },
  { model: 'insurance_reports_files', icon: 'financials', title: 'Reports & Files' },
  { model: 'insurance_claims', icon: 'accounting', title: 'Claims' },
  { model: 'insurance_posting', icon: 'accounting', title: 'Posting' },
  { model: 'insurance_invoicing', icon: 'accounting', title: 'Invoicing' },
  { model: 'insurance_clearinghouse', icon: 'accounting', title: 'Clearinghouse' },

  // Extra
  { model: 'insurance_help_center', icon: 'insurance', title: 'Help Center' },

  // Dev
  { is_dev: true, model: 'revenue_intelligence', ...FEATURES['behave'], title: 'Revenue Intelligence' },
]

export const NOTES_FEATURES = [
  { model: 'rounds', ...FEATURES['rounds'] },
  { model: 'rounds_templates', ...FEATURES['rounds'], title: 'Rounds Templates' },
  { model: 'safety_checks', ...FEATURES['safety_checks'] },
  { model: 'shift_notes', ...FEATURES['housing_notes'] },
  { model: 'peer_notes', ...FEATURES['peer_notes'] },
  { model: 'daily_notes', ...FEATURES['daily_notes'] },
  { model: 'recovery_coaching', ...FEATURES['recovery_coaching'] },

  { model: 'client_custom_notes', ...FEATURES['custom_notes'], title: 'Client Custom Notes' },
  { model: 'client_custom_note_templates', ...FEATURES['custom_note_templates'], title: 'Client Custom Note Templates' },

  { model: 'staff_custom_notes', ...FEATURES['custom_notes'], title: 'Staff Custom Notes' },
  { model: 'staff_custom_note_templates', ...FEATURES['custom_note_templates'], title: 'Staff Custom Note Templates' },

  { model: 'property_custom_notes', ...FEATURES['custom_notes'], title: 'Location Custom Notes' },
  { model: 'property_custom_note_templates', ...FEATURES['custom_note_templates'], title: 'Location Custom Note Templates' },

  { model: 'organization_custom_notes', ...FEATURES['custom_notes'], title: 'Organization Custom Notes' },
  { model: 'organization_custom_note_templates', ...FEATURES['custom_note_templates'], title: 'Organization Custom Note Templates' },

  { model: 'program_custom_notes', ...FEATURES['custom_notes'], title: 'Program Custom Notes' },
  { model: 'program_custom_note_templates', ...FEATURES['custom_note_templates'], title: 'Program Custom Note Templates' },

  { model: 'grievance_notes', ...FEATURES['grievance_notes'], title: 'Grievance Notes' },
  { model: 'grievance_note_templates', ...FEATURES['grievance_notes'], title: 'Grievance Note Templates' },

  // { model: 'admin_hr_custom_notes', ...FEATURES['custom_notes'], title: 'Admin HR Custom Notes' },
  // { model: 'admin_hr_custom_note_templates', ...FEATURES['custom_note_templates'], title: 'Admin HR Custom Note Templates' },
]

export const MEDICAL_FEATURES = [
  { model: 'allergies', ...FEATURES['allergies'], title: 'Allergies' },
  { model: 'test_results', ...FEATURES['test_results'], title: 'Test Results' },
  { model: 'ua_tests', ...FEATURES['test_results'], title: 'UA Tests' },
  { model: 'breathalyzer_tests', ...FEATURES['test_results'], title: 'Breathalyzer Tests' },
  { model: 'communicable_disease_tests', ...FEATURES['test_results'], title: 'Communicable Disease Tests' },
  { model: 'labs', ...FEATURES['test_results'], title: 'Labs' },
  { model: 'vital_signs', ...FEATURES['test_results'], title: 'Vital Signs' },
  { model: 'medications', ...FEATURES['medications'] },
  { model: 'medication_logs', ...FEATURES['medication_logs'] },
  { model: 'med_pass', ...FEATURES['med_pass'] },
  { model: 'medication_destruction_logs', ...FEATURES['medication_logs'], title: 'Medication Destruction Logs' },
  { model: 'medication_incident_logs', ...FEATURES['medication_incidents'], title: 'Medication Incident Logs' },
  { model: 'medical_assessments', ...FEATURES['medical_assessments'] },
  { model: 'nursing', ...FEATURES['nursing'] },
  { model: 'physician_orders', ...FEATURES['physician_orders'] },
  { model: 'progress_notes', ...FEATURES['progress_notes'] },

  { model: 'erx', ...FEATURES['behave'], title: 'e-Prescribing' },
  { is_dev: true, model: 'labs', ...FEATURES['behave'], title: 'Labs, Orders & Results' },
]

export const CLINICAL_FEATURES = [
  { model: 'diagnoses', ...FEATURES['diagnoses'] },
  { model: 'clinical_assessments', ...FEATURES['clinical_assessments'] },
  { model: 'treatment_plans', ...FEATURES['treatment_plans'] },
  { model: 'treatment_plan_templates', ...FEATURES['treatment_plans'], title: 'Treatment Plan Templates' },
  { model: 'treatment_book', ...FEATURES['treatment_plans'], title: 'Treatment Book' },
  { model: 'progress_reviews', ...FEATURES['progress_reviews'] },
  { model: 'clinical_notes', ...FEATURES['clinical_notes'] },
  { model: 'clinical_measures', ...FEATURES['clinical_measurements'] },
  { model: 'outcome_measures', ...FEATURES['outcome_measures'] },
  { model: 'wiley_treatment_plans', ...FEATURES['treatment_plans'], title: 'Wiley Treatment Plans' },
]

export const CLIENT_PORTAL_FEATURES = [
  { model: 'client_portal', ...FEATURES['clients'], title: 'Client Portal' },
  { model: 'client_recovery_coaching', ...FEATURES['recovery_coaching'], title: 'Client Portal Recovery Notes' },
  { model: 'client_portal_form_inbox', ...FEATURES['form_submissions'], title: 'Client Portal Form Inbox' },
  { model: 'client_portal_calendar', ...FEATURES['calendar'], title: 'Client Portal Calendar' },
  { model: 'client_portal_todos', ...FEATURES['todos'], title: "Client Portal To-Do's" },
  { model: 'client_portal_location_checkins', ...FEATURES['location_checkin'], title: "Client Portal Location Check-In's" },
  { model: 'client_portal_contacts', ...FEATURES['contacts'], title: 'Client Portal Contacts' },
  { model: 'client_portal_financials', ...FEATURES['financials'], title: 'Client Portal Financials' },
  { model: 'client_portal_agreements', ...FEATURES['agreements'], title: 'Client Portal Agreements' },
  { model: 'client_portal_discharges', ...FEATURES['discharges'], title: 'Client Portal Discharges' },
  { model: 'client_portal_treatment_plans', ...FEATURES['treatment_plans'], title: 'Client Portal Treatment Plans' },
  { model: 'client_portal_allergies', ...FEATURES['allergies'], title: 'Client Portal Allergies' },
  { model: 'client_portal_medications', ...FEATURES['medications'], title: 'Client Portal Medications' },
  { model: 'client_portal_medication_logs', ...FEATURES['medication_logs'], title: 'Client Portal Medication Logs' },
  { model: 'client_portal_clinical_measures', ...FEATURES['clinical_measurements'], title: 'Client Portal Clinical Measures' },
  { model: 'client_portal_test_results', ...FEATURES['test_results'], title: 'Client Portal Test Results' },
  { model: 'client_portal_custom_notes', ...FEATURES['custom_notes'], title: 'Client Portal Custom Notes' },
  { model: 'client_portal_grievance_notes', ...FEATURES['grievance_notes'], title: 'Client Portal Grievance Notes' },
  { model: 'client_portal_outcome_measures', ...FEATURES['client_outcome_measures'], title: 'Client Portal Outcome Measures' },
  { model: 'client_portal_gratitude_journal', ...FEATURES['gratitude_journal'], title: 'Client Portal Gratitude Journal' },
  { model: 'client_portal_personal_journal', ...FEATURES['personal_journal'], title: 'Client Portal Personal Journal' },
  { model: 'client_portal_personal_inventory', ...FEATURES['personal_inventory'], title: 'Client Portal Personal Inventory' },

  { model: 'client_portal_make_a_payment', icon: 'online_payment', title: 'Client Portal Make a Payment' },
  { model: 'client_portal_form_inbox_self_add', glyph: 'circle_add', title: 'Client Portal Form Inbox Self-Add' },
  { model: 'client_portal_calendar_add', glyph: 'circle_add', title: 'Client Portal Calendar Self-Add' },
  { model: 'client_portal_todo_add', glyph: 'circle_add', title: 'Client Portal To-Do Self-Add' },
  {
    model: 'client_portal_location_check_add',
    glyph: 'circle_add',
    title: 'Client Portal Location Check-In Self-Add',
  },
  { model: 'client_portal_contacts_add', glyph: 'circle_add', title: 'Client Portal Contacts Self-Add' },
  { model: 'client_portal_grievance_notes_add', glyph: 'circle_add', title: 'Client Portal Grievance Notes Self-Add' },
  { model: 'client_portal_payment_method_add', glyph: 'circle_add', title: 'Client Portal Payment Method Self-Add' },
  { model: 'client_portal_allergies_add', glyph: 'circle_add', title: 'Client Portal Allergies Self-Add' },
  { model: 'client_portal_medications_add', glyph: 'circle_add', title: 'Client Portal Medications Self-Add' },
  { model: 'client_portal_medication_logs_add', glyph: 'circle_add', title: 'Client Portal Medication Logs Self-Add' },
  {
    model: 'client_portal_outcome_measures_add',
    glyph: 'circle_add',
    title: 'Client Portal Medication Logs Self-Add',
  },
  {
    model: 'client_portal_gratitude_journal_add',
    glyph: 'circle_add',
    title: 'Client Portal Gratitude Journal Self-Add',
  },
  {
    model: 'client_portal_personal_journal_add',
    glyph: 'circle_add',
    title: 'Client Portal Personal Journal Self-Add',
  },
  {
    model: 'client_portal_personal_journal_add',
    glyph: 'circle_add',
    title: 'Client Portal Personal Inventory Self-Add',
  },
]

export const SECURITY_FEATURES = [
  { model: 'staff_permissions', ...FEATURES['permissions'] },
  { model: 'client_portal_permissions', ...FEATURES['permissions'], title: 'Client Portal Permissions' },
  { model: 'permission_templates', ...FEATURES['permission_templates'] },
  { model: 'staff_ip_restrictions', ...FEATURES['ip_restrictions'] },
  { model: 'staff_clients_access', ...FEATURES['access_lists'] },

  { model: 'pin_based_signature', ...FEATURES['your_settings'], title: 'PIN Signature' },
]

export const SIDEBAR_FEATURES = [
  { model: 'bookmarks', ...FEATURES['bookmarks_manager'], title: 'Bookmarks' },
  { model: 'sticky_notes', icon: 'sticky_notes', title: 'Sticky Notes' },
  { model: 'client_pins', icon: 'pins', title: 'Client Pins' },
  { model: 'quick_text', ...FEATURES['quick_text'], title: 'Quick Text' },
]

export const SYSTEM_FEATURES = [
  { model: 'message_board', icon: 'care_level_4', title: 'Message Board' },
  { model: 'bulk_add', glyph: 'stack', title: 'Bulk Add' },
  { model: 'filters', glyph: 'filter', title: 'Filters' },
  { model: 'tags', ...FEATURES['tags'], title: 'Tags' },

  { is_dev: true, model: 'analytics', ...FEATURES['behave'], title: 'Analytics' },
]

export const BEHAVE_HEALTH_FEATURES = [
  { model: 'behave_billing', ...FEATURES['behave'], title: 'VOB & Billing' },
  { model: 'behave_ur', ...FEATURES['behave'], title: 'Utilization Review' },
  { model: 'bed_dashboard', ...FEATURES['bed_dashboard'] },
  { model: 'bridge_dashboard', ...FEATURES['bridge'] },
  { model: 'user_addon_type', ...FEATURES['behave'], title: 'User Addon Type' },

  { model: 'orgs_forms_profile', ...FEATURES['behave'], title: 'Organizations Forms Profile' },
  { model: 'orgs_forms_send_referral', ...FEATURES['behave'], title: 'Organizations Forms Send Referral' },
  { model: 'orgs_forms_send_message', ...FEATURES['behave'], title: 'Organizations Forms Send Message' },
]

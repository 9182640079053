import React from 'react'
import { NavLink, Navigate, Route, Routes, useParams, useLocation } from 'react-router-dom-v5-compat'

import { useGet } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Button, Grid, Page, Tab, TabList, Tabs, HelpTagIframe } from '@behavehealth/components'
import { ProtectedRouteV6 } from '@behavehealth/components/ProtectedRouteV6'
import { TestResultsOverlayRoutes } from '@behavehealth/constructs/TestResults/TestResultsOverlayRoutes'
import Dropdown from '@behavehealth/components/Dropdown'
import DropdownItem from '@behavehealth/components/DropdownItem'

import BreathalyzerTests from './test_results/breathalyzer_tests'
import CommunicableDiseaseTests from './test_results/communicable_disease_tests'
import Labs from './test_results/labs'
import UATests from './test_results/ua_tests'
import VitalSigns from './test_results/vital_signs'

const pageConfig = {
  feature: 'test_results',
  help: <HelpTagIframe id="test_results" />,
  marketingID: 'test_results',
}

type Props = {
  canCreate: boolean
}

const TestResults = () => {
  return (
    <>
      <TestResultsIndex />
      <TestResultsOverlayRoutes />
    </>
  )
}

const TestResultsIndex: React.FC<Props> = ({ canCreate = true }) => {
  const { pathname } = useLocation()
  const { resource_id }: any = useParams()

  const { data: client }: any = useGet({
    name: ['client', resource_id],
    url: `/residents/${resource_id}`,
  })

  const uaSelected = pathname.includes(`ua-tests`)
  const breathalyzerSelected = pathname.includes(`breathalyzer-tests`)
  const vitalSignsSelected = pathname.includes(`vital-signs`)
  const communicableDiseaseTestsSelected = pathname.includes(`communicable-disease-tests`)
  const labsSelected = pathname.includes(`labs`)

  return (
    <Page
      actions={
        canCreate && (
          <>
            {uaSelected && (
              <Dropdown label="Add UA Tests…" glyph="add" buttonType="primary" permission="test_results.ua_tests.create">
                <DropdownItem
                  as={NavLink}
                  label="Add UA Test"
                  icon="test_results"
                  color="blue"
                  link="ua-tests/new"
                  state={{
                    data: {
                      reference: client,
                    },
                  }}
                />

                <DropdownItem
                  as={NavLink}
                  label="Add Many UA Tests…"
                  glyph="stack"
                  color="blue"
                  featureFlagV2="bulk_add"
                  link="ua-tests/new-batch"
                  state={{
                    data: {
                      reference: client,
                    },
                  }}
                />
              </Dropdown>
            )}

            {breathalyzerSelected && (
              <Dropdown label="Add Breathalyzer Test…" buttonType="primary" permission="test_results.breathalyzer_tests.create">
                <DropdownItem
                  as={NavLink}
                  label="Add Breathalyzer Test"
                  icon="test_results"
                  color="blue"
                  link="breathalyzer-tests/new"
                  state={{
                    data: {
                      resident: client,
                    },
                  }}
                />

                <DropdownItem
                  as={NavLink}
                  label="Add Many Breathalyzer Tests…"
                  glyph="stack"
                  color="blue"
                  featureFlagV2="bulk_add"
                  link="breathalyzer-tests/new-batch"
                  state={{}}
                />
              </Dropdown>
            )}

            {vitalSignsSelected && (
              <Button
                as={NavLink}
                label="Add Vital Signs"
                glyph="add"
                type="primary"
                link="vital-signs/new"
                state={{
                  data: {
                    client: client,
                  },
                }}
                permission="test_results.vital_signs.create"
              />
            )}

            {communicableDiseaseTestsSelected && (
              <Button
                as={NavLink}
                label="Add Communicable Disease Test"
                glyph="add"
                type="primary"
                link="communicable-disease-tests/new"
                state={{
                  data: {
                    client: client,
                  },
                }}
                permission="test_results.communicable_disease_tests.create"
              />
            )}

            {labsSelected && (
              <Button
                as={NavLink}
                label="Add Lab Test"
                glyph="add"
                type="primary"
                link="labs/new"
                state={{
                  data: {
                    client: client,
                  },
                }}
                permission="test_results.labs.create"
              />
            )}
          </>
        )
      }
      {...pageConfig}
    >
      <Grid gap="1rem">
        <Tabs>
          <TabList className="-mt-4">
            <Tab
              as={NavLink}
              showForCommunity
              label="UA Tests"
              to={`ua-tests`}
              permission="test_results.ua_tests.view"
              featureFlagV2="ua_tests"
            />
            <Tab
              as={NavLink}
              showForCommunity
              label="Breathalyzer Tests"
              to={`breathalyzer-tests`}
              permission="test_results.breathalyzer_tests.view"
              featureFlagV2="breathalyzer_tests"
            />
            <Tab
              as={NavLink}
              label="Vital Signs"
              to={`vital-signs`}
              newFeatureFlag="lab_tests_2"
              permission="test_results.vital_signs.view"
              featureFlagV2="vital_signs"
            />
            <Tab
              as={NavLink}
              label="Communicable Disease Tests"
              to={`communicable-disease-tests`}
              newFeatureFlag="lab_tests_2"
              permission="test_results.communicable_disease_tests.view"
              featureFlagV2="communicable_disease_tests"
            />
            <Tab
              as={NavLink}
              label="Labs"
              to={`labs`}
              newFeatureFlag="lab_tests_2"
              permission="test_results.labs.view"
              featureFlagV2="labs"
            />
          </TabList>
        </Tabs>

        <Routes>
          <Route index element={<Navigate to="ua-tests" replace />} />

          <Route
            path="ua-tests/*"
            element={
              <ProtectedRouteV6 permission="test_results.ua_tests.view">
                <UATests />
              </ProtectedRouteV6>
            }
          />
          <Route
            path="breathalyzer-tests/*"
            element={
              <ProtectedRouteV6 permission="test_results.breathalyzer_tests.view">
                <BreathalyzerTests />
              </ProtectedRouteV6>
            }
          />
          <Route
            path="vital-signs/*"
            element={
              <ProtectedRouteV6 permission="test_results.vital_signs.view">
                <VitalSigns />
              </ProtectedRouteV6>
            }
          />
          <Route
            path="communicable-disease-tests/*"
            element={
              <ProtectedRouteV6 permission="test_results.communicable_disease_tests.view">
                <CommunicableDiseaseTests />
              </ProtectedRouteV6>
            }
          />
          <Route
            path="labs/*"
            element={
              <ProtectedRouteV6 permission="test_results.labs.view">
                <Labs />
              </ProtectedRouteV6>
            }
          />
        </Routes>
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(TestResults, pageConfig))

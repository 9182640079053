import React from 'react'

import { LOC } from '../../utils/constants'
import { titleCase } from '../../utils/functions'
import { useSettings } from '../../hooks/useSettings'

import ClientColorPicker from '../../components/ColorPickers/ClientColorPicker'
import Flex from '../../components/Flex'
import IntakeStatus from '../../components/Statuses/IntakeStatus'
import AdmissionQuickView from '../../components/Overlays/quick/AdmissionQuickView'

import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'

import { DataTable } from '../../components/DataTable/DataTable'
import { FILTERS } from '../Filters/config'
import { MainCell } from '../../components/DataTable/cells/MainCell'

export const IntakesReportDataTable = (props: any) => {
  const { to } = props
  const { tenant, isBehave } = useSettings()

  const isEHR = tenant?.category === 'healthcare_facility' || tenant?.category === 'healthcare_practice'

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        id: 'name',
        model: 'data.name',
        width: 260,
        disableHide: true,
        disableSort: false,
        disableSelect: true,
        formatValue: ({ data }: any) => (
          <MainCell
            testKey="main_cell"
            id={data.data.id}
            value={data.data.name}
            avatar={data.data.avatar}
            to={to?.(data.data)}
            quickViewOverlay={<AdmissionQuickView client={data.data} />}
          />
        ),
      },
      {
        title: 'Billed',
        id: 'is_billed_by_behave',
        model: 'data.is_billed_by_behave',
        type: 'boolean',
        permission: isBehave,
        disableSort: false,
      },
      {
        title: 'Treatment Episode Admission Status',
        id: 'admission_status',
        model: 'data.current_admission.status',
        width: 220,
        formatValue: ({ value }: any) => (value ? <IntakeStatus status={value} /> : null),
        disableSort: false,
      },
      {
        title: 'Tags',
        id: 'tags',
        model: 'data.tags',
        type: 'tags',
        featureFlag: 'tags',
        editPermission: 'tags.edit',
        tagSmartCategories: 'accepted',
        disableSort: false,
      },
      {
        title: 'Color',
        id: 'color_code',
        model: 'data.color_code',
        disableSort: false,
        formatValue: ({ data }: any) => {
          if (!data?.data?.color_code) return null

          return (
            <Flex centerX>
              <ClientColorPicker isDisabled clientID={data?.data?.id} color={data.data?.color_code} />
            </Flex>
          )
        },
      },
      {
        title: 'Client ID',
        id: 'behave_id',
        model: 'data.behave_id',
        disableSort: false,
        formatValue: ({ value }: any) => (value ? `#${value}` : null),
      },
      {
        title: 'Phone',
        id: 'phone_no',
        model: 'data.phone_no',
        type: 'phone',
        disableSort: false,
      },
      {
        title: 'Email',
        id: 'email',
        model: 'data.email',
        type: 'email',
        disableSort: false,
      },
      {
        title: 'Sex',
        id: 'sex',
        model: 'data.sex',
        width: 100,
        formatValue: ({ value }: any) => (value ? titleCase(value) : null),
        disableSort: false,
      },
      {
        title: 'Referral In',
        id: 'referral_source',
        // model: 'data.current_admission.referral_source',
        model: 'data.referral_source',
        type: 'profile',
      },
      {
        title: 'Referral In Contact',
        id: 'referral_in_contact',
        // model: 'data.current_admission.referral_in_contact',
        model: 'data.referral_in_contact',
        type: 'profile',
      },
      {
        title: 'Referral In Notes',
        id: 'referral_notes',
        // model: 'data.current_admission.referral_notes',
        model: 'data.referral_notes',
        type: 'rich_text',
      },
      {
        title: 'Referral Out',
        id: 'referral_out',
        // model: 'data.current_admission.referral_out',
        model: 'data.referral_out',
        type: 'profile',
      },
      {
        title: 'Referral Out Contact',
        id: 'referral_out_contact',
        // model: 'data.current_admission.referral_out_contact',
        model: 'data.referral_out_contact',
        type: 'profile',
      },
      {
        title: 'Referral Out Notes',
        id: 'referral_out_notes',
        // model: 'data.current_admission.referral_out_notes',
        model: 'data.referral_out_notes',
        type: 'rich_text',
      },
      {
        title: 'Accepted On',
        id: 'admission_accepted_at',
        model: 'data.current_admission.accepted_at',
        type: 'date_time',
      },
      {
        title: 'Accepted By',
        id: 'admission_accepted_by',
        model: 'data.current_admission.accepted_by',
        type: 'profile',
      },
      {
        title: 'Est. Arrival Date',
        id: 'admission_estimated_arrival',
        model: 'data.current_admission.estimated_arrival',
        type: 'date_time',
      },
      {
        title: 'Est. Discharge Date',
        id: 'admission_estimated_discharge',
        model: 'data.current_admission.estimated_discharge',
        type: 'date_time',
      },
      {
        title: 'First Contact On',
        id: 'first_contact_at',
        model: 'data.first_contact_at',
        type: 'date_time',
      },
      {
        title: 'Last Contacted On',
        id: 'last_contact_at',
        model: 'data.last_contact_at',
        type: 'date_time',
      },
      {
        title: 'Last Contact Notes',
        id: 'last_contact_reason',
        model: 'data.last_contact_reason',
      },
      {
        title: 'Last Contacted By',
        id: 'last_contact_by',
        model: 'data.last_contact_by',
        type: 'profile',
      },
      {
        title: 'Applied On',
        id: 'intake_application_applied_at',
        model: 'data.current_intake_application.applied_at',
        type: 'date_time',
      },
      {
        title: 'Last Name',
        id: 'last_name',
        model: 'data.last_name',
      },
      {
        title: 'Last UA Test At',
        id: 'last_ua_test_at',
        model: 'data.last_ua_test_at',
        type: 'date_time',
      },

      // {
      //   title: 'Name',
      //   model: 'name',
      //   width: 260,
      //   disableHide: true,
      //   // disableSort: true,
      //   disableSelect: true,
      //   formatValue: ({ data }: any) => (
      //     <MainCell
      //       id={data.id}
      //       value={data.name}
      //       avatar={data.avatar}
      //       to={to?.(data)}
      //       quickViewOverlay={<AdmissionQuickView client={data} />}
      //     />
      //   ),
      // },
      // {
      //   title: 'Billed',
      //   model: 'is_billed_by_behave',
      //   type: 'boolean',
      //   permission: isBehave,
      // },
      // {
      //   title: 'Admission Status',
      //   id: 'admission_status',
      //   model: 'current_admission.status',
      //   width: 220,
      //   formatValue: ({ value }: any) => (value ? <IntakeStatus status={value} /> : null),
      //   disableSort: true,
      // },
      // {
      //   title: 'Tags',
      //   model: 'tags',
      //   type: 'tags',
      // },
      // {
      //   title: 'Color',
      //   model: 'color_code',
      //   formatValue: ({ data }: any) => {
      //     if (!data?.color_code) return null

      //     return (
      //       <Flex centerX>
      //         <ClientColorPicker isDisabled clientID={data?.id} color={data.color_code} />
      //       </Flex>
      //     )
      //   },
      // },
      // {
      //   title: 'Client ID',
      //   model: 'behave_id',
      //   formatValue: ({ value }: any) => (value ? `#${value}` : null),
      // },
      // {
      //   title: 'Phone',
      //   model: 'phone_no',
      //   type: 'phone',
      // },
      // {
      //   title: 'Email',
      //   model: 'email',
      //   type: 'email',
      // },
      // {
      //   title: 'Sex',
      //   model: 'sex',
      //   width: 100,
      //   formatValue: ({ value }: any) => (value ? titleCase(value) : null),
      // },
      // {
      //   title: 'Level of Care',
      //   model: 'level_of_care',
      //   formatValue: ({ value }: any) => LOC?.[value] || null,
      //   permission: isEHR,
      // },
      // {
      //   title: 'Referral Source',
      //   model: 'referral_source',
      //   type: 'profile',
      // },
      // {
      //   title: 'Referral Notes',
      //   model: 'referral_notes',
      // },
      // {
      //   title: 'Accepted On',
      //   id: 'admission_accepted_at',
      //   model: 'current_admission.accepted_at',
      //   type: 'date_time',
      // },
      // {
      //   title: 'Accepted By',
      //   id: 'admission_accepted_by',
      //   model: 'current_admission.accepted_by',
      //   type: 'profile',
      // },
      // {
      //   title: 'Est. Arrival Date',
      //   id: 'admission_estimated_arrival',
      //   model: 'current_admission.estimated_arrival',
      //   type: 'date_time',
      // },
      // {
      //   title: 'Est. Discharge Date',
      //   id: 'admission_estimated_discharge',
      //   model: 'current_admission.estimated_discharge',
      //   type: 'date_time',
      // },
      // {
      //   title: 'First Contact On',
      //   model: 'first_contact_at',
      //   type: 'date_time',
      // },
      // {
      //   title: 'Last Contacted On',
      //   model: 'last_contact_at',
      //   type: 'date_time',
      // },
      // {
      //   title: 'Last Contacted By',
      //   model: 'last_contact_by',
      //   type: 'profile',
      // },
      // {
      //   title: 'Applied On',
      //   id: 'intake_application_applied_at',
      //   model: 'current_intake_application.applied_at',
      //   type: 'date_time',
      // },
      // {
      //   title: 'Last Name',
      //   model: 'last_name',
      // },
      // {
      //   title: 'Last UA Test At',
      //   model: 'last_ua_test_at',
      //   type: 'date_time',
      // },
    ]
  }, [to, isEHR, isBehave])

  return (
    <DataTable
      testKey="accepted_applicants_data_table"
      asCard
      title="Accepted Applicants"
      icon="checklist"
      columns={columns}
      filtersConfig={FILTERS.live_reports.accepted}
      headerAfter={
        <ReportRefetchButton
          forceShow={true}
          category="intake"
          invalidate={props.queryKey}
          refetchUrl={'/live_reports/update_live_report?category=intake'}
        />
      }
      {...props}
    />
  )
}

import React from 'react'
import kebabCase from 'lodash/kebabCase'
import { useRouteMatch } from 'react-router-dom'

import { useGet } from '../../hooks/useNewAPI'
import { COLORS } from '../../theme'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Button from '../../components/Button'
import Card from '../../components/Card'
import CardHeader from '../../components/CardHeader'
import CardLink from '../../components/CardLink'
import CardTitle from '../../components/CardTitle'
import Form from '../../components/Forms/Form'
import Glyph from '../../components/Glyph'
import Grid from '../../components/Grid'
import Icon from '../../components/Icon'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Permission from '../../components/Permission'
import Section from '../../components/Section'
import State from '../../components/State'
import Status from '../../components/Status'

import useCopyToClipboard from '../../hooks/useCopyToClipboard'
import { APP_URLS } from '../MyAccount/utils'

const RootClientInvoiceShareOverlay = (props: any) => {
  const match = useRouteMatch()

  const { data, close, initialModel, isFetching, isOverlayLoading, isUpdating, saveWithData } = useOverlay({
    name: 'invoices',
    endpoint: '/invoices',
    invalidate: 'invoices',
    options: props,
    closeOnSave: false,
  })

  const { tenant } = useSettings()

  const { data: client }: any = useGet({
    name: ['client', match.params.resource_id],
    url: `/residents/${match.params.resource_id}`,
  })

  const isPrivate = true // initialModel?.use_public_sharing === false
  const isPublic = false // initialModel?.use_public_sharing === true

  const makePrivate = () => {
    saveWithData({ use_public_sharing: false })
  }

  const makePublic = () => {
    saveWithData({ use_public_sharing: true })
  }

  const invoicesLink = `${APP_URLS.mini_apps}/invoices/${tenant.subdomain}/client/${client?.external_id}`

  const { copy, didCopy: linkCopied } = useCopyToClipboard({
    text: invoicesLink,
    notification: 'Link copied!',
  })

  // if (!invoicesLink) return null

  // if (isOverlayLoading) return <OverlayLoader position="center" />

  return (
    <Overlay showBackdrop closeOnBackdrop maxWidth={33} position="center" onClose={close}>
      <Overlay.Header glyph="share" title="Share Settings" />

      <Overlay.Content>
        <Form>
          <Section>
            {isPrivate && (
              <State
                isEmpty
                minHeight={100}
                glyph="lock"
                glyphColor={COLORS.blue}
                title={
                  <>
                    <Status large label="Private Invoice" css={{ margin: '0 auto 0.5rem' }} color="blue" />
                  </>
                }
                emptyDescription={
                  <>
                    <div className="text-[1.1em] font-[600] text-text">This invoice is currently private</div>
                    <div>Click the button below to generate a secure public link that you can share.</div>
                  </>
                }
              />
            )}

            {isPublic && (
              <>
                <State
                  isEmpty
                  minHeight={100}
                  glyph="public"
                  glyphColor={COLORS.green}
                  title={
                    <>
                      <Status large label="Secure Public Invoice" css={{ margin: '0 auto 0.5rem' }} color="green" />
                    </>
                  }
                  emptyDescription={
                    <>
                      <div className="text-[1.1em] font-[600] text-text">This invoice is currently public</div>
                      <div>You can share the link below with anyone or destroy it to make the invoice private.</div>
                    </>
                  }
                />

                <Card>
                  <CardHeader>
                    <pre
                      css={{
                        width: '100%',
                        wordBreak: 'break-all',
                        whiteSpace: 'pre-wrap',
                        color: COLORS.textMuted,
                        borderRadius: 5,
                        padding: 10,
                        margin: 0,
                      }}
                    >
                      <a
                        href={invoicesLink}
                        target="_blank"
                        rel="noreferrer"
                        css={{
                          display: 'inline-flex',
                          alignItems: 'center',
                          svg: { marginLeft: '0.25em' },
                          '&:hover': { textDecoration: 'underline' },
                        }}
                      >
                        {invoicesLink} <Glyph glyph="external_link" size={14} color={COLORS.blue} />
                      </a>
                    </pre>
                  </CardHeader>
                </Card>
              </>
            )}
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isPrivate && (
          <Button
            label="Generate Secure Public Link 🚧"
            glyph="public"
            type="primary"
            color="green"
            onClick={makePublic}
            isLoading={isFetching || isUpdating}
          />
        )}
        {isPublic && (
          <Grid gap="0.75rem">
            <Button
              glyph={linkCopied ? 'check' : 'copy'}
              label={linkCopied ? 'Link Copied!' : 'Copy Link'}
              onClick={copy}
              type={linkCopied ? 'default' : 'primary'}
              flex="100 1 auto"
            />

            <Button
              label="Destroy Public Link"
              glyph="lock"
              type="minimal"
              color="red"
              onClick={makePrivate}
              isLoading={isFetching || isUpdating}
            />
          </Grid>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const ClientInvoiceShareOverlay = withOverlayError(RootClientInvoiceShareOverlay)

import React from 'react'
import { v4 as uuid } from 'uuid'
import pluralize from 'pluralize'

import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import map from 'lodash/map'
import remove from 'lodash/remove'

import { arrayToMap } from '../../../../utils/functions'
import { DEFAULT_EMPTY_VALUE } from '../../../../utils/constants'
import { validate } from '../../validators'
import { withFormContext } from '../../context'

import Grid from '../../../Grid'
import SmartPortal from '../../../SmartPortal'
import Value from '../../../Value'

import MultiObjectItem from '../MultiObject/MultiObjectItem'
import MultiSingleSelectorMenu from './MultiSingleSelectorMenu'
import SelectorBase from '../SelectorBase'
import SelectorInput from '../SelectorInput'

class MultiObjectSingleSelector extends SelectorBase {
  constructor(props) {
    super(props)

    let errors = []
    let vs = props.validations
    const value = get(props.form.getInitialModel(), props.model, null)
    if (vs) errors = validate(value, vs)

    const modelName = props.asItems ? props.model : `${pluralize.singular(props.model)}_ids`
    let apiData = props.apiData ? arrayToMap(cloneDeep(props.apiData)) : []

    // if the initial model is required, flag the objects as such
    if (props.initialModelRequired && value) {
      for (let i = 0; i < value.length; i++) value[i].isRequired = true
    }

    this.state = {
      type: 'OBJECT',
      id: `${props.model}-${uuid()}`,
      originalModel: props.model,
      model: modelName,
      submodel: props.submodel,
      object: value,
      value: props.asItems ? value : map(value, 'id'),
      apiData: apiData,
      includeObject: props.includeObject,
      isLoading: false,
      isOpen: false,
      isValid: errors.length ? false : true,
      isInvalid: errors.length ? true : false,
      isPristine: true,
      isDirty: false,
      isTouched: false,
      isUntouched: true,
      isValidations: props.validations,
      isRequired: props.validations && props.validations.hasOwnProperty('presence'),
      errors: [],
      reset: this.onReset,
      validate: this.onValidate,
      highlight: this.onHighlight,
      scrollIntoView: this.scrollIntoView,
    }

    this.updateType = 'DATA'
    this.referenceRef = null
    this.selectorRef = null
  }

  /*
    CUSTOM FUNCTIONS
  */
  select = (items) => {
    if (this.props.isDisabled) return

    let errors = []
    const vs = this.props.validations
    if (vs) errors = validate(items, vs)

    let value = this.props.asItems ? items : map(items, 'id')

    this.setState({
      ...this.state,
      object: items,
      value: value,
      isOpen: false,
      isDirty: true,
      isPristine: false,
      isValid: errors.length ? false : true,
      isInvalid: errors.length ? true : false,
      errors: errors,
    })

    document.removeEventListener('click', this.close)
  }

  removeItem = (id) => {
    if (this.props.isDisabled) return

    let newState = cloneDeep(this.state)

    remove(newState.object, { id: id })
    if (newState.apiData && newState.apiData[id]) newState.apiData[id].checked = false

    let errors = []
    const vs = this.props.validations
    if (vs) errors = validate(newState.object, vs)

    let value = this.props.asItems ? newState.object : map(newState.object, 'id')

    newState.value = value
    newState.isOpen = false
    newState.isDirty = true
    newState.isPristine = false
    newState.isValid = errors.length ? false : true
    newState.isInvalid = errors.length ? true : false
    newState.errors = errors

    this.setState(newState)
  }

  clear = () => {
    if (this.props.isDisabled) return

    let errors = []
    const vs = this.props.validations
    let newState = cloneDeep(this.state)

    // remove everything besides required
    let i = newState.object.length
    let requiredIds = []
    while (i--) {
      let id = newState.object[i].id
      if (!newState.object[i].isRequired) {
        newState.object.splice(i, 1)
      } else {
        requiredIds.push(id)
      }
    }

    // clear out all checked values from API
    for (let key in newState.apiData) newState.apiData[key].checked = false
    // add back the ones that are required
    for (let i = 0; i < requiredIds.length; i++) newState.apiData[requiredIds[i]].checked = true

    // find value
    let value = this.props.asItems ? newState.object : map(newState.object, 'id')
    newState.value = value

    if (vs) errors = validate(newState.object, vs)

    newState.isValid = errors.length ? false : true
    newState.isInvalid = errors.length ? true : false
    newState.errors = errors

    this.setState(newState)
  }

  /*
    RENDER
  */
  renderEdit = () => {
    const { blankLabel, icon, label, isDisabled } = this.props

    return (
      <SelectorInput icon={icon} label={blankLabel || `Select ${label}…`} onOpen={this.open} onClear={this.clear} isDisabled={isDisabled} />
    )
  }

  renderSelected = () => {
    const { selectTitle } = this.props
    const { object } = this.state

    return (
      object?.length > 0 && (
        <div css={{ marginTop: '0.5rem !important' }}>
          {object.map((item: any) => (
            <MultiObjectItem
              key={item?.id}
              label={selectTitle?.(item) || item?.name}
              avatar={item?.avatar}
              onClear={() => this.removeItem(item?.id)}
              isDisabled={item?.isRequired}
            />
          ))}
        </div>
      )
    )
  }

  renderReadOnly = () => {
    const { object } = this.state

    if (object && object.length > 0) {
      return (
        <Grid gap={6}>
          {object.map((item) => (
            <Value
              key={item.id}
              value={item.name}
              description={item.description}
              avatar={item.avatar}
              avatarMagnify
              avatarMagnifyScale={2.5}
            />
          ))}
        </Grid>
      )
    } else return DEFAULT_EMPTY_VALUE
  }

  renderSelector = () => {
    const { icon, searchKeys, type, selectTitle, selectDescription, emptyActions } = this.props
    const { isLoading, apiData } = this.state

    return (
      <SmartPortal useReferenceWidth portal="selector" position="bottom-start">
        <MultiSingleSelectorMenu
          data={apiData}
          icon={icon}
          type={type}
          searchKeys={searchKeys}
          isLoading={isLoading}
          selectTitle={selectTitle}
          selectDescription={selectDescription}
          emptyActions={emptyActions}
          onSelect={this.select}
          onClear={this.clear}
        />
      </SmartPortal>
    )
  }
}

MultiObjectSingleSelector.defaultProps = {
  showAs: 'inline',
  prefetch: false,
  asItems: false,
  searchKeys: ['name', 'description'],
  isEditable: true,
  isDisabled: false,
  initialModelRequired: false,
  layout: 'vertical-dense',
  isOpen: false,
}

export default withFormContext(MultiObjectSingleSelector)

import React from 'react'
import { motion } from 'framer-motion'
import clsx from 'clsx'

import { isDefined } from '../../utils/functions'
import { PlainSwitch } from '../../components/Forms/Switch'
import { useFormField } from '../../components/Forms/hooks/useFormField'
import { useOverlay } from '../../hooks/useOverlay'
import { usePermissions } from '../../hooks/usePermissions'
import { useSettings } from '../../hooks/useSettings'
import { useUpdate } from '../../hooks/useNewAPI'
import { withFormContext } from '../../components/Forms/context'
import { withOverlayError } from '../../hocs/withOverlayError'

import Button from '../../components/Button'
import ConfirmDialog from '../../components/Dialogs/ConfirmDialog'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Glyph from '../../components/Glyph'
import Input from '../../components/Forms/Input'
import MiniRichTextEditor from '../../components/Forms/MiniRichTextEditor'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Section from '../../components/Section'
import Status from '../../components/Status'
import Textarea from '../../components/Forms/Textarea'
import TreeItem from '../../components/TreeItem'

const animationProps = {
  type: 'spring',
  duration: 0.35,
  bounce: 0,
  // stiffness: 1500,
  // damping: 300,
}

const RootClientTrackOverlay = (props: any) => {
  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    id,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
  } = useOverlay({
    name: 'client_tracks',
    endpoint: '/client_tracks',
    invalidate: 'client_tracks',
    invalidateKeys: ['client_workflows'],
    options: props,
  })

  const { mutateAsync: setAsDefault, isLoading: isMakingDefault } = useUpdate({
    name: ['update_client_track'],
    url: `/client_tracks/${id}/default`,
    invalidate: 'client_tracks',
  })

  const { tenant, timezone } = useSettings()

  if (isOverlayLoading) {
    return <OverlayLoader position="right" maxWidth={46} />
  }

  return (
    <Overlay onClose={close} showBackdrop={isNew || isEditable} isDirty={isEditable} maxWidth={46}>
      <Overlay.Header
        icon="client_tracks"
        title="Client Track"
        titleAside={data?.is_default && <Status small label="Default" color="blue" className="ml-1.5" />}
      />

      <Overlay.Content>
        <Form
          getForm={form}
          initialModel={initialModel}
          isEditable={isEditable}
          timezone={timezone}
          onValidationUpdate={onValidationUpdate}
        >
          <Section>
            <FormSection maxWidth="100%">
              <Input
                label="Name"
                model="name"
                validations={{
                  presence: {
                    message: 'Please enter a name',
                  },
                }}
              />
              <Textarea label="Description" model="description" />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={save}
              isDisabled={isInvalid}
              flex="100 1 auto"
            />
            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {!isEditable && (
          <>
            <Button
              glyph="edit"
              label="Edit Client Track"
              type="default"
              isDisabled={isLoading}
              onClick={edit}
              flex="100 1 auto"
              permission="client_tracks.edit"
            />

            {!data?.is_default && (
              <>
                <ConfirmDialog
                  title="Set as Default Client Track?"
                  message={`Are you sure you want to set this Client Track as the default?`}
                  yesLabel="Set as Default"
                  onYes={async () => {
                    await setAsDefault({})
                  }}
                >
                  <Button glyph="tick_circle" label="Set as Default" type="default" color="green" isLoading={isMakingDefault} fullWidth />
                </ConfirmDialog>

                <DeleteDialog
                  title="Delete Client Track?"
                  message="Are you sure you want to delete this Client Track? This action cannot be undone."
                  onYes={deleteRecord}
                >
                  <Button
                    glyph="delete"
                    label="Delete"
                    type="default"
                    color="red"
                    isLoading={isDeleting}
                    fullWidth
                    permission="client_tracks.delete"
                  />
                </DeleteDialog>
              </>
            )}
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

const RootWorkflowStep = (props: any) => {
  const { model, form, isEditable, step, stepConfig = {} } = props

  const { label, isRequired: isAlwaysRequired, isRequiredOptional, featureFlag, featureFlagV2 } = stepConfig

  const { allowed: hasFeatureFlag } = usePermissions({ featureFlag: featureFlag || featureFlagV2 })

  const visibilityModel = `${model}.${step}`
  const requiredModel = `${model}_required_steps.${step}`
  const instructionsModel = `${model}_instructions.${step}`

  const [visibility, setVisibility] = React.useState(true)
  const [required, setRequired] = React.useState(!!isAlwaysRequired)
  const [instructions, setInstructions] = React.useState('')

  const { formActions: visibilityActions } = useFormField({
    model: visibilityModel,
    form,
    afterReset: () => {
      let initialVisibility = form?.getInitialInputFieldValue(visibilityModel)

      setVisibility(!!initialVisibility)
    },
  })

  const { formActions: requiredActions } = useFormField({
    model: requiredModel,
    form,
    afterReset: () => {
      let initialRequired = form?.getInitialInputFieldValue(requiredModel)

      setRequired(!!initialRequired)
    },
  })

  const { formActions: instructionsActions } = useFormField({
    model: instructionsModel,
    form,
    afterReset: () => {
      let initialInstructions = form?.getInitialInputFieldValue(instructionsModel)

      setInstructions(initialInstructions)
    },
  })

  // ON MOUNT
  React.useEffect(() => {
    let initialVisibility = form?.getInitialInputFieldValue(visibilityModel)
    let initialRequired = form?.getInitialInputFieldValue(requiredModel)
    let initialInstructions = form?.getInitialInputFieldValue(instructionsModel)

    if (isDefined(initialVisibility)) {
      setVisibility(!!initialVisibility)
    }

    if (isDefined(initialRequired)) {
      setRequired(!!initialRequired)
    }

    if (isDefined(initialInstructions)) {
      setInstructions(initialInstructions)
    }
  }, [])

  // UPDATE FORM
  React.useEffect(() => {
    visibilityActions.setValue(visibility)
  }, [visibility])

  React.useEffect(() => {
    requiredActions.setValue(required)
  }, [required])

  React.useEffect(() => {
    instructionsActions.setValue(instructions)
  }, [instructions])

  const showInstructions = isEditable ? visibility : visibility && instructions

  if (!hasFeatureFlag) return null

  return (
    <div className="border-b border-0 border-solid border-divider">
      <div className="flex items-center py-3">
        <h4 className={clsx('text-[1.1rem] mr-auto', !visibility && 'opacity-50')}>{label}</h4>

        {isAlwaysRequired ? (
          <div className="flex items-center flex-nowrap">
            <Glyph glyph="check" size={16} />
            <div className="font-[500] text-[0.95rem] ml-1.5">Always Visible & Required</div>
          </div>
        ) : (
          <>
            {isEditable && !isRequiredOptional && (
              <div className="flex items-center flex-nowrap">
                <Glyph glyph="info" size={16} />
                <div className="font-[400] italic text-[0.95rem] ml-1.5">Required Option Coming Soon</div>
              </div>
            )}

            {isRequiredOptional && (
              <div className={clsx('flex items-center flex-nowrap', !visibility && 'hidden')}>
                {!isEditable && <Glyph glyph={required ? 'check' : 'cross'} size={16} />}

                {isEditable && (
                  <PlainSwitch
                    size={100}
                    isChecked={required && visibility}
                    onCheckedChange={(value) => {
                      setRequired(value && visibility)
                    }}
                    isDisabled={!visibility}
                  />
                )}

                <div className="font-[500] text-[0.95rem] ml-1.5">Required</div>
              </div>
            )}

            <div className="flex items-center flex-nowrap ml-4">
              {!isEditable && <Glyph glyph={visibility ? 'check' : 'cross'} size={16} />}
              {isEditable && <PlainSwitch size={100} isChecked={visibility} onCheckedChange={setVisibility} />}
              <div className="font-[500] text-[0.95rem] ml-1.5">Visible</div>
            </div>
          </>
        )}
      </div>

      {(isEditable || showInstructions) && (
        <motion.div
          initial={false}
          className={clsx(!showInstructions && 'overflow-hidden')}
          animate={isEditable && { height: showInstructions ? 'auto' : 0, opacity: showInstructions ? 1 : 0 }}
          transition={{
            height: animationProps,
            opacity: animationProps,
          }}
        >
          <TreeItem
            title={<span className="text-[0.95rem] text-text-muted">Instructions</span>}
            withHover={false}
            headerClassName="!pt-0 !pb-3"
          >
            <div className={clsx(isEditable ? 'mb-4' : 'mb-2')}>
              <MiniRichTextEditor
                isCompact
                withHover={false}
                actions={EDITOR_ACTIONS}
                value={instructions}
                onUpdate={({ value }: any) => {
                  setInstructions(value)
                }}
              />
            </div>
          </TreeItem>
        </motion.div>
      )}
    </div>
  )
}

const EDITOR_ACTIONS = ['inline', 'color']

const WorkflowStep = withFormContext(RootWorkflowStep)

export const ClientTrackOverlay = withOverlayError(RootClientTrackOverlay)

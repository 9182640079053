import React from 'react'

import { COLORS } from '../../../theme'
import { withFormContext } from '../../../components/Forms/context'
import { SectionCard } from '../../../components/SectionCard'
import { ComboBox } from '../../../components/Forms/ComboBoxes/ComboBox'

import DataList from '../../../components/DataList'
import Status from '../../../components/Status'
import Tooltip from '../../../components/Tooltip'

const ClaimInsurancePolicies = (props: any) => {
  const {
    className,
    client,
    onPrimaryInsuranceUpdate,
    onSecondaryInsuranceUpdate,
    onTertiaryInsuranceUpdate,
    primaryInsurance,
    secondaryInsurance,
    tertiaryInsurance,
    onUpdate,
  } = props

  const clientId = client?.id || null

  return (
    <SectionCard title="Insurance Policies" className={className}>
      <div className="grid gap-4 grid-cols-1">
        <Tooltip show={!clientId} content="Please select a Client first">
          <ComboBox
            label="Primary Insurance"
            type="client.insurance_policies"
            value={primaryInsurance}
            dependentValue={clientId}
            isDisabled={!clientId}
            validations={{
              presence: {
                message: 'Please select a Primary Insurance',
              },
            }}
            onUpdate={(value: any) => {
              onPrimaryInsuranceUpdate?.(value)
              onUpdate?.({ primary_insurance: value })
            }}
            labelAfter={
              primaryInsurance && (
                <Tooltip
                  content={
                    <DataList isCompact layout="horizontal" labelWidth={90} className="grid min-w-[240px]">
                      <DataList.Item label="Plan Name" value={primaryInsurance.plan} />
                      <DataList.Item label="Member ID" value={primaryInsurance.member_id} />
                      <DataList.Item label="Group ID" value={primaryInsurance.group_id} />
                    </DataList>
                  }
                >
                  <Status small glyph="view" color={COLORS.blue} label="Quickview" className="ml-auto" />
                </Tooltip>
              )
            }
          />
        </Tooltip>

        <ComboBox
          label="Secondary Insurance"
          type="client.insurance_policies"
          value={secondaryInsurance}
          dependentValue={clientId}
          isDisabled={!clientId}
          onUpdate={(value: any) => {
            onSecondaryInsuranceUpdate?.(value)
            onUpdate?.({ secondary_insurance: value })
          }}
          labelAfter={
            secondaryInsurance && (
              <Tooltip
                content={
                  <DataList isCompact layout="horizontal" labelWidth={90} className="grid min-w-[240px]">
                    <DataList.Item label="Plan Name" value={secondaryInsurance.plan} />
                    <DataList.Item label="Member ID" value={secondaryInsurance.member_id} />
                    <DataList.Item label="Group ID" value={secondaryInsurance.group_id} />
                  </DataList>
                }
              >
                <Status small glyph="view" color={COLORS.blue} label="Quickview" className="ml-auto" />
              </Tooltip>
            )
          }
        />

        <ComboBox
          label="Tertiary Insurance"
          type="client.insurance_policies"
          value={tertiaryInsurance}
          dependentValue={clientId}
          isDisabled={!clientId}
          onUpdate={(value: any) => {
            onTertiaryInsuranceUpdate?.(value)
            onUpdate?.({ tertiary_insurance: value })
          }}
          labelAfter={
            tertiaryInsurance && (
              <Tooltip
                content={
                  <DataList isCompact layout="horizontal" labelWidth={90} className="grid min-w-[240px]">
                    <DataList.Item label="Plan Name" value={tertiaryInsurance.plan} />
                    <DataList.Item label="Member ID" value={tertiaryInsurance.member_id} />
                    <DataList.Item label="Group ID" value={tertiaryInsurance.group_id} />
                  </DataList>
                }
              >
                <Status small glyph="view" color={COLORS.blue} label="Quickview" className="ml-auto" />
              </Tooltip>
            )
          }
        />
      </div>
    </SectionCard>
  )
}

export default withFormContext(ClaimInsurancePolicies)

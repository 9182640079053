import React from 'react'
import size from 'lodash/size'

import { countWord } from '../../utils/functions'

import Button from '../../components/Button'
import Card from '../../components/Card'
import Overlay from '../../components/Overlay'
import Section from '../../components/Section'

import { FormsDataTable } from './FormsDataTable'
import { useDataTable } from '../../components/DataTable/useDataTable'
import { useCreate } from '../../hooks/useNewAPI'

export const FormsImportOverlay = ({ onClose, referenceId, referenceType, allowedReference }) => {
  const [selectedRows, setSelectedRows]: any = React.useState([])

  const selectedRowsCount = size(selectedRows)
  const hasSelectedRows = selectedRowsCount >= 1

  const tableProps = useDataTable({
    name: ['forms'],
    endpoint: `/forms`,
    params: { allowed_references: allowedReference },
    localStorageKey: 'forms_v1',
  })

  const { mutateAsync, isLoading: isCreating } = useCreate({
    name: ['form_submissions_import'],
    url: `/form_submissions/import`,
    invalidate: 'form_submissions',
  })

  const importForms = async () => {
    const forms: any = {}

    for (const row of selectedRows) {
      forms[row.id] = true
    }

    try {
      await mutateAsync({
        forms,
        reference_id: referenceId,
        reference_type: referenceType,
      })

      onClose?.()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Overlay showBackdrop position="center" maxWidth={100} onClose={onClose}>
      <Overlay.Header icon="web_form" title="Import Multiple Forms" />

      <Overlay.Content>
        <Section margin="0 0 1.5rem 0">
          <Card className="!mt-4">
            <FormsDataTable canBatchSelect title="Forms" {...tableProps} onRowSelectionUpdate={setSelectedRows} />
          </Card>
        </Section>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label={hasSelectedRows ? `Import ${countWord('Forms', selectedRowsCount)}` : 'Select Forms to Import'}
          isDisabled={!hasSelectedRows}
          isLoading={isCreating}
          type="primary"
          color="green"
          onClick={importForms}
        />
      </Overlay.Footer>
    </Overlay>
  )
}

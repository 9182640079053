import React from 'react'

import { H4 } from '../../components/Typography'
import Divider from '../../components/Divider'
import Grid from '../../components/Grid'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'

import Score from './common/Score'
import { DataFormOverlay } from '../DataFormOverlay'
import { withOverlayError } from '../../hocs/withOverlayError'
import { useSettings } from '../../hooks/useSettings'

const SCORING = {
  2.99: 'non-clinical',
  6: 'clinical',
}

const INITIAL_STATE = {
  little_interest: 0,
  hopeless: 0,
  sleep_issues: 0,
  tiredness: 0,
  eating_issues: 0,
  feeling_as_failure: 0,
  focus_issues: 0,
  movement_issues: 0,
  self_harm_thoughts: 0,
}

const RootPHQ2Overlay = (props: any) => {
  return (
    <DataFormOverlay
      clientPortalCanEdit
      minimizeEnabled
      signoffWithoutSupervisor
      enableTreatmentPlanSelector
      title="PHQ-2"
      category="clinical_measurement"
      subcategory="phq2"
      icon="clinical_measurements"
      requestClientSignature={false}
      requestAuthorSignature={false}
      requireSupervisor={false}
      {...props}
    >
      <Section
        title="Questionnaire"
        headingType="h2"
        scrollview={{
          id: 'questionnaire',
          name: 'Questionnaire',
        }}
        commentsModel="data.questionnaire"
      >
        <Questionnaire />
      </Section>
    </DataFormOverlay>
  )
}

const Questionnaire = (props: any) => {
  const [score, setScore] = React.useState(INITIAL_STATE)

  const finalScore = Object.values(score).reduce((a, b) => a + b)

  const { isPortal } = useSettings()

  return (
    <>
      <Grid gap="1.5rem">
        <H4>Over the last two weeks, how often have you been bothered by the following problems?</H4>

        <RadioGroup
          label="Feeling nervous, anxious or on edge"
          model="data.questionnaire.little_interest"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, little_interest: state.value }))}
        >
          <Radio label="0 = Not at all" value={0} />
          <Radio label="1 = Several days" value={1} />
          <Radio label="2 = More than half the days" value={2} />
          <Radio label="3 = Nearly every day" value={3} />
        </RadioGroup>

        <RadioGroup
          label="Not being able to stop or control worrying"
          model="data.questionnaire.hopeless"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, hopeless: state.value }))}
        >
          <Radio label="0 = Not at all" value={0} />
          <Radio label="1 = Several days" value={1} />
          <Radio label="2 = More than half the days" value={2} />
          <Radio label="3 = Nearly every day" value={3} />
        </RadioGroup>
      </Grid>

      {!isPortal && (
        <>
          <Divider />

          <Score
            title="Scoring"
            finalScore={finalScore}
            scoring={SCORING}
            description="The scoring details below are for information only. The scoring for this survey happens on our side. Please do not provide survey scores."
          />
        </>
      )}
    </>
  )
}

export const PHQ2Overlay = withOverlayError(RootPHQ2Overlay)

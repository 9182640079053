import React from 'react'
import * as HoverCard from '@radix-ui/react-hover-card'
import size from 'lodash/size'

import { niceAmount, nicePercentage } from '../../utils/functions'
import { useSettings } from '../../hooks/useSettings'

import Icon from '../../components/Icon'
import Markup from '../../components/Markup'
import State from '../../components/State'

export const ClientInvoiceLinesTable = (props: any) => {
  const { invoice } = props

  const { isBehave } = useSettings()

  const columns = React.useMemo(
    () => [
      {
        title: '#',
        width: '40px',
        render: (line: any, index: number) => {
          return <div>{index + 1}.</div>
        },
      },
      {
        title: 'Service',
        width: 'minmax(250px, 1fr)',
        render: (line: any) => {
          const name = line.service_name
          const icon = line?.service?.icon

          if (!name) return <div className="text-text-muted">–</div>

          return (
            <span className="truncate min-w-[0] max-w-full inline-flex items-center font-[600]">
              {icon && <Icon icon={icon} size={18} className="mr-2" />}

              <HoverCard.Root openDelay={300} closeDelay={50}>
                <HoverCard.Trigger asChild>
                  <div className="truncate pr-2">{name}</div>
                </HoverCard.Trigger>

                <HoverCard.Portal container={document?.getElementById?.('portal-radix')}>
                  <HoverCard.Content asChild align="start" side="bottom">
                    <div className="w-full flex items-center px-3 py-2 max-w-[400px] bg-white shadow-soft-5 rounded-md">
                      {icon && <Icon icon={icon} size={18} className="mr-2" />}
                      <div className="font-[400]">{name}</div>
                    </div>
                  </HoverCard.Content>
                </HoverCard.Portal>
              </HoverCard.Root>
            </span>
          )
        },
      },
      {
        title: 'Service SKU',
        width: '150px',
        render: (line: any) => {
          if (!line.service_sku) return <div className="text-text-muted">–</div>

          return <div>{line.service_sku}</div>
        },
      },
      {
        title: 'Description',
        width: 'minmax(200px, 1fr)',
        render: (line: any) => {
          if (!line.service_description) return <div className="text-text-muted">–</div>

          return <Markup value={line.service_description} />
        },
      },
      {
        title: 'Units',
        width: '150px',
        alignRight: true,
        render: (line: any) => {
          if (!line.quantity) return <div>0</div>

          return <div>{line.quantity}</div>
        },
      },
      {
        title: 'Unit Amount',
        width: '150px',
        alignRight: true,
        render: (line: any) => {
          return niceAmount(line.unit_price)
        },
      },
      {
        title: 'Discount',
        width: '120px',
        render: (line: any) => {
          const { discount_type, percent_off, amount_off } = line

          if (!discount_type) return <span className="text-text-muted">–</span>

          let info: any = ''

          if (discount_type === 'percentage') {
            info = `${nicePercentage(percent_off)}% off`
          } else if (discount_type === 'fixed') {
            info = `${niceAmount(amount_off)} off`
          }

          return info
        },
      },
      {
        title: 'Discount SKU',
        width: '150px',
        render: (line: any) => {
          if (!line.discount_sku) return <div className="text-text-muted">–</div>

          return <div>{line.discount_sku}</div>
        },
      },
      {
        title: 'Subtotal',
        width: '160px',
        alignRight: true,
        render: (line: any) => {
          if (!!line?.discount_type) {
            return (
              <span>
                <span className="line-through text-text-muted inline-block mr-2">{niceAmount(line.amount)}</span>
                <span className="font-[600]">{niceAmount(line.discounted_amount)}</span>
              </span>
            )
          }

          return <span className="font-[600]">{niceAmount(line.amount)}</span>
        },
      },
    ],
    [],
  )

  const gridTemplateColumns = React.useMemo(() => {
    const finalColumns: any = []

    for (const column of columns) {
      if (column.isBehaveOnly && !isBehave) continue

      finalColumns.push(column.width)
    }

    return finalColumns.join(' ')
  }, [columns, isBehave])

  if (!invoice) return null

  const isEmpty = size(invoice.invoice_lines) === 0

  if (isEmpty) {
    return (
      <State isEmpty icon="invoices" title="Invoice Lines" emptyDescription="No invoice lines added to this invoice yet" minHeight={200} />
    )
  }

  return (
    <div className="grid overflow-x-auto">
      <div
        className="grid gap-6 text-[0.84rem] tracking-[1px] uppercase text-text-muted font-[700] opacity-[0.85]"
        style={{ gridTemplateColumns }}
      >
        {columns.map((column) => {
          if (column.isBehaveOnly && !isBehave) return null

          return (
            <div key={column.title} style={{ textAlign: column.alignRight ? 'right' : 'left' }}>
              {column.title}
            </div>
          )
        })}
      </div>

      {invoice.invoice_lines.map((line: any, index: any) => {
        return (
          <div
            key={line.id}
            className="grid gap-6 py-2 border-b !last:border-none border-solid border-0 border-divider last:border-none"
            style={{ gridTemplateColumns }}
          >
            {columns.map((column) => {
              if (column.isBehaveOnly && !isBehave) return null

              return (
                <div key={column.title} style={{ textAlign: column.alignRight ? 'right' : 'left' }}>
                  {column?.render?.(line, index) || null}
                </div>
              )
            })}
          </div>
        )
      })}
    </div>
  )
}

import React from 'react'
import { produce } from 'immer'

import { COLORS } from '../../theme'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'

import Button from '../../components/Button'
import ColorSelector from '../../components/Forms/ColorSelector'
import ContextShow from '../../components/Forms/ContextShow'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Divider from '../../components/Divider'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import GlyphSelector from '../../components/Forms/GlyphSelector'
import Input from '../../components/Forms/Input'
import Option from '../../components/Forms/Option'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'
import Select from '../../components/Forms/Select'
import Slider from '../../components/Forms/Slider'
import SmartTextarea from '../../components/Forms/SmartTextarea'
import TreeItem from '../../components/TreeItem'

import { withOverlayError } from '../../hocs/withOverlayError'

const COLOR_PALETTE: any = [
  '#7f8c8d',
  '#222222',
  '#795548',
  '#274879',
  '#8bc34a',
  '#4caf50',
  '#009688',
  '#00bcd4',
  '#03a9f4',
  '#2196f3',
  '#3f51b5',
  '#673ab7',
  '#9c27b0',
  '#e91e63',
  '#f44336',
  '#ffc107',
  '#ff9800',
  '#ff5722',
]

const INITIAL_MODEL = {
  settings: {
    max_tokens: 800,
    temperature: 1,
    frequency_penalty: 0,
    presence_penalty: 0,
  },
}

const RootCustomAIActionOverlay = (props: any) => {
  const {
    cancel,
    close,
    deleteRecord,
    edit,
    form,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    saveWithData,
  } = useOverlay({
    name: 'custom-ai-action',
    endpoint: '/custom_ai_actions',
    invalidate: 'custom-ai-actions',
    invalidateKeys: ['ai-action-groups'],
    options: props,
    closeOnSave: true,
  })

  const { isBehave } = useSettings()

  const randomColor = React.useMemo(() => COLOR_PALETTE[Math.floor(Math.random() * COLOR_PALETTE.length)], [])

  const [provider, setProvider] = React.useState(initialModel?.settings?.provider)
  const [model, setModel] = React.useState(initialModel?.settings?.model)

  const handleSave = async () => {
    const formData = form.current.getFormValue()

    const saveData = produce(formData, (draft) => {
      if (draft.settings?.provider === 'anthropic_claude') {
        draft.settings.model = draft.anthropic_claude_model
      } else if (draft.settings?.provider === 'microsoft_azure_gpt') {
        draft.settings.model = draft.microsoft_azure_gpt_model
      } else if (draft.settings?.provider === 'openai_gpt') {
        draft.settings.model = draft.openai_gpt_model
      }

      delete draft.anthropic_claude_model
      delete draft.microsoft_azure_gpt_model
      delete draft.openai_gpt_model
    })

    await saveWithData(saveData)
  }

  const formInitialModel = React.useMemo(() => {
    if (isNew) return INITIAL_MODEL

    if (!initialModel) return null

    return produce(initialModel, (draft) => {
      if (draft.settings?.provider === 'anthropic_claude') {
        draft.anthropic_claude_model = draft.settings.model
      } else if (draft.settings?.provider === 'microsoft_azure_gpt') {
        draft.microsoft_azure_gpt_model = draft.settings.model
      } else if (draft.settings?.provider === 'openai_gpt') {
        draft.openai_gpt_model = draft.settings.model
      }
    })
  }, [initialModel])

  let maxTokens = 4096
  if (provider === 'anthropic_claude') {
    if (model === 'claude-3-5-sonnet-20240620') {
      maxTokens = 8192
    } else {
      maxTokens = 4096
    }
  } else if (provider === 'microsoft_azure_gpt') {
    if (model === 'behave-gpt35-dev') {
      maxTokens = 4096
    } else if (model === 'gpt-4-production') {
      maxTokens = 8193
    } else if (model === 'behave-gpt4-32-dev') {
      maxTokens = 32768
    }
  }

  if (isOverlayLoading) {
    return <OverlayLoader position="right" />
  }

  return (
    <Overlay showBackdrop={isEditable} onClose={close} portal="radix">
      <Overlay.Header icon="ai_prompts" title={isNew ? 'Add Custom AI Action' : 'Edit Custom AI Action'} glyphColor={COLORS.gray} />

      <Overlay.Content>
        <Form getForm={form} isEditable={isEditable} initialModel={formInitialModel} onValidationUpdate={onValidationUpdate}>
          {({ data: formData }: any) => (
            <>
              <Section>
                <FormSection>
                  <Flex gap="0.5rem">
                    <Flex.Item flex="0 0 4.5rem">
                      <ColorSelector label="Color" model="color" colors={COLOR_PALETTE} defaultValue={randomColor} />
                    </Flex.Item>

                    <Flex.Item flex="0 0 4.5rem">
                      <GlyphSelector label="Icon" model="glyph" portal="radix" color={formData.color} defaultValue="ai_action" />
                    </Flex.Item>

                    <Flex.Item flex="1 1 auto">
                      <Input
                        label="Action Name"
                        model="name"
                        validations={{
                          presence: {
                            message: 'Please enter an action name',
                          },
                        }}
                      />
                    </Flex.Item>
                  </Flex>

                  <Input label="Nav Group" model="nav_group" />

                  <SmartTextarea useDictation label="Description" model="description" />

                  <SmartTextarea
                    useDictation
                    label="Action Prompt"
                    model="content"
                    tooltip="Describe what the AI system should do"
                    validations={{
                      presence: {
                        message: 'Please write an action',
                      },
                    }}
                  />
                </FormSection>
              </Section>

              <Divider />

              <Section title="AI Settings" headingType="h2">
                <FormSection>
                  <Select
                    allowEmpty
                    defaultValue="anthropic_claude"
                    label="Ai Provider"
                    model="settings.provider"
                    onUpdate={({ value }) => {
                      setProvider(value)
                    }}
                    validations={{
                      presence: {
                        message: 'Please select a provider',
                      },
                    }}
                  >
                    <Option label="Claude (Anthropic)" value="anthropic_claude" />
                    <Option label="Azure GPT (Microsoft)" value="microsoft_azure_gpt" />
                    <Option label="ChatGPT (OpenAi)" value="openai_gpt" disabled={true} />
                  </Select>

                  {provider === 'anthropic_claude' && (
                    <Select
                      allowEmpty
                      key={`provider-${provider}`}
                      defaultValue="claude-3-5-sonnet-20240620"
                      onUpdate={({ value }) => {
                        setModel(value)
                      }}
                      label="Ai Model"
                      model="anthropic_claude_model"
                      validations={{
                        presence: {
                          message: 'Please select a model',
                        },
                      }}
                    >
                      <Option label="Claude 3.5 Sonnet" value="claude-3-5-sonnet-20240620" />
                      <Option label="Claude 3 Sonnet" value="claude-3-sonnet-20240229" />
                      <Option label="Claude 3 Opus" value="claude-3-opus-20240229" />
                      <Option label="Claude 3 Haiku" value="claude-3-haiku-20240307" />
                    </Select>
                  )}

                  {provider === 'microsoft_azure_gpt' && (
                    <Select
                      allowEmpty
                      key={`provider-${provider}`}
                      defaultValue="gpt-4-production"
                      onUpdate={({ value }) => {
                        setModel(value)
                      }}
                      label="Ai Model"
                      model="microsoft_azure_gpt_model"
                      validations={{
                        presence: {
                          message: 'Please select a model',
                        },
                      }}
                    >
                      <Option label="GPT 3.5" value="behave-gpt35-dev" />
                      <Option label="GPT 4 8k" value="gpt-4-production" />
                      {isBehave && <Option label="GPT 4 32k" value="behave-gpt4-32-dev" />}
                    </Select>
                  )}

                  {provider === 'openai_chatgpt' && (
                    <Select
                      allowEmpty
                      key={`provider-${provider}`}
                      defaultValue="gpt-3.5-turbo"
                      onUpdate={({ value }) => {
                        setModel(value)
                      }}
                      label="Ai Model"
                      model="openai_gpt_model"
                      validations={{
                        presence: {
                          message: 'Please select a model',
                        },
                      }}
                    >
                      <Option label="GPT 3.5 Turbo" value="gpt-3.5-turbo" />
                    </Select>
                  )}

                  {/* <Select
                    allowEmpty
                    isEditable={isNew && isBehave}
                    defaultValue="gpt-4-8k-behavehealth-dev"
                    label="AI Version"
                    model="settings.model"
                    validations={{
                      presence: {
                        message: 'Please select a version',
                      },
                    }}
                  >
                    <Option label="GPT 3.5" value="gpt35-behavehealth-dev" />

                    {isBehave && (
                      <>
                        <Option label="GPT 4 8k" value="gpt-4-8k-behavehealth-dev" />
                        <Option label="GPT 4 32k" value="gpt-4-32k-behavehealth-dev" />
                      </>
                    )}
                  </Select> */}

                  <SmartTextarea
                    useDictation
                    label="AI System Context"
                    model="settings.setup"
                    minRows={8}
                    tooltip="Provide context for the AI system to use when generating responses"
                  />

                  <TreeItem title="Advanced Settings">
                    <FormSection>
                      <Flex centerX className="!my-4 !mx-0">
                        <RadioGroup model="adjust" layout="horizontal-dense" defaultValue="temperature">
                          <Radio label="Adjust Temperature" value="temperature" />
                          <Radio label="Adjust Top P" value="top_p" />
                        </RadioGroup>
                      </Flex>

                      {formData?.adjust === 'temperature' && (
                        <Slider
                          withInputs
                          label="Temperature"
                          model="settings.temperature"
                          step={0.01}
                          min={0}
                          max={2}
                          tooltip={
                            <ul style={{ paddingLeft: '1em' }}>
                              <li>This setting balances the AI assistant's creativity and unpredictability in its responses. </li>
                              <li>A higher temperature value (e.g., .8) will make the assistant's responses more creative and diverse.</li>
                              <li>A lower value (e.g., 0.2) will make them more focused and deterministic.</li>
                            </ul>
                          }
                        />
                      )}

                      {formData?.adjust === 'top_p' && (
                        <Slider
                          withInputs
                          label="Top P"
                          model="settings.top_p"
                          step={0.01}
                          min={0}
                          max={2}
                          tooltip={
                            <ul style={{ paddingLeft: '1em' }}>
                              <li>This setting can fine-tune the balance between response diversity and relevance.</li>
                              <li>A higher value (e.g., .8) promotes a more diverse response</li>
                              <li>A lower value (e.g., .2) promotes the most likely answer</li>
                            </ul>
                          }
                        />
                      )}

                      <Slider
                        withInputs
                        key={`max-${maxTokens}`}
                        label="Maximum Tokens"
                        model="settings.max_tokens"
                        step={1}
                        min={1}
                        max={maxTokens}
                        tooltip={
                          <ul style={{ paddingLeft: '1em' }}>
                            <li>This setting limits the response length.</li>
                            <li>Increasing the token limit may result in longer, more detailed responses. </li>
                            <li>Decreasing it may lead to shorter, more focused answers.</li>
                          </ul>
                        }
                      />

                      {provider !== 'anthropic_claude' && (
                        <>
                          <Slider
                            withInputs
                            label="Frequency Penalty"
                            model="settings.frequency_penalty"
                            step={0.01}
                            min={-2}
                            max={2}
                            tooltip={
                              <ul style={{ paddingLeft: '1em' }}>
                                <li>This setting controls how creative or predictable the responses will be.</li>
                                <li>A higher setting promotes use of more unique words and provide more creative answers</li>
                                <li>A lower setting promotes use of more common words and give you more familiar answers</li>
                              </ul>
                            }
                          />

                          <Slider
                            withInputs
                            label="Presence Penalty"
                            model="settings.presence_penalty"
                            step={0.01}
                            min={-2}
                            max={2}
                            tooltip="This setting prevents the AI assistant from providing redundant information in its responses. By increasing the Presence Penalty value, the assistant will be more likely to offer new and informative answers without repeating previously provided information."
                          />
                        </>
                      )}
                    </FormSection>
                  </TreeItem>
                </FormSection>
              </Section>
            </>
          )}
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label="Save AI Action"
              glyph="check"
              type="primary"
              color="green"
              flex="100 1 auto"
              onClick={handleSave}
              isLoading={isSaving}
              isDisabled={isInvalid}
            />
            <Button label="Cancel" glyph="cross" onClick={cancel} isDisabled={isSaving} />
          </>
        )}

        {!isEditable && (
          <>
            <Button label="Edit" glyph="edit" onClick={edit} flex="100 1 auto" />

            <DeleteDialog
              title="Delete AI Action?"
              message="Are you sure you want to delete this AI Action? This action cannot be undone."
              onYes={deleteRecord}
            >
              <Button label="Delete" glyph="delete" color="red" isLoading={isDeleting} />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const CustomAIActionOverlay = withOverlayError(RootCustomAIActionOverlay)

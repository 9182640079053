import React from 'react'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import { FILTERS } from '../Filters/config'
import { useSettings } from '../../hooks/useSettings'

const SOURCE: any = {
  internal: 'Current Facility',
  erx: 'eRX',
}

export const AllergiesDataTable = (props: any) => {
  const { mainLinkAs, to } = props
  const { tenant, isBehave } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        id: 'name',
        model: 'name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => <MainCell as={mainLinkAs} id={data.id} value={data.name} to={to?.(data)} />,
      },
      {
        width: 130,
        id: 'status',
        model: 'status',
        title: 'Status',
        type: 'title',
      },
      {
        width: 130,
        id: 'category',
        model: 'category',
        title: 'Category',
        type: 'title',
      },
      {
        width: 130,
        id: 'criticality',
        model: 'criticality',
        title: 'Risk',
      },
      {
        width: 130,
        id: 'severity',
        model: 'severity',
        title: 'Severity',
      },
      {
        width: 130,
        id: 'source',
        model: 'source',
        title: 'Source',
        formatValue: ({ value }: any) => SOURCE[value],
      },
      {
        width: 130,
        id: 'reaction_type',
        model: 'reaction_type',
        title: 'Reaction Type',
        type: 'title',
      },
      {
        width: 130,
        id: 'started_on',
        model: 'started_on',
        title: 'Onset On',
        type: 'date',
      },
      {
        width: 130,
        id: 'ended_on',
        model: 'ended_on',
        title: 'Resolved On',
        type: 'date',
      },
    ]
  }, [mainLinkAs, to])

  return <DataTable asCard title="Allergies" icon="treatment_data" columns={columns} filtersConfig={FILTERS.allergies} {...props} />
}

import React from 'react'
import { Route, Switch, Redirect, useRouteMatch, useLocation } from 'react-router-dom'

import { FILTERS } from '@behavehealth/constructs/Filters/config'
import { niceAmount } from '@behavehealth/utils/functions'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { InsuranceInvoiceOverlay } from '@behavehealth/constructs/RCM/InsuranceInvoiceOverlay'
import { InsuranceInvoicesDataTable } from '@behavehealth/constructs/RCM/InsuranceInvoicesDataTable'
import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'

import AnimatedSwitch from '@behavehealth/components/AnimatedSwitch'
import Button from '@behavehealth/components/Button'
import CardLink from '@behavehealth/components/CardLink'
import CardSubtitle from '@behavehealth/components/CardSubtitle'
import CardTitle from '@behavehealth/components/CardTitle'
import ClientColorPicker from '@behavehealth/components/ColorPickers/ClientColorPicker'
import ClientQuickView from '@behavehealth/components/Overlays/quick/ClientQuickView'
import Dropdown from '@behavehealth/components/Dropdown'
import DropdownItem from '@behavehealth/components/DropdownItem'
import Icon from '@behavehealth/components/Icon'
import Overlay from '@behavehealth/components/Overlay'
import PageHeader from '@behavehealth/components/PageHeader'
import SummonOverlay from '@behavehealth/components/SummonOverlay'
import Tabs from '@behavehealth/components/Tabs'

export const Statements = () => {
  const match = useRouteMatch()
  const location = useLocation()

  const tableProps = useDataTable({
    name: ['invoices'],
    endpoint: '/invoices',
    params: { category: 'insurance' },
    localStorageKey: 'insurance_invoices_v1',
  })

  return (
    <>
      <div className="grid grid-cols-[100%] grid-rows-[auto_auto_1fr] overflow-hidden">
        <PageHeader
          title="Client Statements"
          icon="client_statements"
          breakpoint="0"
          aside={
            <>
              <Button
                label="Add Statement"
                glyph="add"
                type="primary"
                link={`${location.pathname}/new`}
                permission="insurance_invoicing.create"
              />
            </>
          }
        />

        <Tabs className="px-4">
          <Tabs.List>
            <Tabs.Item label="Client Statements" to={`${match.url}/client-statements`} />
            <Tabs.Item label="Client Balances" to={`${match.url}/client-balances`} />
          </Tabs.List>
        </Tabs>

        <div className="grid grid-cols-[100%] grid-rows-[100%] p-4 overflow-hidden">
          <Switch>
            <Route path={`${match.url}/client-statements`}>
              <InsuranceInvoicesDataTable {...tableProps} to={(data) => `${match.url}/client-statements/${data.id}`} />
            </Route>

            <Route path={`${match.url}/client-balances`}>
              <ClientBalances />
            </Route>

            <Redirect exact from={match.url} to={`${match.url}/client-statements`} />
          </Switch>
        </div>
      </div>

      <AnimatedSwitch className="overlays" animation="animation" timeout={{ enter: 400, exit: 400 }}>
        <Route path={`${match.path}/client-statements/:id`} component={InsuranceInvoiceOverlay} />
        <Route path={`${match.path}/client-balances/:id`} component={InsuranceInvoiceOverlay} />
      </AnimatedSwitch>
    </>
  )
}

const CLIENT_STATUSES = {
  lead: {
    label: 'New Applicant',
    icon: 'admissions',
  },
  intake: {
    label: 'Accepted Applicant',
    icon: 'admissions',
  },
  current: {
    label: 'Current Client',
    icon: 'clients',
  },
  alumni: {
    label: 'Past Client',
    icon: 'alumni_relations',
  },
}

const ClientBalances = () => {
  const match = useRouteMatch()

  const tableProps = useDataTable({
    name: 'clients',
    endpoint: '/residents',
    params: { status: 'current,intake,lead,alumni' },
    // localStorageKey: 'client_balances_v1',
  })

  const [payerData, setPayerData] = React.useState<any>(null)

  const data = React.useMemo(() => {
    if (!tableProps.data) return []

    return tableProps.data.map((row: any) => {
      return {
        ...row,
        _current_balance: Math.floor(Math.random() * 900) + 10,
        _billable_service_lines: Math.floor(Math.random() * 20) + 1,
      }
    })
  }, [tableProps.data])

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        width: 300,
        disableHide: true,
        disableSelect: true,
        formatValue: ({ data }: any) => (
          <MainCell
            testKey="main_cell"
            id={data.id}
            value={data.name}
            avatar={data.avatar}
            to={`${match.url}/${data.id}`}
            quickViewOverlay={<ClientQuickView client={data} />}
          />
        ),
      },
      {
        title: 'Status',
        model: 'status',
        formatValue: ({ value }: any) => {
          const config = CLIENT_STATUSES[value]

          if (!config) return null

          return (
            <div className="flex flex-nowrap items-center font-[500]">
              <Icon icon={config.icon} size={16} />
              <span className="ml-1">{config.label}</span>
            </div>
          )
        },
      },
      {
        title: 'Actions',
        id: 'actions',
        hoverExpand: false,
        width: 150,
        formatValue: ({ data }: any) => {
          return (
            <Dropdown label="Add Statement…" buttonSize={100}>
              <DropdownItem
                label="Create Blank Statement"
                icon="online_card_payment"
                link={{
                  pathname: `${match.url}/new`,
                  data: {
                    client: data,
                  },
                }}
              />
              <DropdownItem
                label="All Billable Services Statement"
                icon="online_card_payment"
                onClick={() => {
                  setPayerData({
                    client: data,
                    _with_service_lines: true,
                  })
                }}
              />
            </Dropdown>
          )
        },
      },
      {
        title: 'Current Balance',
        model: '_current_balance',
        type: 'amount',
        width: 140,
      },
      {
        title: 'Billable Services',
        model: '_billable_service_lines',
        type: 'number',
        width: 135,
      },
      {
        title: 'Tags',
        model: 'tags',
        type: 'tags',
        featureFlag: 'tags',
        editPermission: 'tags.edit',
        tagSmartCategories: 'current_clients',
      },
      {
        title: 'Sex',
        model: 'sex',
        type: 'title',
        width: 140,
      },
      {
        id: 'house',
        title: 'House',
        model: 'current_bed_occupancy.place.house',
        type: 'profile',
      },
      {
        id: 'room',
        title: 'Room',
        model: 'current_bed_occupancy.place.room',
        type: 'profile',
      },
      {
        title: 'Phone',
        type: 'phone',
        model: 'phone_no',
      },
      {
        title: 'Email',
        type: 'email',
        model: 'email',
      },
      {
        title: 'Color',
        model: 'color_code',
        width: 70,
        centerX: true,
        formatValue: ({ data }: any) => <ClientColorPicker clientID={data.id} color={data.color_code} />,
      },
      {
        title: 'Client ID',
        model: 'behave_id',
        width: 180,
        formatValue: ({ data }: any) => `#${data.behave_id}`,
      },
      {
        title: 'Last Name',
        model: 'last_name',
        width: 180,
      },
      {
        title: 'Admit Date',
        model: 'admitted_at',
        id: 'admission_admitted_at',
        type: 'date',
      },
      {
        title: 'Additional Info',
        model: 'notes',
        id: 'notes',
      },
      {
        title: 'Using Portal',
        model: 'client_portal_status',
        width: 120,
        formatValue: ({ data }: any) => {
          const value = data.client_portal_status

          if (value === 'access') return 'Yes'
          else if (value === 'invited') return 'Invited'
          else return 'No'
        },
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
      },
    ],
    [],
  )

  return (
    <>
      <DataTable
        {...tableProps}
        asCard
        data={data}
        title="Clients"
        icon="clients"
        columns={columns}
        canSelect={false}
        filtersConfig={CLIENT_FILTERS}
        onRowSelect={() => {}}
        onDataInitialized={() => {}}
        updateKey="client"
        updateDeleteEndpoint="/residents"
        invalidate={['clients', { status: 'current' }]}
        headerLinksConfig={[
          {
            type: 'report',
            to: '/reports/clients',
          },
        ]}
      />

      <SummonOverlay
        isOpen={!!payerData}
        onClose={() => {
          setPayerData(null)
        }}
        overlay={<PayerSelectOverlay data={payerData} />}
      />
    </>
  )
}

const PayerSelectOverlay = (props: any) => {
  const match = useRouteMatch()

  const { data, onClose } = props

  return (
    <Overlay onClose={onClose} position="center" showBackdrop closeOnBackdrop>
      <Overlay.Header icon="bank_building" title="Select Insurance Payer" />

      <Overlay.Content>
        <div className="p-3 grid gap-3">
          {[1, 2, 3].map((o) => (
            <CardLink
              // variant="variant-list"
              to={{
                pathname: `${match.url}/new`,
                data: data,
              }}
              onClick={() => {
                onClose?.()
              }}
              graphic={<Icon icon="bank_building" size={22} />}
            >
              <CardTitle title={`Payer ${o}`} />
              <div>
                <CardSubtitle subtitle={`Total Billable Balance: ${niceAmount(1000 * o)}`} />
                <CardSubtitle subtitle="In-Network" />
              </div>
            </CardLink>
          ))}
        </div>
      </Overlay.Content>
    </Overlay>
  )
}

const CLIENT_FILTERS = {
  ...FILTERS.clients,
  _current_balance: {
    label: 'Current Balance',
    type: 'number',
  },
  _billable_service_lines: {
    label: 'Billable Service Lines',
    type: 'number',
  },
  status: {
    label: 'Status',
    type: 'multi_select',
    options: Object.keys(CLIENT_STATUSES).map((key) => ({ label: CLIENT_STATUSES[key].label, value: key })),
  },
}

import React from 'react'
import size from 'lodash/size'

import { COLORS } from '../../../../theme'
import { mapToArray, arrayToInternalMap, isDefined } from '../../../../utils/functions'

import Alert from '../../../../components/Alert'
import AmountInput from '../../../../components/Forms/AmountInput'
import Checkbox from '../../../../components/Forms/Checkbox'
import CheckboxGroup from '../../../../components/Forms/CheckboxGroup'
import DataArray from '../../../../components/Forms/DataArray'
import Input from '../../../../components/Forms/Input'
import Option from '../../../../components/Forms/Option'
import Select from '../../../../components/Forms/Select'
import TableArrayForm from '../../../../components/Forms/TableArrayForm'

import { FORM_ELEMENTS_VALUE_TYPES } from '../../utils/constants'
import { useFormBuilder } from '../../useFormBuilder'

export const ConditionalEditor = (props: any) => {
  const { activeElement, editElementConfig, editActiveElementFromInput: onUpdate } = props

  const activeElementId: any = useFormBuilder((state: any) => state.activeElementId)
  const allElements: any = useFormBuilder((state: any) => state.allElements)
  const pages: any = useFormBuilder((state: any) => state.pages)

  const formElements = React.useMemo(() => {
    const result: any = {}
    const orderedIds: any = []
    const excludedIds: any = []

    if (size(pages) === 0 || !activeElement || activeElementId !== activeElement?.uuid) return result

    // find all child ids as they cannot be used to create conditions for their parent
    const collectExcludedIds = (element: any) => {
      if (size(element?.elements_order) === 0) return

      for (const childId of element.elements_order) {
        excludedIds.push(childId)
        collectExcludedIds(allElements[childId])
      }
    }

    collectExcludedIds(activeElement)

    // collect all active pages
    for (const pageId in pages) {
      const page = pages[pageId]

      if (!page || page?._destroy) continue

      const { elements, elements_order } = page

      if (size(elements_order) === 0) continue

      // find all form elements that can be used to create conditions
      const collectOrderedIds = (element: any) => {
        if (element && FORM_ELEMENTS_VALUE_TYPES.hasOwnProperty(element.category) && !excludedIds.includes(element.uuid)) {
          orderedIds.push(element.uuid)
          result[element.uuid] = element
        }

        if (element?.elements_order) {
          for (const id of element.elements_order) {
            const childElement = allElements[id]

            if (FORM_ELEMENTS_VALUE_TYPES.hasOwnProperty(childElement.category) && !excludedIds.includes(id)) {
              orderedIds.push(id)
              result[id] = childElement
            }

            collectOrderedIds(childElement)
          }
        }
      }

      // collect all root and child form element ids in order
      for (const rootId of elements_order) {
        collectOrderedIds(allElements[rootId])
      }
    }

    return result
  }, [pages, activeElement, allElements])

  const formElementsEmpty = size(formElements) === 0

  if (!activeElement) return null

  const { element_uuid, element_model, value, array_value = [], condition } = activeElement.config

  return (
    <>
      {formElementsEmpty ? (
        <Alert small contrast glyph="warning" type="warning">
          There are no form elements to use as condition triggers
        </Alert>
      ) : (
        <>
          <Select label="Condition Trigger" model="element_uuid" value={element_uuid} onUpdate={onUpdate}>
            <Option />

            {mapToArray(formElements).map((formElement: any) => (
              <Option key={formElement.uuid} label={formElement.config?.label} value={formElement.uuid} />
            ))}
          </Select>

          {element_uuid ? (
            <ConditionalValue
              key={element_uuid}
              formElement={formElements[element_uuid]}
              condition={condition}
              onUpdate={onUpdate}
              value={value}
              arrayValue={array_value}
              editElementConfig={editElementConfig}
              activeElementId={activeElementId}
              activeElement={activeElement}
            />
          ) : (
            <Alert small contrast glyph="info">
              Select a form element to create a condition
            </Alert>
          )}
        </>
      )}
    </>
  )
}

const ConditionalValue = (props: any) => {
  const { formElement, value, arrayValue, condition, onUpdate, editElementConfig, activeElementId, activeElement } = props

  const valueType = FORM_ELEMENTS_VALUE_TYPES?.[formElement?.category]
  const config = VALUES_CONFIG?.[valueType]

  if (!config) return null

  const { component: Tag, conditions } = config

  return (
    <>
      <Select label="Condition" model="condition" value={condition} onUpdate={onUpdate} defaultValue={conditions?.[0]}>
        {conditions.map((conditionKey) => (
          <Option key={conditionKey} label={CONDITIONS_CONFIG[conditionKey]} value={conditionKey} />
        ))}
      </Select>

      <Tag
        model="value"
        arrayModel="array_value"
        value={value}
        arrayValue={arrayValue}
        onUpdate={onUpdate}
        condition={condition}
        formElement={formElement}
        editElementConfig={editElementConfig}
        activeElementId={activeElementId}
        activeElement={activeElement}
      />
    </>
  )
}

const TextValue = (props: any) => {
  const { model, value, onUpdate, condition } = props

  if (ARRAY_CONDITIONS.includes(condition)) return <TextArrayValues {...props} />

  return <Input label="Value" model={model} value={value} onUpdate={onUpdate} />
}

const TextArrayValues = ({ arrayModel, arrayValue, onUpdate }: any) => {
  const TEXT_INPUTS = React.useMemo(() => {
    return [
      {
        name: 'Text Value',
        width: '1fr',
        isRequired: true,
        element: (id: string, data: any) => {
          return (
            <>
              <Input
                model={`${id}.value`}
                value={data.value}
                placeholder="Text value…"
                validations={{
                  presence: {
                    message: 'Please enter a value',
                  },
                }}
              />
            </>
          )
        },
      },
    ]
  }, [])

  return <TableArrayForm name="Values" model={arrayModel} value={arrayValue} inputs={TEXT_INPUTS} onUpdate={onUpdate} />
}

const NumberValue = (props: any) => {
  const { model, value, onUpdate, condition } = props

  if (ARRAY_CONDITIONS.includes(condition)) return <NumberArrayValues {...props} />

  return <Input type="number" label="Value" model={model} value={value} onUpdate={onUpdate} />
}

const NumberArrayValues = ({ arrayModel, arrayValue, onUpdate }: any) => {
  const TEXT_INPUTS = React.useMemo(() => {
    return [
      {
        name: 'Numeric Value',
        width: '1fr',
        isRequired: true,
        element: (id: string, data: any) => {
          return (
            <>
              <Input
                type="number"
                model={`${id}.value`}
                value={data.value}
                placeholder="Numeric value…"
                validations={{
                  presence: {
                    message: 'Please enter a numeric value',
                  },
                }}
              />
            </>
          )
        },
      },
    ]
  }, [])

  return <TableArrayForm name="Values" model={arrayModel} value={arrayValue} inputs={TEXT_INPUTS} onUpdate={onUpdate} />
}

const AmountValue = (props: any) => {
  const { model, value, onUpdate, condition } = props

  if (ARRAY_CONDITIONS.includes(condition)) return <AmountArrayValues {...props} />

  return <AmountInput label="Value" model={model} value={value} onUpdate={onUpdate} />
}

const AmountArrayValues = ({ arrayModel, arrayValue, onUpdate }: any) => {
  const TEXT_INPUTS = React.useMemo(() => {
    return [
      {
        name: 'Amount',
        width: '1fr',
        isRequired: true,
        element: (id: string, data: any) => {
          return (
            <>
              <AmountInput
                model={`${id}.value`}
                value={data.value}
                placeholder="Amount…"
                validations={{
                  presence: {
                    message: 'Please enter an amount',
                  },
                }}
              />
            </>
          )
        },
      },
    ]
  }, [])

  return <TableArrayForm name="Values" model={arrayModel} value={arrayValue} inputs={TEXT_INPUTS} onUpdate={onUpdate} />
}

const RadioCheckboxValues = (props: any) => {
  const { formElement, editElementConfig, activeElementId, activeElement } = props

  const handleUpdate = ({ value }) => {
    if (!value) return

    editElementConfig({
      uuid: activeElementId,
      config: { radio_checkbox_options: value },
    })
  }

  // get current options of the radio element
  const optionsMap = React.useMemo(() => {
    // handle checkbox conditional
    if (formElement?.category === 'checkbox_input') {
      return arrayToInternalMap(formElement?.config?.multi_input_values)
    }
    // handle radio conditional
    else {
      return arrayToInternalMap(formElement?.config?.single_select_input_values)
    }
  }, [formElement?.config?.single_select_input_values])

  // construct initial value based on the current options of the radio element
  const initialValue = React.useMemo(() => {
    const result: any = []
    const conditionValues = arrayToInternalMap(activeElement?.config?.radio_checkbox_options)

    if (size(optionsMap) === 0) return result

    for (const id in optionsMap) {
      const option = optionsMap[id]
      const conditionalValue = conditionValues[id]

      result.push({
        _id: option._id,
        is_active: isDefined(conditionalValue?.is_active) ? conditionalValue.is_active : false,
      })
    }

    return result
  }, [formElement, activeElement, optionsMap])

  if (size(initialValue) === 0) {
    return (
      <Alert glyph="warning" type="warning">
        The Single Select input does not have any options to choose from.
      </Alert>
    )
  }

  return (
    <DataArray value={initialValue} onUpdate={handleUpdate}>
      {({ orderedIds, elements }: any) => (
        <CheckboxGroup layout="vertical-dense">
          {orderedIds.map((id: string) => {
            const element = elements[id]
            const radioOption = optionsMap[id]

            if (!element || !radioOption) return null

            return (
              <React.Fragment key={id}>
                <Checkbox key={id} label={radioOption.label} model={`${id}.is_active`} />
                <Input isEditable={false} model={`${id}._id`} value={element._id} css={STYLES.hidden} />
              </React.Fragment>
            )
          })}
        </CheckboxGroup>
      )}
    </DataArray>
  )
}

const ObjectSelectorValues = (props: any) => {
  const { formElement, editElementConfig, activeElementId, activeElement } = props

  const handleUpdate = ({ value }) => {
    if (!value) return

    editElementConfig({
      uuid: activeElementId,
      config: { object_selector_options: value },
    })
  }

  // get current options of the radio element
  const optionsMap = React.useMemo(() => {
    return arrayToInternalMap(formElement?.config?.object_selector_options)
  }, [formElement?.config?.object_selector_options])

  // construct initial value based on the current options of the radio element
  const initialValue = React.useMemo(() => {
    const result: any = []
    const conditionValues = arrayToInternalMap(activeElement?.config?.object_selector_options)

    if (size(optionsMap) === 0) return result

    for (const id in optionsMap) {
      const option = optionsMap[id]
      const conditionalValue = conditionValues[id]

      result.push({
        _id: option._id,
        is_active: isDefined(conditionalValue?.is_active) ? conditionalValue.is_active : false,
      })
    }

    return result
  }, [formElement, activeElement, optionsMap])

  if (size(initialValue) === 0) {
    return (
      <Alert glyph="warning" type="warning">
        The Single-Item Dropdown Selector does not have any options to choose from.
      </Alert>
    )
  }

  return (
    <DataArray value={initialValue} onUpdate={handleUpdate}>
      {({ orderedIds, elements }: any) => (
        <CheckboxGroup layout="vertical-dense">
          {orderedIds.map((id: string) => {
            const element = elements[id]
            const option = optionsMap[id]

            if (!element || !option) return null

            return (
              <React.Fragment key={id}>
                <Checkbox key={id} label={option.name} model={`${id}.is_active`} />
                <Input isEditable={false} model={`${id}._id`} value={element._id} css={STYLES.hidden} />
              </React.Fragment>
            )
          })}
        </CheckboxGroup>
      )}
    </DataArray>
  )
}

const MultiObjectSelectorValues = (props: any) => {
  const { formElement, editElementConfig, activeElementId, activeElement } = props

  const handleUpdate = ({ value }) => {
    if (!value) return

    editElementConfig({
      uuid: activeElementId,
      config: { multi_object_selector_options: value },
    })
  }

  // get current options of the radio element
  const optionsMap = React.useMemo(() => {
    return arrayToInternalMap(formElement?.config?.multi_object_selector_options)
  }, [formElement?.config?.multi_object_selector_options])

  // construct initial value based on the current options of the radio element
  const initialValue = React.useMemo(() => {
    const result: any = []
    const conditionValues = arrayToInternalMap(activeElement?.config?.multi_object_selector_options)

    if (size(optionsMap) === 0) return result

    for (const id in optionsMap) {
      const option = optionsMap[id]
      const conditionalValue = conditionValues[id]

      result.push({
        _id: option._id,
        is_active: isDefined(conditionalValue?.is_active) ? conditionalValue.is_active : false,
      })
    }

    return result
  }, [formElement, activeElement, optionsMap])

  if (size(initialValue) === 0) {
    return (
      <Alert glyph="warning" type="warning">
        The Single-Item Dropdown Selector does not have any options to choose from.
      </Alert>
    )
  }

  return (
    <DataArray value={initialValue} onUpdate={handleUpdate}>
      {({ orderedIds, elements }: any) => (
        <CheckboxGroup layout="vertical-dense">
          {orderedIds.map((id: string) => {
            const element = elements[id]
            const option = optionsMap[id]

            if (!element || !option) return null

            return (
              <React.Fragment key={id}>
                <Checkbox key={id} label={option.name} model={`${id}.is_active`} />
                <Input isEditable={false} model={`${id}._id`} value={element._id} css={STYLES.hidden} />
              </React.Fragment>
            )
          })}
        </CheckboxGroup>
      )}
    </DataArray>
  )
}

const ARRAY_CONDITIONS = ['is_within', 'is_not_within']

const VALUES_CONFIG = {
  text: {
    component: TextValue,
    conditions: ['is', 'is_not', 'is_within', 'is_not_within'],
  },
  number: {
    component: NumberValue,
    conditions: ['is', 'is_not', 'is_greater_than', 'is_less_than', 'is_within', 'is_not_within'],
  },
  amount: {
    component: AmountValue,
    conditions: ['is', 'is_not', 'is_greater_than', 'is_less_than', 'is_within', 'is_not_within'],
  },
  radio: {
    component: RadioCheckboxValues,
    conditions: ['is_within', 'is_not_within'],
  },
  checkbox: {
    component: RadioCheckboxValues,
    conditions: ['is_some_selected', 'is_some_not_selected', 'is_all_selected', 'is_all_not_selected'],
  },
  object_selector: {
    component: ObjectSelectorValues,
    conditions: ['is_within', 'is_not_within'],
  },
  multi_object_selector: {
    component: MultiObjectSelectorValues,
    conditions: ['is_within', 'is_not_within'],
  },
}

const CONDITIONS_CONFIG = {
  // generic conditions:
  is: 'Is',
  is_not: 'Is not',
  is_within: 'Is any of',
  is_within_exact: 'Is all of',
  is_not_within: 'Is none of',
  is_greater_than: 'Is greater than',
  is_less_than: 'Is less than',

  // checkbox conditions:
  is_some_selected: 'Selected any of',
  is_some_not_selected: 'Did not select any of',
  is_all_selected: 'Selected all of',
  is_all_not_selected: 'Did not select all of',
}

const STYLES = {
  inputLink: {
    marginLeft: 'auto',
    fontSize: '0.9rem',
    color: COLORS.blue,
    fontWeight: 500,
    cursor: 'pointer',
  },

  hidden: {
    display: 'none !important',
  },
}

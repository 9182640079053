import React from 'react'
import size from 'lodash/size'
import omit from 'lodash/omit'
import { v4 as uuid } from 'uuid'
import { useRouteMatch, useLocation } from 'react-router-dom'

import { countWord, DT } from '../../utils/functions'
import { useSimpleOverlay } from '../../hooks/useSimpleOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Alert from '../../components/Alert'
import Button from '../../components/Button'
import ContextShow from '../../components/Forms/ContextShow'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Overlay from '../../components/Overlay'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'

function compare(dateA: any, dateB: any) {
  if (!dateA.record_started_at) return 1
  if (!dateB.record_started_at) return 1

  if (DT(dateA.record_started_at) < DT(dateB.record_started_at)) return -1
  if (DT(dateA.record_started_at) > DT(dateB.record_started_at)) return 1

  return 0
}

const findAmountInFeeSchedule = (feeSchedule: any, code_id: string) => {
  if (!feeSchedule) return 0
  if (!feeSchedule.insurance_new_fee_schedule_services) return 0

  for (let i = 0; i < feeSchedule.insurance_new_fee_schedule_services.length; i++) {
    if (feeSchedule.insurance_new_fee_schedule_services[i].insurance_new_code.id === code_id) {
      return feeSchedule.insurance_new_fee_schedule_services[i].price
    }
  }

  return 0
}

const RootRosterClaimBuilderOverlay = (props: any) => {
  const { form, isInvalid, onValidationUpdate } = useSimpleOverlay({})
  const location = useLocation()

  const { selectedRosterItems } = props
  const { timezone } = useSettings()

  const [claimTemplate, setClaimTemplate] = React.useState<any | null>(null)

  const batchData = React.useMemo(() => {
    const result: any = []
    const rosterItemsByClient: any = {}

    // split selected roster items by client
    for (const rosterItem of selectedRosterItems) {
      const clientId = rosterItem?.client?.id

      if (!clientId) continue

      if (!rosterItemsByClient.hasOwnProperty(clientId)) {
        rosterItemsByClient[clientId] = []
      }

      rosterItemsByClient[clientId].push(rosterItem)
    }

    // process roster items for each client
    for (const clientId in rosterItemsByClient) {
      const clientRosterItems = rosterItemsByClient[clientId]
      const client = clientRosterItems[0]?.client

      let finalServiceLines = []
      let templateServiceLines = claimTemplate?.insurance_claim_template_service_lines?.map((o: any) => omit(o, ['id'])) || []

      // manually set the insurance_new_code_id's if found
      templateServiceLines = templateServiceLines?.map((o: any) => ({ ...o, insurance_new_code_id: o.insurance_new_code?.id }))

      const orderedRosterItems = clientRosterItems.sort(compare)
      const clientRosterItemsLength = orderedRosterItems.length
      const templateRosterItemsLength = templateServiceLines.length

      if (clientRosterItemsLength <= templateRosterItemsLength) {
        for (let i = 0; i < templateRosterItemsLength; i++) {
          const element = templateServiceLines[i]
          if (!element) continue

          const hasSelectedRostersLeft = i < clientRosterItemsLength

          const price = findAmountInFeeSchedule(
            orderedRosterItems[i]?.record?.insurance_new_fee_schedule,
            orderedRosterItems[i]?.record?.insurance_new_code?.id,
          )

          finalServiceLines.push({
            ...element,
            ...(hasSelectedRostersLeft && {
              insurance_roster_item_id: orderedRosterItems[i]?.id,
              service_date_start: orderedRosterItems[i]?.record_started_at,
              service_date_end: orderedRosterItems[i]?.record_ended_at,
              ...(orderedRosterItems[i]?.record?.is_billable && {
                // insurance_new_code: orderedRosterItems[i]?.record?.insurance_new_code,
                // insurance_new_code_id: orderedRosterItems[i]?.record?.insurance_new_code.id,
                // modifier_codes: orderedRosterItems[i]?.record?.insurance_new_code.modifier_codes,
                units: 1,
                unit_price: price,
              }),
            }),
          })
        }
      } else {
        for (let i = 0; i < clientRosterItemsLength; i++) {
          const element = orderedRosterItems[i]
          if (!element) continue

          const hasTemplateRostersLeft = i < templateRosterItemsLength

          const price = findAmountInFeeSchedule(element.record?.insurance_new_fee_schedule, element.record?.insurance_new_code?.id)

          finalServiceLines.push({
            ...(hasTemplateRostersLeft && templateServiceLines[i]),
            insurance_roster_item_id: element.id,
            service_date_start: element.record_started_at,
            service_date_end: element.record_ended_at,
            ...(element?.record?.is_billable && {
              // insurance_new_code: element?.record?.insurance_new_code,
              // insurance_new_code_id: element?.record?.insurance_new_code.id,
              // modifier_codes: element?.record?.insurance_new_code.modifier_codes,
              units: 1,
              unit_price: price,
            }),
          })
        }
      }

      result.push({
        _id: uuid(),
        resident: client,
        insurance_new_fee_schedule: clientRosterItems?.[0]?.record?.insurance_new_fee_schedule,
        insurance_claim_service_lines: finalServiceLines,

        ...(claimTemplate && {
          category: claimTemplate.category,
          // claim data
          assignee: claimTemplate.assignee,
          insurance_local_payer: claimTemplate.insurance_local_payer,
          insurance_new_fee_schedule: claimTemplate.insurance_new_fee_schedule,
          claim_filing_code: claimTemplate.claim_filing_code,
          claim_frequency_code: claimTemplate.claim_frequency_code,
          place_of_service_code: claimTemplate.place_of_service_code,
          bill_type: claimTemplate.bill_type,
          admission_type_code: claimTemplate.admission_type_code,
          admission_source_code: claimTemplate.admission_source_code,
          condition_codes: claimTemplate.condition_codes,
          patient_status_code: claimTemplate.patient_status_code,
          // providers
          facility_provider: claimTemplate.facility_provider,
          billing_provider: claimTemplate.billing_provider,
          rendering_provider: claimTemplate.rendering_provider,
          supervising_provider: claimTemplate.supervising_provider,
          ordering_provider: claimTemplate.ordering_provider,
          referring_provider: claimTemplate.referring_provider,
          attending_provider: claimTemplate.attending_provider,
        }),
      })
    }

    return result
  }, [selectedRosterItems, claimTemplate])

  if (size(selectedRosterItems) === 0) {
    throw new Error('Select one or more roster items to create claims.')
  }

  return (
    <Overlay showBackdrop closeOnBackdrop onClose={props.onClose} position="center">
      <Overlay.Header icon="accounting" title="Create Claims From Roster Items" />

      <Overlay.Content>
        <Form isEditable getForm={form} timezone={timezone} onValidationUpdate={onValidationUpdate}>
          <Section>
            <FormSection maxWidth="100%">
              <Alert contrast type="default" glyph="info">
                Create claim with the selected {countWord('roster items', size(selectedRosterItems))}.
              </Alert>

              <RadioGroup defaultValue="template" layout="horizontal" model="starting_point">
                <Radio label="Start From Template" value="template" />
                <Radio label="Create Blank Claim" value="blank" />
              </RadioGroup>

              <ContextShow when="starting_point" is="template">
                <ObjectSelector
                  icon="accounting"
                  label="Claim Template"
                  model="insurance_claim_template"
                  type="insurance.insurance_claim_templates"
                  selectTitle={(data: any) => data.name}
                  validations={{
                    presence: {
                      message: 'Please select a Claim Template',
                    },
                  }}
                  onUpdate={(state: any) => setClaimTemplate(state.value)}
                />
              </ContextShow>
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label="Create Claims"
          glyph="check"
          type="primary"
          color="blue"
          isDisabled={isInvalid}
          flex="100 1 auto"
          link={{
            pathname: `${location.pathname}/claim/new/form`,
            parent: location.pathname,
            data: {
              batchData,
            },
          }}
          onClick={props.onClose}
        />
      </Overlay.Footer>
    </Overlay>
  )
}

export const RosterClaimBuilderOverlay = withOverlayError(RootRosterClaimBuilderOverlay)

import React from 'react'

import Form from '../../../components/Forms/Form'
import FormSection from '../../../components/Forms/FormSection'
import Radio from '../../../components/Forms/Radio'
import RadioGroup from '../../../components/Forms/RadioGroup'
import Textarea from '../../../components/Forms/Textarea'

const Accept = ({ data, setData, setStatus }: any) => {
  const form = React.useRef()
  const [valid, setValid] = React.useState()

  React.useEffect(() => {
    if (valid) setStatus?.('completed')
    else setStatus?.('todo')
  }, [valid])

  return (
    <Form getForm={form} initialModel={data} onValidationUpdate={setValid} onUpdate={setData}>
      <FormSection maxWidth="100%">
        <RadioGroup
          label="Treatment Episode Admission Status"
          model="status"
          layout="vertical-dense"
          defaultValue="pending"
          validations={{
            presence: {
              message: 'Please select an admission status',
            },
          }}
        >
          <Radio label="Pending" description="No decision to be made yet" value="pending" />
          <Radio label="Incoming" description="Client is expected to move in soon" value="incoming" />
          <Radio label="Waitlist" description="Client is approved but there are no vacant beds" value="waitlist" />
          <Radio label="Planned" description="Client is approved but unable to admit yet" value="planned" />
        </RadioGroup>

        <Textarea useQuickText label="Admission Status Notes" model="notes" />
      </FormSection>
    </Form>
  )
}

export default Accept

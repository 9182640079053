import React from 'react'
import startCase from 'lodash/startCase'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import { FILTERS } from '../Filters/config'

import Status from '@behavehealth/components/Status'

export const ClientContactsDataTable = (props: any) => {
  const { to } = props

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        model: 'name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => <MainCell id={data.id} value={data.name} avatar={data.avatar} to={to?.(data)} />,
      },
      {
        width: 130,
        model: 'relationship',
        title: 'Relationship',
        type: 'title',
      },
      {
        width: 130,
        model: 'dob',
        title: 'Date of Birth',
        type: 'date',
      },
      {
        width: 100,
        model: 'sex',
        title: 'Sex',
        formatValue: ({ data, value }: any) => startCase(value),
      },
      {
        width: 160,
        model: 'phone_no',
        title: 'Phone',
        type: 'phone',
      },
      {
        width: 240,
        model: 'email',
        title: 'Email',
        type: 'email',
      },
      {
        width: 160,
        model: 'notes',
        title: 'Notes',
      },
    ]
  }, [to])

  return <DataTable asCard title="Client Contacts" icon="contacts" columns={columns} filtersConfig={FILTERS.client_contacts} {...props} />
}

import React from 'react'

import { usDateTime } from '../../utils/functions'
import { useSettings } from '../../hooks/useSettings'

import Status from '../../components/Status'
import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import { FILTERS } from '../Filters/config'
import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'

export const UATestsReportDataTable = (props: any) => {
  const { to } = props

  const { timezone, user } = useSettings()

  const isBehave = user?.type === 'bh_employee'

  const billingColumn = (timezone: string) => ({
    width: 140,
    id: 'billed_at',
    model: 'data.billed_at',
    title: 'Billed',
    type: 'date_time',
    disableSort: false,
  })

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Date & Time',
        id: 'collected_at',
        model: 'data.collected_at',
        width: 260,
        disableHide: true,
        formatValue: ({ data, value }: any) => <MainCell id={data.data.id} value={usDateTime(value, timezone)} to={to?.(data.data)} />,
      },
      {
        title: 'Client',
        id: 'reference',
        model: 'data.reference.name',
        type: 'profile',
      },
      {
        title: 'Current Location',
        id: 'current_location',
        model: 'data.current_location.name',
        type: 'profile',
      },
      ...(isBehave ? [billingColumn(timezone)] : []),
      {
        width: 180,
        id: 'status',
        model: 'data.status',
        title: 'Status',
        formatValue: ({ data, value }) => {
          if (value === 'refused') return <Status color="red" label="Refused Collection" />
          if (value === 'collected') return <Status color="blue" label="Collected" />

          // NOTE @Andrei: Implement NotApplicable component
          return 'N/A'
        },
      },
      {
        width: 130,
        id: 'result',
        model: 'data.result',
        title: 'Result',
        formatValue: ({ data, value }) => {
          if (value === 'positive') return <Status color="red" label="Positive" />
          if (value === 'negative') return <Status color="green" label="Negative" />
          if (value === 'not_available') return <Status color="grey" label="N/A" />
          return '–'
        },
      },
      {
        width: 180,
        id: 'positive_results_count',
        model: 'data.positive_results_count',
        title: 'Positive Results',
        formatValue: ({ value }: any) => `${value || 0} Positive Results` || '–',
      },
      {
        width: 180,
        id: 'other_positive_results',
        model: 'data.other_positive_results',
        title: 'Other Positive Results',
      },
      {
        width: 240,
        id: 'positive_due_medication',
        model: 'data.positive_due_medication',
        title: 'Positive due to prescription',
        type: 'boolean',
      },
      {
        width: 180,
        id: 'additional_info',
        model: 'data.additional_info',
        title: 'Note',
      },
      {
        width: 180,
        id: 'author',
        model: 'data.author.name',
        title: 'Added By',
        type: 'profile',
      },
    ]
  }, [to, timezone])

  return (
    <DataTable
      asCard
      title="UA Tests"
      icon="test_results"
      columns={columns}
      filtersConfig={FILTERS.live_reports.ua_tests}
      {...props}
      headerAfter={
        <ReportRefetchButton
          forceShow={true}
          category="ua_tests"
          invalidate={props.queryKey}
          refetchUrl={'/live_reports/update_live_report?category=ua_tests'}
        />
      }
    />
  )
}

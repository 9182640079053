import React from 'react'

import { withFormContext } from '../../../components/Forms/context'

import DataList from '../../../components/DataList'
import OverlaySelector from '../../../components/Forms/Selectors/OverlaySelector/OverlaySelector'

import { SectionCard } from '../../../components/SectionCard'

const ClaimDependent = (props: any) => {
  const { className, client, onClientUpdate } = props

  return (
    <SectionCard title="Client" className={className}>
      <div className="grid">
        <OverlaySelector
          isEditable={props?.disableEdit ? false : undefined}
          label="Client"
          type="insurance.billable_clients"
          model="resident"
          icon="clients"
          value={client}
          onUpdate={onClientUpdate}
          validations={{
            presence: {
              message: 'Please select a client',
            },
          }}
        />

        <DataList layout="horizontal" isCompact>
          <DataList.Item label="First Name" value={client?.first_name} />
          <DataList.Item label="Middle Name" value={client?.middle_name} />
          <DataList.Item label="Last Name" value={client?.last_name} />
          <DataList.Item label="Control Number" value={client?.behave_identifier} />
        </DataList>
      </div>
    </SectionCard>
  )
}

export default withFormContext(ClaimDependent)

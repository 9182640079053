import React from 'react'
import { v4 as uuid } from 'uuid'
import produce from 'immer'

const DEBUG = false

import { validate as validateField } from '../validators'
import { isDefined } from '../../../utils/functions'

const asObject = (args: any) => {
  if (args.isRelation) return true
  if (args.isRelations) return true
  if (args.isPolymorphic) return true

  return false
}

export const useFormField = (args: any) => {
  const {
    debug,
    model,
    form,
    isValid,
    isRelation = false,
    isRelations = false,
    isPolymorphic = false,
    isNested = false,
    includeObject = false,
    validations,
    afterMount,
    afterChange,
    afterReset,
    afterValidation,
    onUpdate,
    defaultValue,
    validateOn = 'blur-change',
    initialValue,
    resetData,
    initValueWith,
    defaultEmptyValue = null,
    skipDeregister = false,
  } = args

  const [registered, setRegistered] = React.useState(false)
  const shouldUpdateFormEngine = React.useRef(isDefined(form) && isDefined(model))
  const eventsQueue = React.useRef([])
  const processedEventsQueue = React.useRef([])

  const afterMountCallback = (state: any) => {
    if (onUpdate) onUpdate(state)
  }

  const afterChangeCallback = (state: any) => {
    if (afterChange) afterChange(state)
  }

  const afterResetCallback = (state: any) => {
    if (afterReset) afterReset(state)
  }

  const afterValidationCallback = (state: any) => {
    if (afterValidation) afterValidation(state)
  }

  // build initial value
  const initValue = React.useRef(initialValue || form?.getInitialInputFieldValue(model) || defaultEmptyValue)

  const [state, setState] = React.useState({
    id: `${model}-${uuid()}`,
    model: model,
    ...(asObject(args) && { object: initValue.current }),
    value: initValue.current,
    isRelation: isRelation,
    isRelations: isRelations,
    isPolymorphic: isPolymorphic,
    includeObject: includeObject,
    isNested: isNested,
    isValid: isDefined(isValid) ? isValid : false,
    isInvalid: isDefined(isValid) ? !isValid : true,
    isPristine: true,
    isDirty: false,
    isTouched: false,
    isUntouched: true,
    isBlur: false,
    isValidations: validations,
    isRequired: validations?.hasOwnProperty('presence'),
    errors: [],
    reset: () => queue({ type: 'RESET' }),
    validate: () => queue({ type: 'VALIDATE' }),
  })

  // REGISTER WHEN FORM FOUND
  React.useEffect(() => {
    if (registered) return
    if (!shouldUpdateFormEngine.current) return

    queue({ type: 'REGISTRATION' })

    afterMount ? afterMount(state) : afterMountCallback(state)
  }, [shouldUpdateFormEngine.current])

  // CLEANUP ON UNMOUNT
  React.useEffect(() => {
    return () => {
      if (shouldUpdateFormEngine.current && !skipDeregister) form.deregister(state)
    }
  }, [])

  const queue = (data: any) => {
    eventsQueue.current.push(data)
    if (DEBUG || debug) console.debug(`👉 ${data.type} `, { event: data.event })

    process()
  }

  const processChangeValue = (value: any) => {
    const newState = produce(state, (draft: any) => {
      draft.value = value
      if (draft.hasOwnProperty('object')) draft.object = value
      draft.isPristine = false
      draft.isDirty = true
    })

    setState((prevState: any) => ({
      ...prevState,
      value: value,
      ...(prevState.hasOwnProperty('object') && { object: value }),
      isPristine: false,
      isDirty: true,
    }))

    return newState
  }

  const processFocus = (_queueEvent: any) => {
    const newState = produce(state, (draft: any) => {
      draft.isTouched = true
      draft.isUntouched = true
    })

    setState((prevState: any) => ({
      ...prevState,
      isTouched: true,
      isUntouched: true,
    }))

    return newState
  }

  const processBlur = (_queueEvent: any) => {
    const newState = produce(state, (draft: any) => {
      draft.isBlur = true
    })

    setState((prevState: any) => ({
      ...prevState,
      isBlur: true,
    }))

    return newState
  }

  const processValidation = (queueEvent: any) => {
    const errors = validateField(queueEvent.value, validations)

    const newState = produce(state, (draft: any) => {
      draft.isValid = errors.length ? false : true
      draft.isInvalid = errors.length ? true : false
      draft.errors = errors
    })

    // update local state
    setState((prevState: any) => ({
      ...prevState,
      isValid: errors.length ? false : true,
      isInvalid: errors.length ? true : false,
      errors: errors,
    }))

    return newState
  }

  const processReset = (_queueEvent: any) => {
    // check if the input has any initial data defined by itself or not
    const hasInitialData = isDefined(resetData)

    const newState = produce(state, (draft: any) => {
      // if input has initial data use that / otherwise use the calculated value
      if (hasInitialData) {
        // update each draft key based on keys found in the initial data
        Object.keys(resetData).map((key: string) => {
          draft[key] = resetData[key]
        })

        draft.isPristine = true
        draft.isDirty = false
      } else {
        let value = args.value
        if (!value) {
          const initialModelVal = form?.getInitialInputFieldValue(model)

          if (isDefined(initialModelVal)) value = initialModelVal
          else if (isDefined(defaultValue)) value = defaultValue
          else value = ''
        }

        draft.value = value
        draft.isPristine = true
        draft.isDirty = false
      }
    })

    // if input has initial data use that / otherwise use the calculated value
    if (hasInitialData) {
      setState((prevState: any) => ({
        ...prevState,
        ...resetData,
        isPristine: newState.isPristine,
        isDirty: newState.isDirty,
      }))
    } else {
      setState((prevState: any) => ({
        ...prevState,
        value: newState.value,
        isPristine: newState.isPristine,
        isDirty: newState.isDirty,
      }))
    }

    return newState
  }

  // * Process
  const process = () => {
    if (eventsQueue.current.length <= 0) return

    let t0 = performance.now()

    // process normal queue
    let event: any = eventsQueue.current.shift()

    // process
    switch (event.type) {
      case 'REGISTRATION':
        // update Form Engine
        if (shouldUpdateFormEngine.current) form.register(state)
        if (initValueWith) initValueWith(state.value)

        // run validation if we should validate on registration
        if (validateOn?.includes('registration')) queue({ type: 'SET_VALIDITY', value: state.isValid })

        // update registration state
        setRegistered(true)

        break

      case 'CHANGE_VALUE':
        const changeValueState = processChangeValue(event.value)

        // run validation if we should validate on blur
        if (validateOn?.includes('change')) queue({ type: 'VALIDATE', value: changeValueState.value })

        // update Form Engine
        if (shouldUpdateFormEngine.current) form.change(changeValueState)

        afterChangeCallback(changeValueState)

        break

      case 'CHANGE':
        const changeState = processChangeValue(event)

        // run validation if we should validate on blur
        if (validateOn?.includes('change')) queue({ type: 'VALIDATE', value: changeState.value })

        // update Form Engine
        if (shouldUpdateFormEngine.current) form.change(changeState)

        afterChangeCallback(changeState)

        break

      case 'FOCUS':
        const focusState = processFocus(event)

        // run validation if we should validate on blur
        if (validateOn?.includes('focus')) queue({ type: 'VALIDATE', value: focusState.value })

        // update Form Engine
        if (shouldUpdateFormEngine.current) form.focus(focusState)

        break

      case 'BLUR':
        const blurState = processBlur(event)

        // run validation if we should validate on blur
        if (validateOn?.includes('blur')) queue({ type: 'VALIDATE', value: blurState.value })

        // update Form Engine
        if (shouldUpdateFormEngine.current) form.blur(blurState)

        break

      case 'VALIDATE':
        const validationState = processValidation(event)

        // update Form Engine
        if (shouldUpdateFormEngine.current) form.validate(validationState)

        afterValidationCallback(validationState)

        break
      case 'SET_VALIDITY':
        const newValidityState = produce(state, (draft: any) => {
          draft.isValid = event.value
          draft.isInvalid = !event.value
          draft.errors = event.errors || []
        })

        // update local state
        setState((prevState: any) => ({
          ...prevState,
          isValid: newValidityState.isValid,
          isInvalid: newValidityState.isInvalid,
          errors: newValidityState.errors,
        }))

        // update Form Engine
        if (shouldUpdateFormEngine.current) form.validate(newValidityState)

        break

      case 'RESET':
        const resetState = processReset(event)

        // run validation if we should validate on blur
        queue({ type: 'VALIDATE', value: resetState.value })

        // update Form Engine
        if (shouldUpdateFormEngine.current) form.reset(resetState)

        afterResetCallback(resetState)

        break
    }

    processedEventsQueue.current.push(event)

    if (DEBUG || debug) console.debug(`✅ ${event.type} `, { event, state: state, props: args })

    let t1 = performance.now()
    if (DEBUG || debug) console.debug(`${event.type} took ` + (t1 - t0) + ' milliseconds.')

    // go recursively through it until there is nothing to process
    process()
  }

  // EVENT BASED CHANGES
  const onFocus = (event: any) => queue({ type: 'FOCUS', event })
  const onBlur = (event: any) => queue({ type: 'BLUR', event })
  const onChange = (event: any) => queue({ type: 'CHANGE', event })

  // ACTION BASED CHANGES
  const reset = () => queue({ type: 'RESET' })
  const validate = () => queue({ type: 'VALIDATE' })

  // SETTERS
  const setValue = (value: any) => queue({ type: 'CHANGE_VALUE', value })
  const setValidity = (value: any, errors = []) => queue({ type: 'SET_VALIDITY', value, errors })

  return {
    form: form,
    formState: state,
    initialValue: initValue.current,

    // events
    onFocus,
    onBlur,
    onChange,

    // actions
    formActions: {
      reset,
      validate,
      setValue,
      setValidity,
    },
  }
}

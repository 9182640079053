import React from 'react'
import clsx from 'clsx'
import { tint, transparentize } from 'polished'

import { useUpdate } from '../../hooks/useNewAPI'
import { COLORS, INPUT_STYLES, SHADOW } from '../../theme'

import Status from '../../components/Status'
import RadioCheckElement from '../../components/Forms/RadioCheckElement'
import Loader from '../../components/Loader'

export const QuoteLineActions = (props: any) => {
  const { isPublic, quote, quoteLine, canEdit } = props

  const [isSelected, setIsSelected] = React.useState(quoteLine?.is_selected)

  const { mutateAsync, isLoading } = useUpdate({
    name: ['quote_lines', 'delete_quote_lines'],
    url: isPublic ? `/apps/quotes/${quote?.external_id}/quote_lines/${quoteLine?.id}` : `/quote_lines/${quoteLine?.id}`,
    invalidate: 'quotes',
  })

  const handleSubscribe = async () => {
    try {
      await mutateAsync({
        is_selected: true,
      })

      setIsSelected(true)
    } catch (error) {
      console.error(error)
    }
  }

  const handleUnsubscribe = async () => {
    try {
      await mutateAsync({
        is_selected: false,
      })

      setIsSelected(false)
    } catch (error) {
      console.error(error)
    }
  }

  if (!quoteLine) return null

  const isRequired = !!quoteLine?.product?.is_required

  if (isRequired) {
    return <Status label="Selected" color={COLORS.green} className="!justify-center !text-center !min-w-[120px] mr-3" />
  }

  if (!canEdit) {
    return isSelected ? (
      <Status label="Selected" color={COLORS.green} className="!justify-center !text-center !min-w-[120px]" />
    ) : (
      <Status label="Not Selected" color={COLORS.red} className="!justify-center !text-center !min-w-[120px]" />
    )
  }

  return (
    <AddonCheckbox
      isSelected={isSelected}
      isLoading={isLoading}
      onClick={() => {
        isSelected ? handleUnsubscribe() : handleSubscribe()
      }}
    />
  )
}

const AddonCheckbox = ({ isSelected, isLoading, onClick }) => {
  return (
    <div
      css={STYLES.checkbox}
      className={clsx('!px-2.5 !py-2 !min-h-0 !h-auto !w-auto !min-w-[160px] flex items-center font-[500]', isSelected && 'is-checked')}
      onClick={onClick}
    >
      <div className="flex-[0_0_auto] mr-1.5">
        {isLoading ? <Loader color={COLORS.green} size={18} /> : <RadioCheckElement isChecked={isSelected} type="checkbox" />}
      </div>
      <div>{isSelected ? 'Add-On Selected' : 'Select Add-On'}</div>
    </div>
  )
}

const STYLES = {
  checkbox: {
    display: 'flex',
    alignItems: 'center',
    ...INPUT_STYLES,

    padding: 0,
    width: 'auto',
    cursor: 'pointer',

    '&:hover': {
      ...INPUT_STYLES['&:hover'],
      boxShadow: SHADOW(3),
      zIndex: 2,

      '& .check-element': {
        borderColor: tint(0.2, COLORS.green),
        boxShadow: `0 0 0 1px ${tint(0.85, COLORS.green)}, ${SHADOW(1)}`,
      },
    },

    '&.is-checked': {
      background: tint(0.96, COLORS.green),
      borderColor: `${transparentize(0.5, COLORS.green)} !important`,

      zIndex: 1,
      // position: 'relative',

      '&:hover': {
        boxShadow: SHADOW(2, transparentize(0.95, COLORS.green)),

        '.check-element': {
          borderColor: COLORS.green,
        },
      },
    },
  },
}
